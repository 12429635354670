import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ListingService } from 'src/app/_services';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  LiveListingRequest,
  LiveListings,
  OwnedListing,
  Sorting,
  SORTING_OPTIONS,
} from './properties-landing-page.util';
import { ReloadComponent } from 'src/shared/reload/reload.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  LISTING_TYPES,
  SelectObject,
} from '../create-property/add-property-data/add-property-data.util';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Options, LabelType } from '@angular-slider/ngx-slider';
import { Title } from '@angular/platform-browser';
import { ReplaySubject, Subject, take, takeUntil } from 'rxjs';

@Component({
  selector: 'properties-landing-page',
  templateUrl: './properties-landing-page.component.html',
  styleUrls: ['./properties-landing-page.component.scss'],
})
export class PropertiesLandingPageComponent
  extends ReloadComponent
  implements OnInit, OnDestroy
{
  loadingProperties: boolean = false;
  currentTab: string = 'Sælg ejendomme';
  screen_wmin_1380;

  OwnedListings: OwnedListing[] = [];

  favoriteListings: OwnedListing[] = [];
  liveListings: LiveListings[] = [];
  url: any;

  sortOptions = JSON.parse(JSON.stringify(SORTING_OPTIONS));
  selectedSortOption: Sorting = this.sortOptions[1].value;

  filterPanelOpened: boolean = false;
  totalFilters: number = 0;
  previousAppliedFilters: LiveListingRequest = {};
  filtersmodified: boolean = false;
  _listingTypes = JSON.parse(JSON.stringify(LISTING_TYPES));
  _postalcodes: SelectObject[] = [];
  filteredPostalcodes: ReplaySubject<SelectObject[]> = new ReplaySubject<
    SelectObject[]
  >(1);
  postalcodesFilter: FormControl = new FormControl('');
  protected _onDestroy = new Subject<void>();
  filterForm: FormGroup;
  filterPriceOptions: Options = {
    floor: 0,
    ceil: 10000000 + 100000, // ceil + step
    step: 100000,
    // minRange: 100000,
    hidePointerLabels: true,
    hideLimitLabels: true,
    // noSwitching: true,
    getPointerColor: (value: number): string => '#24535B',
    getSelectionBarColor: (value: number): string => '#24535B',
  };
  filterAreaOptions: Options = {
    floor: 0,
    ceil: 10000 + 100, // ceil + step
    step: 100,
    // minRange: 100,
    hidePointerLabels: true,
    hideLimitLabels: true,
    // noSwitching: true,
    getPointerColor: (value: number): string => '#24535B',
    getSelectionBarColor: (value: number): string => '#24535B',
  };
  filterGroundAreaOptions: Options = {
    floor: 0,
    ceil: 10000 + 100, // ceil + step
    step: 100,
    // minRange: 100,
    hidePointerLabels: true,
    hideLimitLabels: true,
    // noSwitching: true,
    getPointerColor: (value: number): string => '#24535B',
    getSelectionBarColor: (value: number): string => '#24535B',
  };
  filterYearOptions: Options = {
    floor: 1800,
    ceil: new Date().getFullYear(),
    step: 1,
    // minRange: 1,
    hidePointerLabels: true,
    hideLimitLabels: true,
    // noSwitching: true,
    getPointerColor: (value: number): string => '#24535B',
    getSelectionBarColor: (value: number): string => '#24535B',
  };

  constructor(
    private spinner: NgxSpinnerService,
    public override router: Router,
    private listService: ListingService,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    private titleService: Title
  ) {
    super(router);
    this.titleService.setTitle('Ejos - Sælg Ejendomme');
    sessionStorage.removeItem('listing');

    // this.url = '/assets/home/listing/no-picture.svg';
    this.url = '/assets/home/listing/test-image.png';

    this.filterForm = this.fb.group({
      postalcodes: new FormControl([]),
      listingTypes: new FormControl([]),
      priceSlider: new FormControl([
        this.filterPriceOptions.floor,
        this.filterPriceOptions.ceil,
      ]),
      areaSlider: new FormControl([
        this.filterAreaOptions.floor,
        this.filterAreaOptions.ceil,
      ]),
      groundAreaSlider: new FormControl([
        this.filterGroundAreaOptions.floor,
        this.filterGroundAreaOptions.ceil,
      ]),
      yearSlider: new FormControl([
        this.filterYearOptions.floor,
        this.filterYearOptions.ceil,
      ]),
    });
  }

  ngOnInit(): void {
    this.getOwnedListings();
    this.OnFilterFormValueChanges();
    this.onPostalcodeSearchValueChanges();
    this.onResizeWindow();
    this.initializeFilters();
  }

  tabChanged(event) {
    this.currentTab = event.tab.textLabel;
    event.tab.textLabel === 'Sælg Ejendomme'
      ? (this.getOwnedListings(),
        this.titleService.setTitle('Ejos - Sælg Ejendomme'))
      : event.tab.textLabel === 'Alle Ejendomme'
      ? (this.titleService.setTitle('Ejos - Alle Ejendomme'),
        this.getLiveListings(),
        this.getAllPostalcodes())
      : event.tab.textLabel === 'Foretrukne Ejendomme'
      ? (this.titleService.setTitle('Ejos - Foretrukne Ejendomme'),
        this.getFavoriteListings('favoritesTab'))
      : '';
  }

  createProperty() {
    sessionStorage.removeItem('listing');
    this.router.navigate(['/home/properties/create-property']);
  }

  deleteListing(listingId: string) {
    this.listService.deleteListingByListingId(listingId).subscribe({
      next: (res) => {
        this.reloadComponent(true);
        this._snackBar.open('Ejendom slettet', 'Dismiss', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      },
      error: (err) => {
        console.log(err);
        this._snackBar.open('Fejl', 'Dismiss', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      },
    });
  }

  editListing(listingId: string, todo: string) {
    this.spinner.show();
    this.loadingProperties = true;
    this.listService.getListingByListingId(listingId).subscribe({
      next: (res) => {
        todo === 'edit'
          ? (res.lastExecutedStep = 1)
          : (res.lastExecutedStep += 1);
        sessionStorage.setItem('listing', JSON.stringify(res));
        this.reloadComponent(false, '/home/properties/create-property');
        this.loadingProperties = false;
        this.spinner.hide();
      },
      error: (err) => {
        console.log(err);
        this.loadingProperties = false;
        this.spinner.hide();
      },
    });
  }

  markListingAsSold(listingId: string) {
    console.log(' markListingAsSoldCalled');
  }
  viewListing(listingId: string) {
    this.spinner.show();
    this.loadingProperties = true;
    this.listService.getListingByListingId(listingId).subscribe({
      next: (res) => {
        sessionStorage.setItem('listingView', JSON.stringify(res));
        this.router.navigateByUrl('/home/properties/view-property');
        this.loadingProperties = false;
        this.spinner.hide();
      },
      error: (err) => {
        console.log(err);
        this.loadingProperties = false;
        this.spinner.hide();
      },
    });
  }

  getOwnedListings() {
    const userId = JSON.parse(localStorage.getItem('user'))?.id;
    if (userId) {
      this.spinner.show();
      this.loadingProperties = true;
      this.listService.getOwnedListingsById(userId).subscribe({
        next: (res) => {
          this.OwnedListings = res?.ownedListings;
          this.loadingProperties = false;
          this.spinner.hide();
        },
        error: (err) => {
          console.log(err);
          this.loadingProperties = false;
          this.spinner.hide();
        },
      });
    }
  }

  getLiveListings() {
    this.spinner.show();
    this.loadingProperties = true;

    let appliedFilters: LiveListingRequest = {};

    if (this.postalcodes?.value?.length)
      appliedFilters.postalCode = this.postalcodes?.value;
    if (this.listingTypes?.value?.length)
      appliedFilters.typeOfListings = this.listingTypes?.value;

    if (this.priceSlider?.value[0] != this.filterPriceOptions.floor)
      appliedFilters.minPrice = this.priceSlider?.value[0];
    if (this.priceSlider?.value[1] != this.filterPriceOptions.ceil)
      appliedFilters.maxPrice = this.priceSlider?.value[1];

    if (this.areaSlider?.value[0] != this.filterAreaOptions.floor)
      appliedFilters.minTotalUnitArea = this.areaSlider?.value[0];
    if (this.areaSlider?.value[1] != this.filterAreaOptions.ceil)
      appliedFilters.maxTotalUnitArea = this.areaSlider?.value[1];

    if (this.groundAreaSlider?.value[0] != this.filterGroundAreaOptions.floor)
      appliedFilters.minTotalLandArea = this.groundAreaSlider?.value[0];
    if (this.groundAreaSlider?.value[1] != this.filterGroundAreaOptions.ceil)
      appliedFilters.maxTotalLandArea = this.groundAreaSlider?.value[1];

    if (this.yearSlider?.value[0] != this.filterYearOptions.floor)
      appliedFilters.minListedYear = this.yearSlider?.value[0];
    if (this.yearSlider?.value[1] != this.filterYearOptions.ceil)
      appliedFilters.maxListedYear = this.yearSlider?.value[1];

    if (this.selectedSortOption)
      appliedFilters.sorting = this.selectedSortOption;

    this.listService.getLiveListings(appliedFilters).subscribe({
      next: (res) => {
        this.liveListings = res?.properties || [];
        this.getFavoriteListings('liveListingsTab');
        this.previousAppliedFilters = JSON.parse(
          JSON.stringify(this.filterForm.value)
        );
        this.filtersmodified = false;
        this.countFilters();
        this.filterPanelOpened = false;
      },
      error: (err) => {
        console.log(err);
        this._snackBar.open('Fejl', 'Dismiss', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
        this.loadingProperties = false;
        this.spinner.hide();
      },
    });
  }

  getFavoriteListings(calledFrom: string) {
    const userId = JSON.parse(localStorage.getItem('user'))?.id;
    if (userId) {
      if (calledFrom === 'favoritesTab') {
        this.spinner.show();
        this.loadingProperties = true;
      }
      this.listService.getFavoriteListingsById(userId).subscribe({
        next: (res) => {
          this.favoriteListings = res?.ownedListings;
          if (this.liveListings?.length && calledFrom === 'liveListingsTab') {
            this.liveListings.map((liveListing, index) => {
              this.liveListings[index].isFavorite = false;
              this.favoriteListings.map((favListing) => {
                if (favListing.listingId === liveListing.id)
                  this.liveListings[index].isFavorite = true;
              });
            });
          }
          this.loadingProperties = false;
          this.spinner.hide();
        },
        error: (err) => {
          console.log(err);
          this._snackBar.open('Fejl', 'Dismiss', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
          this.loadingProperties = false;
          this.spinner.hide();
        },
      });
    }
  }

  toogleFavorite(listingId: string, isFavorite) {
    this.spinner.show();
    this.loadingProperties = true;
    isFavorite
      ? this.listService
          .removeListingFromFavorites({
            id: JSON.parse(localStorage.getItem('user'))?.id,
            listingId: listingId,
          })
          .subscribe({
            next: (res) => {
              this.getLiveListings();
            },
            error: (err) => {
              console.log(err);
              this._snackBar.open('Fejl', 'Dismiss', {
                duration: 3000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              });
              this.getLiveListings();
            },
          })
      : this.listService
          .addListingToFavorites({
            id: JSON.parse(localStorage.getItem('user'))?.id,
            listingId: listingId,
          })
          .subscribe({
            next: (res) => {
              this.getLiveListings();
            },
            error: (err) => {
              console.log(err);
              this._snackBar.open('Fejl', 'Dismiss', {
                duration: 3000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              });
              this.getLiveListings();
            },
          });
  }

  getAllPostalcodes() {
    this.spinner.show();
    this.loadingProperties = true;
    this.listService.getAllPostalcodes().subscribe({
      next: (res) => {
        res?.map((item, index) => {
          if (item?.postalNumber && item?.city) {
            this._postalcodes.push({
              value: item.postalNumber,
              text: item.postalNumber + ', ' + item.city,
            });
          }
        });
        this.filteredPostalcodes.next(this._postalcodes.slice());
        this.loadingProperties = false;
        this.spinner.hide();
      },
      error: (err) => {
        console.log(err);
        this.loadingProperties = false;
        this.spinner.hide();
      },
    });
  }

  onSort(option: Sorting) {
    this.selectedSortOption = option;
    this.getLiveListings();
  }

  filterPostalcodes() {
    if (!this._postalcodes) {
      return;
    }
    // get the search keyword
    let search = this.postalcodesFilter.value;
    if (!search) {
      this.filteredPostalcodes.next(this._postalcodes.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the postalcodes
    this.filteredPostalcodes.next(
      this._postalcodes.filter(
        (code) => code.text.toLowerCase().indexOf(search) > -1
      )
    );
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  toggleFilterPanel() {
    this.filterPanelOpened = !this.filterPanelOpened;
  }

  private removeChip<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  onPostalcodeRemoved(postalcode: string) {
    const _postalcodes = this.postalcodes.value as string[];
    this.removeChip(_postalcodes, postalcode);
    this.postalcodes.setValue(_postalcodes); // To trigger change detection
  }
  onListingTypeRemoved(listingType: string) {
    const _listingTypes = this.listingTypes.value as string[];
    this.removeChip(_listingTypes, listingType);
    this.listingTypes.setValue(_listingTypes); // To trigger change detection
  }

  onPriceRemoved() {
    this.priceSlider.reset([
      this.filterPriceOptions.floor,
      this.filterPriceOptions.ceil,
    ]);
  }

  onAreaRemoved() {
    this.areaSlider.reset([
      this.filterAreaOptions.floor,
      this.filterAreaOptions.ceil,
    ]);
  }

  onGroundAreaRemoved() {
    this.groundAreaSlider.reset([
      this.filterGroundAreaOptions.floor,
      this.filterGroundAreaOptions.ceil,
    ]);
  }
  onYearRemoved() {
    this.yearSlider.reset([
      this.filterYearOptions.floor,
      this.filterYearOptions.ceil,
    ]);
  }

  countFilters() {
    //logic to count number of filters selected
    let count = 0;
    if (this.filterForm?.value?.postalcodes)
      count += this.filterForm.value.postalcodes.length;
    if (this.filterForm?.value?.listingTypes)
      count += this.filterForm.value.listingTypes.length;
    if (
      this.filterForm?.value?.priceSlider[0] !==
        this.filterPriceOptions.floor ||
      this.filterForm?.value?.priceSlider[1] !== this.filterPriceOptions.ceil
    )
      count += 1;
    if (
      this.filterForm?.value?.areaSlider[0] !== this.filterAreaOptions.floor ||
      this.filterForm?.value?.areaSlider[1] !== this.filterAreaOptions.ceil
    )
      count += 1;
    if (
      this.filterForm?.value?.groundAreaSlider[0] !==
        this.filterGroundAreaOptions.floor ||
      this.filterForm?.value?.groundAreaSlider[1] !==
        this.filterGroundAreaOptions.ceil
    )
      count += 1;
    if (
      this.filterForm?.value?.yearSlider[0] !== this.filterYearOptions.floor ||
      this.filterForm?.value?.yearSlider[1] !== this.filterYearOptions.ceil
    )
      count += 1;

    this.totalFilters = count;
  }

  resetFilters() {
    this.postalcodes.reset([]);
    this.listingTypes.reset([]);
    this.priceSlider.reset([
      this.filterPriceOptions.floor,
      this.filterPriceOptions.ceil,
    ]);
    this.areaSlider.reset([
      this.filterAreaOptions.floor,
      this.filterAreaOptions.ceil,
    ]);
    this.groundAreaSlider.reset([
      this.filterGroundAreaOptions.floor,
      this.filterGroundAreaOptions.ceil,
    ]);
    this.yearSlider.reset([
      this.filterYearOptions.floor,
      this.filterYearOptions.ceil,
    ]);
  }

  //value Changes

  initializeFilters() {
    this.previousAppliedFilters = JSON.parse(
      JSON.stringify(this.filterForm.value)
    );
  }

  OnFilterFormValueChanges() {
    this.filterForm.valueChanges.subscribe((value) => {
      JSON.stringify(this.previousAppliedFilters) ===
      JSON.stringify(this.filterForm.value)
        ? (this.filtersmodified = false)
        : (this.filtersmodified = true);
    });
  }

  onPostalcodeSearchValueChanges() {
    this.postalcodesFilter.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterPostalcodes();
      });
  }

  @HostListener('window:resize', ['$event'])
  onResizeWindow() {
    this.screen_wmin_1380 = window.matchMedia('(min-width: 1380px)');
  }

  // Getters

  get postalcodes() {
    return this.filterForm.get('postalcodes');
  }
  get listingTypes() {
    return this.filterForm.get('listingTypes');
  }
  get priceSlider() {
    return this.filterForm.get('priceSlider');
  }
  get areaSlider() {
    return this.filterForm.get('areaSlider');
  }
  get groundAreaSlider() {
    return this.filterForm.get('groundAreaSlider');
  }
  get yearSlider() {
    return this.filterForm.get('yearSlider');
  }

  //helpers
  displaySelectText(list, value) {
    if (list && value) return list?.find((list) => list.value === value).text;
  }
}

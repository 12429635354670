import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-house-simple',
  templateUrl: './house-simple.component.html',
  styleUrls: ['./house-simple.component.scss'],
})
export class HouseSimpleComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

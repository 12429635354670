<mat-form-field
  *ngIf="mode === 'SINGLE'"
  class="w-full relative | select-box monthpicker color-747f81 color-091315 text-size-16 line-height-20 font-weight-600 padding-52"
  appearance="fill"
>
  <input
    matInput
    [placeholder]="placeholder"
    floatPlaceholder="never"
    [(ngModel)]="monthAndYear"
    name="monthAndYear"
    [matDatepicker]="picker"
    [max]="maxDate"
    (dateChange)="emitDateChange($event)"
  />
  <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker
    #picker
    [startView]="'multi-year'"
    (monthSelected)="monthChanged($event, picker)"
  ></mat-datepicker>
  <tooltip
    *ngIf="tooltipMessage"
    class="absolute right-0 bottom-[6px]"
    [message]="tooltipMessage"
  ></tooltip>
  <mat-error> Påkrævet </mat-error>
</mat-form-field>

<mat-form-field
  *ngIf="mode === 'FORM'"
  class="w-full relative | select-box monthpicker color-747f81 color-091315 text-size-16 line-height-20 font-weight-600 padding-52"
  appearance="fill"
>
  <input
    matInput
    [placeholder]="placeholder"
    floatPlaceholder="never"
    [formControl]="control"
    name="monthAndYear"
    [matDatepicker]="picker"
    [max]="maxDate"
  />
  <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker
    #picker
    [startView]="'multi-year'"
    (monthSelected)="monthChanged($event, picker)"
  ></mat-datepicker>
  <tooltip
    *ngIf="tooltipMessage"
    class="absolute right-0 bottom-[6px]"
    [message]="tooltipMessage"
  ></tooltip>
  <mat-error> Påkrævet </mat-error>
</mat-form-field>

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'icon-minus-twenty',
  templateUrl: './minus-twenty.component.html',
  styleUrls: ['./minus-twenty.component.scss'],
})
export class MinusTwentyComponent implements OnInit {
  @Input() strokeColor = '#24535B';

  constructor() {}

  ngOnInit(): void {}
}

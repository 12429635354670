<div class="h-full w-full flex justify-center items-start pt-[184px]">
  <div class="max-w-[360px] w-full mx-4 relative">
    <div *ngIf="!_passwordUpdated">
      <h2 class="font-semibold text-5xl leading-[48px] mb-8">Ny adgangskode</h2>
      <form
        #form="ngForm"
        [formGroup]="resetPasswordForm"
        (ngSubmit)="handleSubmit()"
      >
        <mat-form-field class="mb-3 w-full" appearance="fill">
          <mat-label>Indsæt token her</mat-label>
          <input
            matInput
            minlength="6"
            maxlength="6"
            pattern="\d*"
            formControlName="token"
          />
          <mat-error
            *ngIf="
              token.hasError('minlength') &&
              !token.hasError('required') &&
              form.submitted
            "
          >
            Indtast venligst et gyldigt token
          </mat-error>
          <mat-error *ngIf="token.hasError('required')"> Påkrævet </mat-error>
        </mat-form-field>

        <mat-form-field class="w-full" appearance="fill">
          <mat-label>Adgangskode</mat-label>
          <input
            formControlName="password"
            matInput
            [type]="_hidePassword ? 'password' : 'text'"
            autocomplete="off"
          />
          <icon-password-hide
            *ngIf="_hidePassword"
            matSuffix
            (click)="_hidePassword = !_hidePassword"
          ></icon-password-hide>
          <icon-password-visible
            *ngIf="!_hidePassword"
            matSuffix
            (click)="_hidePassword = !_hidePassword"
          ></icon-password-visible>
          <mat-error
            *ngIf="
              password.invalid &&
              !password.hasError('required') &&
              form.submitted
            "
          >
            Indtast venligst en gyldig adgangskode
          </mat-error>
          <mat-error *ngIf="password.hasError('required')">
            Påkrævet
          </mat-error>
        </mat-form-field>

        <div class="mb-3 mt-1 flex justify-between">
          <p class="text-sm font-semibold">
            {{
              _passwordStrength === 100
                ? "Strong password"
                : _passwordStrength === 75
                ? "Medium password"
                : _passwordStrength === 50
                ? "Medium password"
                : _passwordStrength === 25
                ? "Weak password"
                : "Password strength"
            }}
          </p>
        </div>

        <mat-password-strength
          #passwordComponent
          [enableDigitRule]="false"
          [password]="password.value"
          (onStrengthChanged)="onStrengthChanged($event)"
        >
        </mat-password-strength>

        <div class="flex items-center gap-3 mt-3">
          <div
            class="flex items-center justify-center gap-[9px] px-3 py-2 rounded-[90px] text-sm leading-4 bg-[#EFF6F6] text-[#8A9C9F]"
          >
            <icon-check
              class="flex-shrink-0"
              *ngIf="
                password?.hasError('requiresUppercase') ||
                password?.hasError('requiresLowercase') ||
                password?.hasError('required')
              "
              strokeColor="#8A9C9F"
            ></icon-check>
            <icon-check
              class="flex-shrink-0"
              *ngIf="
                !password?.hasError('requiresUppercase') &&
                !password?.hasError('requiresLowercase') &&
                !password?.hasError('required')
              "
              strokeColor="#0E7254"
            ></icon-check>
            <span
              [ngClass]="{
                'bg-[#E4FFF6] text-[#0E7254] transition-all':
                  !password?.hasError('requiresUppercase') &&
                  !password?.hasError('requiresLowercase') &&
                  !password?.hasError('required')
              }"
              >Et stort og et lille bogstav</span
            >
          </div>

          <div
            class="flex items-center justify-center gap-[9px] px-3 py-2 rounded-[90px] text-sm leading-4 bg-[#EFF6F6] text-[#8A9C9F]"
          >
            <icon-check
              class="flex-shrink-0"
              *ngIf="
                password?.hasError('requiresDigit') ||
                password?.hasError('required')
              "
              strokeColor="#8A9C9F"
            ></icon-check>
            <icon-check
              class="flex-shrink-0"
              *ngIf="
                !password?.hasError('requiresDigit') &&
                !password?.hasError('required')
              "
              strokeColor="#0E7254"
            ></icon-check>
            <span
              class="flex-1"
              [ngClass]="{
                'bg-[#E4FFF6] text-[#0E7254] transition-all':
                  !password?.hasError('requiresDigit') &&
                  !password?.hasError('required')
              }"
              >Et tal</span
            >
          </div>
        </div>

        <div class="flex items-center gap-3 my-3">
          <div
            class="flex items-center justify-center gap-[9px] px-3 py-2 rounded-[90px] text-sm leading-4 bg-[#EFF6F6] text-[#8A9C9F]"
          >
            <icon-check
              class="flex-shrink-0"
              *ngIf="
                password?.hasError('minlength') ||
                password?.hasError('required')
              "
              strokeColor="#8A9C9F"
            ></icon-check>
            <icon-check
              class="flex-shrink-0"
              *ngIf="
                !password?.hasError('minlength') &&
                !password?.hasError('required')
              "
              strokeColor="#0E7254"
            ></icon-check>
            <span
              [ngClass]="{
                'bg-[#E4FFF6] text-[#0E7254] transition-all':
                  !password?.hasError('minlength') &&
                  !password?.hasError('required')
              }"
              >Mindst 8 tegn</span
            >
          </div>

          <div
            class="flex items-center justify-center gap-[9px] px-3 py-2 rounded-[90px] text-sm leading-4 bg-[#EFF6F6] text-[#8A9C9F]"
          >
            <icon-check
              class="flex-shrink-0"
              *ngIf="
                password?.hasError('requiresSpecialChars') ||
                password?.hasError('required')
              "
              strokeColor="#8A9C9F"
            ></icon-check>
            <icon-check
              class="flex-shrink-0"
              *ngIf="
                !password?.hasError('requiresSpecialChars') &&
                !password?.hasError('required')
              "
              strokeColor="#0E7254"
            ></icon-check>
            <span
              class="flex-1"
              [ngClass]="{
                'bg-[#E4FFF6] text-[#0E7254] transition-all':
                  !password?.hasError('requiresSpecialChars') &&
                  !password?.hasError('required')
              }"
              >Et specialtegn</span
            >
          </div>
        </div>

        <mat-form-field class="my-3 w-full" appearance="fill">
          <mat-label>Bekræft adgangskode</mat-label>
          <input
            formControlName="confirmPassword"
            matInput
            [type]="_hideConfirmPassword ? 'password' : 'text'"
            autocomplete="off"
          />
          <icon-password-hide
            *ngIf="_hideConfirmPassword"
            matSuffix
            (click)="_hideConfirmPassword = !_hideConfirmPassword"
            width="24"
          ></icon-password-hide>
          <icon-password-visible
            *ngIf="!_hideConfirmPassword"
            matSuffix
            (click)="_hideConfirmPassword = !_hideConfirmPassword"
          ></icon-password-visible>
          <mat-error
            *ngIf="
              confirmPassword.invalid &&
              !confirmPassword.hasError('required') &&
              form.submitted
            "
          >
            Password's do not match
          </mat-error>
          <mat-error *ngIf="confirmPassword.hasError('required')">
            Påkrævet
          </mat-error>
        </mat-form-field>

        <button
          type="submit"
          class="!w-full !mb-4"
          [ngClass]="{
            'animate-shake': form.invalid && form.submitted
          }"
          mat-flat-button
          color="primary"
        >
          <div
            *ngIf="isLoaderEnabled?.value"
            class="flex items-center justify-center"
          >
            <icon-loading-button class="animate-spin"></icon-loading-button>
          </div>
          <span class="uppercase" *ngIf="!isLoaderEnabled?.value">Fortsæt</span>
        </button>
      </form>
    </div>
    <div *ngIf="_passwordUpdated">
      <svg
        class="checkmark"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
      >
        <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
        <path
          class="checkmark__check"
          fill="none"
          d="M14.1 27.2l7.1 7.2 16.7-16.8"
        />
      </svg>
      <h2 class="font-semibold text-5xl leading-[60px] mb-8 mt-5">
        Din adgangskode er blevet opdateret!
      </h2>
      <p class="mt-6">
        Du vil blive viderstillet til <b>EJOS.dk</b> om {{ _counter }} sekunder
      </p>
      <div class="mt-9 flex items-center gap-3">
        <div class="flex flex-1 items-center">
          <div class="flex-1 border-t border-solid border-primary"></div>
          <div class="h-2 w-2 rounded-full bg-primary"></div>
        </div>
        <p class="font-semibold text-primary">eller</p>
        <div class="flex flex-1 items-center">
          <div class="h-2 w-2 rounded-full bg-primary"></div>
          <div class="flex-1 border-t border-solid border-primary"></div>
        </div>
      </div>
      <button
        class="w-full uppercase text-white font-semibold !mt-9 !mb-4"
        mat-flat-button
        color="primary"
      >
        Gå til Login
      </button>
    </div>
  </div>
</div>

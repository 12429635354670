<!-- // part of form group -->
<div *ngIf="mode === 'FORM'" class="text-input-container" [formGroup]="group">
  <div *ngIf="showLabel" class="text-input-title flex-row-4 mar-btm-4">
    <label>{{ title }}</label>
    <p class="text-input-required" *ngIf="isRequired">*</p>
    <span class="info-icon" *ngIf="isInfo"></span>
  </div>
  <div
    [ngClass]="{
      'text-input': true,
      'text-input-error': control?.invalid && isErrorsEnabledLocale
    }"
  >
    <ng-select
      [ngClass]="{
        error:
          control?.invalid &&
          control?.hasError('required') &&
          isErrorsEnabledLocale
      }"
      [bindLabel]="'text'"
      tabindex="0"
      [searchable]="false"
      [clearable]="false"
      [formControlName]="_id"
      [placeholder]="placeholder"
      (change)="OnChange($event)"
      *ngIf="!isLoading"
    >
      <ng-option *ngFor="let option of options" [value]="option">
        <div class="flex-row-8">
          <svg
            class="circle"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              *ngIf="!option.isSelected"
              cx="9.99991"
              cy="10.0002"
              r="8.42105"
              fill="white"
              stroke="#828282"
              stroke-width="3"
            />

            <circle
              *ngIf="option.isSelected"
              cx="9.99991"
              cy="10.0002"
              r="8.42105"
              fill="white"
              stroke="#262F63"
              stroke-width="3"
            />

            <circle
              *ngIf="option.isSelected"
              cx="9.99991"
              cy="10.0002"
              r="4"
              fill="#262F63"
              stroke="#262F63"
              stroke-width="3"
            />
          </svg>
          <!-- {{ option.isSelected }} -->
          <span>
            {{ option.text }}
          </span>
        </div>
      </ng-option>
    </ng-select>
  </div>

  <!-- general required error message -->
  <error-message
    [isRenderEnabled]="
      control?.invalid && control?.hasError('required') && isErrorsEnabledLocale
    "
  >
    Påkrævet
  </error-message>
</div>

<!-- // Not part of form group -->

<div *ngIf="mode === 'SINGLE'" class="text-input-container">
  <div *ngIf="showLabel" class="text-input-title flex-row-4 mar-btm-4">
    <label>{{ title }}</label>
    <p class="text-input-required" *ngIf="isRequired">*</p>
    <span class="info-icon" *ngIf="isInfo"></span>
  </div>
  <div
    [ngClass]="{
      'text-input': true
    }"
  >
    <ng-select
      [ngClass]="{ error: isErrorsEnabledLocale }"
      bindLabel="text"
      tabindex="0"
      [searchable]="false"
      [clearable]="false"
      [placeholder]="'Placeholder'"
      (change)="OnChange($event)"
      [(ngModel)]="selectedType"
    >
      <ng-option *ngFor="let option of options" [value]="option">
        <div class="flex-row-8">
          <svg
            class="circle"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              *ngIf="!option.isSelected"
              cx="9.99991"
              cy="10.0002"
              r="8.42105"
              fill="white"
              stroke="#828282"
              stroke-width="3"
            />

            <circle
              *ngIf="option.isSelected"
              cx="9.99991"
              cy="10.0002"
              r="8.42105"
              fill="white"
              stroke="#262F63"
              stroke-width="3"
            />

            <circle
              *ngIf="option.isSelected"
              cx="9.99991"
              cy="10.0002"
              r="4"
              fill="#262F63"
              stroke="#262F63"
              stroke-width="3"
            />
          </svg>
          <span>
            {{ option.text }}
          </span>
        </div>
      </ng-option>
    </ng-select>
  </div>

  <!-- general required error message -->
  <error-message [isRenderEnabled]="isErrorsEnabledLocale">
    Påkrævet
  </error-message>
</div>

<mat-dialog-content class="rounded-lg">
  <form
    #firstForm="ngForm"
    [formGroup]="addExpenseForm"
    (ngSubmit)="_saveChanges()"
  >
    <div class="flex justify-between items-center min-w-[236px]">
      <p class="text-black text-2xl font-semibold leading-[30px] !m-0">
        Tilføj udgift
      </p>
      <icon-close-dialog
        class="cursor-pointer"
        mat-dialog-close
      ></icon-close-dialog>
    </div>
    <mat-form-field class="w-full mt-8" appearance="fill">
      <mat-label>Indtast udgiftsnavn</mat-label>
      <input matInput formControlName="tempName" />
      <mat-error> Påkrævet </mat-error>
    </mat-form-field>

    <mat-form-field
      class="w-full mt-3 | textarea rental-notes"
      appearance="fill"
    >
      <textarea
        matInput
        placeholder="Skriv beskrivelse her"
        formControlName="tempDescription"
      ></textarea>
      <icon-text-align-right matPrefix> </icon-text-align-right>
      <mat-error> Påkrævet </mat-error>
    </mat-form-field>

    <div class="flex flex-wrap items-center gap-4 mt-8">
      <button
        mat-flat-button
        type="submit"
        color="primary"
        class="!flex-1 uppercase"
        [ngClass]="{
          'animate-shake': firstForm.invalid && firstForm.submitted
        }"
      >
        Gem
      </button>
      <button
        mat-dialog-close
        class="flex-1 bg-white border border-solid border-[#D1D8D9] py-[17px] px-5 uppercase rounded-[90px] semibold text-black"
      >
        Annuller
      </button>
    </div>
  </form>
</mat-dialog-content>

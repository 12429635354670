export type IMAGE = {
  file: File;
  propertyId: string;
  pageType: string;
  header?: {
    _id: string;
    title: string;
    placeholder: string;
    isRequired: boolean;
  };
  subHeading?: {
    _id: string;
    title: string;
    placeholder: string;
    isRequired: boolean;
  };
  propertyFacts?: {
    _id: string;
    title: string;
    placeholder: string;
    isRequired: boolean;
    isInfo: boolean;
    isTextArea: boolean;
    textAreaHeight: string;
  };
  caption?: {
    _id: string;
    title: string;
    placeholder: string;
    isRequired: boolean;
  };
};
export type IMAGES = IMAGE[];

export const IMAGE_UPLOAD_FORM_DATA: IMAGES = [
  {
    file: null,
    propertyId: null,
    pageType: 'FrontPage',
    header: {
      _id: 'header',
      title: 'Overskrift',
      placeholder: 'Skriv overskrift',
      isRequired: true,
    },
    subHeading: {
      _id: 'subHeading',
      title: 'Underoverskrift',
      placeholder: 'Skriv overskrift',
      isRequired: true,
    },
    propertyFacts: {
      _id: 'propertyFacts',
      title: 'Fakta om ejendommen',
      placeholder: 'Skriv fakta om ejendommen her...',
      isRequired: true,
      isInfo: true,
      isTextArea: true,
      textAreaHeight: '140px',
    },
    caption: {
      _id: 'caption',
      title: 'Billedtekst',
      placeholder: 'Skriv overskrift',
      isRequired: true,
    },
  },
];

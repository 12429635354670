import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextInputComponent } from './text-input/text-input.component';
import { UserInputSanitizerDirective } from './user-input-sanitizer.directive';
import { CheckBoxComponent } from './check-box/check-box.component';
import { SubmitButtonComponent } from './submit-button/submit-button.component';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PhoneFormatterDirective } from './phone-formatter.directive';
import { LoaderComponent } from './loader/loader.component';
import { DateFormatterPipe } from './date-formatter.pipe';
import { SelectRadioBoxComponent } from './select-radio-box/select-radio-box.component';
import { OutsideClickDirective } from './outside-click.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { AccordianComponent } from './accordian/accordian.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { FileDragNDropDirective } from './file-drag-n-drop.directive';
import { PanelComponent } from './panel/panel.component';
import { SelectCheckboxComponent } from './select-checkbox/select-checkbox.component';
import { PasswordValidatorComponent } from './password-validator/password-validator.component';
import { NumberDirective } from './number-only.directive';
import { TooltipComponent } from './tooltip/tooltip.component';
import { CaretDownComponent } from './icons/caret-down/caret-down.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { DeleteComponent } from './icons/delete/delete.component';
import { PlusComponent } from './icons/plus/plus.component';
import { PasswordHideComponent } from './icons/password-hide/password-hide.component';
import { PasswordVisibleComponent } from './icons/password-visible/password-visible.component';
import { LoadingButtonComponent } from './icons/loading-button/loading-button.component';
import { CaretLeftComponent } from './icons/caret-left/caret-left.component';
import { CheckComponent } from './icons/check/check.component';
import { LogoSidenavbarComponent } from './icons/logo-sidenavbar/logo-sidenavbar.component';
import { LogoTextSidenavbarComponent } from './icons/logo-text-sidenavbar/logo-text-sidenavbar.component';
import { LogoMenuSidenavbarComponent } from './icons/logo-menu-sidenavbar/logo-menu-sidenavbar.component';
import { ChatTopnavbarComponent } from './icons/chat-topnavbar/chat-topnavbar.component';
import { NotificationTopnavbarComponent } from './icons/notification-topnavbar/notification-topnavbar.component';
import { SparkleHubComponent } from './icons/sparkle-hub/sparkle-hub.component';
import { BooksHubComponent } from './icons/books-hub/books-hub.component';
import { TagHubComponent } from './icons/tag-hub/tag-hub.component';
import { LifebuoyHubComponent } from './icons/lifebuoy-hub/lifebuoy-hub.component';
import { CaretRightComponent } from './icons/caret-right/caret-right.component';
import { SaveComponent } from './icons/save/save.component';
import { HomeCreatePropertyComponent } from './icons/home-create-property/home-create-property.component';
import { PencilLineComponent } from './icons/pencil-line/pencil-line.component';
import { CloseDialogComponent } from './icons/close-dialog/close-dialog.component';
import { MonthpickerComponent } from './monthpicker/monthpicker.component';
import { TextAlignRightComponent } from './icons/text-align-right/text-align-right.component';
import { TextAlignRightTwentyComponent } from './icons20/text-align-right-twenty/text-align-right-twenty.component';
import { NumberWithDecimalDirective } from './number-withdecimal.directive';
import { MenuHorizontalComponent } from './icons/menu-horizontal/menu-horizontal.component';
import { MatRadioModule } from '@angular/material/radio';
import { ArticleComponent } from './icons/article/article.component';
import { NoDescriptionsAvailableComponent } from './icons/no-descriptions-available/no-descriptions-available.component';
import { CheckGreenComponent } from './icons20/check-green/check-green.component';
import { StarFourComponent } from './icons/star-four/star-four.component';
import { CrownComponent } from './icons/crown/crown.component';
import { ReceiptComponent } from './icons/receipt/receipt.component';
import { CardHolderComponent } from './icons/card-holder/card-holder.component';
import { PlusTwentyComponent } from './icons20/plus-twenty/plus-twenty.component';
import { HouseSimpleComponent } from './icons/house-simple/house-simple.component';
import { TrendUpComponent } from './icons/trend-up/trend-up.component';
import { HeartFavComponent } from './icons/heart-fav/heart-fav.component';
import { ReloadComponent } from './reload/reload.component';
import { MinusTwentyComponent } from './icons20/minus-twenty/minus-twenty.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MatChipsModule } from '@angular/material/chips';
import { CloseComponent } from './icons/close/close.component';
import { ThousandSuffixesPipe } from './thousand-suffixes.pipe';
import { CopyrightComponent } from './icons/copyright/copyright.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatIconModule } from '@angular/material/icon';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  declarations: [
    TextInputComponent,
    UserInputSanitizerDirective,
    CheckBoxComponent,
    SubmitButtonComponent,
    ErrorMessageComponent,
    PhoneFormatterDirective,
    LoaderComponent,
    DateFormatterPipe,
    SelectRadioBoxComponent,
    OutsideClickDirective,
    AccordianComponent,
    ImageUploadComponent,
    FileDragNDropDirective,
    PanelComponent,
    SelectCheckboxComponent,
    PasswordValidatorComponent,
    NumberDirective,
    NumberWithDecimalDirective,
    TooltipComponent,
    CaretDownComponent,
    DeleteComponent,
    PlusComponent,
    PasswordHideComponent,
    PasswordVisibleComponent,
    LoadingButtonComponent,
    CaretLeftComponent,
    CheckComponent,
    LogoSidenavbarComponent,
    LogoTextSidenavbarComponent,
    LogoMenuSidenavbarComponent,
    ChatTopnavbarComponent,
    NotificationTopnavbarComponent,
    SparkleHubComponent,
    BooksHubComponent,
    TagHubComponent,
    LifebuoyHubComponent,
    CaretRightComponent,
    SaveComponent,
    HomeCreatePropertyComponent,
    PencilLineComponent,
    CloseDialogComponent,
    MonthpickerComponent,
    TextAlignRightComponent,
    TextAlignRightTwentyComponent,
    MenuHorizontalComponent,
    ArticleComponent,
    NoDescriptionsAvailableComponent,
    CheckGreenComponent,
    StarFourComponent,
    CrownComponent,
    ReceiptComponent,
    CardHolderComponent,
    PlusTwentyComponent,
    HouseSimpleComponent,
    TrendUpComponent,
    HeartFavComponent,
    ReloadComponent,
    MinusTwentyComponent,
    CloseComponent,
    ThousandSuffixesPipe,
    CopyrightComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  providers: [MatDatepickerModule],

  exports: [
    MatDialogModule,
    TextInputComponent,
    CheckBoxComponent,
    SelectRadioBoxComponent,
    SubmitButtonComponent,
    ErrorMessageComponent,
    DateFormatterPipe,
    OutsideClickDirective,
    AccordianComponent,
    ImageUploadComponent,
    FileDragNDropDirective,
    PanelComponent,
    SelectCheckboxComponent,
    PasswordValidatorComponent,
    UserInputSanitizerDirective,
    NumberDirective,
    TooltipComponent,
    CaretDownComponent,
    CaretLeftComponent,
    CaretRightComponent,
    DeleteComponent,
    PlusComponent,
    PasswordHideComponent,
    PasswordVisibleComponent,
    LoadingButtonComponent,
    CheckComponent,
    LogoSidenavbarComponent,
    LogoTextSidenavbarComponent,
    LogoMenuSidenavbarComponent,
    ChatTopnavbarComponent,
    NotificationTopnavbarComponent,
    SparkleHubComponent,
    BooksHubComponent,
    TagHubComponent,
    LifebuoyHubComponent,
    SaveComponent,
    PencilLineComponent,
    ArticleComponent,
    HomeCreatePropertyComponent,
    CloseDialogComponent,
    MonthpickerComponent,
    TextAlignRightComponent,
    TextAlignRightTwentyComponent,
    MenuHorizontalComponent,
    MatRadioModule,
    NumberWithDecimalDirective,
    NoDescriptionsAvailableComponent,
    CheckGreenComponent,
    StarFourComponent,
    CrownComponent,
    ReceiptComponent,
    CardHolderComponent,
    PlusTwentyComponent,
    HouseSimpleComponent,
    TrendUpComponent,
    HeartFavComponent,
    ReloadComponent,
    MinusTwentyComponent,
    NgxSliderModule,
    MatChipsModule,
    CloseComponent,
    ThousandSuffixesPipe,
    CopyrightComponent,
    NgxMatSelectSearchModule,
    MatIconModule,
    ScrollingModule,
  ],
})
export class SharedModule {}

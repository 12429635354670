<div class="mt-[52px] h-full">
  <h3 class="font-semibold text-[32px] leading-10 text-black">Beskrivelse</h3>
  <p class="text-[#747F81] mt-3">
    Her kan du vælge mellem 2 forskellige templates og rette i dem eller skrive
    din helt egen ejendomsbeskrivelse
  </p>

  <h5 class="font-semibold text-xl leading-[25px] text-black mt-10">
    Ejendomsbeskrivelse
  </h5>

  <div class="editor mt-6 h-[256px]">
    <ngx-editor-menu
      [editor]="editorLisitngDescription"
      [toolbar]="toolbar"
    ></ngx-editor-menu>
    <ngx-editor
      [editor]="editorLisitngDescription"
      [(ngModel)]="listingDescription"
      [disabled]="false"
      [placeholder]="'Beskriv din ejendom'"
      (ngModelChange)="updateListingDescription($event)"
      class="relative"
    >
      <icon-text-align-right
        class="absolute top-4 left-4"
      ></icon-text-align-right
    ></ngx-editor>
  </div>

  <hr class="border-t border-solid border-[#D1D8D9] my-6" />

  <h5 class="font-semibold text-xl leading-[25px] text-black mt-10">
    Områdebeskrivelse
  </h5>
  <div class="editor mt-6 relative h-[256px]">
    <div *ngIf="_city" class="xl:absolute xl:right-4 xl:top-4 mb-4">
      <button class="flex items-center gap-2" (click)="selectTemplate()">
        <icon-article strokeColor="#EB4C60"></icon-article>
        <span class="font-semibold text-sm leading-[18px] text-[#EB4C60]"
          >Vælg template fra {{ _city }}</span
        >
      </button>
    </div>

    <ngx-editor-menu
      [editor]="editorAreaDescription"
      [toolbar]="toolbar"
    ></ngx-editor-menu>
    <ngx-editor
      [editor]="editorAreaDescription"
      [(ngModel)]="areaDescription"
      [disabled]="false"
      [placeholder]="'Beskriv dit område'"
      (ngModelChange)="updateAreaDescription($event)"
      class="relative"
    >
      <icon-text-align-right
        class="absolute top-4 left-4"
      ></icon-text-align-right>
    </ngx-editor>
  </div>

  <div
    class="flex items-center gap-3 border-t border-solid border-[#D1D8D9] mt-[80px] py-10"
  >
    <button
      class="!py-[15px] !px-[25px] !border !border-solid !border-[#D1D8D9] !rounded-[90px] !min-w-fit"
      mat-flat-button
      (click)="onPrevClicked()"
    >
      <icon-caret-left></icon-caret-left>
    </button>
    <button
      class="w-full max-w-[274px]"
      mat-flat-button
      color="primary"
      (click)="onNextClicked()"
    >
      <div *ngIf="loading" class="flex items-center justify-center">
        <icon-loading-button class="animate-spin"></icon-loading-button>
      </div>
      <span *ngIf="!loading">GEM & FORTSÆT</span>
    </button>
  </div>
</div>

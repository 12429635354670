import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-logo-menu-sidenavbar',
  templateUrl: './logo-menu-sidenavbar.component.html',
  styleUrls: ['./logo-menu-sidenavbar.component.scss'],
})
export class LogoMenuSidenavbarComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

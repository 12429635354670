<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="timer"
  [fullScreen]="true"
  ><p style="color: white">Loading...</p></ngx-spinner
>
<div *ngIf="!pageLoading" class="mt-[52px] min-w-[420px] !overflow-x-auto">
  <h3 class="font-semibold text-[32px] leading-10 text-black">Salgsstrategi</h3>
  <p class="text-[#747F81] mt-3">
    EJOS omfattende basispakke fremgår af nedenstående og du kan efterfølgende
    foretage tilvalg hertil
  </p>
  <div
    class="rounded-[20px] border border-solid border-[#D1D8D9] mt-12 px-10 pb-7 mb-6"
  >
    <div class="flex items-start flex-wrap gap-[56px]">
      <div class="mt-[52px] pl-6 flex flex-col gap-2 w-[350px]">
        <p class="font-semibold text-lg leading-6 text-[#EB4C60]">
          {{ _selectedPackage?.packageName }}
        </p>
        <p class="font-semibold text-[40px] leading-10 text-[#24535B]">
          {{ _selectedPackage?.packagePrice | number : "1.0-2" }} kr.
        </p>
        <p class="font-semibold text-black">
          Ejendomstype: {{ displaySelectText(_listingTypes, _typeOfListing) }}
          {{ _selectedPackage?.packageDescription }}
          mio. kr.
        </p>
      </div>
      <div class="mt-[52px] flex flex-col gap-4">
        <p class="font-semibold text-black">Ejendomspakken inkluderer</p>
        <div
          class="flex gap-3"
          *ngFor="let item of _selectedPackage?.packageBenefits"
        >
          <icon-check-green></icon-check-green>
          <p class="leading-5">{{ item }}</p>
        </div>
      </div>
    </div>

    <hr class="border-t border-solid border-[#D1D8D9] my-6" />
    <div class="flex gap-3 items-center mb-7 pl-6">
      <p class="font-semibold text-xl leading-[25px] text-black">
        Ejendomspakken - tilvalg
      </p>
      <tooltip [message]="'abc'"></tooltip>
    </div>
    <div
      class="flex items-start flex-wrap gap-[56px] bg-[#EFF6F6] rounded-[20px] p-[52px]"
    >
      <p class="font-semibold leading-5 text-black max-w-[298px] w-full">
        Tilvalg til pakken:
      </p>
      <div class="flex flex-col gap-6 max-w-[540px] w-full">
        <div
          *ngFor="
            let item of _selectedPackage?.packageServiceAdditions;
            index as i
          "
          class="flex items-center justify-between flex-wrap gap-4"
        >
          <div class="flex items-center gap-2">
            <mat-checkbox
              [checked]="item?.isChecked"
              (change)="toggleSelectedPackageServiceAddition(i)"
            >
            </mat-checkbox>
            <div>
              <p class="leading-5 text-black">{{ item?.serviceName }}</p>
              <p class="text-sm leading-[18px] text-[#747F81]">
                {{ item?.serviceDescription }}
              </p>
            </div>
          </div>
          <p class="font-semibold leading-5 text-black">
            {{ item?.price | number : "1.0-2" }} kr.
          </p>
        </div>
      </div>
    </div>

    <hr class="border-t border-solid border-[#D1D8D9] my-6" />

    <p class="font-semibold text-2xl leading-[30px] text-black">
      Formidlingsstrategi
    </p>

    <mat-radio-group
      class="flex gap-6 mt-6 flex-wrap"
      [(ngModel)]="_selectedSalesStrategy"
      (change)="toggleSelectedSalesStrategy()"
    >
      <div
        *ngFor="let item of salesStrategies"
        class="py-[15px] px-4 rounded-[20px] border border-solid border-[#D1D8D9] max-w-[512px]"
      >
        <div
          class="flex flex-wrap justify-between sm:gap-16 items-center gap-8"
        >
          <mat-radio-button [value]="item">
            <p class="font-[700] text-lg leading-[22px] text-black">
              Salgsstrategi
            </p>
            <p class="leading-[20px] text-black">
              {{ item?.salesStrategyName }}
            </p>
          </mat-radio-button>
          <div class="flex items-center gap-2">
            <span class="font-[700] text-lg leading-[22px]"
              >{{ item?.salesStrategyPrice | number : "1.0-2" }} kr.</span
            >
            <icon-star-four
              *ngIf="item?.salesStrategyName === 'Basis'"
            ></icon-star-four>
            <icon-crown
              *ngIf="item?.salesStrategyName !== 'Basis'"
            ></icon-crown>
          </div>
        </div>

        <div class="flex flex-col gap-4 mt-6">
          <div
            class="flex gap-3"
            *ngFor="let benefit of item?.salesStrategyBenefits"
          >
            <icon-check-green></icon-check-green>
            <p class="leading-5">{{ benefit }}</p>
          </div>
        </div>
      </div>
    </mat-radio-group>

    <div class="flex gap-3 items-center mb-7 pl-6 mt-11">
      <p class="font-semibold text-xl leading-[25px] text-black">
        Ejendomspakken - tilvalg
      </p>
      <tooltip [message]="'abc'"></tooltip>
    </div>
    <div
      class="flex items-start flex-wrap gap-[56px] bg-[#EFF6F6] rounded-[20px] p-[52px]"
    >
      <p class="font-semibold leading-5 text-black max-w-[298px] w-full">
        Tilvalg til salgsstrategi:
      </p>
      <div class="flex flex-col gap-6 max-w-[540px] w-full">
        <div
          *ngFor="
            let item of _selectedSalesStrategy?.salesStrategyServiceAdditions;
            index as i
          "
          class="flex items-center justify-between flex-wrap gap-4"
        >
          <div class="flex items-center gap-2">
            <mat-checkbox
              [checked]="item?.isChecked"
              (change)="toggleSelectedSalesStrategyServiceAddition(i)"
            >
            </mat-checkbox>
            <div>
              <p class="leading-5 text-black">{{ item?.serviceName }}</p>
              <p class="text-sm leading-[18px] text-[#747F81]">
                {{ item?.serviceDescription }}
              </p>
            </div>
          </div>
          <p class="font-semibold leading-5 text-black">
            {{ item?.price | number : "1.0-2" }} kr.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div
    class="border-t border-solid border-[#D1D8D9] pt-7 pb-10 flex flex-wrap gap-8 justify-between"
  >
    <div>
      <p class="leading-5 text-black">Samlet honorar</p>
      <p class="font-semibold text-2xl leading-[30px]">
        {{ totalSumForPackagesAndStrategies | number : "1.0-2" }} kr.
      </p>
    </div>

    <div class="flex items-center gap-3 w-full max-w-[364px]">
      <button
        class="!py-[15px] !px-[25px] !border !border-solid !border-[#D1D8D9] !rounded-[90px] !min-w-fit"
        mat-flat-button
        (click)="onPrevClicked()"
      >
        <icon-caret-left></icon-caret-left>
      </button>
      <button
        class="w-full max-w-[274px]"
        mat-flat-button
        color="primary"
        (click)="onNextClicked()"
      >
        <div *ngIf="loading" class="flex items-center justify-center">
          <icon-loading-button class="animate-spin"></icon-loading-button>
        </div>
        <span *ngIf="!loading">GEM & FORTSÆT</span>
      </button>
    </div>
  </div>
</div>

import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive({
  selector: 'input[numbersOnly]',
})
export class NumberDirective {
  @Input() formControlName: string;
  @Input() group: FormGroup;

  constructor(private _el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;
    this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
    if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
      if (this.formControlName && this.group) {
        const sanitizedValue = initalValue.replace(/[^0-9]/gi, '');
        const control = this.group.get(this.formControlName);
        control.setValue(sanitizedValue);
      }
    }
  }
}

import { Injectable } from '@angular/core';
import { UpsertUserSettings } from 'src/app/_models/notification.model';
import { ApiService } from '../api.service';
import { GlobalConstants } from '../global-constants';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  constructor(private apiService: ApiService) {}

  upsertUserSettings = (body: UpsertUserSettings) =>
    this.apiService.post(
      `${GlobalConstants.notificationURL}usersettings`,
      body
    );

  getUserSettingsByEmail = (email: string) =>
    this.apiService.get(
      `${GlobalConstants.notificationURL}usersettings/email/${email}`
    );
}

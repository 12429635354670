import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-check-green',
  templateUrl: './check-green.component.html',
  styleUrls: ['./check-green.component.scss'],
})
export class CheckGreenComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Listing } from 'src/app/_models/property.model';
import { ListingService, PaymentService } from 'src/app/_services';

interface SavingsParameters {
  id: string;
  typeOfListing?:
    | 'RentalProperty'
    | 'ProjectAndTurnkey'
    | 'RawAndAgriculturalLand'
    | 'GroundsAndBuildings';
  eligibilityPriceMinimum: number;
  eligibilityPriceMaximum: number;
  rivalCommission: number;
  ejosCommission: number;
}

@Component({
  selector: 'summary-data',
  templateUrl: './summary-data.component.html',
  styleUrls: ['./summary-data.component.scss'],
})
export class SummaryDataComponent implements OnInit {
  @Output() prevClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() nextClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  listing: Listing;
  loading: boolean = false;
  savingsParameters: SavingsParameters;
  rivalCommissionInDkk: number = 0;
  moneySavedInDkk: number = 0;
  moneySavedInPercentage: number = 0;

  constructor(
    private payment: PaymentService,
    private listService: ListingService
  ) {}

  ngOnInit(): void {
    this.listing = JSON.parse(sessionStorage.getItem('listing'));
    this.fetchSavingsParamters();
    this.calculateSavings(this.savingsParameters);
  }

  fetchSavingsParamters() {
    if (
      this.listing?.id &&
      this.listing?.creationStep >= 6 &&
      this.listing?.typeOfListing &&
      this.listing?.indicativePrice
    ) {
      this.payment
        .getSavingParameter(
          this.listing?.typeOfListing,
          this.listing?.indicativePrice
        )
        .subscribe({
          next: (res) => {
            this.savingsParameters = res;
            this.calculateSavings(this.savingsParameters);
          },
          error: (err) => {
            console.log(err);
          },
        });
    }
  }

  calculateSavings(savingsParameters: SavingsParameters) {
    if (this.savingsParameters?.rivalCommission) {
      this.rivalCommissionInDkk =
        (this.listing.indicativePrice *
          this.savingsParameters?.rivalCommission) /
        100;

      this.moneySavedInDkk =
        this.rivalCommissionInDkk -
        this.listing?.totalSumForPackagesAndStrategies;

      this.moneySavedInPercentage =
        this.rivalCommissionInDkk > 0
          ? (this.moneySavedInDkk / this.rivalCommissionInDkk) * 100
          : 0;
    }
  }

  onNextClicked() {
    if (this.savingsParameters?.rivalCommission && this.listing) {
      this.loading = true;
      const createOrUpdateProperty: Listing = { ...this.listing };
      createOrUpdateProperty.creationStep = 7;
      // console.log(createOrUpdateProperty);
      this.listService
        .createOrUpdateProperty(createOrUpdateProperty)
        .subscribe({
          next: (res) => {
            sessionStorage.setItem('listing', JSON.stringify(res));
            this.loading = false;
            this.nextClick.emit(true);
          },
          error: (err) => {
            console.log(err);
            this.loading = false;
          },
        });
    }
    this.nextClick.emit(true);
  }

  onPrevClicked() {
    this.prevClick.emit(true);
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'icon-text-align-right-twenty',
  templateUrl: './text-align-right-twenty.component.html',
  styleUrls: ['./text-align-right-twenty.component.scss'],
})
export class TextAlignRightTwentyComponent {
  @Input() strokeColor: string = '#747F81';
}

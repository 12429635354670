import { environment } from '../../environments/environment';

export class GlobalConstants {
  public static authURL: string = `${environment.baseUrl}authentication/api/v1/`;
  public static listURL: string = `${environment.baseUrl}listing/api/v1/`;
  public static paymentURL: string = `${environment.baseUrl}payment/api/v1/`;
  public static notificationURL: string = `${environment.baseUrl}notification/api/v1/`;
}

// console.log(GlobalConstants.authURL);

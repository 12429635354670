<svg
  width="297"
  height="229"
  viewBox="0 0 297 229"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M36.8021 218.954H23.2351C23.2351 218.954 14.8832 211.534 12.1656 201.577C10.9112 196.993 17.0738 193.823 20.8021 197.101C29.5317 204.773 36.8021 218.954 36.8021 218.954Z"
    fill="#D1D8D9"
  />
  <path
    d="M42.5153 218.954H28.9476C28.9476 218.954 21.1892 203.018 24.1867 191.417C24.4176 190.507 24.8914 189.676 25.5576 189.014C26.2238 188.352 27.0573 187.884 27.969 187.659C28.8807 187.434 29.8365 187.461 30.7341 187.737C31.6318 188.013 32.4376 188.527 33.0655 189.226C43.3281 200.515 42.5153 218.954 42.5153 218.954Z"
    fill="#D1D8D9"
  />
  <path
    d="M42.5278 218.954H51.2864C51.2864 218.954 56.7835 209.269 61.9412 197.364C66.7517 186.266 58.551 181.628 53.4005 192.539C46.8497 206.418 42.5278 218.954 42.5278 218.954Z"
    fill="#D1D8D9"
  />
  <path
    d="M44.9258 218.954H58.4929C58.4929 218.954 72.155 212.491 71.3904 200.258C71.1924 197.057 67.5288 194.976 64.289 196.218C49.7243 201.799 44.9258 218.954 44.9258 218.954Z"
    fill="#D1D8D9"
  />
  <path
    d="M37.3123 218.954H50.88C50.88 218.954 54.9794 196.324 50.1194 182.067C48.6669 177.808 43.5046 177.063 41.1264 180.765C32.282 194.529 37.3123 218.954 37.3123 218.954Z"
    fill="#D1D8D9"
  />
  <path
    d="M127.177 98.5278L165.397 68.1784L276.813 104.029H127.177V98.5278Z"
    fill="#D1D8D9"
  />
  <path
    d="M271.804 109.861V218.622H208.789V109.861L271.804 109.861Z"
    fill="#D1D8D9"
    stroke="#D1D8D9"
    stroke-width="0.66023"
  />
  <path
    d="M255.738 218.954H243.486C243.486 218.954 235.943 212.988 233.489 204.982C232.359 201.295 237.921 198.747 241.289 201.383C249.172 207.552 255.738 218.954 255.738 218.954Z"
    fill="#EFF6F7"
  />
  <path
    d="M260.896 218.954H248.644C248.644 218.954 241.638 206.141 244.345 196.813C245.284 193.578 249.839 192.58 252.363 195.051C261.631 204.127 260.896 218.954 260.896 218.954Z"
    fill="#EFF6F7"
  />
  <path
    d="M260.91 218.954H268.818C272.318 213.335 275.529 207.542 278.439 201.597C282.784 192.673 275.378 188.944 270.726 197.717C267.084 204.619 263.807 211.707 260.91 218.954Z"
    fill="#EFF6F7"
  />
  <path
    d="M263.074 218.954H275.325C275.325 218.954 287.662 213.757 286.972 203.921C286.791 201.346 283.484 199.674 280.559 200.673C267.409 205.16 263.074 218.954 263.074 218.954Z"
    fill="#EFF6F7"
  />
  <path
    d="M256.195 218.954H268.447C268.447 218.954 272.149 200.758 267.76 189.294C267.454 188.516 266.945 187.834 266.285 187.319C265.625 186.805 264.839 186.477 264.009 186.37C263.18 186.263 262.336 186.381 261.568 186.712C260.799 187.042 260.133 187.572 259.64 188.248C251.655 199.315 256.195 218.954 256.195 218.954Z"
    fill="#EFF6F7"
  />
  <path
    d="M208.458 109.532H272.133L276.812 104.03H208.458V109.532Z"
    fill="#D1D8D9"
  />
  <path d="M244.006 118.636H180.446V171.821H244.006V118.636Z" fill="#D1D8D9" />
  <path d="M241.019 168.466V121.992H183.433V168.466H241.019Z" fill="#EFF6F7" />
  <path d="M242.77 142.976H181.681V147.481H242.77V142.976Z" fill="#D1D8D9" />
  <path d="M208.458 90.5087H45.895V218.953H208.458V90.5087Z" fill="#EFF6F7" />
  <path d="M134.533 113.414H58.4907V177.044H134.533V113.414Z" fill="#D1D8D9" />
  <path d="M130.959 173.03V117.428H62.0644V173.03H130.959Z" fill="white" />
  <path d="M130.959 161.549V117.428H62.0644V161.549H130.959Z" fill="#EFF6F7" />
  <path d="M133.054 158.854H59.9688V164.243H133.054V158.854Z" fill="#D1D8D9" />
  <path d="M151.949 203.211H195.4V110.827H151.949V203.211Z" fill="#D1D8D9" />
  <path d="M193.111 203.211V112.923H154.238V203.211H193.111Z" fill="#D1D8D9" />
  <path d="M189.679 152.019V116.545H157.673V152.019H189.679Z" fill="#EFF6F7" />
  <path d="M189.679 192.916V157.442H157.673V192.916H189.679Z" fill="#EFF6F7" />
  <path
    d="M211.099 99.4581H43.2539V87.8677L127.176 44.1466L211.099 87.8677V99.4581Z"
    fill="#EFF6F7"
  />
  <path
    d="M218.52 93.9669L127.176 46.3795L35.8336 93.9669L34.0034 90.4538L127.177 41.9131L220.349 90.4532L218.52 93.9669Z"
    fill="#D1D8D9"
  />
  <path
    d="M127.176 91.7446C123.602 91.7447 120.108 90.6849 117.136 88.6993C114.164 86.7136 111.848 83.8912 110.48 80.589C109.112 77.2868 108.754 73.6532 109.451 70.1475C110.148 66.6419 111.87 63.4218 114.397 60.8943C116.924 58.3668 120.144 56.6456 123.65 55.9482C127.156 55.2509 130.789 55.6087 134.091 56.9765C137.394 58.3443 140.216 60.6606 142.202 63.6325C144.188 66.6044 145.248 70.0985 145.248 73.6728C145.243 78.4641 143.337 83.0578 139.949 86.4458C136.561 89.8339 131.968 91.7395 127.176 91.7446ZM127.176 59.5623C124.386 59.5622 121.658 60.3897 119.337 61.94C117.016 63.4904 115.208 65.6941 114.14 68.2725C113.072 70.8508 112.792 73.688 113.336 76.4252C113.881 79.1624 115.225 81.6767 117.198 83.6501C119.171 85.6236 121.686 86.9675 124.423 87.512C127.16 88.0565 129.997 87.7771 132.576 86.7092C135.154 85.6412 137.358 83.8326 138.908 81.5122C140.459 79.1917 141.286 76.4636 141.286 73.6728C141.281 69.9321 139.793 66.346 137.148 63.7008C134.503 61.0557 130.917 59.5674 127.176 59.5623Z"
    fill="#D1D8D9"
  />
  <path
    d="M127.176 86.8292C134.443 86.8292 140.333 80.9389 140.333 73.6728C140.333 66.4067 134.443 60.5164 127.176 60.5164C119.91 60.5164 114.02 66.4067 114.02 73.6728C114.02 80.9389 119.91 86.8292 127.176 86.8292Z"
    fill="#EFF6F7"
  />
  <path d="M200.976 201.67H146.375V204.506H200.976V201.67Z" fill="#D1D8D9" />
  <path d="M199.728 204.506H147.622V210.311H199.728V204.506Z" fill="#D1D8D9" />
  <path d="M205.12 210.311H142.229V213.146H205.12V210.311Z" fill="#D1D8D9" />
  <path d="M203.684 213.147H143.667V218.953H203.684V213.147Z" fill="#D1D8D9" />
  <path
    d="M190.98 152.543C190.981 153.018 190.84 153.481 190.577 153.876C190.313 154.27 189.939 154.578 189.5 154.759C189.062 154.941 188.58 154.988 188.115 154.896C187.65 154.803 187.222 154.575 186.887 154.239C186.552 153.904 186.323 153.477 186.231 153.012C186.138 152.546 186.186 152.064 186.367 151.626C186.549 151.188 186.856 150.813 187.251 150.55C187.645 150.286 188.109 150.146 188.583 150.146C189.219 150.146 189.829 150.399 190.278 150.848C190.728 151.298 190.98 151.907 190.98 152.543Z"
    fill="white"
  />
  <path
    d="M34.4235 26.1666C30.5942 17.8912 33.6312 12 42.9946 12C48.8654 12 56.1451 14.3194 63.2333 18.0946C64.0342 15.9422 66.2895 14.6522 69.9181 14.6522C76.3039 14.6522 85.1034 18.638 92.0114 24.2611C93.6825 23.0067 96.1002 22.2395 99.2079 22.1161C105.319 21.8625 112.722 24.1522 119.157 27.8772C119.534 27.5196 119.979 27.2406 120.465 27.0564C120.951 26.8722 121.469 26.7865 121.989 26.8043C125.511 26.6598 130.278 28.9712 132.647 31.964C132.794 32.1522 132.925 32.3331 133.056 32.5147H29.6678C26.401 29.5819 30.0118 26.3151 34.4235 26.1666Z"
    fill="#EFF6F7"
  />
  <path
    d="M169.598 40.1813C169.892 40.15 170.187 40.1343 170.483 40.1344C170.208 37.065 171.799 35.1054 175.196 35.1054C177.647 35.1054 180.655 36.1281 183.721 37.8599C182.844 31.902 185.652 28.0297 192.012 28.0297C197.144 28.0297 203.639 30.5485 210.055 34.6565C210.618 32.3192 212.508 30.9182 215.684 30.9182C221.264 30.9182 229.193 35.2467 235.55 41.3538C235.55 41.3538 235.55 41.3618 235.558 41.3538C236.931 39.9924 238.995 39.1507 241.708 39.0166C247.021 38.7413 253.618 41.2284 259.468 45.2815C259.746 44.9056 260.11 44.6029 260.531 44.3999C260.952 44.1968 261.416 44.0995 261.883 44.1162C264.788 43.967 268.792 46.2019 271.117 49.1928H164.921C162.426 44.6596 164.415 40.6916 169.598 40.1813Z"
    fill="#EFF6F7"
  />
</svg>

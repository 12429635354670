<div
  class="flex items-center justify-start gap-1 py-5 pl-[40px] bg-[#EFF6F7] border-y border-solid border-[#D1D8D9] w-full left-0 overflow-x-auto | steps"
>
  <div
    class="flex items-center gap-1 flex-shrink-0 relative"
    *ngFor="let item of steps; let index = index"
  >
    <a
      class="text-base leading-5 text-[#747F81]"
      [ngClass]="{
        'step-active': item?.isActive,
        'step-complete': item?.isComplete
      }"
      >{{ item.title }}
    </a>
    <hr
      *ngIf="item?.isActive && screen_wmin_1320.matches"
      class="absolute border-b-[2px] border-solid border-red w-[calc(100%-24px)] -bottom-5 last:w-full"
    />
    <div *ngIf="index + 1 !== steps.length">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.5 3.75L13.75 10L7.5 16.25"
          [attr.stroke]="
            item?.isActive
              ? '#091315'
              : item?.isComplete
              ? '#091315'
              : '#747F81'
          "
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
</div>

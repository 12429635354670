<svg
  width="32"
  height="32"
  viewBox="0 0 32 32"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    opacity="0.2"
    d="M24.4876 7.51251C25.6026 8.6269 26.487 9.95005 27.0905 11.4064C27.6939 12.8627 28.0045 14.4236 28.0045 16C28.0045 17.5764 27.6939 19.1373 27.0905 20.5937C26.487 22.05 25.6026 23.3731 24.4876 24.4875L19.5376 19.5375C20.4734 18.5981 20.9989 17.326 20.9989 16C20.9989 14.674 20.4734 13.402 19.5376 12.4625L24.4876 7.51251Z"
    fill="#24535B"
  />
  <path
    opacity="0.2"
    d="M7.51254 24.4875C6.39758 23.3731 5.5131 22.05 4.90965 20.5937C4.3062 19.1373 3.99561 17.5764 3.99561 16C3.99561 14.4236 4.3062 12.8627 4.90965 11.4064C5.5131 9.95005 6.39758 8.6269 7.51254 7.51251L12.4625 12.4625C11.5267 13.402 11.0013 14.674 11.0013 16C11.0013 17.326 11.5267 18.5981 12.4625 19.5375L7.51254 24.4875Z"
    fill="#24535B"
  />
  <path
    d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
    stroke="#24535B"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M16 21C18.7614 21 21 18.7614 21 16C21 13.2386 18.7614 11 16 11C13.2386 11 11 13.2386 11 16C11 18.7614 13.2386 21 16 21Z"
    stroke="#24535B"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M12.4625 12.4625L7.51245 7.51251"
    stroke="#24535B"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M19.5376 12.4625L24.4876 7.51251"
    stroke="#24535B"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M19.5376 19.5375L24.4876 24.4875"
    stroke="#24535B"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M12.4625 19.5375L7.51245 24.4875"
    stroke="#24535B"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<ul class="accordion">
  <li [class.active]="active" class="accordion-menu" (click)="toogle()">
    <span class="flex-row-10">
      <h6 class="accordion-menu-header">{{ accodinaMenuHeadder }}</h6>
      <span *ngIf="isInfo" class="info-icon-white"></span>
    </span>
    <span class="dropdown-filled-white"></span>
  </li>
  <li class="accordian-submenu" [ngClass]="{ 'expand-submenu': active }">
    <ng-content></ng-content>
  </li>
</ul>

import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive({
  selector: 'input[numbersWithDecimalOnly]',
})
export class NumberWithDecimalDirective {
  @Input() formControlName: string;
  @Input() group: FormGroup;
  constructor(private _el: ElementRef) {}

  replaceAllExceptFirst(str, search, replace) {
    return str
      .split(search)
      .reduce((prev, curr, i) => prev + (i == 1 ? search : replace) + curr);
  }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;
    this._el.nativeElement.value = this.replaceAllExceptFirst(
      initalValue.replace(/[^0-9,]*/g, ''),
      ',',
      ''
    );
    if (initalValue !== this._el.nativeElement.value || true) {
      event.stopPropagation();
      if (this.formControlName && this.group) {
        const sanitizedValue = initalValue.replace(/[^0-9,]/gi, '');
        const control = this.group.get(this.formControlName);
        control.setValue(sanitizedValue);
      }
    }
  }
}

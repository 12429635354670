import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss'],
})
export class DeleteComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

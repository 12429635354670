import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Editor, toHTML, toDoc, Toolbar } from 'ngx-editor';
import { preDefinedAreaDescriptions } from './description-data.util';
import { MatDialog } from '@angular/material/dialog';
import { PickDesriptionDialogComponent } from '../dialogs/pick-desription-dialog/pick-desription-dialog.component';
import { ListingService } from 'src/app/_services/listing/listing.service';
import { Listing } from 'src/app/_models/property.model';

@Component({
  selector: 'description-data',
  templateUrl: './description-data.component.html',
  styleUrls: ['./description-data.component.scss'],
})
export class DescriptionDataComponent implements OnInit, OnDestroy {
  @Output() prevClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() nextClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  loading: boolean = false;
  _city: string = '';
  _postalCode: string = '';

  listingDescription: any = '';
  editorLisitngDescription: Editor;

  areaDescription: any = '';
  editorAreaDescription: Editor;

  toolbar: Toolbar = [
    ['bold', 'italic', 'underline'],
    ['ordered_list', 'bullet_list'],
    ['text_color', 'background_color'],
    // [{ heading: ['h1', 'h2', 'h3'] }],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
    ['link'],
  ];

  constructor(private listService: ListingService, public dialog: MatDialog) {}

  ngOnInit(): void {
    //Load city from saved primary address
    const listing = JSON.parse(sessionStorage.getItem('listing')) || '';
    listing?.id && listing.creationStep >= 3
      ? ((this._city = listing.primaryAddress.city),
        (this._postalCode = listing.primaryAddress.postalCode),
        (this.listingDescription = toDoc(listing.listingDescription)),
        (this.areaDescription = toDoc(listing.areaDescription)))
      : '';

    this.editorLisitngDescription = new Editor({
      history: true,
      keyboardShortcuts: true,
      inputRules: true,
    });

    this.editorAreaDescription = new Editor({
      history: true,
      keyboardShortcuts: true,
      inputRules: true,
    });
  }

  ngOnDestroy(): void {
    this.editorLisitngDescription.destroy();
    this.editorAreaDescription.destroy();
  }

  updateListingDescription(event) {
    this.listingDescription = event;
  }

  updateAreaDescription(event) {
    this.areaDescription = event;
  }

  selectTemplate() {
    this.dialog
      .open(PickDesriptionDialogComponent, {
        autoFocus: false,
        data: {
          city: this._city,
          postalCode: this._postalCode,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        this.areaDescription =
          res?.areaDescriptionSelected || this.areaDescription;
      });
  }

  onNextClicked() {
    const listing = JSON.parse(sessionStorage.getItem('listing'));
    if (listing) {
      this.loading = true;
      const createOrUpdateProperty: Listing = { ...listing };
      createOrUpdateProperty.creationStep = 4;
      createOrUpdateProperty.listingDescription = this.listingDescription
        ? toHTML(this.listingDescription)
        : '';
      createOrUpdateProperty.areaDescription = this.areaDescription
        ? toHTML(this.areaDescription)
        : '';
      // console.log(createOrUpdateProperty);
      this.listService
        .createOrUpdateProperty(createOrUpdateProperty)
        .subscribe({
          next: (res) => {
            sessionStorage.setItem('listing', JSON.stringify(res));
            this.loading = false;
            this.nextClick.emit(true);
          },
          error: (err) => {
            console.log(err);
            this.loading = false;
          },
        });
    }
  }

  onPrevClicked() {
    this.prevClick.emit(true);
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-crown',
  templateUrl: './crown.component.html',
  styleUrls: ['./crown.component.scss'],
})
export class CrownComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

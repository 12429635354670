import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'menu-icons',
  templateUrl: './menu-icons.component.html',
  styleUrls: ['./menu-icons.component.scss'],
})
export class MenuIconsComponent implements OnInit {
  @Input() id: string;
  @Input() active: boolean;

  constructor() {}

  ngOnInit(): void {}
}

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="timer"
  [fullScreen]="true"
  ><p style="color: white">Loading...</p></ngx-spinner
>

<p class="font-[700] text-lg leading-[23px] mb-5 uppercase">
  {{ currentTab }}
</p>

<mat-tab-group
  class="pt-1 my-profile-tab"
  animationDuration="500ms"
  [dynamicHeight]="true"
  (selectedTabChange)="tabChanged($event)"
>
  <mat-tab label="Sælg Ejendomme">
    <div
      class="mt-[10rem] flex items-center justify-center mb-4"
      *ngIf="OwnedListings?.length === 0 && !loadingProperties"
    >
      <div class="overflow-x-auto">
        <icon-home-create-property></icon-home-create-property>

        <p class="font-semibold text-xl leading-[25px] text-black my-4">
          Du har ingen ejendom til salg!
        </p>

        <p class="text-sm leading-[18px] text-black">
          For at komme igang skal du sætte mindst én ejendom til salg.
        </p>

        <button
          class="w-full max-w-[180px] !my-4"
          mat-flat-button
          color="primary"
          (click)="createProperty()"
        >
          <div *ngIf="true" class="flex items-center justify-center gap-[10px]">
            <icon-plus-twenty></icon-plus-twenty>
            <span class="uppercase text-sm leading-[18px]"> sælg ejendom</span>
          </div>
        </button>
      </div>
    </div>

    <div *ngIf="OwnedListings?.length > 0 && !loadingProperties" class="mt-6">
      <div class="flex justify-end items-center mb-6">
        <button
          class="!py-[15px] !px-[25px] !border !border-solid !border-[#D1D8D9] !rounded-[90px] !min-w-fit"
          mat-flat-button
          (click)="createProperty()"
        >
          <div class="flex items-center justify-start gap-[10px]">
            <icon-plus strokeColor="#091315"></icon-plus>
            <span class="font-semibold text-base leading-5 text-black uppercase"
              >SÆLG EJENDOM</span
            >
          </div>
        </button>
      </div>
      <div class="!overflow-x-auto mb-4">
        <table>
          <thead>
            <tr>
              <th>Ejendom / Projekt</th>
              <th>Adresse</th>
              <th>Pris(in kr.)</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let listing of OwnedListings">
              <td>{{ listing?.listingTitle }}</td>
              <td>
                {{ listing?.address?.street }}
                {{ listing?.address?.buildingNumber }},
                {{ listing?.address?.postalCode }}
                {{ listing?.address?.city }}
              </td>
              <td>
                {{
                  listing?.price != 0
                    ? (listing?.price | number : "1.0-2")
                    : "-"
                }}
              </td>
              <td>
                <div
                  class="flex justify-between gap-5 items-center font-semibold text-sm leading-[18px]"
                >
                  <span
                    class="py-1 px-2 rounded-[90px]"
                    [ngClass]="{
                      'bg-[#FBF3E7] text-[#E97B2C]':
                        listing?.state === 'Awaiting',
                      'bg-[#E4FFF6] text-[#19BC8B]': listing?.state === 'Live',
                      'bg-[#FBE8E7] text-[#EB4C44]':
                        listing?.state === 'Rejected',
                      'bg-[#EFF6F6] text-[#24535B]':
                        listing?.state === 'InProgress'
                    }"
                    >{{
                      listing?.state === "Awaiting"
                        ? "Afventer godkendelse"
                        : listing?.state === "Live"
                        ? "Aktiv"
                        : listing?.state === "Rejected"
                        ? "Afslået"
                        : listing?.state === "InProgress"
                        ? "Igangværende"
                        : "Error-fetching state"
                    }}
                  </span>
                  <span
                    *ngIf="listing?.state === 'InProgress'"
                    class="text-[#747F81]"
                  >
                    {{ listing?.step }} ud af 8 steps er udfyldt
                  </span>

                  <div>
                    <button [matMenuTriggerFor]="menu">
                      <icon-menu-horizontal></icon-menu-horizontal>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button
                        *ngIf="
                          listing?.state === 'Live' ||
                          listing?.state === 'Awaiting' ||
                          listing?.state === 'Rejected'
                        "
                        mat-menu-item
                        (click)="viewListing(listing?.listingId)"
                      >
                        <span class="text-sm text-[#1B2022]">Udsigt</span>
                      </button>
                      <button
                        *ngIf="listing?.state === 'Live'"
                        mat-menu-item
                        (click)="markListingAsSold(listing?.listingId)"
                      >
                        <span class="text-sm text-[#1B2022]"
                          >Marker som solgt</span
                        >
                      </button>
                      <button
                        *ngIf="listing?.state === 'InProgress'"
                        mat-menu-item
                        (click)="editListing(listing?.listingId, 'resume')"
                      >
                        <span class="text-sm text-[#1B2022]">Genoptag</span>
                      </button>
                      <button
                        *ngIf="listing?.state === 'Rejected'"
                        mat-menu-item
                        (click)="editListing(listing?.listingId, 'edit')"
                      >
                        <span class="text-sm text-[#1B2022]">Rediger</span>
                      </button>
                      <button
                        *ngIf="
                          listing?.state === 'Rejected' ||
                          listing?.state === 'InProgress'
                        "
                        mat-menu-item
                        (click)="deleteListing(listing?.listingId)"
                      >
                        <span class="text-sm text-[#EB4C44]">Slet</span>
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Alle Ejendomme" panelClass="extend-panel">
    <div *ngIf="!loadingProperties">
      <div class="py-6 flex items-center gap-5 flex-wrap justify-between">
        <div>
          <div
            class="flex gap-4 items-center cursor-pointer flex-wrap"
            [matMenuTriggerFor]="sortMenu"
            #sortMenuOpened="matMenuTrigger"
          >
            <span class="font-semibold leading-5 text-base text-black"
              >Sort by</span
            >
            <button
              class="!p-4 !border !border-solid !border-[#D1D8D9] !rounded-[12px] !min-w-fit"
              mat-button
              [matMenuTriggerFor]="sortMenu"
              #sortMenuOpened="matMenuTrigger"
            >
              <div class="flex items-center gap-[10px]">
                <span class="font-semibold leading-5 text-base text-black">{{
                  displaySelectText(sortOptions, selectedSortOption)
                }}</span>
                <icon-caret-down
                  strokeColor="#091315"
                  class="!transition-transform"
                  [ngClass]="{
                    'rotate-180 transition-transform': sortMenuOpened.menuOpen
                  }"
                ></icon-caret-down>
              </div>
            </button>
          </div>
          <mat-menu #sortMenu="matMenu" xPosition="before">
            <button
              mat-menu-item
              (click)="onSort(option.value)"
              *ngFor="let option of sortOptions"
            >
              <span class="leading-5 text-base text-black">{{
                option.text
              }}</span>
            </button>
          </mat-menu>
        </div>
        <div>
          <button
            class="flex items-center justify-start gap-[10px]"
            type="button"
            (click)="toggleFilterPanel()"
          >
            <icon-plus-twenty
              *ngIf="!filterPanelOpened"
              strokeColor="#24535B"
            ></icon-plus-twenty>
            <icon-minus-twenty
              *ngIf="filterPanelOpened"
              strokeColor="#24535B"
            ></icon-minus-twenty>
            <p
              class="font-semibold text-base leading-20 text-[#24535B] uppercase"
            >
              Flere søgekriterier
            </p>
            <p *ngIf="totalFilters" class="font-normal text-sm leading-[18px]">
              {{ totalFilters }} filtre valgt
            </p>
          </button>
        </div>
      </div>
      <mat-expansion-panel
        hideToggle
        class="panel"
        [ngClass]="{ '!pb-6': filterPanelOpened }"
        [expanded]="filterPanelOpened"
      >
        <form
          [formGroup]="filterForm"
          #form="ngForm"
          (ngSubmit)="getLiveListings()"
        >
          <div
            class="mb-8"
            *ngIf="
              postalcodes?.value?.length ||
              listingTypes?.value?.length ||
              priceSlider?.value[0] != filterPriceOptions.floor ||
              priceSlider?.value[1] < filterPriceOptions.ceil ||
              areaSlider?.value[0] != filterAreaOptions.floor ||
              areaSlider?.value[1] < filterAreaOptions.ceil ||
              groundAreaSlider?.value[0] != filterGroundAreaOptions.floor ||
              groundAreaSlider?.value[1] < filterGroundAreaOptions.ceil ||
              yearSlider?.value[0] != filterYearOptions.floor ||
              yearSlider?.value[1] < filterYearOptions.ceil
            "
          >
            <mat-chip-list aria-label="Filters selection">
              <mat-chip
                *ngFor="let postalcode of postalcodes?.value"
                (removed)="onPostalcodeRemoved(postalcode)"
              >
                <div class="flex items-center">
                  <span class="!font-normal !text-base !leading-5 text-black">
                    {{ displaySelectText(_postalcodes, postalcode) }}
                  </span>
                  <button matChipRemove>
                    <icon-close strokeColor="#EB4C60"></icon-close>
                  </button>
                </div>
              </mat-chip>

              <mat-chip
                *ngFor="let listingType of listingTypes?.value"
                (removed)="onListingTypeRemoved(listingType)"
              >
                <div class="flex items-center">
                  <span class="!font-normal !text-base !leading-5 text-black">
                    {{ displaySelectText(_listingTypes, listingType) }}
                  </span>
                  <button matChipRemove>
                    <icon-close strokeColor="#EB4C60"></icon-close>
                  </button>
                </div>
              </mat-chip>

              <mat-chip
                *ngIf="
                  priceSlider?.value[0] != filterPriceOptions.floor ||
                  priceSlider?.value[1] < filterPriceOptions.ceil
                "
                (removed)="onPriceRemoved()"
              >
                <div class="flex items-center">
                  <span class="!font-normal !text-base !leading-5 text-black">
                    Kontantpris (
                    {{
                      priceSlider?.value[0] <=
                      filterPriceOptions.ceil - filterPriceOptions.step
                        ? (priceSlider?.value[0] | thousandSuff)
                        : (filterPriceOptions.ceil - filterPriceOptions.step
                          | thousandSuff)
                    }}
                    <span
                      *ngIf="
                        priceSlider?.value[0] >
                        filterPriceOptions.ceil - filterPriceOptions.step
                      "
                    >
                      +
                    </span>
                    <span *ngIf="priceSlider?.value[0] < priceSlider?.value[1]">
                      -
                      {{
                        priceSlider?.value[1] <=
                        filterPriceOptions.ceil - filterPriceOptions.step
                          ? (priceSlider?.value[1] | thousandSuff)
                          : (filterPriceOptions.ceil - filterPriceOptions.step
                            | thousandSuff)
                      }}
                      <span
                        *ngIf="
                          priceSlider?.value[1] >
                          filterPriceOptions.ceil - filterPriceOptions.step
                        "
                      >
                        +
                      </span>
                    </span>
                    ) kr.
                  </span>
                  <button matChipRemove>
                    <icon-close strokeColor="#EB4C60"></icon-close>
                  </button>
                </div>
              </mat-chip>

              <mat-chip
                *ngIf="
                  areaSlider?.value[0] != filterAreaOptions.floor ||
                  areaSlider?.value[1] < filterAreaOptions.ceil
                "
                (removed)="onAreaRemoved()"
              >
                <div class="flex items-center">
                  <span class="!font-normal !text-base !leading-5 text-black">
                    Areal (
                    {{
                      areaSlider?.value[0] <=
                      filterAreaOptions.ceil - filterAreaOptions.step
                        ? (areaSlider?.value[0] | number : "1.0-2")
                        : (filterAreaOptions.ceil - filterAreaOptions.step
                          | number : "1.0-2")
                    }}
                    <span
                      *ngIf="
                        areaSlider?.value[0] >
                        filterAreaOptions.ceil - filterAreaOptions.step
                      "
                    >
                      +
                    </span>
                    <span *ngIf="areaSlider?.value[0] < areaSlider?.value[1]">
                      -
                      {{
                        areaSlider?.value[1] <=
                        filterAreaOptions.ceil - filterAreaOptions.step
                          ? (areaSlider?.value[1] | number : "1.0-2")
                          : (filterAreaOptions.ceil - filterAreaOptions.step
                            | number : "1.0-2")
                      }}
                      <span
                        *ngIf="
                          areaSlider?.value[1] >
                          filterAreaOptions.ceil - filterAreaOptions.step
                        "
                      >
                        +
                      </span>
                    </span>
                    ) m<sup>2</sup>
                  </span>
                  <button matChipRemove>
                    <icon-close strokeColor="#EB4C60"></icon-close>
                  </button>
                </div>
              </mat-chip>

              <mat-chip
                *ngIf="
                  groundAreaSlider?.value[0] != filterGroundAreaOptions.floor ||
                  groundAreaSlider?.value[1] < filterGroundAreaOptions.ceil
                "
                (removed)="onGroundAreaRemoved()"
              >
                <div class="flex items-center">
                  <span class="!font-normal !text-base !leading-5 text-black">
                    Grundareal (
                    {{
                      groundAreaSlider?.value[0] <=
                      filterGroundAreaOptions.ceil -
                        filterGroundAreaOptions.step
                        ? (groundAreaSlider?.value[0] | number : "1.0-2")
                        : (filterGroundAreaOptions.ceil -
                            filterGroundAreaOptions.step | number : "1.0-2")
                    }}
                    <span
                      *ngIf="
                        groundAreaSlider?.value[0] >
                        filterGroundAreaOptions.ceil -
                          filterGroundAreaOptions.step
                      "
                    >
                      +
                    </span>
                    <span
                      *ngIf="
                        groundAreaSlider?.value[0] < groundAreaSlider?.value[1]
                      "
                    >
                      -
                      {{
                        groundAreaSlider?.value[1] <=
                        filterGroundAreaOptions.ceil -
                          filterGroundAreaOptions.step
                          ? (groundAreaSlider?.value[1] | number : "1.0-2")
                          : (filterGroundAreaOptions.ceil -
                              filterGroundAreaOptions.step | number : "1.0-2")
                      }}
                      <span
                        *ngIf="
                          groundAreaSlider?.value[1] >
                          filterGroundAreaOptions.ceil -
                            filterGroundAreaOptions.step
                        "
                      >
                        +
                      </span>
                    </span>
                    ) m<sup>2</sup>
                  </span>
                  <button matChipRemove>
                    <icon-close strokeColor="#EB4C60"></icon-close>
                  </button>
                </div>
              </mat-chip>

              <mat-chip
                *ngIf="
                  yearSlider?.value[0] != filterYearOptions.floor ||
                  yearSlider?.value[1] < filterYearOptions.ceil
                "
                (removed)="onYearRemoved()"
              >
                <div class="flex items-center">
                  <span class="!font-normal !text-base !leading-5 text-black">
                    Byggeår (
                    {{ yearSlider?.value[0] }}
                    <span *ngIf="yearSlider?.value[0] < yearSlider?.value[1]">
                      -
                      {{ yearSlider?.value[1] }}
                    </span>
                    ) år.
                  </span>
                  <button matChipRemove>
                    <icon-close strokeColor="#EB4C60"></icon-close>
                  </button>
                </div>
              </mat-chip>
            </mat-chip-list>
          </div>

          <div
            class="flex items-center justify-start !mx-[1px] flex-wrap gap-7 pb-6 border-b border-solid border-[#D1D8D9]"
          >
            <div class="flex md:flex-1 w-full flex-col gap-6">
              <p class="font-semibold text-lg leading-6 text-black">
                Beliggenhed
              </p>

              <mat-form-field
                class="w-full relative | select-box no-background no-error color-747f81 color-091315 text-size-16 line-height-20 padding-16"
                appearance="fill"
              >
                <mat-select
                  multiple
                  formControlName="postalcodes"
                  placeholder="Vælg postnumre"
                  floatPlaceholder="never"
                  panelClass="text-size-16 line-height-20 color-1b2022"
                >
                  <mat-select-trigger>
                    <span *ngIf="postalcodes?.value?.length > 0">
                      {{ postalcodes?.value?.length }} lokationer valgt
                    </span>
                  </mat-select-trigger>
                  <!-- <cdk-virtual-scroll-viewport
                    itemSize="25"
                    [ngStyle]="{ height: '200px' }"
                  > -->
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Søg..."
                      [formControl]="postalcodesFilter"
                    >
                      <span ngxMatSelectNoEntriesFound>No entries found</span>
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let item of filteredPostalcodes | async"
                    [value]="item.value"
                  >
                    <span class="text-base leading-5">{{ item.text }}</span>
                  </mat-option>
                  <!-- </cdk-virtual-scroll-viewport> -->
                </mat-select>
                <icon-caret-down
                  class="absolute right-0 bottom-[6px]"
                ></icon-caret-down>
              </mat-form-field>
            </div>

            <div class="flex md:flex-1 w-full flex-col gap-6">
              <p class="font-semibold text-lg leading-6 text-black">
                Ejendomstype
              </p>

              <mat-form-field
                class="w-full relative | select-box no-background no-error color-747f81 color-091315 text-size-16 line-height-20 padding-16"
                appearance="fill"
              >
                <mat-select
                  multiple
                  formControlName="listingTypes"
                  placeholder="Vælg ejendomstype"
                  floatPlaceholder="never"
                  panelClass="text-size-16 line-height-20 color-1b2022"
                >
                  <mat-select-trigger>
                    {{
                      listingTypes?.value
                        ? displaySelectText(
                            _listingTypes,
                            listingTypes?.value[0]
                          )
                        : ""
                    }}
                    <span
                      *ngIf="listingTypes?.value?.length > 1"
                      class="opacity-75 text-sm"
                    >
                      +{{ listingTypes?.value?.length - 1 }}
                    </span>
                  </mat-select-trigger>
                  <mat-option
                    *ngFor="let item of _listingTypes"
                    [value]="item.value"
                  >
                    <span class="text-base leading-5"> {{ item.text }}</span>
                  </mat-option>
                </mat-select>
                <icon-caret-down
                  class="absolute right-0 bottom-[6px]"
                ></icon-caret-down>
              </mat-form-field>
            </div>
          </div>

          <div
            class="mt-4 flex items-center justify-start !mx-[1px] flex-wrap gap-7 pb-6 border-b border-solid border-[#D1D8D9]"
          >
            <div class="flex md:flex-1 w-full flex-col">
              <p class="font-semibold text-lg leading-6 text-black">
                Kontantpris
              </p>
              <ngx-slider
                formControlName="priceSlider"
                [options]="filterPriceOptions"
              ></ngx-slider>
              <div
                class="flex gap-3 justify-between font-semibold text-sm leading-[18px] text-black"
              >
                <p>
                  {{
                    priceSlider?.value[0] <=
                    filterPriceOptions.ceil - filterPriceOptions.step
                      ? (priceSlider?.value[0] | number : "1.0-2")
                      : (filterPriceOptions.ceil - filterPriceOptions.step
                        | number : "1.0-2")
                  }}
                  <span
                    *ngIf="
                      priceSlider?.value[0] >
                      filterPriceOptions.ceil - filterPriceOptions.step
                    "
                    >+
                  </span>
                  kr.
                </p>
                <p>
                  {{
                    priceSlider?.value[1] <=
                    filterPriceOptions.ceil - filterPriceOptions.step
                      ? (priceSlider?.value[1] | number : "1.0-2")
                      : (filterPriceOptions.ceil - filterPriceOptions.step
                        | number : "1.0-2")
                  }}
                  <span
                    *ngIf="
                      priceSlider?.value[1] >
                      filterPriceOptions.ceil - filterPriceOptions.step
                    "
                    >+</span
                  >
                  kr.
                </p>
              </div>
            </div>
            <div class="flex md:flex-1 w-full flex-col">
              <p class="font-semibold text-lg leading-6 text-black">Areal</p>
              <ngx-slider
                formControlName="areaSlider"
                [options]="filterAreaOptions"
              ></ngx-slider>
              <div
                class="flex justify-between font-semibold text-sm leading-[18px] text-black"
              >
                <p>
                  {{
                    areaSlider?.value[0] <=
                    filterAreaOptions.ceil - filterAreaOptions.step
                      ? (areaSlider?.value[0] | number : "1.0-2")
                      : (filterAreaOptions.ceil - filterAreaOptions.step
                        | number : "1.0-2")
                  }}
                  <span
                    *ngIf="
                      areaSlider?.value[0] >
                      filterAreaOptions.ceil - filterAreaOptions.step
                    "
                    >+</span
                  >
                  kr.
                </p>
                <p>
                  {{
                    areaSlider?.value[1] <=
                    filterAreaOptions.ceil - filterAreaOptions.step
                      ? (areaSlider?.value[1] | number : "1.0-2")
                      : (filterAreaOptions.ceil - filterAreaOptions.step
                        | number : "1.0-2")
                  }}
                  <span
                    *ngIf="
                      areaSlider?.value[1] >
                      filterAreaOptions.ceil - filterAreaOptions.step
                    "
                    >+</span
                  >
                  m<sup>2</sup>
                </p>
              </div>
            </div>
          </div>

          <div
            class="mt-4 flex items-center justify-start !mx-[1px] flex-wrap gap-7 pb-6 border-b border-solid border-[#D1D8D9]"
          >
            <div class="flex md:flex-1 w-full flex-col">
              <p class="font-semibold text-lg leading-6 text-black">
                Grundareal
              </p>
              <ngx-slider
                formControlName="groundAreaSlider"
                [options]="filterGroundAreaOptions"
              ></ngx-slider>
              <div
                class="flex justify-between font-semibold text-sm leading-[18px] text-black"
              >
                <p>
                  {{
                    groundAreaSlider?.value[0] <=
                    filterGroundAreaOptions.ceil - filterGroundAreaOptions.step
                      ? (groundAreaSlider?.value[0] | number : "1.0-2")
                      : (filterGroundAreaOptions.ceil -
                          filterGroundAreaOptions.step | number : "1.0-2")
                  }}
                  <span
                    *ngIf="
                      groundAreaSlider?.value[0] >
                      filterGroundAreaOptions.ceil -
                        filterGroundAreaOptions.step
                    "
                    >+</span
                  >
                  kr.
                </p>
                <p>
                  {{
                    groundAreaSlider?.value[1] <=
                    filterGroundAreaOptions.ceil - filterGroundAreaOptions.step
                      ? (groundAreaSlider?.value[1] | number : "1.0-2")
                      : (filterGroundAreaOptions.ceil -
                          filterGroundAreaOptions.step | number : "1.0-2")
                  }}
                  <span
                    *ngIf="
                      groundAreaSlider?.value[1] >
                      filterGroundAreaOptions.ceil -
                        filterGroundAreaOptions.step
                    "
                    >+</span
                  >
                  m<sup>2</sup>
                </p>
              </div>
            </div>
            <div class="flex md:flex-1 w-full flex-col">
              <p class="font-semibold text-lg leading-6 text-black">Byggeår</p>
              <ngx-slider
                formControlName="yearSlider"
                [options]="filterYearOptions"
              ></ngx-slider>
              <div
                class="flex gap-3 justify-between font-semibold text-sm leading-[18px] text-black"
              >
                <p>{{ yearSlider?.value[0] }} år.</p>
                <p>{{ yearSlider?.value[1] }} år.</p>
              </div>
            </div>
          </div>

          <div class="flex items-center gap-4 mt-8">
            <button
              type="button"
              class="!max-w-[170px] !w-full hover:bg-[#EFF6F6] bg-white border border-solid border-[#D1D8D9] py-[17px] px-5 uppercase rounded-[90px] font-semibold text-black"
              (click)="resetFilters()"
            >
              Nulstil
            </button>
            <button
              mat-flat-button
              type="submit"
              [disabled]="!filtersmodified"
              color="primary"
              class="uppercase !max-w-[170px] !w-full"
              [ngClass]="{
                'animate-shake': form.invalid && form.submitted
              }"
            >
              Gem
            </button>
          </div>
        </form>
      </mat-expansion-panel>

      <div *ngIf="liveListings.length && screen_wmin_1380.matches">
        <div
          *ngFor="let listing of liveListings"
          class="live-property-container hover:shadow-live-listing mb-5"
        >
          <div class="w-[304px] h-[204px] rounded-[24px] bg-[#E5EAE4] m-2">
            <img
              class="w-[304px] h-[204px] rounded-[24px] bg-[#E5EAE4]"
              [src]="url"
              [ngStyle]="{
                objectFit:
                  url === '/assets/home/listing/no-picture.svg'
                    ? 'none'
                    : 'fill'
              }"
            />
          </div>

          <div class="flex gap-9 flex-wrap flex-1 justify-between my-2 mx-6">
            <div class="flex flex-col gap-3">
              <p class="font-semibold text-xl leading-[25px] text-black">
                {{ listing?.listingTitle }}
              </p>
              <div class="leading-5 text-black">
                <p>
                  {{ listing?.primaryAddress?.street }}
                  {{ listing?.primaryAddress?.buildingNumber }},
                </p>
                <p>
                  {{ listing?.primaryAddress?.postalCode }}
                  {{ listing?.primaryAddress?.city }}
                </p>
              </div>
              <div class="leading-5 text-black">
                <p>Ejendomstype</p>
                <p>
                  {{ displaySelectText(_listingTypes, listing?.typeOfListing) }}
                </p>
              </div>
            </div>

            <div class="flex flex-col gap-3 leading-5 text-black">
              <div class="flex items-center gap-2">
                <icon-house-simple></icon-house-simple>
                <p>
                  Etageareal
                  {{ listing?.totalLandArea | number : "1.0-2" }} m<sup>2</sup>
                </p>
              </div>
              <div class="flex items-center gap-2">
                <icon-trend-up></icon-trend-up>
                <p>Afkast {{ listing?.finalBaseReturn }} %</p>
              </div>
              <!-- <div class="flex items-center gap-2">
                <icon-copyright></icon-copyright>
                <p>Energimærke</p>
              </div> -->
            </div>

            <div class="flex flex-col gap-6">
              <div class="leading-5 text-black">
                <p>Pris</p>
                <p class="font-semibold text-xl leading-[25px]">
                  {{ listing?.desiredPrice | number : "1.0-2" }} kr.
                </p>
              </div>
              <button
                class="w-full max-w-[180px] !mb-4"
                mat-flat-button
                color="primary"
              >
                <div class="flex items-center justify-center gap-[10px]">
                  <span class="uppercase leading-5"> Kontakt sælger</span>
                </div>
              </button>
            </div>

            <icon-heart-fav
              class="cursor-pointer"
              [isFav]="listing?.isFavorite"
              (click)="toogleFavorite(listing.id, listing?.isFavorite)"
            ></icon-heart-fav>
          </div>
        </div>
      </div>

      <div
        *ngIf="liveListings.length && !screen_wmin_1380.matches"
        class="properties-container"
      >
        <div
          *ngFor="let listing of liveListings"
          class="rounded-[28px] border border-solid border-[#D1D8D9] hover:shadow-live-listing"
        >
          <div class="rounded-[24px] bg-[#E5EAE4] m-2 relative">
            <img
              class="w-full h-full rounded-[24px] bg-[#E5EAE4]"
              [src]="url"
              [ngStyle]="{
                objectFit:
                  url === '/assets/home/listing/no-picture.svg'
                    ? 'none'
                    : 'fill'
              }"
            />
            <div
              class="cursor-pointer absolute top-3 right-3 rounded-full w-12 h-12 bg-white flex items-center justify-center"
            >
              <icon-heart-fav
                [isFav]="listing?.isFavorite"
                (click)="toogleFavorite(listing.id, listing?.isFavorite)"
              ></icon-heart-fav>
            </div>
          </div>

          <div class="mx-[28px] my-6">
            <div class="flex justify-between gap-6 items-center flex-wrap">
              <p class="text-sm leading-[18px] text-primary">
                {{ displaySelectText(_listingTypes, listing?.typeOfListing) }}
              </p>
              <p class="font-semibold text-xl leading-[25px] text-black">
                {{ listing?.desiredPrice | number : "1.0-2" }} kr.
              </p>
            </div>
            <p
              class="font-semibold text-lg leading-[23px] text-black mt-[10px]"
            >
              {{ listing?.listingTitle }}
            </p>

            <p class="leading-[20px] text-black mt-[10px]">
              {{ listing?.primaryAddress?.street }}
              {{ listing?.primaryAddress?.buildingNumber }},
              {{ listing?.primaryAddress?.postalCode }}
              {{ listing?.primaryAddress?.city }}
            </p>

            <div
              class="leading-[20px] text-black mt-[10px] flex justify-start items-center gap-[10px] flex-wrap"
            >
              <p>
                {{ listing?.totalLandArea | number : "1.0-2" }} m<sup>2</sup>
              </p>
              <span
                class="w-2 h-2 rounded-full bg-[#747F81] flex-shrink-0"
              ></span>
              <p>
                {{ listing?.desiredPricePerSquareMeter | number : "1.0-2" }}
                kr./m<sup>2</sup>
              </p>
              <!-- <span
                class="w-2 h-2 rounded-full bg-[#747F81] flex-shrink-0"
              ></span>
              <icon-copyright></icon-copyright> -->
            </div>
            <button
              class="w-full max-w-[225px] !mt-6"
              mat-flat-button
              color="primary"
            >
              <div class="flex items-center justify-center gap-[10px]">
                <span class="uppercase leading-5"> Kontakt sælger</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Foretrukne Ejendomme">
    <div *ngIf="screen_wmin_1380.matches && !loadingProperties" class="mt-4">
      <div
        *ngFor="let listing of favoriteListings"
        class="live-property-container hover:shadow-live-listing mb-5"
      >
        <div class="w-[304px] h-[204px] rounded-[24px] bg-[#E5EAE4] m-2">
          <img
            class="w-[304px] h-[204px] rounded-[24px] bg-[#E5EAE4]"
            [src]="url"
            [ngStyle]="{
              objectFit:
                url === '/assets/home/listing/no-picture.svg' ? 'none' : 'fill'
            }"
          />
        </div>

        <div class="flex gap-9 flex-wrap flex-1 justify-between my-2 mx-6">
          <div class="flex flex-col gap-3">
            <p class="font-semibold text-xl leading-[25px] text-black">
              {{ listing?.listingTitle }}
            </p>
            <div class="leading-5 text-black">
              <p>
                {{ listing?.address?.street }}
                {{ listing?.address?.buildingNumber }},
              </p>
              <p>
                {{ listing?.address?.postalCode }}
                {{ listing?.address?.city }}
              </p>
            </div>
            <div class="leading-5 text-black">
              <p>Ejendomstype</p>
              <p>
                {{ displaySelectText(_listingTypes, listing?.typeOfListing) }}
              </p>
            </div>
          </div>

          <div class="flex flex-col gap-3 leading-5 text-black">
            <div class="flex items-center gap-2">
              <icon-house-simple></icon-house-simple>
              <p>
                Etageareal
                {{ listing?.totalLandArea | number : "1.0-2" }} m<sup>2</sup>
              </p>
            </div>
            <div class="flex items-center gap-2">
              <icon-trend-up></icon-trend-up>
              <p>Afkast {{ listing?.finalBaseReturn }} %</p>
            </div>
            <!-- <div class="flex items-center gap-2">
              <icon-copyright></icon-copyright>
              <p>Energimærke</p>
            </div> -->
          </div>

          <div class="flex flex-col gap-6">
            <div class="leading-5 text-black">
              <p>Pris</p>
              <p class="font-semibold text-xl leading-[25px]">
                {{ listing?.price | number : "1.0-2" }} kr.
              </p>
            </div>
            <button
              class="w-full max-w-[180px] !mb-4"
              mat-flat-button
              color="primary"
            >
              <div class="flex items-center justify-center gap-[10px]">
                <span class="uppercase leading-5"> Kontakt sælger</span>
              </div>
            </button>
          </div>

          <icon-heart-fav
            class="cursor-pointer"
            [isFav]="true"
            (click)="toogleFavorite(listing?.listingId, true)"
          ></icon-heart-fav>
        </div>
      </div>
    </div>

    <div
      *ngIf="!screen_wmin_1380.matches && !loadingProperties"
      class="properties-container mt-4"
    >
      <div
        *ngFor="let listing of favoriteListings"
        class="rounded-[28px] border border-solid border-[#D1D8D9] hover:shadow-live-listing"
      >
        <div class="rounded-[24px] bg-[#E5EAE4] m-2 relative">
          <img
            class="w-full h-full rounded-[24px] bg-[#E5EAE4]"
            [src]="url"
            [ngStyle]="{
              objectFit:
                url === '/assets/home/listing/no-picture.svg' ? 'none' : 'fill'
            }"
          />
          <div
            class="cursor-pointer absolute top-3 right-3 rounded-full w-12 h-12 bg-white flex items-center justify-center"
          >
            <icon-heart-fav
              [isFav]="true"
              (click)="toogleFavorite(listing?.listingId, true)"
            ></icon-heart-fav>
          </div>
        </div>

        <div class="mx-[28px] my-6">
          <div class="flex justify-between gap-6 items-center flex-wrap">
            <p class="text-sm leading-[18px] text-primary">
              {{ displaySelectText(_listingTypes, listing?.typeOfListing) }}
            </p>
            <p class="font-semibold text-xl leading-[25px] text-black">
              {{ listing?.price | number : "1.0-2" }} kr.
            </p>
          </div>
          <p class="font-semibold text-lg leading-[23px] text-black mt-[10px]">
            {{ listing?.listingTitle }}
          </p>

          <p class="leading-[20px] text-black mt-[10px]">
            {{ listing?.address?.street }}
            {{ listing?.address?.buildingNumber }},
            {{ listing?.address?.postalCode }}
            {{ listing?.address?.city }}
          </p>

          <div
            class="leading-[20px] text-black mt-[10px] flex justify-start items-center gap-[10px] flex-wrap"
          >
            <p>{{ listing?.totalLandArea | number : "1.0-2" }} m<sup>2</sup></p>
            <span
              class="w-2 h-2 rounded-full bg-[#747F81] flex-shrink-0"
            ></span>
            <p>
              {{ listing?.pricePerSquareMeter | number : "1.0-2" }}
              kr./m<sup>2</sup>
            </p>
            <!-- <span
              class="w-2 h-2 rounded-full bg-[#747F81] flex-shrink-0"
            ></span>
            <icon-copyright></icon-copyright> -->
          </div>
          <button
            class="w-full max-w-[225px] !mt-6"
            mat-flat-button
            color="primary"
          >
            <div class="flex items-center justify-center gap-[10px]">
              <span class="uppercase leading-5"> Kontakt sælger</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>

export const PHONE_CODES = [
  {
    name: 'Afghanistan',
    text: '+93',
    value: 'AF',
  },
  {
    name: 'Aland Islands',
    text: '+358',
    value: 'AX',
  },
  {
    name: 'Albania',
    text: '+355',
    value: 'AL',
  },
  {
    name: 'Algeria',
    text: '+213',
    value: 'DZ',
  },
  {
    name: 'AmericanSamoa',
    text: '+1684',
    value: 'AS',
  },
  {
    name: 'Andorra',
    text: '+376',
    value: 'AD',
  },
  {
    name: 'Angola',
    text: '+244',
    value: 'AO',
  },
  {
    name: 'Anguilla',
    text: '+1264',
    value: 'AI',
  },
  {
    name: 'Antarctica',
    text: '+672',
    value: 'AQ',
  },
  {
    name: 'Antigua and Barbuda',
    text: '+1268',
    value: 'AG',
  },
  {
    name: 'Argentina',
    text: '+54',
    value: 'AR',
  },
  {
    name: 'Armenia',
    text: '+374',
    value: 'AM',
  },
  {
    name: 'Aruba',
    text: '+297',
    value: 'AW',
  },
  {
    name: 'Australia',
    text: '+61',
    value: 'AU',
  },
  {
    name: 'Austria',
    text: '+43',
    value: 'AT',
  },
  {
    name: 'Azerbaijan',
    text: '+994',
    value: 'AZ',
  },
  {
    name: 'Bahamas',
    text: '+1242',
    value: 'BS',
  },
  {
    name: 'Bahrain',
    text: '+973',
    value: 'BH',
  },
  {
    name: 'Bangladesh',
    text: '+880',
    value: 'BD',
  },
  {
    name: 'Barbados',
    text: '+1246',
    value: 'BB',
  },
  {
    name: 'Belarus',
    text: '+375',
    value: 'BY',
  },
  {
    name: 'Belgium',
    text: '+32',
    value: 'BE',
  },
  {
    name: 'Belize',
    text: '+501',
    value: 'BZ',
  },
  {
    name: 'Benin',
    text: '+229',
    value: 'BJ',
  },
  {
    name: 'Bermuda',
    text: '+1441',
    value: 'BM',
  },
  {
    name: 'Bhutan',
    text: '+975',
    value: 'BT',
  },
  {
    name: 'Bolivia, Plurinational State of',
    text: '+591',
    value: 'BO',
  },
  {
    name: 'Bosnia and Herzegovina',
    text: '+387',
    value: 'BA',
  },
  {
    name: 'Botswana',
    text: '+267',
    value: 'BW',
  },
  {
    name: 'Brazil',
    text: '+55',
    value: 'BR',
  },
  {
    name: 'British Indian Ocean Territory',
    text: '+246',
    value: 'IO',
  },
  {
    name: 'Brunei Darussalam',
    text: '+673',
    value: 'BN',
  },
  {
    name: 'Bulgaria',
    text: '+359',
    value: 'BG',
  },
  {
    name: 'Burkina Faso',
    text: '+226',
    value: 'BF',
  },
  {
    name: 'Burundi',
    text: '+257',
    value: 'BI',
  },
  {
    name: 'Cambodia',
    text: '+855',
    value: 'KH',
  },
  {
    name: 'Cameroon',
    text: '+237',
    value: 'CM',
  },
  {
    name: 'Canada',
    text: '+1',
    value: 'CA',
  },
  {
    name: 'Cape Verde',
    text: '+238',
    value: 'CV',
  },
  {
    name: 'Cayman Islands',
    text: '+ 345',
    value: 'KY',
  },
  {
    name: 'Central African Republic',
    text: '+236',
    value: 'CF',
  },
  {
    name: 'Chad',
    text: '+235',
    value: 'TD',
  },
  {
    name: 'Chile',
    text: '+56',
    value: 'CL',
  },
  {
    name: 'China',
    text: '+86',
    value: 'CN',
  },
  {
    name: 'Christmas Island',
    text: '+61',
    value: 'CX',
  },
  {
    name: 'Cocos (Keeling) Islands',
    text: '+61',
    value: 'CC',
  },
  {
    name: 'Colombia',
    text: '+57',
    value: 'CO',
  },
  {
    name: 'Comoros',
    text: '+269',
    value: 'KM',
  },
  {
    name: 'Congo',
    text: '+242',
    value: 'CG',
  },
  {
    name: 'Congo, The Democratic Republic of the Congo',
    text: '+243',
    value: 'CD',
  },
  {
    name: 'Cook Islands',
    text: '+682',
    value: 'CK',
  },
  {
    name: 'Costa Rica',
    text: '+506',
    value: 'CR',
  },
  {
    name: "Cote d'Ivoire",
    text: '+225',
    value: 'CI',
  },
  {
    name: 'Croatia',
    text: '+385',
    value: 'HR',
  },
  {
    name: 'Cuba',
    text: '+53',
    value: 'CU',
  },
  {
    name: 'Cyprus',
    text: '+357',
    value: 'CY',
  },
  {
    name: 'Czech Republic',
    text: '+420',
    value: 'CZ',
  },
  {
    name: 'Denmark',
    text: '+45',
    value: 'DK',
  },
  {
    name: 'Djibouti',
    text: '+253',
    value: 'DJ',
  },
  {
    name: 'Dominica',
    text: '+1767',
    value: 'DM',
  },
  {
    name: 'Dominican Republic',
    text: '+1849',
    value: 'DO',
  },
  {
    name: 'Ecuador',
    text: '+593',
    value: 'EC',
  },
  {
    name: 'Egypt',
    text: '+20',
    value: 'EG',
  },
  {
    name: 'El Salvador',
    text: '+503',
    value: 'SV',
  },
  {
    name: 'Equatorial Guinea',
    text: '+240',
    value: 'GQ',
  },
  {
    name: 'Eritrea',
    text: '+291',
    value: 'ER',
  },
  {
    name: 'Estonia',
    text: '+372',
    value: 'EE',
  },
  {
    name: 'Ethiopia',
    text: '+251',
    value: 'ET',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    text: '+500',
    value: 'FK',
  },
  {
    name: 'Faroe Islands',
    text: '+298',
    value: 'FO',
  },
  {
    name: 'Fiji',
    text: '+679',
    value: 'FJ',
  },
  {
    name: 'Finland',
    text: '+358',
    value: 'FI',
  },
  {
    name: 'France',
    text: '+33',
    value: 'FR',
  },
  {
    name: 'French Guiana',
    text: '+594',
    value: 'GF',
  },
  {
    name: 'French Polynesia',
    text: '+689',
    value: 'PF',
  },
  {
    name: 'Gabon',
    text: '+241',
    value: 'GA',
  },
  {
    name: 'Gambia',
    text: '+220',
    value: 'GM',
  },
  {
    name: 'Georgia',
    text: '+995',
    value: 'GE',
  },
  {
    name: 'Germany',
    text: '+49',
    value: 'DE',
  },
  {
    name: 'Ghana',
    text: '+233',
    value: 'GH',
  },
  {
    name: 'Gibraltar',
    text: '+350',
    value: 'GI',
  },
  {
    name: 'Greece',
    text: '+30',
    value: 'GR',
  },
  {
    name: 'Greenland',
    text: '+299',
    value: 'GL',
  },
  {
    name: 'Grenada',
    text: '+1473',
    value: 'GD',
  },
  {
    name: 'Guadeloupe',
    text: '+590',
    value: 'GP',
  },
  {
    name: 'Guam',
    text: '+1671',
    value: 'GU',
  },
  {
    name: 'Guatemala',
    text: '+502',
    value: 'GT',
  },
  {
    name: 'Guernsey',
    text: '+44',
    value: 'GG',
  },
  {
    name: 'Guinea',
    text: '+224',
    value: 'GN',
  },
  {
    name: 'Guinea-Bissau',
    text: '+245',
    value: 'GW',
  },
  {
    name: 'Guyana',
    text: '+595',
    value: 'GY',
  },
  {
    name: 'Haiti',
    text: '+509',
    value: 'HT',
  },
  {
    name: 'Holy See (Vatican City State)',
    text: '+379',
    value: 'VA',
  },
  {
    name: 'Honduras',
    text: '+504',
    value: 'HN',
  },
  {
    name: 'Hong Kong',
    text: '+852',
    value: 'HK',
  },
  {
    name: 'Hungary',
    text: '+36',
    value: 'HU',
  },
  {
    name: 'Iceland',
    text: '+354',
    value: 'IS',
  },
  {
    name: 'India',
    text: '+91',
    value: 'IN',
  },
  {
    name: 'Indonesia',
    text: '+62',
    value: 'ID',
  },
  {
    name: 'Iran, Islamic Republic of Persian Gulf',
    text: '+98',
    value: 'IR',
  },
  {
    name: 'Iraq',
    text: '+964',
    value: 'IQ',
  },
  {
    name: 'Ireland',
    text: '+353',
    value: 'IE',
  },
  {
    name: 'Isle of Man',
    text: '+44',
    value: 'IM',
  },
  {
    name: 'Israel',
    text: '+972',
    value: 'IL',
  },
  {
    name: 'Italy',
    text: '+39',
    value: 'IT',
  },
  {
    name: 'Jamaica',
    text: '+1876',
    value: 'JM',
  },
  {
    name: 'Japan',
    text: '+81',
    value: 'JP',
  },
  {
    name: 'Jersey',
    text: '+44',
    value: 'JE',
  },
  {
    name: 'Jordan',
    text: '+962',
    value: 'JO',
  },
  {
    name: 'Kazakhstan',
    text: '+77',
    value: 'KZ',
  },
  {
    name: 'Kenya',
    text: '+254',
    value: 'KE',
  },
  {
    name: 'Kiribati',
    text: '+686',
    value: 'KI',
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    text: '+850',
    value: 'KP',
  },
  {
    name: 'Korea, Republic of South Korea',
    text: '+82',
    value: 'KR',
  },
  {
    name: 'Kuwait',
    text: '+965',
    value: 'KW',
  },
  {
    name: 'Kyrgyzstan',
    text: '+996',
    value: 'KG',
  },
  {
    name: 'Laos',
    text: '+856',
    value: 'LA',
  },
  {
    name: 'Latvia',
    text: '+371',
    value: 'LV',
  },
  {
    name: 'Lebanon',
    text: '+961',
    value: 'LB',
  },
  {
    name: 'Lesotho',
    text: '+266',
    value: 'LS',
  },
  {
    name: 'Liberia',
    text: '+231',
    value: 'LR',
  },
  {
    name: 'Libyan Arab Jamahiriya',
    text: '+218',
    value: 'LY',
  },
  {
    name: 'Liechtenstein',
    text: '+423',
    value: 'LI',
  },
  {
    name: 'Lithuania',
    text: '+370',
    value: 'LT',
  },
  {
    name: 'Luxembourg',
    text: '+352',
    value: 'LU',
  },
  {
    name: 'Macao',
    text: '+853',
    value: 'MO',
  },
  {
    name: 'Macedonia',
    text: '+389',
    value: 'MK',
  },
  {
    name: 'Madagascar',
    text: '+261',
    value: 'MG',
  },
  {
    name: 'Malawi',
    text: '+265',
    value: 'MW',
  },
  {
    name: 'Malaysia',
    text: '+60',
    value: 'MY',
  },
  {
    name: 'Maldives',
    text: '+960',
    value: 'MV',
  },
  {
    name: 'Mali',
    text: '+223',
    value: 'ML',
  },
  {
    name: 'Malta',
    text: '+356',
    value: 'MT',
  },
  {
    name: 'Marshall Islands',
    text: '+692',
    value: 'MH',
  },
  {
    name: 'Martinique',
    text: '+596',
    value: 'MQ',
  },
  {
    name: 'Mauritania',
    text: '+222',
    value: 'MR',
  },
  {
    name: 'Mauritius',
    text: '+230',
    value: 'MU',
  },
  {
    name: 'Mayotte',
    text: '+262',
    value: 'YT',
  },
  {
    name: 'Mexico',
    text: '+52',
    value: 'MX',
  },
  {
    name: 'Micronesia, Federated States of Micronesia',
    text: '+691',
    value: 'FM',
  },
  {
    name: 'Moldova',
    text: '+373',
    value: 'MD',
  },
  {
    name: 'Monaco',
    text: '+377',
    value: 'MC',
  },
  {
    name: 'Mongolia',
    text: '+976',
    value: 'MN',
  },
  {
    name: 'Montenegro',
    text: '+382',
    value: 'ME',
  },
  {
    name: 'Montserrat',
    text: '+1664',
    value: 'MS',
  },
  {
    name: 'Morocco',
    text: '+212',
    value: 'MA',
  },
  {
    name: 'Mozambique',
    text: '+258',
    value: 'MZ',
  },
  {
    name: 'Myanmar',
    text: '+95',
    value: 'MM',
  },
  {
    name: 'Namibia',
    text: '+264',
    value: 'NA',
  },
  {
    name: 'Nauru',
    text: '+674',
    value: 'NR',
  },
  {
    name: 'Nepal',
    text: '+977',
    value: 'NP',
  },
  {
    name: 'Netherlands',
    text: '+31',
    value: 'NL',
  },
  {
    name: 'Netherlands Antilles',
    text: '+599',
    value: 'AN',
  },
  {
    name: 'New Caledonia',
    text: '+687',
    value: 'NC',
  },
  {
    name: 'New Zealand',
    text: '+64',
    value: 'NZ',
  },
  {
    name: 'Nicaragua',
    text: '+505',
    value: 'NI',
  },
  {
    name: 'Niger',
    text: '+227',
    value: 'NE',
  },
  {
    name: 'Nigeria',
    text: '+234',
    value: 'NG',
  },
  {
    name: 'Niue',
    text: '+683',
    value: 'NU',
  },
  {
    name: 'Norfolk Island',
    text: '+672',
    value: 'NF',
  },
  {
    name: 'Northern Mariana Islands',
    text: '+1670',
    value: 'MP',
  },
  {
    name: 'Norway',
    text: '+47',
    value: 'NO',
  },
  {
    name: 'Oman',
    text: '+968',
    value: 'OM',
  },
  {
    name: 'Pakistan',
    text: '+92',
    value: 'PK',
  },
  {
    name: 'Palau',
    text: '+680',
    value: 'PW',
  },
  {
    name: 'Palestinian Territory, Occupied',
    text: '+970',
    value: 'PS',
  },
  {
    name: 'Panama',
    text: '+507',
    value: 'PA',
  },
  {
    name: 'Papua New Guinea',
    text: '+675',
    value: 'PG',
  },
  {
    name: 'Paraguay',
    text: '+595',
    value: 'PY',
  },
  {
    name: 'Peru',
    text: '+51',
    value: 'PE',
  },
  {
    name: 'Philippines',
    text: '+63',
    value: 'PH',
  },
  {
    name: 'Pitcairn',
    text: '+872',
    value: 'PN',
  },
  {
    name: 'Poland',
    text: '+48',
    value: 'PL',
  },
  {
    name: 'Portugal',
    text: '+351',
    value: 'PT',
  },
  {
    name: 'Puerto Rico',
    text: '+1939',
    value: 'PR',
  },
  {
    name: 'Qatar',
    text: '+974',
    value: 'QA',
  },
  {
    name: 'Romania',
    text: '+40',
    value: 'RO',
  },
  {
    name: 'Russia',
    text: '+7',
    value: 'RU',
  },
  {
    name: 'Rwanda',
    text: '+250',
    value: 'RW',
  },
  {
    name: 'Reunion',
    text: '+262',
    value: 'RE',
  },
  {
    name: 'Saint Barthelemy',
    text: '+590',
    value: 'BL',
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    text: '+290',
    value: 'SH',
  },
  {
    name: 'Saint Kitts and Nevis',
    text: '+1869',
    value: 'KN',
  },
  {
    name: 'Saint Lucia',
    text: '+1758',
    value: 'LC',
  },
  {
    name: 'Saint Martin',
    text: '+590',
    value: 'MF',
  },
  {
    name: 'Saint Pierre and Miquelon',
    text: '+508',
    value: 'PM',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    text: '+1784',
    value: 'VC',
  },
  {
    name: 'Samoa',
    text: '+685',
    value: 'WS',
  },
  {
    name: 'San Marino',
    text: '+378',
    value: 'SM',
  },
  {
    name: 'Sao Tome and Principe',
    text: '+239',
    value: 'ST',
  },
  {
    name: 'Saudi Arabia',
    text: '+966',
    value: 'SA',
  },
  {
    name: 'Senegal',
    text: '+221',
    value: 'SN',
  },
  {
    name: 'Serbia',
    text: '+381',
    value: 'RS',
  },
  {
    name: 'Seychelles',
    text: '+248',
    value: 'SC',
  },
  {
    name: 'Sierra Leone',
    text: '+232',
    value: 'SL',
  },
  {
    name: 'Singapore',
    text: '+65',
    value: 'SG',
  },
  {
    name: 'Slovakia',
    text: '+421',
    value: 'SK',
  },
  {
    name: 'Slovenia',
    text: '+386',
    value: 'SI',
  },
  {
    name: 'Solomon Islands',
    text: '+677',
    value: 'SB',
  },
  {
    name: 'Somalia',
    text: '+252',
    value: 'SO',
  },
  {
    name: 'South Africa',
    text: '+27',
    value: 'ZA',
  },
  {
    name: 'South Sudan',
    text: '+211',
    value: 'SS',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    text: '+500',
    value: 'GS',
  },
  {
    name: 'Spain',
    text: '+34',
    value: 'ES',
  },
  {
    name: 'Sri Lanka',
    text: '+94',
    value: 'LK',
  },
  {
    name: 'Sudan',
    text: '+249',
    value: 'SD',
  },
  {
    name: 'Suriname',
    text: '+597',
    value: 'SR',
  },
  {
    name: 'Svalbard and Jan Mayen',
    text: '+47',
    value: 'SJ',
  },
  {
    name: 'Swaziland',
    text: '+268',
    value: 'SZ',
  },
  {
    name: 'Sweden',
    text: '+46',
    value: 'SE',
  },
  {
    name: 'Switzerland',
    text: '+41',
    value: 'CH',
  },
  {
    name: 'Syrian Arab Republic',
    text: '+963',
    value: 'SY',
  },
  {
    name: 'Taiwan',
    text: '+886',
    value: 'TW',
  },
  {
    name: 'Tajikistan',
    text: '+992',
    value: 'TJ',
  },
  {
    name: 'Tanzania, United Republic of Tanzania',
    text: '+255',
    value: 'TZ',
  },
  {
    name: 'Thailand',
    text: '+66',
    value: 'TH',
  },
  {
    name: 'Timor-Leste',
    text: '+670',
    value: 'TL',
  },
  {
    name: 'Togo',
    text: '+228',
    value: 'TG',
  },
  {
    name: 'Tokelau',
    text: '+690',
    value: 'TK',
  },
  {
    name: 'Tonga',
    text: '+676',
    value: 'TO',
  },
  {
    name: 'Trinidad and Tobago',
    text: '+1868',
    value: 'TT',
  },
  {
    name: 'Tunisia',
    text: '+216',
    value: 'TN',
  },
  {
    name: 'Turkey',
    text: '+90',
    value: 'TR',
  },
  {
    name: 'Turkmenistan',
    text: '+993',
    value: 'TM',
  },
  {
    name: 'Turks and Caicos Islands',
    text: '+1649',
    value: 'TC',
  },
  {
    name: 'Tuvalu',
    text: '+688',
    value: 'TV',
  },
  {
    name: 'Uganda',
    text: '+256',
    value: 'UG',
  },
  {
    name: 'Ukraine',
    text: '+380',
    value: 'UA',
  },
  {
    name: 'United Arab Emirates',
    text: '+971',
    value: 'AE',
  },
  {
    name: 'United Kingdom',
    text: '+44',
    value: 'GB',
  },
  {
    name: 'United States',
    text: '+1',
    value: 'US',
  },
  {
    name: 'Uruguay',
    text: '+598',
    value: 'UY',
  },
  {
    name: 'Uzbekistan',
    text: '+998',
    value: 'UZ',
  },
  {
    name: 'Vanuatu',
    text: '+678',
    value: 'VU',
  },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    text: '+58',
    value: 'VE',
  },
  {
    name: 'Vietnam',
    text: '+84',
    value: 'VN',
  },
  {
    name: 'Virgin Islands, British',
    text: '+1284',
    value: 'VG',
  },
  {
    name: 'Virgin Islands, U.S.',
    text: '+1340',
    value: 'VI',
  },
  {
    name: 'Wallis and Futuna',
    text: '+681',
    value: 'WF',
  },
  {
    name: 'Yemen',
    text: '+967',
    value: 'YE',
  },
  {
    name: 'Zambia',
    text: '+260',
    value: 'ZM',
  },
  {
    name: 'Zimbabwe',
    text: '+263',
    value: 'ZW',
  },
];

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { SnackBarService } from 'src/snack-bar.service';
import { ForgotPasswordRequest } from 'src/app/_models/user.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-passwd.component.html',
  styleUrls: ['./forgot-passwd.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  isLoaderEnabled = new BehaviorSubject(false);
  responseErrorMessage = '';

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private snackService: SnackBarService,
    private _snackBar: MatSnackBar,
    private titleService: Title
  ) {
    this.titleService.setTitle('Ejos - Glemt adgangskode');
    this.forgotPasswordForm = this.fb.group({});
  }

  ngOnInit(): void {
    this.forgotPasswordForm = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  handleSubmit = () => {
    this.responseErrorMessage = '';

    if (this.forgotPasswordForm.status === 'VALID') {
      this.isLoaderEnabled.next(true);

      const forgotPasswordRequest: ForgotPasswordRequest = {
        email: this.email.value,
      };

      this.authService
        .generateForgotPasswordToken(forgotPasswordRequest)
        .subscribe({
          next: (res) => {
            this._snackBar.open(
              'Tjek venligst din indbakke for token-nummeret',
              'Dismiss',
              {
                duration: 3000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              }
            );
            this.isLoaderEnabled.next(false);
            this.router.navigate([
              '/user/reset-password',
              { email: res?.userResponse?.email },
            ]);
          },
          error: (err) => {
            this.isLoaderEnabled.next(false);
            if (err?.error?.messageStatus === 'EmailDoesNotExist')
              this._snackBar.open('E-mail-id ikke registreret!', 'Dismiss', {
                duration: 3000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              });
            else
              this._snackBar.open(
                'Server Fejl! Prøv venligst efter et stykke tid',
                'Dismiss',
                {
                  duration: 3000,
                  horizontalPosition: 'center',
                  verticalPosition: 'top',
                }
              );
          },
        });
    }
  };

  get email() {
    return this.forgotPasswordForm.get('email');
  }
}

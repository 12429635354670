import { Platform } from '@angular/cdk/platform';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MonthpickerDateAdapter } from './monthpicker-date-formats';

@Component({
  selector: 'monthpicker',
  templateUrl: './monthpicker.component.html',
  styleUrls: ['./monthpicker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MonthpickerDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform],
    },
  ],
})
export class MonthpickerComponent {
  @Input() monthAndYear: Date | null = null;
  @Input() mode: 'FORM' | 'SINGLE' = 'FORM';
  @Input() placeholder: string | null;
  @Input() tooltipMessage: string | null;
  @Input() control: FormControl;
  @Output() monthAndYearChange = new EventEmitter<Date | null>();

  maxDate = new Date();

  public emitDateChange(
    event: MatDatepickerInputEvent<Date | null, unknown>
  ): void {
    debugger;
    this.monthAndYear = event.value;
    this.monthAndYearChange.emit(event.value);
  }

  public monthChanged(value: any, widget: any): void {
    if (this.mode === 'SINGLE') this.monthAndYear = value;
    if (this.mode === 'FORM') {
      console.log(value);
      this.control.setValue(value);
    }
    widget.close();
  }
}

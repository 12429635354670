import { Address } from 'src/app/_models/address.model';

export interface SelectObject {
  value: string;
  text: string;
}

export const UNIT_TYPES: SelectObject[] = [
  { value: 'TerracedHouseFirstFloor', text: 'Rækkehus 1. plan' },
  { value: 'TerracedHouseSecondFloor', text: 'Rækkehus 2. plan' },
  { value: 'FloorOrApartment', text: 'Etage/lejlighed' },
  { value: 'OtherTypeOfHousing', text: 'Bolig anden type' },
  { value: 'BusinessOrRetail', text: 'Erhverv eller butik' },
];

export const NUMBER_OF_ROOMS: SelectObject[] = [
  { value: 'One', text: '1' },
  { value: 'Two', text: '2' },
  { value: 'Three', text: '3' },
  { value: 'Four', text: '4' },
  { value: 'Five', text: '5' },
  { value: 'Six', text: '6' },
  { value: 'MoreThanSix', text: '6+' },
];

export const TENANT: SelectObject[] = [
  { value: 'Private', text: 'Privat' },
  { value: 'Business', text: 'Erhverv' },
];

export const RENT_ADJUSTMENT: SelectObject[] = [
  { value: 'PredeterminedYearlyRentRise', text: 'Trappeleje' },
  { value: 'NetPriceIndex', text: 'Nettoprisindeks' },
  { value: 'NoRegulation', text: 'Ingen regulering' },
];

export const TERMINATION_DURATION: SelectObject[] = [
  { value: 'FourteenDays', text: '14 dage' },
  { value: 'ThirtyDays', text: '30 dage' },
  { value: 'TwoToTwentyThreeMonths', text: '2-3 måneder' },
  { value: 'MoreThanTwoYears', text: '24+ måneder' },
  { value: 'Irrevocable', text: 'Uopsigelig' },
];

<div
  class="h-full w-full flex justify-center items-start pt-[184px] w960xh1024:bg-no-repeat w960xh1024:bg-[length:100%_100%]"
  style="background-image: url('/assets/auth/login/login-bg.png')"
>
  <form
    [formGroup]="loginForm"
    (ngSubmit)="handleSubmit()"
    class="max-w-[360px] w-full mx-4"
    #form="ngForm"
  >
    <h2 class="font-semibold text-5xl leading-[48px] mb-12">Log ind</h2>

    <mat-form-field class="mb-6 w-full" appearance="fill">
      <mat-label>Email</mat-label>
      <input formControlName="email" matInput />
      <mat-error
        *ngIf="
          email.hasError('email') &&
          !email.hasError('required') &&
          form.submitted
        "
      >
        Indtast venligst en gyldig e-mailadresse
      </mat-error>
      <mat-error *ngIf="email.hasError('required')"> Påkrævet </mat-error>
    </mat-form-field>

    <mat-form-field class="mb-3 w-full" appearance="fill">
      <mat-label>Password</mat-label>
      <input
        formControlName="password"
        matInput
        [type]="hide ? 'password' : 'text'"
        autocomplete="off"
      />
      <icon-password-hide
        *ngIf="hide"
        (click)="hide = !hide"
        matSuffix
      ></icon-password-hide>
      <icon-password-visible
        *ngIf="!hide"
        matSuffix
        (click)="hide = !hide"
      ></icon-password-visible>
      <mat-error *ngIf="password.invalid && !password.hasError('required')">
        Indtast venligst en gyldig adgangskode
      </mat-error>
      <mat-error *ngIf="password.hasError('required')"> Påkrævet </mat-error>
    </mat-form-field>

    <a
      routerLink="/user/forgot-password"
      class="font-semibold text-base leading-5 mb-9 w-full text-right text-red block"
    >
      Glemt adgangskode?
    </a>

    <mat-checkbox color="primary" formControlName="rememberMe" class="mb-6">
      <span class="font-normal text-base leading-5 text-black">
        Husk mig næste gang
      </span>
    </mat-checkbox>

    <button
      type="submit"
      class="w-full"
      [ngClass]="{ 'animate-shake': form.invalid && form.submitted }"
      mat-flat-button
      color="primary"
    >
      <div *ngIf="loading" class="flex items-center justify-center">
        <icon-loading-button class="animate-spin"></icon-loading-button>
      </div>
      <span *ngIf="!loading">LOG IND</span>
    </button>

    <p class="font-normal text-base leading-5 mt-6 mb-4 text-black">
      Har du ikke en konto
      <a
        routerLink="/user/sign-up"
        class="underline cursor-pointer text-primary"
        >Opret dig her</a
      >
    </p>
  </form>
</div>

<div class="mt-[52px]">
  <h3 class="font-semibold text-[32px] leading-10 text-black">Opsummering</h3>
  <p class="text-[#747F81] mt-3">
    Her kan du læse en opsumering af hovedpunkterne i formidlingsaftale,
    salgspakke og salgsstrategi
  </p>
  <p class="font-semibold text-xl leading-[25px] text-black mt-10">
    Formidlingsaftale hovedpunkter
  </p>

  <div class="rounded-[20px] py-6 px-12 bg-[#EFF6F6] mt-5">
    <p class="font-semibold text-2xl leading-[30px] text-black">
      {{ listing?.primaryAddress?.street }}&nbsp;
      {{ listing?.primaryAddress?.buildingNumber }},&nbsp;
      {{ listing?.primaryAddress?.postalCode }}&nbsp;
      {{ listing?.primaryAddress?.city }}
    </p>
    <p
      *ngFor="let address of listing?.secondaryAddress"
      class="text-lg leading-6 text-black mt-3"
    >
      {{ address?.street }}&nbsp; {{ address?.buildingNumber }},&nbsp;
      {{ address?.postalCode }}&nbsp; {{ address?.city }} (sekundær adresse)
    </p>
    <p class="font-semibold leading-5 text-[#283F43] mt-2">
      Salgspris: {{ listing?.indicativePrice | number : "1.0-2" }} DKK
    </p>
    <p class="text-sm leading-[18px] text-black mt-2">
      Aftalen er bindende i 6 måneder
    </p>
  </div>

  <p class="font-semibold text-xl leading-[25px] text-black mt-12">
    Valgt pakke
  </p>
  <div
    class="rounded-[20px] py-6 px-12 bg-[#EFF6F6] mt-5 flex flex-wrap gap-[76px]"
  >
    <div class="flex flex-col gap-4 flex-1">
      <p class="font-semibold leading-5 text-black">Ejendomspakken</p>
      <div class="flex gap-3" *ngFor="let item of listing?.packageBenefits">
        <icon-check-green></icon-check-green>
        <p class="leading-5">{{ item }}</p>
      </div>
    </div>
    <div
      *ngIf="listing?.packageServiceAdditions?.length"
      class="flex flex-col gap-4 flex-1"
    >
      <p class="font-semibold leading-5 text-black">Tilvalg til pakken:</p>
      <div
        class="flex gap-3"
        *ngFor="let item of listing?.packageServiceAdditions"
      >
        <icon-check-green></icon-check-green>
        <p class="leading-5">{{ item?.serviceName }}</p>
      </div>
    </div>
  </div>

  <p class="font-semibold text-xl leading-[25px] text-black mt-12">
    Salgstrategi
  </p>
  <div
    class="rounded-[20px] py-6 px-12 bg-[#EFF6F6] mt-5 flex flex-wrap gap-[76px]"
  >
    <div class="flex flex-col gap-4 flex-1">
      <p class="font-semibold leading-5 text-black">Formidlingsstrategi</p>
      <div
        class="flex gap-3"
        *ngFor="let item of listing?.salesStrategyBenefits"
      >
        <icon-check-green></icon-check-green>
        <p class="leading-5">{{ item }}</p>
      </div>
    </div>
    <div
      *ngIf="listing?.strategyAdditions?.length"
      class="flex flex-col gap-4 flex-1"
    >
      <p class="font-semibold leading-5 text-black">
        Tilvalg til salgsstrategi:
      </p>
      <div class="flex gap-3" *ngFor="let item of listing?.strategyAdditions">
        <icon-check-green></icon-check-green>
        <p class="leading-5">{{ item?.serviceName }}</p>
      </div>
    </div>
  </div>

  <p class="font-semibold text-xl leading-[25px] text-black mt-12">Honorar</p>
  <div class="rounded-[20px] py-6 px-12 bg-[#EFF6F6] mt-5">
    <div
      class="flex justify-between items-center gap-2 flex-wrap max-w-[1440px]"
    >
      <p class="leading-5 text-black max-w-[540px] w-full">Ejendomspakken</p>
      <p class="leading-5 text-black">
        {{ listing?.packagePrice | number : "1.0-2" }} kr.
      </p>
    </div>
    <div
      *ngFor="let item of listing?.packageServiceAdditions"
      class="flex justify-between items-center gap-2 flex-wrap max-w-[1440px] mt-6"
    >
      <p class="leading-5 text-black max-w-[540px] w-full">
        {{ item?.serviceName }}
      </p>
      <p class="leading-5 text-black">
        {{ item?.price | number : "1.0-2" }} kr.
      </p>
    </div>

    <div
      class="flex justify-between items-center gap-2 flex-wrap max-w-[1440px] mt-6"
    >
      <p class="leading-5 text-black max-w-[540px] w-full">
        Salgsstrategi - {{ listing?.salesStrategyName }}
      </p>
      <p class="leading-5 text-black">
        {{ listing?.saleStrategyPrice | number : "1.0-2" }} kr.
      </p>
    </div>
    <div
      *ngFor="let item of listing?.strategyAdditions"
      class="flex justify-between items-center gap-2 flex-wrap max-w-[1440px] mt-6"
    >
      <p class="leading-5 text-black max-w-[540px] w-full">
        {{ item?.serviceName }}
      </p>
      <p class="leading-5 text-black">
        {{ item?.price | number : "1.0-2" }} kr.
      </p>
    </div>
    <hr class="border-t border-solid border-[#D1D8D9] mt-4" />
    <div
      class="flex justify-between items-center gap-2 flex-wrap max-w-[1440px] mt-2"
    >
      <p class="font-semibold leading-5 text-black max-w-[540px] w-full">
        Honorar ved salg hos EJOS
      </p>
      <p class="font-semibold leading-5 text-black">
        {{ listing?.totalSumForPackagesAndStrategies | number : "1.0-2" }} kr.
      </p>
    </div>
  </div>

  <p
    *ngIf="savingsParameters?.rivalCommission"
    class="font-semibold leading-5 text-blac mt-6"
  >
    Din besparelse ved salg hos EJOS DKK
    {{ moneySavedInDkk | number : "1.0-2" }} =
    {{ moneySavedInPercentage | number : "1.0-2" }} % Besparelse (i forhold til
    standardhonorar)
  </p>
  <p
    *ngIf="savingsParameters?.rivalCommission"
    class="text-sm leading-[18px] text-black mt-2"
  >
    Estimeret standardhonorar andre mæglere DKK
    {{ rivalCommissionInDkk | number : "1.0-2" }}
  </p>

  <div
    class="border-t border-solid border-[#D1D8D9] pt-7 pb-10 flex flex-wrap gap-8 justify-between mt-10"
  >
    <div>
      <p class="leading-5 text-black">Samlet honorar</p>
      <p class="font-semibold text-2xl leading-[30px]">
        {{ listing?.totalSumForPackagesAndStrategies | number : "1.0-2" }} kr.
      </p>
    </div>

    <div class="flex items-center gap-3 w-full max-w-[364px]">
      <button
        class="!py-[15px] !px-[25px] !border !border-solid !border-[#D1D8D9] !rounded-[90px] !min-w-fit"
        mat-flat-button
        (click)="onPrevClicked()"
      >
        <icon-caret-left></icon-caret-left>
      </button>
      <button
        class="w-full max-w-[274px]"
        mat-flat-button
        color="primary"
        (click)="onNextClicked()"
      >
        <div *ngIf="loading" class="flex items-center justify-center">
          <icon-loading-button class="animate-spin"></icon-loading-button>
        </div>
        <span *ngIf="!loading">GEM & FORTSÆT</span>
      </button>
    </div>
  </div>
</div>

<svg
  width="32"
  height="32"
  viewBox="0 0 32 32"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    opacity="0.2"
    d="M5 10H11V6C11 5.73478 10.8946 5.48043 10.7071 5.29289C10.5196 5.10536 10.2652 5 10 5H6C5.73478 5 5.48043 5.10536 5.29289 5.29289C5.10536 5.48043 5 5.73478 5 6V10Z"
    fill="#24535B"
  />
  <path
    opacity="0.2"
    d="M11 22H17V6C17 5.73478 16.8946 5.48043 16.7071 5.29289C16.5196 5.10536 16.2652 5 16 5H12C11.7348 5 11.4804 5.10536 11.2929 5.29289C11.1054 5.48043 11 5.73478 11 6V22Z"
    fill="#24535B"
  />
  <path
    opacity="0.2"
    d="M21.3999 22.4L27.1999 20.8375L28.2374 24.7C28.2713 24.8272 28.2798 24.9599 28.2623 25.0904C28.2449 25.2209 28.2018 25.3467 28.1356 25.4605C28.0694 25.5743 27.9814 25.6739 27.8766 25.7536C27.7718 25.8334 27.6523 25.8916 27.5249 25.925L23.6624 26.9625C23.4072 27.0315 23.135 26.9965 22.9054 26.8654C22.6758 26.7342 22.5076 26.5174 22.4374 26.2625L21.3999 22.4Z"
    fill="#24535B"
  />
  <path
    opacity="0.2"
    d="M18.3 10.8L24.1 9.25L23.0625 5.3875C22.9921 5.13208 22.8245 4.91442 22.5954 4.78122C22.3664 4.64801 22.0943 4.60988 21.8375 4.675L17.975 5.7125C17.7196 5.78287 17.5019 5.95056 17.3687 6.17957C17.2355 6.40859 17.1974 6.68069 17.2625 6.9375L18.3 10.8Z"
    fill="#24535B"
  />
  <path
    d="M10 5H6C5.44772 5 5 5.44772 5 6V26C5 26.5523 5.44772 27 6 27H10C10.5523 27 11 26.5523 11 26V6C11 5.44772 10.5523 5 10 5Z"
    stroke="#24535B"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M5 10H11"
    stroke="#24535B"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M16 5H12C11.4477 5 11 5.44772 11 6V26C11 26.5523 11.4477 27 12 27H16C16.5523 27 17 26.5523 17 26V6C17 5.44772 16.5523 5 16 5Z"
    stroke="#24535B"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M11 22H17"
    stroke="#24535B"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M21.8304 4.67983L17.9667 5.7151C17.4332 5.85805 17.1166 6.40638 17.2596 6.93985L22.4359 26.2584C22.5789 26.7918 23.1272 27.1084 23.6607 26.9655L27.5244 25.9302C28.0578 25.7873 28.3744 25.2389 28.2315 24.7055L23.0551 5.38693C22.9122 4.85347 22.3638 4.53689 21.8304 4.67983Z"
    stroke="#24535B"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M21.3999 22.4L27.1999 20.8375"
    stroke="#24535B"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M18.3 10.8L24.1 9.25"
    stroke="#24535B"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<svg
  width="32"
  height="32"
  viewBox="0 0 32 32"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    opacity="0.2"
    d="M15.3374 3.23751L5.24988 5.25001L3.23738 15.3375C3.20585 15.4986 3.21444 15.6649 3.2624 15.8219C3.31036 15.9788 3.39621 16.1216 3.51239 16.2375L16.5624 29.2875C16.6544 29.3816 16.7642 29.4564 16.8855 29.5075C17.0068 29.5585 17.137 29.5848 17.2686 29.5848C17.4002 29.5848 17.5305 29.5585 17.6518 29.5075C17.7731 29.4564 17.8829 29.3816 17.9749 29.2875L29.2874 17.975C29.3815 17.883 29.4563 17.7732 29.5073 17.6519C29.5584 17.5306 29.5847 17.4003 29.5847 17.2688C29.5847 17.1372 29.5584 17.0069 29.5073 16.8856C29.4563 16.7643 29.3815 16.6545 29.2874 16.5625L16.2374 3.51251C16.1215 3.39633 15.9787 3.31048 15.8218 3.26252C15.6648 3.21456 15.4984 3.20597 15.3374 3.23751Z"
    fill="white"
  />
  <path
    d="M15.3374 3.23751L5.24988 5.25001L3.23738 15.3375C3.20585 15.4986 3.21444 15.6649 3.2624 15.8219C3.31036 15.9788 3.39621 16.1216 3.51239 16.2375L16.5624 29.2875C16.6544 29.3816 16.7642 29.4564 16.8855 29.5075C17.0068 29.5585 17.137 29.5848 17.2686 29.5848C17.4002 29.5848 17.5305 29.5585 17.6518 29.5075C17.7731 29.4564 17.8829 29.3816 17.9749 29.2875L29.2874 17.975C29.3815 17.883 29.4563 17.7732 29.5073 17.6519C29.5584 17.5306 29.5847 17.4003 29.5847 17.2688C29.5847 17.1372 29.5584 17.0069 29.5073 16.8856C29.4563 16.7643 29.3815 16.6545 29.2874 16.5625L16.2374 3.51251C16.1215 3.39633 15.9787 3.31048 15.8218 3.26252C15.6648 3.21456 15.4984 3.20597 15.3374 3.23751V3.23751Z"
    stroke="white"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M10.5 12C11.3284 12 12 11.3284 12 10.5C12 9.67157 11.3284 9 10.5 9C9.67157 9 9 9.67157 9 10.5C9 11.3284 9.67157 12 10.5 12Z"
    fill="white"
  />
</svg>

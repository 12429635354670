import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-home-create-property',
  templateUrl: './home-create-property.component.html',
  styleUrls: ['./home-create-property.component.scss'],
})
export class HomeCreatePropertyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

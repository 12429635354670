import { Component, OnInit, Input } from '@angular/core';
import {
  shakeAnimation,
  fadeInOnEnterAnimation,
  fadeOutOnLeaveAnimation,
} from 'angular-animations';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'submit-button',
  templateUrl: './submit-button.component.html',
  styleUrls: ['./submit-button.component.scss'],
  animations: [shakeAnimation()],
})
export class SubmitButtonComponent implements OnInit {
  @Input() group: any;
  @Input() title: string = '';
  @Input() isLoaderEnabled: BehaviorSubject<boolean> | undefined;
  @Input() onClick = () => {};
  @Input() isSubmit = true;
  @Input() mode: 'DARK' | 'LIGHT' = 'DARK';

  isLoaderEnabledLocale = false;

  constructor() {}

  ngOnInit(): void {
    if (this.isLoaderEnabled) {
      this.isLoaderEnabled.subscribe((newVal) => {
        this.isLoaderEnabledLocale = newVal;
      });
    }
  }

  handleClick = () => {
    this.onClick();
  };
}

<div class="h-full w-full flex justify-center items-start pt-[184px]">
  <form
    [formGroup]="forgotPasswordForm"
    (submit)="handleSubmit()"
    class="max-w-[360px] w-full mx-4 relative"
    #form="ngForm"
  >
    <h2 class="font-semibold text-5xl leading-[48px] mb-5">
      Glemt adgangskode?
    </h2>

    <p class="font-normal text-base leading-5 mb-8">
      Indtast din mail herunder og vi sender dig en token til at lave en ny
      adgangskode med
    </p>

    <mat-form-field class="mb-6 w-full" appearance="fill">
      <mat-label>Email</mat-label>
      <input formControlName="email" matInput />
      <mat-error
        *ngIf="
          email.hasError('email') &&
          !email.hasError('required') &&
          form.submitted
        "
      >
        Indtast venligst en gyldig e-mailadresse
      </mat-error>
      <mat-error *ngIf="email.hasError('required')"> Påkrævet </mat-error>
    </mat-form-field>

    <button
      type="submit"
      class="w-full"
      [ngClass]="{ 'animate-shake': form.invalid && form.submitted }"
      mat-flat-button
      color="primary"
    >
      <div
        *ngIf="isLoaderEnabled?.value"
        class="flex items-center justify-center"
      >
        <icon-loading-button class="animate-spin"></icon-loading-button>
      </div>
      <span class="uppercase" *ngIf="!isLoaderEnabled?.value"
        >Send ny adgangskode</span
      >
    </button>

    <button
      type="button"
      class="!py-[15px] !px-[20px] !border !border-solid !border-[#D1D8D9] !rounded-[90px] !w-full !my-4 !leading-none"
      mat-button
      [routerLink]="['/user/login', email.value]"
    >
      <div class="flex justify-center items-center relative">
        <icon-caret-left class="absolute left-0" matPrefix></icon-caret-left>
        <span class="uppercase text-black font-semibold text-base leading-5"
          >Tilbage til login</span
        >
      </div>
    </button>
  </form>
</div>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-sparkle-hub',
  templateUrl: './sparkle-hub.component.html',
  styleUrls: ['./sparkle-hub.component.scss'],
})
export class SparkleHubComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SnackBarService } from 'src/snack-bar.service';

@Component({
  selector: 'image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent implements OnInit {
  @Input() _id: string;
  @Input() _width: string;
  @Input() _height: string;
  @Input() group: FormGroup;
  @Input() imagesFiles: any;
  @Input() mode: string = 'FORM';

  // @Output() emitFiles: EventEmitter<any> = new EventEmitter<any>();
  // files: any[] = [];

  url: any;
  isUploaded: boolean = false;
  isLoaderEnabled = false;
  viewModal = false;

  constructor(private snackService: SnackBarService) {
    this.url = '/assets/image-placeholder-white.svg';
  }

  ngOnInit(): void {
    if (this.imagesFiles) this.onFileChange([this.imagesFiles]);
  }

  viewImage() {
    if (this.url !== '/assets/image-placeholder-white.svg')
      this.viewModal = true;
  }
  // When the user clicks on <span> (x), close the modal
  closeModal() {
    this.viewModal = false;
  }

  // When the user clicks on delet bin, chnage the url back to default
  deleteImage() {
    this.isUploaded = false;
    this.url = '/assets/image-placeholder-white.svg';
  }

  // Upload Image file code
  onFileChange(pFileList: File[]) {
    // Get list of files to be uploaded
    let files = Object.keys(pFileList).map((key) => pFileList[key]);
    let file;
    this.isLoaderEnabled = true;

    // filter files based on size
    // let imagesMorethan10MB = this.files(file);

    //filter images based on type
    // let imagesOfValidTypes = this.files(file);

    // this.emitFiles.emit(this.files);

    // console.log('total files', files.length);
    for (let i = 0; i < files.length; i++) {
      this.isLoaderEnabled = true;
      let reader = new FileReader();
      file = files[i];
      // console.log(file.name);
      reader.onload = (file) => {
        // called once readAsDataURL is completed
        this.isUploaded = true;

        this.url = file.target.result;
        this.isLoaderEnabled = false;

        // if (i === files.length - 1)
        //   this.snackService.createNotification({
        //     title: 'Upload Successfull',
        //     isPositive: true,
        //     isAutoDissmissEnabled: true,
        //   });
      };
      reader.readAsDataURL(file);
    }
  }
}

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="timer"
  [fullScreen]="true"
  ><p style="color: white">Loading...</p></ngx-spinner
>

<div class="flex justify-between items-center gap-10 mb-5">
  <p class="text-black text-2xl font-semibold leading-[30px] !m-0">
    Vælg template fra {{ this.data?.city }}
  </p>
  <icon-close-dialog
    class="cursor-pointer"
    mat-dialog-close
  ></icon-close-dialog>
</div>

<mat-dialog-content
  *ngIf="!loading"
  class="rounded-lg !overflow-x-hidden !max-w-[1092px] !max-h-[708px]"
>
  <form
    #firstForm="ngForm"
    id="pickDescriptionForm"
    [formGroup]="pickDescriptionForm"
    (ngSubmit)="selectDescription()"
    class="mt-5"
  >
    <div
      *ngIf="!areaDescriptions.length"
      class="flex flex-col justify-center items-center gap-4 py-[32px]"
    >
      <icon-no-descriptions-available></icon-no-descriptions-available>
      <p class="font-semibold text-xl leading-[25px] text-black">
        Ingen templates blev fundet for dette område
      </p>
      <p class="text-sm leading-[18px] text-black">
        Prøv igen senere eller udfyld områdebeskrivelsen selv
      </p>
    </div>

    <mat-radio-group
      *ngIf="areaDescriptions.length"
      class="flex items-center justify-center gap-6 flex-wrap mt-[32px] mb-[36px] !overflow-y-auto max-h-[435px]"
      formControlName="areaDescription"
    >
      <div
        *ngFor="let item of areaDescriptions"
        class="p-4 flex flex-col gap-6 border border-solid border-[#D1D8D9] max-w-[410px] rounded-[20px]"
        [ngClass]="{
          'border-[#24535b] ': areaDescription.value === item.description
        }"
      >
        <mat-radio-button [value]="item.description">
          <span class="font-semibold text-lg leading[22px] text-black">{{
            item.title
          }}</span>
        </mat-radio-button>
        <p class="text-sm leading-5 text-black">
          {{ item.description }}
        </p>
      </div>
    </mat-radio-group>
    <mat-error
      *ngIf="areaDescription.hasError('required') && firstForm.submitted"
    >
      <span class="text-sm"> *Påkrævet</span>
    </mat-error>
  </form>
</mat-dialog-content>

<div
  class="flex flex-wrap items-center gap-4 border-t pt-7 border-solid border-[#D1D8D9]"
>
  <button
    mat-flat-button
    type="submit"
    color="primary"
    form="pickDescriptionForm"
    class="!flex-1 !min-w-fit uppercase"
    [disabled]="!areaDescriptions.length"
    [ngClass]="{
      'animate-shake':
        pickDescriptionForm.invalid && pickDescriptionFormSubmitted
    }"
  >
    Bekræft
  </button>
  <button
    mat-dialog-close
    class="bg-white border border-solid border-[#D1D8D9] py-[14px] px-5 uppercase rounded-[90px] flex-1"
  >
    <span class="font-semibold leading-5 text-black">Annuller</span>
  </button>
</div>

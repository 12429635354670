<div class="mt-[52px]">
  <h3 class="font-semibold text-[32px] leading-10 text-black">
    2. Lejeindtægter
  </h3>
  <p class="text-[#747F81] mt-3">
    I nedenstående skal du oplyse lejeindtægt mv. for hver enkelt
    bolig-/erhvervslejemål i ejendommen.
  </p>

  <div class="mt-6 max-w-[628px] w-full">
    <button
      class="flex items-center justify-start gap-[10px]"
      type="button"
      (click)="addNewUnit()"
    >
      <icon-plus></icon-plus>
      <span class="font-semibold text-base leading-20 text-red uppercase"
        >TILFØJ NY ENHED</span
      >
    </button>
  </div>

  <div class="!overflow-x-auto mt-8">
    <table class="w-full">
      <thead>
        <tr>
          <th>Adresse</th>
          <th>Antal rum/ værelser</th>
          <th>Areal (m<sup>2</sup>)</th>
          <th>Månedlig leje (kr.)</th>
          <th>Årlig leje (kr)</th>
          <th>Årlig leje pr. (m<sup>2</sup>) (kr.)</th>
          <th>Depositum og forudbetalt leje (kr)</th>
          <th>Husleje- principper</th>
          <th>Indflytnings- dato</th>
          <th></th>
        </tr>
      </thead>
      <tbody *ngIf="units?.length">
        <tr *ngFor="let unit of units; index as i">
          <td>
            <p class="text-xs leading-[15px] text-[#283F43] mb-1">
              Type:&nbsp;{{ displaySelectText(_unitType, unit?.unitType) }}
            </p>
            <p class="text-sm leading-[18px] text-black">
              {{ unit?.primaryAddress?.street }}&nbsp;{{
                unit?.primaryAddress?.buildingNumber
              }}
            </p>
            <div
              *ngIf="!unit?.notes"
              class="mt-4 flex gap-1 items-center flex-nowrap"
            >
              <icon-text-align-right-twenty
                strokeColor="#747F81"
              ></icon-text-align-right-twenty>
              <span class="text-xs leading-[15px] text-[#747F81]">No note</span>
            </div>
            <div
              *ngIf="unit?.notes"
              class="mt-4 flex gap-1 items-center flex-nowrap"
            >
              <icon-text-align-right-twenty
                strokeColor="#283F43"
              ></icon-text-align-right-twenty>
              <span class="text-xs leading-[15px] text-[#283F43]">{{
                unit?.notes
              }}</span>
            </div>
          </td>
          <td>
            <span class="text-sm leading-[18px] text-black">{{
              displaySelectText(_numberOfRooms, unit?.numberOfRooms)
            }}</span>
          </td>
          <td>
            <span class="text-sm leading-[18px] text-black">{{
              unit?.unitArea | number : "1.0-2"
            }}</span>
          </td>
          <td>
            <span class="text-sm leading-[18px] text-black">{{
              unit?.monthlyRent | number : "1.0-2"
            }}</span>
          </td>
          <td>
            <span class="text-sm leading-[18px] text-black">{{
              unit?.yearlyRent | number : "1.0-2"
            }}</span>
          </td>
          <td>
            <span class="text-sm leading-[18px] text-black">{{
              unit?.yearlyRentPerSquareMeter | number : "1.0-2"
            }}</span>
          </td>
          <td>
            <span class="text-sm leading-[18px] text-black">{{
              unit?.deposit | number : "1.0-2"
            }}</span>
          </td>
          <td>
            <span class="text-sm leading-[18px] text-black">
              {{ displaySelectText(_rentAdjustment, unit?.rentAdjustment) }}
              <div *ngIf="unit?.predeterminedYearlyRentRise != 0">
                &nbsp;+&nbsp;{{
                  unit?.predeterminedYearlyRentRise | number : "1.0-2"
                }}
              </div>
            </span>
          </td>
          <td>
            <p class="text-sm leading-[18px] text-black">
              {{ unit?.moveInDate | date : "MM/y" }}
            </p>
            <p class="text-xs leading-[15px] text-[#283F43] mt-1">
              Ops. varsel(mdr.):&nbsp;{{
                displaySelectText(
                  _terminationDuration,
                  unit?.terminationDuration
                )
              }}
            </p>
          </td>
          <td>
            <button [matMenuTriggerFor]="menu">
              <icon-menu-horizontal></icon-menu-horizontal>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="editUnit(i)">
                <span class="text-sm text-[#1B2022]">Rediger</span>
              </button>
              <button mat-menu-item (click)="duplicateUnit(i)">
                <span class="text-sm text-[#1B2022]">Dupliker</span>
              </button>
              <button mat-menu-item (click)="deleteUnit(i)">
                <span class="text-sm text-[#EB4C44]">Slet</span>
              </button>
            </mat-menu>
          </td>
        </tr>
      </tbody>
      <tfoot *ngIf="units?.length">
        <tr>
          <td colspan="2">I alt</td>
          <td>{{ totalUnitArea | number : "1.0-2" }}</td>
          <td>{{ totalMonthlyRent | number : "1.0-2" }}</td>
          <td>{{ totalMonthlyRent * 12 | number : "1.0-2" }}</td>
          <td>
            {{
              totalUnitArea == 0
                ? 0
                : ((totalMonthlyRent * 12) / totalUnitArea | number : "1.0-2")
            }}
          </td>
          <td>{{ totalDeposit || 0 | number : "1.0-2" }}</td>
          <td colspan="3"></td>
        </tr>

        <tr>
          <td colspan="2">Boligdelens andel</td>
          <td>{{ privateTotalUnitArea }}</td>
          <td>{{ privateTotalMonthlyRent | number : "1.0-2" }}</td>
          <td>{{ privateTotalMonthlyRent * 12 | number : "1.0-2" }}</td>
          <td>
            {{
              privateTotalUnitArea == 0
                ? 0
                : ((privateTotalMonthlyRent * 12) / privateTotalUnitArea
                  | number : "1.0-2")
            }}
          </td>
          <td>{{ privateTotalDeposit | number : "1.0-2" }}</td>
          <td colspan="3"></td>
        </tr>

        <tr>
          <td colspan="2">Erhvervsdelens andel</td>
          <td>{{ businessTotalUnitArea | number : "1.0-2" }}</td>
          <td>{{ businessTotalMonthlyRent | number : "1.0-2" }}</td>
          <td>{{ businessTotalMonthlyRent * 12 | number : "1.0-2" }}</td>
          <td>
            {{
              businessTotalUnitArea == 0
                ? 0
                : ((businessTotalMonthlyRent * 12) / businessTotalUnitArea
                  | number : "1.0-2")
            }}
          </td>
          <td>{{ businessTotalDeposit | number : "1.0-2" }}</td>
          <td colspan="3"></td>
        </tr>
      </tfoot>
    </table>

    <div
      *ngIf="!units?.length"
      class="flex flex-col justify-start gap-4 items-center mt-10"
    >
      <h5 class="text-black font-semibold text-xl leading-[25px] text-center">
        Ingen enheder tilføjet
      </h5>
      <p class="text-black text-sm leading-[18px] text-center">
        Udfyld data for din første enhed
      </p>
      <button
        class="!py-[15px] !px-[25px] !border !border-solid !border-[#D1D8D9] !rounded-[90px] !min-w-fit"
        mat-flat-button
        (click)="addNewUnit()"
      >
        <div class="flex items-center justify-start gap-[10px]">
          <icon-plus strokeColor="#091315"></icon-plus>
          <span
            class="font-semibold text-sm leading-[18px] text-black uppercase"
            >TILFØJ NY ENHED</span
          >
        </div>
      </button>
    </div>

    <hr
      *ngIf="!units?.length"
      class="border-t border-solid border-[#D1D8D9] mt-12"
    />

    <div
      class="flex items-center gap-3 border-t border-solid border-[#D1D8D9] mt-[180px] py-10"
      [ngClass]="{ '!mt-[80px]': units?.length }"
    >
      <button
        class="!py-[15px] !px-[25px] !border !border-solid !border-[#D1D8D9] !rounded-[90px] !min-w-fit"
        mat-flat-button
        (click)="onPrevClicked()"
      >
        <icon-caret-left></icon-caret-left>
      </button>
      <button
        class="w-full max-w-[274px]"
        [ngClass]="{
          'animate-shake': units?.length === 0 && isSubmitted
        }"
        mat-flat-button
        color="primary"
        (click)="onNextClicked()"
      >
        <div *ngIf="loading" class="flex items-center justify-center">
          <icon-loading-button class="animate-spin"></icon-loading-button>
        </div>
        <span class="uppercase" *ngIf="!loading">GEM & FORTSÆT</span>
      </button>
    </div>
  </div>
</div>

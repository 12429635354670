import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  activeRoute: any = {};
  firstName: string = 'Navn Navnsen';
  screen_wmin_1024: any;
  screen_wmin_640: any;
  collapse = false;
  drawerOpened: boolean = false;
  @ViewChild('drawer') public drawer: MatSidenav;

  routes = [
    {
      title: 'Forside',
      _id: 'home',
      url: '/home/front-page',
      isActive: this.router.url.includes('/home/front-page'),
    },
    {
      title: 'Ejendomme',
      _id: 'properties',
      url: '/home/properties',
      isActive: this.router.url.includes('/home/properties'),
    },
    {
      title: 'Statistik',
      _id: 'statistics',
      url: '/home/statistics',
      isActive: this.router.url.includes('/home/statistics'),
    },
    {
      title: 'Beskeder',
      _id: 'messages',
      url: '/home/messages',
      isActive: this.router.url.includes('/home/messages'),
    },
    {
      title: 'Fremvisninger',
      _id: 'screenings',
      url: '/home/screenings',
      isActive: this.router.url.includes('/home/screenings'),
    },
    {
      title: 'Kalender',
      _id: 'calendar',
      url: '/home/calendar',
      isActive: this.router.url.includes('/home/calendar'),
    },
    {
      title: 'Fremvisninger',
      _id: 'documents',
      url: '/home/documents',
      isActive: this.router.url.includes('/home/documents'),
    },
    {
      title: 'Min Profil',
      _id: 'profile',
      url: '/home/my-profile',
      isActive: this.router.url.includes('/home/my-profile'),
    },
  ];

  constructor(private router: Router, private location: Location) {
    location.onUrlChange((url) => {
      this.activeRoute = this.routes?.find((obj) =>
        // obj.url.includes(this.router.url)
        this.router.url.includes(obj.url)
      );
      this.routes.map((route) => {
        route.isActive = false;
        if (url.indexOf(route.url) === 0) route.isActive = true;
      });
      this.drawerOpened = false;
    });
  }

  ngOnInit(): void {
    this.activeRoute = this.routes?.find((obj) =>
      this.router.url.includes(obj.url)
    );
    this.firstName = JSON.parse(localStorage.getItem('user')).firstName;
    this.onResizeWindow();
  }

  @HostListener('window:resize', ['$event'])
  onResizeWindow() {
    this.screen_wmin_1024 = window.matchMedia('(min-width: 1024px)');
    this.screen_wmin_640 = window.matchMedia('(min-width: 640px)');
    if (!this.screen_wmin_1024.matches) this.drawerOpened = false;
  }

  toggleSidebar() {
    this.collapse = !this.collapse;
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/user/login']);
  }

  openDrawer() {
    this.drawer.toggle();
    this.drawerOpened = true;
  }

  closeDrawer(reason: string = 'default') {
    if (reason == 'default') this.drawer.toggle();
    this.drawerOpened = false;
  }
}

import { DecimalPipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { ListingService } from 'src/app/_services/listing/listing.service';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
} from 'rxjs/operators';
import {
  SelectObject,
  LISTING_TYPES,
  LISTING_CREATOR_ROLES,
  defaultAdvisersList,
  NUMBER_OF_FLOORS,
} from './add-property-data.util';
import { PHONE_CODES } from 'src/shared/text-input/helper';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';
import { MatDialog } from '@angular/material/dialog';
import { EditAdvisorTitleDialogComponent } from '../dialogs/edit-advisor-title-dialog/edit-advisor-title-dialog.component';
import { Adviser, Listing } from 'src/app/_models/property.model';
import { Address } from 'src/app/_models/address.model';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { formatNumber, unformatNumber } from 'src/utils';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'add-property-data',
  templateUrl: './add-property-data.component.html',
  styleUrls: ['./add-property-data.component.scss'],
})
export class AddPropertyDataComponent implements OnInit {
  @Output() nextClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('firstForm') firstForm: NgForm;
  @ViewChild('secondForm') secondForm: NgForm;

  propertyDataForm: FormGroup;
  filteredPrimaryAddresses;
  filteredSecondaryAddresses = [];
  _primaryAddress: Address;
  _secondaryAddresses: Address[] = [];
  _years: number[] = [];
  _numberofFloors: SelectObject[] = JSON.parse(
    JSON.stringify(NUMBER_OF_FLOORS)
  );
  _listingTypes: SelectObject[] = JSON.parse(JSON.stringify(LISTING_TYPES));

  dineDataForm: FormGroup;
  _listingCreatorRoles: SelectObject[] = JSON.parse(
    JSON.stringify(LISTING_CREATOR_ROLES)
  );
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.Denmark,
    CountryISO.Sweden,
    CountryISO.Finland,
    CountryISO.Norway,
  ];

  advisersDataForm: FormGroup;
  _defaultAdvisersList = JSON.parse(JSON.stringify(defaultAdvisersList));

  loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private listService: ListingService,
    public dialog: MatDialog,
    private numberpipe: DecimalPipe,
    private _snackBar: MatSnackBar
  ) {
    this.propertyDataForm = this.fb.group({
      listingTitle: new FormControl('', [Validators.required]),
      primaryAddress: new FormControl('', [
        Validators.required,
        this.requireMatch.bind(this),
      ]),
      secondaryAddresses: this.fb.array([]),
      typeOfListing: new FormControl('', [Validators.required]),
      totalLandArea: new FormControl('', [Validators.required]),
      numberOfFloors: new FormControl('', [Validators.required]),
      listedYear: new FormControl('', [Validators.required]),
    });
    for (let year = new Date().getFullYear(); year >= 1800; year--) {
      this._years.push(year);
    }

    this.dineDataForm = this.fb.group({
      listingCreatorRole: new FormControl('', [Validators.required]),
      sellerFullName: new FormControl('', [Validators.required]),
      firmName: new FormControl(''),
      email: new FormControl('', [Validators.required, Validators.email]),
      phoneNumberCountryCode: new FormControl('DK', [Validators.required]),
      phoneNumber: new FormControl(undefined, [Validators.required]),
      saveAsInvoice: new FormControl(false),
    });

    this.advisersDataForm = this.fb.group({
      advisers: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    const listing = JSON.parse(sessionStorage.getItem('listing'));
    listing?.id && listing?.creationStep >= 1
      ? this.setStoredValues(listing)
      : this.addDefaultAdvisers();
    this.managePrimaryAddresses();
    this.manageSecondaryAddresses();
  }

  // Set initial values if listing is in sessionStorage
  setStoredValues(listing: Listing) {
    this.listingTitle.setValue(listing.listingTitle);
    this.primaryAddress.setValue(
      JSON.parse(JSON.stringify(listing.primaryAddress))
    );
    this._primaryAddress = JSON.parse(JSON.stringify(listing.primaryAddress));
    listing.secondaryAddress.map((address) => {
      const secondaryAddress = new FormControl(
        JSON.parse(JSON.stringify(address)),
        [Validators.required, this.requireMatch.bind(this)]
      );
      this.secondaryAddresses.push(secondaryAddress);
    });
    this._secondaryAddresses = JSON.parse(
      JSON.stringify(listing.secondaryAddress)
    );
    this.typeOfListing.setValue(listing.typeOfListing);
    this.totalLandArea.setValue(formatNumber(listing.totalLandArea));
    this.numberOfFloors.setValue(listing.numberOfFloors);
    this.listedYear.setValue(listing.listedYear);
    this.listingCreatorRole.setValue(listing.listingCreatorRole);
    this.sellerFullName.setValue(listing.sellerFullName);
    this.firmName.setValue(listing.firmName);
    this.email.setValue(listing.email);
    this.phoneNumberCountryCode.setValue(listing.phoneNumber.split('-')[0]);
    this.phoneNumber.setValue(listing.phoneNumber.split('-')[1]);
    this.saveAsInvoiceInfo.setValue(listing.saveAsInvoiceInfo);
    listing.advisers.map((adviser) => {
      this.advisers.push(
        this.fb.group({
          title: new FormControl(`${adviser.title}`),
          name: new FormControl(`${adviser.name}`),
          firmName: new FormControl(`${adviser.firmName}`),
          email: new FormControl(`${adviser.email}`, [Validators.email]),
          phoneNumberCountryCode: new FormControl(
            `${adviser?.phoneNumber?.split('-')[0] || 'DK'}`
          ),
          phoneNumber: new FormControl(
            `${adviser?.phoneNumber?.split('-')[1] || ''}`
          ),
        })
      );
    });
  }

  lookup(value: string): Observable<any> {
    return this.listService
      .searchProperyAddress(value.toLowerCase())
      .pipe(map((results) => results.filter((result) => result?.data?.id)));
  }

  managePrimaryAddresses() {
    this.primaryAddress.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap((value) =>
          value && typeof value === 'string' ? this.lookup(value) : of(null)
        )
      )
      .subscribe((res) => {
        this.filteredPrimaryAddresses = res;
      });
  }

  setPrimaryAddress($event: MatAutocompleteSelectedEvent) {
    const listing = JSON.parse(sessionStorage.getItem('listing'));
    let addresssInUnits: boolean = false;
    listing?.units.map((unit) => {
      if (
        unit?.primaryAddress?.bbrAddressId ===
        this._primaryAddress?.bbrAddressId
      )
        addresssInUnits = true;
    });

    let addressAlreadyPresent: boolean = false;
    this._secondaryAddresses?.map((address) => {
      if (address?.bbrAddressId === this.primaryAddress?.value?.bbrAddressId)
        addressAlreadyPresent = true;
    });
    let address = $event.option.value;
    if (address && !addresssInUnits && !addressAlreadyPresent) {
      this._primaryAddress = {
        bbrAddressId: this.primaryAddress?.value?.bbrAddressId || '',
        city: this.primaryAddress?.value?.city || '',
        street: this.primaryAddress?.value?.streetName || '',
        buildingNumber: this.primaryAddress?.value?.buildingNumber || '',
        postalCode: this.primaryAddress?.value?.postalCode || '',
        longitude: this.primaryAddress?.value?.longitude || '',
        latitude: this.primaryAddress?.value?.latitude || '',
      };
      this.primaryAddress.setValue(this._primaryAddress);
    } else {
      if (addresssInUnits) {
        this._snackBar.open('Address present in Units', 'Dismiss', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
        this.primaryAddress.setValue(this._primaryAddress || '');
      } else if (addressAlreadyPresent) {
        this._snackBar.open('Address already added', 'Dismiss', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
        this.primaryAddress.setValue('');
      } else {
        this._snackBar.open('Invalid Address', 'Dismiss', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
        this.primaryAddress.setValue('');
      }
    }
    this.filteredPrimaryAddresses = [];
  }

  manageSecondaryAddresses() {
    this.secondaryAddresses.controls.map((_, i) => {
      this.secondaryAddresses.controls[i].valueChanges
        .pipe(
          debounceTime(1000),
          distinctUntilChanged(),
          switchMap((value) =>
            value && typeof value === 'string' ? this.lookup(value) : of(null)
          )
        )
        .subscribe((res) => {
          this.filteredSecondaryAddresses[i] = res;
        });
    });
  }

  setSecondaryAddress($event: MatAutocompleteSelectedEvent, index: number) {
    const listing = JSON.parse(sessionStorage.getItem('listing'));
    let addresssInUnits: boolean = false;
    listing?.units.map((unit) => {
      if (
        unit?.primaryAddress?.latitude ===
          this._secondaryAddresses?.[index]?.latitude &&
        unit?.primaryAddress?.longitude ===
          this._secondaryAddresses?.[index]?.longitude
      )
        addresssInUnits = true;
    });

    let addressAlreadyPresent: boolean = false;
    this._secondaryAddresses?.map((address) => {
      if (
        address?.bbrAddressId ===
        this.secondaryAddresses?.value?.[index]?.bbrAddressId
      )
        addressAlreadyPresent = true;
    });

    if (
      this.secondaryAddresses?.value?.[index]?.bbrAddressId ===
      this.primaryAddress?.value?.bbrAddressId
    )
      addressAlreadyPresent = true;

    let address = $event.option.value;
    if (address && !addresssInUnits && !addressAlreadyPresent) {
      this._secondaryAddresses[index] = {
        bbrAddressId:
          this.secondaryAddresses.value?.[index]?.bbrAddressId || '',
        city: this.secondaryAddresses.value?.[index]?.city || '',
        street: this.secondaryAddresses.value?.[index]?.streetName || '',
        buildingNumber:
          this.secondaryAddresses.value?.[index]?.buildingNumber || '',
        postalCode: this.secondaryAddresses.value?.[index]?.postalCode || '',
        longitude: this.secondaryAddresses.value?.[index]?.longitude || '',
        latitude: this.secondaryAddresses.value?.[index]?.latitude || '',
      };
      this.secondaryAddresses.controls[index].setValue(
        this._secondaryAddresses[index]
      );
    } else {
      if (addresssInUnits) {
        this._snackBar.open('Address present in Units', 'Dismiss', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
        this.secondaryAddresses.controls[index].setValue(
          this._secondaryAddresses[index]
        );
      } else if (addressAlreadyPresent) {
        this._snackBar.open('Address already added', 'Dismiss', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
        this.secondaryAddresses.controls[index].setValue('');
      } else {
        this._snackBar.open('Invalid Address', 'Dismiss', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
        this.secondaryAddresses.controls[index].setValue('');
      }
    }
    this.filteredSecondaryAddresses[index] = [];
  }

  addSecondaryAddress() {
    const secondaryAddress = new FormControl('', [
      Validators.required,
      this.requireMatch.bind(this),
    ]);
    this.secondaryAddresses.push(secondaryAddress);
    this.manageSecondaryAddresses();
  }

  deleteSecondaryAddress(index: number) {
    const listing = JSON.parse(sessionStorage.getItem('listing'));
    let addresssInUnits: boolean = false;
    listing?.units.map((unit) => {
      if (
        unit?.primaryAddress?.latitude ===
          this._secondaryAddresses[index]?.latitude &&
        unit?.primaryAddress?.longitude ===
          this._secondaryAddresses[index]?.longitude
      )
        addresssInUnits = true;
    });
    if (index > -1 && !addresssInUnits) {
      this.secondaryAddresses.removeAt(index);
      this._secondaryAddresses.splice(index, 1);
    } else {
      this._snackBar.open('Address present in Units', 'Dismiss', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }

  // advisers

  createAdviser(title: string): FormGroup {
    return this.fb.group({
      title: new FormControl(title ? title : 'Rådgiver'),
      name: new FormControl(''),
      firmName: new FormControl(''),
      email: new FormControl('', [Validators.email]),
      phoneNumberCountryCode: new FormControl('DK'),
      phoneNumber: new FormControl(''),
    });
  }

  addAdvisor() {
    // open dialog
    this.dialog
      .open(EditAdvisorTitleDialogComponent, {})
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.advisers.push(this.createAdviser(res?.title));
        }
      });
  }

  addDefaultAdvisers() {
    this._defaultAdvisersList.map((title) => {
      this.advisers.push(
        this.fb.group({
          title: new FormControl(`${title}`),
          name: new FormControl(''),
          firmName: new FormControl(''),
          email: new FormControl('', [Validators.email]),
          phoneNumberCountryCode: new FormControl('DK'),
          phoneNumber: new FormControl(''),
        })
      );
    });
  }

  deleteAdvisor(index: number) {
    if (index > -1) {
      this.advisers.removeAt(index);
    }
  }

  editAdvisorTitle(index: number) {
    const advisers = this.advisers;
    this.dialog
      .open(EditAdvisorTitleDialogComponent, {
        data: {
          advisers,
          indexTobeEdited: index,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.advisers.controls[index].get('title').setValue(res?.title);
        }
      });
  }

  createAdvisersList() {
    let _advisers: Adviser[] = [];
    this.advisers.controls.map((adviser) => {
      _advisers.push({
        title: adviser.get('title')?.value,
        name: adviser.get('name')?.value,
        firmName: adviser.get('firmName')?.value,
        email: adviser.get('email')?.value,
        phoneNumber: adviser.get('phoneNumber')?.value?.number
          ? `${adviser.get('phoneNumber')?.value.countryCode}-${
              adviser.get('phoneNumber')?.value.number
            }`
          : '',
      });
    });
    return _advisers;
  }

  // On Submit
  onNextClicked() {
    this.firstForm.onSubmit(undefined);
    this.secondForm.onSubmit(undefined);
    if (this.propertyDataForm.valid && this.dineDataForm.valid) {
      this.loading = true;
      this.createOrUpdateProperty();
    }
  }
  createOrUpdateProperty() {
    const listing = JSON.parse(sessionStorage.getItem('listing'));
    const userId = JSON.parse(localStorage.getItem('user'))?.id;

    if (listing) {
      const createOrUpdateProperty: Listing = {
        id: listing.id,
        creationStep: 1,
        listingTitle: this.listingTitle.value,
        primaryAddress: this.primaryAddress.value,
        secondaryAddress: this.secondaryAddresses.value,
        typeOfListing: this.typeOfListing.value,
        totalLandArea: unformatNumber(this.totalLandArea.value),
        numberOfFloors: this.numberOfFloors.value,
        listedYear: this.listedYear.value,
        listingCreatorRole: this.listingCreatorRole.value,
        sellerFullName: this.sellerFullName.value,
        firmName: this.firmName.value,
        email: this.email.value,
        phoneNumber: `${this.phoneNumber.value.countryCode}-${this.phoneNumber.value.number}`,
        saveAsInvoiceInfo: this.saveAsInvoiceInfo.value,
        advisers: this.createAdvisersList(),
      };
      // console.log(createOrUpdateProperty);
      this.listService
        .createOrUpdateProperty(createOrUpdateProperty)
        .subscribe({
          next: (res) => {
            sessionStorage.setItem('listing', JSON.stringify(res));
            this.loading = false;
            this.nextClick.emit(true);
          },
          error: (err) => {
            console.log(err);
            this.loading = false;
          },
        });
    } else {
      if (userId) {
        const createOrUpdateProperty: Listing = {
          listingSellerId: JSON.parse(localStorage.getItem('user'))?.id,
          creationStep: 1,
          listingTitle: this.listingTitle.value,
          primaryAddress: this.primaryAddress.value,
          secondaryAddress: this.secondaryAddresses.value,
          typeOfListing: this.typeOfListing.value,
          totalLandArea: unformatNumber(this.totalLandArea.value),
          numberOfFloors: this.numberOfFloors.value,
          listedYear: this.listedYear.value,
          listingCreatorRole: this.listingCreatorRole.value,
          sellerFullName: this.sellerFullName.value,
          firmName: this.firmName.value,
          email: this.email.value,
          phoneNumber: `${this.phoneNumber.value.countryCode}-${this.phoneNumber.value.number}`,
          saveAsInvoiceInfo: this.saveAsInvoiceInfo.value,
          advisers: this.createAdvisersList(),
        };
        // console.log(createOrUpdateProperty);
        this.listService
          .createOrUpdateProperty(createOrUpdateProperty)
          .subscribe({
            next: (res) => {
              sessionStorage.setItem('listing', JSON.stringify(res));
              this.loading = false;
              this.nextClick.emit(true);
            },
            error: (err) => {
              console.log(err);
              this.loading = false;
            },
          });
      } else {
        this.loading = false;
        this._snackBar.open('Please login again', 'Dismiss', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }
    }
  }

  // Getters
  get listingTitle() {
    return this.propertyDataForm.get('listingTitle');
  }
  get primaryAddress() {
    return this.propertyDataForm.get('primaryAddress');
  }
  get secondaryAddresses(): FormArray {
    return <FormArray>this.propertyDataForm.get('secondaryAddresses');
  }
  get typeOfListing() {
    return this.propertyDataForm.get('typeOfListing');
  }
  get totalLandArea() {
    return this.propertyDataForm.get('totalLandArea');
  }
  get numberOfFloors() {
    return this.propertyDataForm.get('numberOfFloors');
  }
  get listedYear() {
    return this.propertyDataForm.get('listedYear');
  }
  get listingCreatorRole() {
    return this.dineDataForm.get('listingCreatorRole');
  }
  get sellerFullName() {
    return this.dineDataForm.get('sellerFullName');
  }
  get firmName() {
    return this.dineDataForm.get('firmName');
  }
  get email() {
    return this.dineDataForm.get('email');
  }
  get phoneNumberCountryCode() {
    return this.dineDataForm.get('phoneNumberCountryCode');
  }
  get phoneNumber() {
    return this.dineDataForm.get('phoneNumber');
  }
  get saveAsInvoiceInfo() {
    return this.dineDataForm.get('saveAsInvoice');
  }
  get advisers(): FormArray {
    return this.advisersDataForm.get('advisers') as FormArray;
  }

  // helpers
  formatInput(event: any) {
    const parts = event.target.value.toString().split(',');
    if (!isNaN(Number(parts[0]))) {
      parts[0] = this.numberpipe.transform(
        parts[0].replace(/\./g, '').replace(/,/g, ''),
        '1.0-2',
        'da-DK'
      );
      event.target.value = parts.join(',');
    }
  }

  public valueMapper = (option) => {
    if (option) {
      return `${option?.street} ${option?.buildingNumber}, ${option?.postalCode} ${option?.city}`;
    } else return '';
  };

  private requireMatch(control: FormControl): ValidationErrors | null {
    const selection: any = control.value;
    if (!selection?.bbrAddressId) {
      return { requireMatch: true };
    }
    return null;
  }
}

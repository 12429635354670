import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/_services';
import { ConfirmedValidator, patternValidator } from 'src/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';

import { NOTIFICATIONS_DATA, NotitficationData } from './profile-settings.util';

import {
  changePasswordRequest,
  updateUserInfoRequest,
} from 'src/app/_models/user.model';
import { UpsertUserSettings } from 'src/app/_models/notification.model';
import { ContactService } from 'src/app/_services/notification/contact.service';

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.scss'],
})
export class ProfileSettingsComponent implements OnInit {
  notitficationsData = NOTIFICATIONS_DATA;
  profileDataForm: FormGroup;
  passwordResetForm: FormGroup;
  userInfo: any;

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO.Denmark;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.Denmark,
    CountryISO.Sweden,
    CountryISO.Finland,
    CountryISO.Norway,
  ];

  // @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
  @ViewChild('secondForm') formGroupDirective: FormGroupDirective;

  // For Profile Data Form
  isProfileFormLoaderEnabled = new BehaviorSubject(false);
  hasProfileFormChanged: boolean = false;
  isLoading = false;

  // For Change Password Form
  isPasswordFormLoaderEnabled = new BehaviorSubject(false);
  hasPasswordFormChanged: boolean = false;

  _hideOldPassword = true;
  _hideNewPassword = true;
  _hideConfirmPassword = true;
  _passwordStrength: number;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private _snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private notificationService: ContactService,
    private titleService: Title
  ) {
    this.titleService.setTitle('Ejos - Min Profil');
    this.profileDataForm = this.fb.group({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      cvr: new FormControl('', []),
      address: new FormControl('', [Validators.required]),
      postalcode: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[0-9]{4}$/),
      ]),
      city: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phoneNumber: new FormControl(undefined, [Validators.required]),
    });

    this.passwordResetForm = this.fb.group(
      {
        oldPassword: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
          patternValidator(new RegExp('(?=.*[0-9])'), {
            requiresDigit: true,
          }),
          patternValidator(new RegExp('(?=.*[A-Z])'), {
            requiresUppercase: true,
          }),
          patternValidator(new RegExp('(?=.*[a-z])'), {
            requiresLowercase: true,
          }),
          patternValidator(new RegExp('(?=.*[$@^!%*?&#-+])'), {
            requiresSpecialChars: true,
          }),
        ]),
        newPassword: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
          patternValidator(new RegExp('(?=.*[0-9])'), {
            requiresDigit: true,
          }),
          patternValidator(new RegExp('(?=.*[A-Z])'), {
            requiresUppercase: true,
          }),
          patternValidator(new RegExp('(?=.*[a-z])'), {
            requiresLowercase: true,
          }),
          patternValidator(new RegExp('(?=.*[$@^!%*?&#-+])'), {
            requiresSpecialChars: true,
          }),
        ]),
        confirmPassword: new FormControl('', [Validators.required]),
      },
      {
        validator: ConfirmedValidator('newPassword', 'confirmPassword'),
      }
    );
  }

  ngOnInit(): void {
    //fetch user information and display it in profileDataForm
    this.getUserById();

    // check if Profile form value change from initital values
    this.onProfileDataFormValueChange();

    // check if Password Reset form value change from initital values
    this.onPasswordResetFormValueChange();
  }

  onStrengthChanged(value: number) {
    setTimeout(() => {
      this._passwordStrength = value;
    }, 0);
  }

  // Define getters for formcontrol
  get firstName() {
    return this.profileDataForm.get('firstName');
  }

  get lastName() {
    return this.profileDataForm.get('lastName');
  }

  get cvr() {
    return this.profileDataForm.get('cvr');
  }

  get address() {
    return this.profileDataForm.get('address');
  }

  get postalcode() {
    return this.profileDataForm.get('postalcode');
  }

  get city() {
    return this.profileDataForm.get('city');
  }

  get email() {
    return this.profileDataForm.get('email');
  }

  get phoneNumber() {
    return this.profileDataForm.get('phoneNumber');
  }

  get oldPassword() {
    return this.passwordResetForm.get('oldPassword');
  }

  get newPassword() {
    return this.passwordResetForm.get('newPassword');
  }

  get confirmPassword() {
    return this.passwordResetForm.get('confirmPassword');
  }

  // Load Profile Form Data
  getUserById() {
    this.isLoading = true;
    this.spinner.show();

    const user = JSON.parse(localStorage.getItem('user'));
    this.authService.getUserById(user.id).subscribe({
      next: (res) => {
        this.userInfo = res;
        this.firstName.setValue(res?.firstName || '', { emitEvent: false });
        this.lastName.setValue(res?.lastName || '', { emitEvent: false });
        this.cvr.setValue(res?.cvr || '', { emitEvent: false });
        this.address.setValue(
          (res?.address?.street || '') +
            ' ' +
            (res?.address?.buildingNumber || '') ===
            ' '
            ? ''
            : (res?.address?.street || '') +
                ' ' +
                (res?.address?.buildingNumber || ''),
          { emitEvent: false }
        );
        this.postalcode.setValue(res.address?.postalCode || '', {
          emitEvent: false,
        });
        this.city.setValue(res?.address?.city || '', { emitEvent: false });
        this.email.setValue(res?.email || '', { emitEvent: false });
        this.CountryISO =
          res?.phoneNumber?.slice(0, res?.phoneNumber?.indexOf('-')) || 'dk';
        this.phoneNumber.setValue(
          res?.phoneNumber.slice(res?.phoneNumber?.indexOf('-') + 1) || '',
          { emitEvent: false }
        );

        this.getUserSettings();
      },
      error: (err) => {
        console.log(err);
        // this.isCalledFromUpdateMethod = false;
        this.isProfileFormLoaderEnabled.next(false);
        this.isLoading = false;
        this.spinner.hide();
      },
    });
  }

  // Track if any changes are made in the profile form
  onProfileDataFormValueChange() {
    this.profileDataForm.valueChanges.subscribe((value) => {
      if (
        this.firstName.value !== this.userInfo?.firstName ||
        this.lastName.value !== this.userInfo?.lastName ||
        this.cvr.value !== this.userInfo?.cvr ||
        this.address.value.slice(0, this.address.value.indexOf(' ')) !==
          this.userInfo?.address?.street ||
        this.address.value.slice(this.address.value.indexOf(' ') + 1) !==
          this.userInfo?.address?.buildingNumber ||
        this.postalcode.value !== this.userInfo?.address?.postalCode ||
        this.city.value !== this.userInfo?.address?.city ||
        this.email.value !== this.userInfo?.email ||
        this.phoneNumber.value.countryCode !==
          this.userInfo?.phoneNumber.slice(
            0,
            this.userInfo?.phoneNumber?.indexOf('-')
          ) ||
        this.phoneNumber.value.number !==
          this.userInfo?.phoneNumber?.slice(
            this.userInfo?.phoneNumber?.indexOf('-') + 1
          )
      ) {
        this.hasProfileFormChanged = true;
      } else {
        this.hasProfileFormChanged = false;
      }
    });
  }

  // update profile form data
  updateUserInfo() {
    const updateUserInfoRequestData: updateUserInfoRequest = {
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      cvr: this.cvr.value,
      phoneNumber: `${
        this.profileDataForm.get('phoneNumber')!.value.countryCode
      }-${this.profileDataForm.get('phoneNumber')!.value.number}`,
      address: {
        postalCode: this.postalcode.value,
        city: this.city.value,
        street: this.address.value.slice(0, this.address.value.indexOf(' ')),
        buildingNumber: this.address.value.slice(
          this.address.value.indexOf(' ') + 1
        ),
      },
    };
    if (this.profileDataForm.status === 'VALID') {
      this.isProfileFormLoaderEnabled.next(true);
      this.authService
        .updateUserInfo(this.userInfo.id, updateUserInfoRequestData)
        .subscribe({
          next: (res) => {
            this._snackBar.open('Profil opdateret', 'Dismiss', {
              duration: 3000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });

            // fetch user information and display it in profileDataForm
            this.getUserById();
            this.isProfileFormLoaderEnabled.next(false);
            this.hasProfileFormChanged = false;
          },
          error: (err) => {
            this._snackBar.open('Fejl under opdatering af profil', 'Dismiss', {
              duration: 3000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
            console.log(err);
            this.isProfileFormLoaderEnabled.next(false);
          },
        });
    }
  }

  onPasswordResetFormValueChange() {
    this.passwordResetForm.valueChanges.subscribe((value) => {
      if (
        this.oldPassword.value &&
        this.newPassword.value &&
        this.confirmPassword.value
      ) {
        this.hasPasswordFormChanged = true;
      } else this.hasPasswordFormChanged = false;
    });
  }

  // update user login password
  changePassword() {
    const changePasswordRequestData: changePasswordRequest = {
      userId: this.userInfo.id,
      oldPassword: this.oldPassword.value,
      newPassword: this.newPassword.value,
    };

    if (this.passwordResetForm.status === 'VALID') {
      this.isPasswordFormLoaderEnabled.next(true);
      this.authService.changePassword(changePasswordRequestData).subscribe({
        next: (res) => {
          this._snackBar.open('Adgangskoden blev ændret', 'Dismiss', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });

          this.isPasswordFormLoaderEnabled.next(false);
          this.passwordResetForm.reset('', { emitEvent: false });
          this.clearFormGroupDirective();
        },
        error: (err) => {
          this._snackBar.open('Fejl ved ændring af adgangskode', 'Dismiss', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
          console.log(err);
          this.isPasswordFormLoaderEnabled.next(false);
        },
      });
    }
  }

  clearFormGroupDirective() {
    this.formGroupDirective.resetForm();
  }

  // update notifications tab

  onChange(checked: boolean, notification: NotitficationData, type: string) {
    if (type === 'PUSH') notification.pushEnabled = checked;
    else notification.emailEnabled = checked;
  }

  upsertUserSettings() {
    const chatMessageReceived = this.notitficationsData.find(
      (item) => item.value === 'chatMessageReceived'
    );
    const newExhibition = this.notitficationsData.find(
      (item) => item.value === 'newExhibition'
    );
    const newsletter = this.notitficationsData.find(
      (item) => item.value === 'newsletter'
    );

    const userSettings: UpsertUserSettings = {
      email: this.email.value,
      emailNotifications: {
        chatMessageReceived: chatMessageReceived.emailEnabled,
        newExhibition: newExhibition.emailEnabled,
        newsletter: newsletter.emailEnabled,
      },
      pushNotifications: {
        chatMessageReceived: chatMessageReceived.pushEnabled,
        newExhibition: newExhibition.pushEnabled,
        newsletter: newsletter.pushEnabled,
      },
    };

    this.notificationService.upsertUserSettings(userSettings).subscribe({
      next: (res) => {
        this._snackBar.open('Gemt', 'Dismiss', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      },
      error: (err) => {
        console.log(err);
        this._snackBar.open('Mislykkedes', 'Dismiss', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      },
    });
  }

  getUserSettings() {
    this.notificationService
      .getUserSettingsByEmail(this.email.value)
      .subscribe({
        next: (res) => {
          this.notitficationsData.map((item) => {
            item?.value === 'chatMessageReceived'
              ? ((item.emailEnabled =
                  res?.emailNotifications.chatMessageReceived),
                (item.pushEnabled = res?.pushNotifications.chatMessageReceived))
              : item?.value === 'newExhibition'
              ? ((item.emailEnabled = res?.emailNotifications.newExhibition),
                (item.pushEnabled = res?.pushNotifications.newExhibition))
              : item?.value === 'newsletter'
              ? ((item.emailEnabled = res?.emailNotifications.newsletter),
                (item.pushEnabled = res?.pushNotifications.newsletter))
              : '';
          });

          this.isProfileFormLoaderEnabled.next(false);
          this.isLoading = false;
          this.spinner.hide();
        },
        error: (err) => {
          console.log(err);
          this._snackBar.open('Mislykkedes', 'Dismiss', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });

          this.isProfileFormLoaderEnabled.next(false);
          this.isLoading = false;
          this.spinner.hide();
        },
      });
  }
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeDk from '@angular/common/locales/da';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from 'src/shared/shared.module';
import { HomeModule } from 'src/home/home.module';
import { SnackBarsComponent } from './snack-bars/snack-bars.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { UserModule } from 'src/user/user.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { DatePipe, DecimalPipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';

registerLocaleData(localeDk);

@NgModule({
  declarations: [AppComponent, SnackBarsComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    UserModule,
    HomeModule,
    NgSelectModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    DatePipe,
    DecimalPipe,
    { provide: LOCALE_ID, useValue: 'da' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="timer"
  [fullScreen]="true"
  ><p style="color: white">Loading...</p></ngx-spinner
>

<div *ngIf="!pageLoading" class="mt-[52px]">
  <h3 class="font-semibold text-[32px] leading-10 text-black">
    Driftsudgifter
  </h3>
  <p class="text-[#747F81] mt-3">
    Du bedes nu indtaste ejendommens driftsudgifter på årsbasis. Endvidere
    angiver du, hvorvidt udgiften er faktisk eller en skønnet udgift.
  </p>
  <form
    #firstForm="ngForm"
    [formGroup]="expenseForm"
    class="!overflow-x-auto mt-8 min-w-[960px]"
  >
    <table>
      <thead>
        <tr>
          <th>Udgifter</th>
          <th>Beløb i kr.</th>
          <th>Faktiske- eller anslåede udgifter</th>
          <th>Kr. pr. m<sup>2</sup></th>
        </tr>
      </thead>
      <tbody>
        <tr
          formArrayName="expenses"
          *ngFor="let item of expenses.controls; let i = index"
        >
          <td>
            <div class="flex gap-2 items-center flex-nowrap">
              <span class="text-sm leading-[18px] text-[#091315]">
                {{ item.get("name")?.value }}
              </span>
              <tooltip
                *ngIf="item.get('description')?.value"
                [message]="item.get('description')?.value"
              ></tooltip>

              <span *ngIf="i > 9" class="flex items-center gap-3">
                <icon-pencil-line
                  class="cursor-pointer"
                  (click)="editExpenseName(i)"
                ></icon-pencil-line>
                <icon-delete
                  class="cursor-pointer"
                  (click)="deleteExpense(i)"
                ></icon-delete>
              </span>
            </div>
          </td>
          <td [formGroupName]="i">
            <mat-form-field
              class="max-w-[252px] w-full relative | unit center no-error"
              appearance="fill"
            >
              <input
                class="!text-right !mt-0"
                matInput
                numbersWithDecimalOnly
                maxlength="20"
                autocomplete="off"
                formControlName="tax"
                (input)="formatInput($event)"
                placeholder="0,0"
              />
              <div
                class="absolute right-[-1rem] bottom-[-10px] h-[54px] p-[16px] bg-[#D1D8D9] text-[#747F81] rounded-tr-[12px] rounded-br-[12px]"
              >
                <span class="font-semibold text-base leading-5"> kr. </span>
              </div>
              <mat-error
                class="!pt-5"
                *ngIf="item.get('tax')?.hasError('required')"
              >
                Påkrævet
              </mat-error>
              <mat-error
                class="!pt-5"
                class="!pt-5"
                *ngIf="!item.get('tax')?.hasError('required')"
              >
                Enter Valid Input
              </mat-error>
            </mat-form-field>
          </td>
          <td [formGroupName]="i">
            <mat-radio-group
              class="max-w-[252px] w-full expense-type flex items-center gap-3 p-4 bg-[#EFF6F6] rounded-[12px]"
              formControlName="expenseType"
            >
              <mat-radio-button value="Actual">Faktisk</mat-radio-button>
              <mat-radio-button value="Estimated">Anslået</mat-radio-button>
            </mat-radio-group>
          </td>
          <td [formGroupName]="i">
            <mat-form-field
              class="max-w-[252px] w-full relative | unit-tooltip center read-only no-error"
              appearance="fill"
            >
              <input
                class="!text-right !mt-0"
                readonly
                tabindex="-1"
                matInput
                autocomplete="off"
                placeholder="0,0"
                formControlName="taxPerSquareMeter"
              />
              <div
                class="absolute right-[-1rem] bottom-[-10px] h-[54px] p-[16px] bg-[#D1D8D9] text-[#747F81] rounded-tr-[12px] rounded-br-[12px]"
              >
                <span class="font-semibold text-base leading-5">
                  kr./ m<sup>2</sup>
                </span>
              </div>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td colspan="4" class="border-b border-solid border-[#D1D8D9]">
            <div class="max-w-[628px] w-full">
              <button
                class="flex items-center justify-start gap-[10px]"
                type="button"
                (click)="addExpense()"
              >
                <icon-plus></icon-plus>
                <span
                  class="font-semibold text-base leading-20 text-red uppercase"
                  >Tilføj udgift</span
                >
              </button>
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="border-b border-dashed border-[#D1D8D9]">
          <td class="text-sm leading-[18px] font-semibold text-[#091315]">
            Samlet driftsudgifter
          </td>
          <td>
            <mat-form-field
              class="max-w-[252px] w-full relative | unit center no-error read-only"
              appearance="fill"
            >
              <input
                class="!text-right !mt-0"
                readonly
                tabindex="-1"
                matInput
                autocomplete="off"
                placeholder="0,0"
                formControlName="sumOfOperatingExpenses"
              />
              <div
                class="absolute right-[-1rem] bottom-[-10px] h-[54px] p-[16px] bg-[#D1D8D9] text-[#747F81] rounded-tr-[12px] rounded-br-[12px]"
              >
                <span class="font-semibold text-base leading-5"> kr. </span>
              </div>
            </mat-form-field>
          </td>
          <td></td>
          <td>
            <mat-form-field
              class="max-w-[252px] w-full relative | unit-tooltip center read-only no-error"
              appearance="fill"
            >
              <input
                class="!text-right !mt-0"
                readonly
                tabindex="-1"
                matInput
                autocomplete="off"
                placeholder="0,0"
                formControlName="sumOfOperatingExpensesPerSqMeter"
              />
              <div
                class="absolute right-[-1rem] bottom-[-10px] h-[54px] p-[16px] bg-[#D1D8D9] text-[#747F81] rounded-tr-[12px] rounded-br-[12px]"
              >
                <span class="font-semibold text-base leading-5">
                  kr./ m<sup>2</sup>
                </span>
              </div>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td class="text-sm leading-[18px] text-[#091315]">
            Samlet bruttolejenidtægter
          </td>
          <td>
            <mat-form-field
              class="max-w-[252px] w-full relative | unit center no-error read-only"
              appearance="fill"
            >
              <input
                class="!text-right !mt-0"
                readonly
                tabindex="-1"
                matInput
                autocomplete="off"
                placeholder="0,0"
                formControlName="totalGrossRentalIncome"
              />
              <div
                class="absolute right-[-1rem] bottom-[-10px] h-[54px] p-[16px] bg-[#D1D8D9] text-[#747F81] rounded-tr-[12px] rounded-br-[12px]"
              >
                <span class="font-semibold text-base leading-5"> kr. </span>
              </div>
            </mat-form-field>
          </td>
          <td></td>
          <td>
            <mat-form-field
              class="max-w-[252px] w-full relative | unit-tooltip center read-only no-error"
              appearance="fill"
            >
              <input
                class="!text-right !mt-0"
                readonly
                tabindex="-1"
                matInput
                autocomplete="off"
                placeholder="0,0"
                formControlName="totalGrossRentalIncomePerSqMeter"
              />
              <div
                class="absolute right-[-1rem] bottom-[-10px] h-[54px] p-[16px] bg-[#D1D8D9] text-[#747F81] rounded-tr-[12px] rounded-br-[12px]"
              >
                <span class="font-semibold text-base leading-5">
                  kr./ m<sup>2</sup>
                </span>
              </div>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td class="text-sm leading-[18px] text-[#091315]">
            Samlet nettoleje
          </td>
          <td>
            <mat-form-field
              class="max-w-[252px] w-full relative | unit center no-error read-only"
              appearance="fill"
            >
              <input
                class="!text-right !mt-0"
                readonly
                tabindex="-1"
                matInput
                autocomplete="off"
                placeholder="0,0"
                formControlName="totalNetRent"
              />
              <div
                class="absolute right-[-1rem] bottom-[-10px] h-[54px] p-[16px] bg-[#D1D8D9] text-[#747F81] rounded-tr-[12px] rounded-br-[12px]"
              >
                <span class="font-semibold text-base leading-5"> kr. </span>
              </div>
            </mat-form-field>
          </td>
          <td></td>
          <td>
            <mat-form-field
              class="max-w-[252px] w-full relative | unit-tooltip center read-only no-error"
              appearance="fill"
            >
              <input
                class="!text-right !mt-0"
                readonly
                tabindex="-1"
                matInput
                autocomplete="off"
                placeholder="0,0"
                formControlName="totalNetRentPerSqMeter"
              />
              <div
                class="absolute right-[-1rem] bottom-[-10px] h-[54px] p-[16px] bg-[#D1D8D9] text-[#747F81] rounded-tr-[12px] rounded-br-[12px]"
              >
                <span class="font-semibold text-base leading-5">
                  kr./ m<sup>2</sup>
                </span>
              </div>
            </mat-form-field>
          </td>
        </tr>
      </tfoot>
    </table>
  </form>

  <form #secondForm="ngForm" [formGroup]="pricingForm" class="mt-[52px]">
    <h3 class="font-semibold text-[32px] leading-10 text-black">Prissætning</h3>
    <p class="text-[#747F81] mt-3">
      Her indtastes forskellige oplysninger af relevans for prissætningen af
      ejendommen
    </p>

    <div>
      <div class="flex items-center gap-[11px] mt-6">
        <h5 class="font-semibold text-xl leading-[25px] text-black">Afkast</h5>
        <tooltip message="Message...."></tooltip>
      </div>
      <hr class="border-t border-solid border-[#D1D8D9] mt-6" />
      <div class="mt-8 flex gap-4 justify-start items-center flex-wrap">
        <p class="font-semibold leading-5 max-w-[390px] w-full">
          Beregnet basisafkast
        </p>
        <mat-form-field
          class="max-w-[334px] w-full relative | read-only unit center no-error"
          appearance="fill"
        >
          <input
            class="!text-right !mt-0"
            matInput
            numbersWithDecimalOnly
            autocomplete="off"
            placeholder="0,0"
            readonly
            tabindex="-1"
            formControlName="baseReturn"
            (input)="formatInput($event)"
          />
          <div
            class="absolute right-[-1rem] bottom-[-10px] h-[54px] p-[16px] bg-[#D1D8D9] text-[#747F81] rounded-tr-[12px] rounded-br-[12px]"
          >
            <span class="font-semibold text-base leading-5"> % </span>
          </div>
          <mat-error class="!pt-5" class="!pt-5"> Påkrævet </mat-error>
        </mat-form-field>
      </div>
      <div class="mt-8 flex gap-4 justify-start items-center flex-wrap">
        <div class="flex gap-2 max-w-[390px] w-full">
          <p class="font-semibold leading-5">Beliggenhedsfaktor</p>
          <tooltip message="Message..."></tooltip>
        </div>
        <mat-form-field
          class="max-w-[334px] w-full relative | select-box no-error color-747f81 color-091315 text-size-16 line-height-20 font-weight-600 padding-52"
          appearance="fill"
        >
          <mat-select
            placeholder="Vælg beliggenhedsfaktor"
            floatPlaceholder="never"
            panelClass="text-size-16 line-height-20 color-1b2022"
            formControlName="locationFactor"
          >
            <mat-option
              *ngFor="let factor of _locationFactor"
              [value]="factor.value"
            >
              {{ factor.text }}
            </mat-option>
          </mat-select>
          <mat-error class="!pt-5"> Påkrævet </mat-error>
          <icon-caret-down
            class="absolute right-0 bottom-[6px]"
          ></icon-caret-down>
        </mat-form-field>
      </div>

      <div class="mt-8 flex gap-4 justify-start items-center flex-wrap">
        <p class="font-semibold leading-5 max-w-[390px] w-full">
          Endeligt afkastkrav
        </p>
        <mat-form-field
          class="max-w-[334px] w-full relative | read-only unit center no-error"
          appearance="fill"
        >
          <input
            class="!text-right !mt-0"
            matInput
            numbersWithDecimalOnly
            autocomplete="off"
            placeholder="0,0"
            readonly
            tabindex="-1"
            formControlName="finalBaseReturn"
            (input)="formatInput($event)"
          />
          <div
            class="absolute right-[-1rem] bottom-[-10px] h-[54px] p-[16px] bg-[#D1D8D9] text-[#747F81] rounded-tr-[12px] rounded-br-[12px]"
          >
            <span class="font-semibold text-base leading-5"> % </span>
          </div>
          <mat-error class="!pt-5"> Påkrævet </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div>
      <div class="flex items-center gap-[11px] mt-[56px]">
        <h5 class="font-semibold text-xl leading-[25px] text-black">
          Ejendomsfaktorer
        </h5>
        <tooltip
          message="Nedenfor er der mulighed for at angive faktorer, der har betydning for prissætningen"
        ></tooltip>
      </div>
      <hr class="border-t border-solid border-[#D1D8D9] mt-6" />
      <div class="mt-8 flex gap-4 justify-start items-center flex-wrap">
        <p class="font-semibold leading-5 max-w-[390px] w-full">
          Ejendommen er
        </p>
        <div class="flex gap-4 flex-wrap max-w-[334px] w-full items-center">
          <mat-form-field
            class="max-w-[334px] w-full relative | select-box no-error color-747f81 color-091315 text-size-16 line-height-20 font-weight-600 padding-52"
            appearance="fill"
          >
            <mat-select
              placeholder="Vælg renovation"
              floatPlaceholder="never"
              panelClass="text-size-16 line-height-20 color-1b2022"
              formControlName="renovation"
            >
              <mat-option *ngFor="let type of _renovation" [value]="type.value">
                {{ type.text }}
              </mat-option>
            </mat-select>
            <mat-error class="!pt-5"> Påkrævet </mat-error>
            <icon-caret-down
              class="absolute right-0 bottom-[6px]"
            ></icon-caret-down>
          </mat-form-field>

          <!-- <mat-form-field
            class="max-w-[334px] w-full relative | select-box no-error color-747f81 color-091315 text-size-16 line-height-20 font-weight-600 padding-52"
            appearance="fill"
          >
            <mat-select
              placeholder="Angiv årstal for renovation"
              floatPlaceholder="never"
              panelClass="text-size-16 line-height-20 color-1b2022"
              formControlName="yearOfRenovation"
            >
              <mat-option *ngFor="let year of _yearOfRenovation" [value]="year">
                {{ year }}
              </mat-option>
            </mat-select>
            <mat-error class="!pt-5"> Påkrævet </mat-error>
            <icon-caret-down
              class="absolute right-0 bottom-[6px]"
            ></icon-caret-down>
          </mat-form-field> -->
        </div>
      </div>
      <div class="mt-8 flex gap-4 justify-start items-center flex-wrap">
        <div class="flex gap-2 max-w-[390px] w-full">
          <p class="font-semibold leading-5">Ejendommens stand</p>
          <tooltip message="Angiv forhold omkring ejendommens stand"></tooltip>
        </div>
        <mat-form-field
          class="max-w-[334px] w-full relative | select-box no-error color-747f81 color-091315 text-size-16 line-height-20 font-weight-600 padding-52"
          appearance="fill"
        >
          <mat-select
            placeholder="Vælg ejendommens stand"
            floatPlaceholder="never"
            panelClass="text-size-16 line-height-20 color-1b2022"
            formControlName="propertyCondition"
          >
            <mat-option
              *ngFor="let condition of _propertyCondition"
              [value]="condition.value"
            >
              {{ condition.text }}
            </mat-option>
          </mat-select>
          <mat-error class="!pt-5"> Påkrævet </mat-error>
          <icon-caret-down
            class="absolute right-0 bottom-[6px]"
          ></icon-caret-down>
        </mat-form-field>
      </div>

      <div class="mt-8 flex gap-4 justify-start items-center flex-wrap">
        <div class="flex gap-2 max-w-[390px] w-full">
          <p class="font-semibold leading-5">Klimaforbedringer</p>
          <tooltip
            message="Du markerer hvilke områder du har lavet klimaforbedringer på"
          ></tooltip>
        </div>
        <div class="flex gap-4 flex-wrap max-w-[692px] w-full items-center">
          <mat-form-field
            class="max-w-[334px] w-full relative | select-box no-error color-747f81 color-091315 text-size-16 line-height-20 font-weight-600 padding-52"
            appearance="fill"
          >
            <mat-select
              multiple
              placeholder="Vælg klimaforbedringer"
              floatPlaceholder="never"
              panelClass="text-size-16 line-height-20 color-1b2022"
              formControlName="climateImprovements"
            >
              <mat-option
                *ngFor="let improvement of _climateImprovementsOptions"
                selected
                [value]="improvement.value"
              >
                {{ improvement.text }}
              </mat-option>
            </mat-select>
            <mat-error class="!pt-5"> Påkrævet </mat-error>
            <icon-caret-down
              class="absolute right-0 bottom-[6px]"
            ></icon-caret-down>
          </mat-form-field>

          <mat-form-field
            *ngIf="climateImprovements.value.includes('others')"
            class="max-w-[334px] w-full relative | center no-error"
            appearance="fill"
          >
            <input
              class="!mt-0"
              matInput
              autocomplete="off"
              placeholder="Angiv andre"
              formControlName="manualInput"
            />
          </mat-form-field>
        </div>
      </div>
    </div>

    <div>
      <div class="flex items-center gap-[11px] mt-[56px]">
        <h5 class="font-semibold text-xl leading-[25px] text-black">
          Finansieringsforhold
        </h5>
        <tooltip message="Message..."></tooltip>
      </div>
      <hr class="border-t border-solid border-[#D1D8D9] mt-6" />

      <div class="!overflow-x-auto mt-8">
        <table>
          <thead>
            <tr>
              <th>Lånetype</th>
              <th>Rentetype</th>
              <th>Låneudbyder</th>
              <th>Finansieringstype</th>
              <th>Rentesats p.a (%)</th>
              <th>Hovedstol (kr.)</th>
              <th>Restgæld(kr.)</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="last:border-b last:border-solid last:border-[#D1D8D9]"
              *ngFor="let finance of financing; index as i"
            >
              <td>
                <p class="text-sm leading-[18px] text-black">
                  {{ displaySelectText(_loanType, finance.loanType) }}
                </p>
              </td>
              <td>
                <p class="text-sm leading-[18px] text-black">
                  {{
                    displaySelectText(
                      _interestRateType,
                      finance.interestRateType
                    )
                  }}
                </p>
              </td>
              <td>
                <p class="text-sm leading-[18px] text-black">
                  {{ finance.loanProvider }}
                </p>
              </td>
              <td>
                <p class="text-sm leading-[18px] text-black">
                  {{ finance.financingType }}
                </p>
              </td>
              <td>
                <p class="text-sm leading-[18px] text-black">
                  {{ finance.interestRate | number : "1.0-2" }}
                </p>
              </td>
              <td>
                <p class="text-sm leading-[18px] text-black">
                  {{ finance.totalDebt | number : "1.0-2" }}
                </p>
              </td>
              <td>
                <p class="text-sm leading-[18px] text-black">
                  {{ finance.remainingDebt | number : "1.0-2" }}
                </p>
              </td>
              <td>
                <button [matMenuTriggerFor]="menu">
                  <icon-menu-horizontal></icon-menu-horizontal>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="editFinanceItem(i)">
                    <span class="text-sm text-[#1B2022]">Rediger</span>
                  </button>
                  <button mat-menu-item (click)="duplicateFinance(i)">
                    <span class="text-sm text-[#1B2022]">Dupliker</span>
                  </button>
                  <button mat-menu-item (click)="deleteFinance(i)">
                    <span class="text-sm text-[#EB4C44]">Slet</span>
                  </button>
                </mat-menu>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="mt-6 max-w-[628px] w-full">
          <button
            class="flex items-center justify-start gap-[10px]"
            type="button"
            (click)="addFinanceItem()"
          >
            <icon-plus></icon-plus>
            <span class="font-semibold text-base leading-20 text-red uppercase"
              >TILFØJ LÅNEGRUPPE</span
            >
          </button>
        </div>
      </div>
    </div>

    <div class="mt-[56px]">
      <div class="flex items-center gap-[11px]">
        <h5 class="font-semibold text-xl leading-[25px] text-black">Pris</h5>
        <tooltip message="Message...."></tooltip>
      </div>
      <hr class="border-t border-solid border-[#D1D8D9] mt-6" />
      <div class="mt-8 flex gap-4 justify-start items-center flex-wrap">
        <div class="flex gap-2 max-w-[390px] w-full">
          <p class="font-semibold leading-5">Beregnet salgspris</p>
          <tooltip message="Message..."></tooltip>
        </div>

        <div class="flex gap-4 flex-wrap max-w-[692px] w-full items-center">
          <mat-form-field
            class="max-w-[334px] w-full relative | unit center no-error read-only"
            appearance="fill"
          >
            <input
              class="!text-right !mt-0"
              matInput
              readonly
              tabindex="-1"
              autocomplete="off"
              placeholder="0,0"
              formControlName="estimatedSellingPrice"
            />
            <div
              class="absolute right-[-1rem] bottom-[-10px] h-[54px] p-[16px] bg-[#D1D8D9] text-[#747F81] rounded-tr-[12px] rounded-br-[12px]"
            >
              <span class="font-semibold text-base leading-5"> kr. </span>
            </div>
          </mat-form-field>
          <mat-form-field
            class="max-w-[334px] w-full relative | unit-tooltip center read-only no-error"
            appearance="fill"
          >
            <input
              class="!text-right !mt-0"
              readonly
              tabindex="-1"
              matInput
              autocomplete="off"
              placeholder="0,0"
              formControlName="estimatedSellingPricePerSquareMeter"
            />
            <div
              class="absolute right-[-1rem] bottom-[-10px] h-[54px] p-[16px] bg-[#D1D8D9] text-[#747F81] rounded-tr-[12px] rounded-br-[12px]"
            >
              <span class="font-semibold text-base leading-5">
                kr./ m<sup>2</sup>
              </span>
            </div>
          </mat-form-field>
        </div>
      </div>

      <div class="mt-8 flex gap-4 justify-start items-center flex-wrap">
        <div class="flex gap-2 max-w-[390px] w-full">
          <p class="font-semibold leading-5">Ønsket pris på byggeret</p>
          <tooltip
            message="Indtast beløb på en eventuel byggeret på ejendommen"
          ></tooltip>
        </div>
        <div class="flex gap-4 flex-wrap max-w-[692px] w-full items-center">
          <mat-form-field
            class="max-w-[334px] w-full relative | unit center no-error"
            appearance="fill"
          >
            <input
              class="!text-right !mt-0"
              numbersWithDecimalOnly
              maxlength="20"
              [group]="pricingForm"
              matInput
              autocomplete="off"
              placeholder="0,0"
              (input)="formatInput($event)"
              formControlName="desiredPrice"
            />
            <div
              class="absolute right-[-1rem] bottom-[-10px] h-[54px] p-[16px] bg-[#D1D8D9] text-[#747F81] rounded-tr-[12px] rounded-br-[12px]"
            >
              <span class="font-semibold text-base leading-5"> kr. </span>
            </div>
          </mat-form-field>
          <mat-form-field
            class="max-w-[334px] w-full relative | unit-tooltip center read-only no-error"
            appearance="fill"
          >
            <input
              class="!text-right !mt-0"
              readonly
              tabindex="-1"
              matInput
              autocomplete="off"
              placeholder="0,0"
              formControlName="desiredPricePerSquareMeter"
            />
            <div
              class="absolute right-[-1rem] bottom-[-10px] h-[54px] p-[16px] bg-[#D1D8D9] text-[#747F81] rounded-tr-[12px] rounded-br-[12px]"
            >
              <span class="font-semibold text-base leading-5">
                kr./ m<sup>2</sup>
              </span>
            </div>
          </mat-form-field>
        </div>
      </div>

      <div class="mt-8 flex gap-4 justify-start items-center flex-wrap">
        <div class="flex gap-2 max-w-[390px] w-full">
          <p class="font-semibold leading-5">Lejepotentiale</p>
          <tooltip
            message="Indtast dit eget estimeret lejepotentiale i kr. på års basis"
          ></tooltip>
        </div>
        <div class="flex gap-4 flex-wrap max-w-[692px] w-full items-center">
          <mat-form-field
            class="max-w-[334px] w-full relative | unit center no-error"
            appearance="fill"
          >
            <input
              class="!text-right !mt-0"
              numbersWithDecimalOnly
              maxlength="20"
              [group]="pricingForm"
              matInput
              autocomplete="off"
              placeholder="0,0"
              formControlName="rentPotential"
              (input)="formatInput($event)"
            />
            <div
              class="absolute right-[-1rem] bottom-[-10px] h-[54px] p-[16px] bg-[#D1D8D9] text-[#747F81] rounded-tr-[12px] rounded-br-[12px]"
            >
              <span class="font-semibold text-base leading-5"> kr. </span>
            </div>
          </mat-form-field>
          <mat-form-field
            class="max-w-[334px] w-full relative | unit-tooltip center read-only no-error"
            appearance="fill"
          >
            <input
              class="!text-right !mt-0"
              readonly
              tabindex="-1"
              matInput
              autocomplete="off"
              placeholder="0,0"
              formControlName="rentPotentialPerSquareMeters"
            />
            <div
              class="absolute right-[-1rem] bottom-[-10px] h-[54px] p-[16px] bg-[#D1D8D9] text-[#747F81] rounded-tr-[12px] rounded-br-[12px]"
            >
              <span class="font-semibold text-base leading-5">
                kr./ m<sup>2</sup>
              </span>
            </div>
          </mat-form-field>
        </div>
      </div>

      <div class="mt-8 flex gap-4 justify-start items-center flex-wrap">
        <div class="flex gap-2 max-w-[390px] w-full">
          <p class="font-semibold leading-5">EJOS vurderede udbudspris</p>
          <tooltip message="Message..."></tooltip>
        </div>
        <div class="flex gap-4 flex-wrap max-w-[692px] w-full items-center">
          <mat-form-field
            class="max-w-[334px] w-full relative | unit center no-error read-only"
            appearance="fill"
          >
            <input
              class="!text-right !mt-0"
              matInput
              readonly
              tabindex="-1"
              autocomplete="off"
              placeholder="0,0"
              formControlName="ejosIndicativeAssessedPrice"
            />
            <div
              class="absolute right-[-1rem] bottom-[-10px] h-[54px] p-[16px] bg-[#D1D8D9] text-[#747F81] rounded-tr-[12px] rounded-br-[12px]"
            >
              <span class="font-semibold text-base leading-5"> kr. </span>
            </div>
          </mat-form-field>
          <mat-form-field
            class="max-w-[334px] w-full relative | unit-tooltip center read-only no-error"
            appearance="fill"
          >
            <input
              class="!text-right !mt-0"
              readonly
              tabindex="-1"
              matInput
              autocomplete="off"
              placeholder="0,0"
              formControlName="ejosIndicativeAssessedPricePerSquareMeter"
            />
            <div
              class="absolute right-[-1rem] bottom-[-10px] h-[54px] p-[16px] bg-[#D1D8D9] text-[#747F81] rounded-tr-[12px] rounded-br-[12px]"
            >
              <span class="font-semibold text-base leading-5">
                kr./ m<sup>2</sup>
              </span>
            </div>
          </mat-form-field>
        </div>
      </div>

      <div class="mt-8 flex gap-4 justify-start items-center flex-wrap">
        <div class="flex gap-2 max-w-[390px] w-full">
          <p class="font-semibold leading-5">Ønsket pris udbudspris</p>
          <tooltip message="Indtast din ønsket udbudspris"></tooltip>
        </div>
        <div class="flex gap-4 flex-wrap max-w-[692px] w-full items-center">
          <mat-form-field
            class="max-w-[334px] w-full relative | unit center no-error"
            appearance="fill"
          >
            <input
              class="!text-right !mt-0"
              numbersWithDecimalOnly
              maxlength="20"
              [group]="pricingForm"
              matInput
              autocomplete="off"
              placeholder="0,0"
              formControlName="indicativePrice"
              (input)="formatInput($event)"
            />
            <div
              class="absolute right-[-1rem] bottom-[-10px] h-[54px] p-[16px] bg-[#D1D8D9] text-[#747F81] rounded-tr-[12px] rounded-br-[12px]"
            >
              <span class="font-semibold text-base leading-5"> kr. </span>
            </div>
            <mat-error class="!pt-5" class="!pt-5"> Påkrævet </mat-error>
          </mat-form-field>
          <mat-form-field
            class="max-w-[334px] w-full relative | unit-tooltip center read-only no-error"
            appearance="fill"
          >
            <input
              class="!text-right !mt-0"
              readonly
              tabindex="-1"
              matInput
              autocomplete="off"
              placeholder="0,0"
              formControlName="indicativePricePerSqMeters"
            />
            <div
              class="absolute right-[-1rem] bottom-[-10px] h-[54px] p-[16px] bg-[#D1D8D9] text-[#747F81] rounded-tr-[12px] rounded-br-[12px]"
            >
              <span class="font-semibold text-base leading-5">
                kr./ m<sup>2</sup>
              </span>
            </div>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
  <div
    class="flex items-center gap-3 border-t border-solid border-[#D1D8D9] mt-[80px] py-10"
  >
    <button
      class="!py-[15px] !px-[25px] !border !border-solid !border-[#D1D8D9] !rounded-[90px] !min-w-fit"
      mat-flat-button
      (click)="onPrevClicked()"
    >
      <icon-caret-left></icon-caret-left>
    </button>
    <button
      class="w-full max-w-[274px]"
      [ngClass]="{
        'animate-shake':
          (firstForm.invalid && firstForm.submitted) ||
          (secondForm.invalid && secondForm.submitted)
      }"
      mat-flat-button
      color="primary"
      (click)="onNextClicked()"
    >
      <div *ngIf="loading" class="flex items-center justify-center">
        <icon-loading-button class="animate-spin"></icon-loading-button>
      </div>
      <span *ngIf="!loading">GEM & FORTSÆT</span>
    </button>
  </div>
</div>

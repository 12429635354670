import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Listing } from 'src/app/_models/property.model';
import { ListingService } from 'src/app/_services/listing/listing.service';

type Agreement = {
  creationDate: string;
  id: string;
  terms: string;
  version: number;
};

@Component({
  selector: 'agreement-data',
  templateUrl: './agreement-data.component.html',
  styleUrls: ['./agreement-data.component.scss'],
})
export class AgreementDataComponent implements OnInit {
  @Output() prevClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() nextClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  loading: boolean = false;
  agreement: Agreement;

  agreementForm: FormGroup;

  constructor(
    private listService: ListingService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService
  ) {
    this.agreementForm = this.fb.group({
      termsAccepted: new FormControl(false, [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.spinner.show();
    this.listService.getAgreementVersion().subscribe({
      next: (res) => {
        // console.log(res);
        this.agreement = res;
        this.spinner.hide();
      },
      error: (err) => {
        console.log(err);
        this.spinner.hide();
      },
    });
  }

  get termsAccepted() {
    return this.agreementForm.get('termsAccepted');
  }

  onNextClicked() {
    const listing = JSON.parse(sessionStorage.getItem('listing'));
    if (listing) {
      this.loading = true;
      const createOrUpdateProperty: Listing = { ...listing };
      createOrUpdateProperty.creationStep = 6;
      createOrUpdateProperty.acceptedSaleTermsVersion =
        this.agreement?.version || 0;
      // console.log(createOrUpdateProperty);
      this.listService
        .createOrUpdateProperty(createOrUpdateProperty)
        .subscribe({
          next: (res) => {
            sessionStorage.setItem('listing', JSON.stringify(res));
            this.loading = false;
            this.nextClick.emit(true);
          },
          error: (err) => {
            console.log(err);
            this.loading = false;
          },
        });
    }
  }

  onPrevClicked() {
    this.prevClick.emit(true);
  }
}

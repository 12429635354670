import { DecimalPipe } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Address } from 'src/app/_models/address.model';
import { Unit } from 'src/app/_models/property.model';
import { formatNumber, ifNaN, roundNumber, unformatNumber } from 'src/utils';
import {
  NUMBER_OF_ROOMS,
  RENT_ADJUSTMENT,
  SelectObject,
  TENANT,
  TERMINATION_DURATION,
  UNIT_TYPES,
} from './add-edit-unit-dialog.util';

@Component({
  selector: 'add-edit-unit-dialog',
  templateUrl: './add-edit-unit-dialog.component.html',
  styleUrls: ['./add-edit-unit-dialog.component.scss'],
})
export class AddEditUnitDialogComponent implements OnInit {
  rentalIncomeForm: FormGroup;
  _unitTypes: SelectObject[] = JSON.parse(JSON.stringify(UNIT_TYPES));
  _userAddresses: Address[] = [];
  _tenant: SelectObject[] = JSON.parse(JSON.stringify(TENANT));
  _rentAdjustment: SelectObject[] = JSON.parse(JSON.stringify(RENT_ADJUSTMENT));
  _numberOfRooms: SelectObject[] = JSON.parse(JSON.stringify(NUMBER_OF_ROOMS));
  _terminationDuration: SelectObject[] = JSON.parse(
    JSON.stringify(TERMINATION_DURATION)
  );
  monthAndYear: Date | null;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private numberpipe: DecimalPipe,
    private dialogRef: MatDialogRef<AddEditUnitDialogComponent>
  ) {
    this.rentalIncomeForm = this.fb.group({
      unitType: new FormControl('', [Validators.required]),
      primaryAddress: new FormControl('', [Validators.required]),
      numberOfRooms: new FormControl('', [Validators.required]),
      unitArea: new FormControl('', [
        Validators.required,
        Validators.pattern(/(?!0*[,]*0*$)/),
      ]),
      monthlyRent: new FormControl('', [
        Validators.required,
        Validators.pattern(/(?!0*[,]*0*$)/),
      ]),
      yearlyRent: new FormControl('0,0', [Validators.required]),
      yearlyRentPerSquareMeter: new FormControl('0,0', [Validators.required]),
      tenant: new FormControl('', [Validators.required]),
      prePaidRent: new FormControl('', [
        Validators.required,
        Validators.pattern(/(?!0*[,]*0*$)/),
      ]),
      deposit: new FormControl('', [
        Validators.required,
        Validators.pattern(/(?!0*[,]*0*$)/),
      ]),
      rentAdjustment: new FormControl('', [Validators.required]),
      predeterminedYearlyRentRise: new FormControl('0,0'),
      moveInDate: new FormControl(''),
      terminationDuration: new FormControl(''),
      notes: new FormControl(''),
    });
  }

  ngOnInit(): void {
    const tempAddresses: Address[] = JSON.parse(
      sessionStorage.getItem('listing')
    )?.secondaryAddress;
    tempAddresses?.unshift(
      JSON.parse(sessionStorage.getItem('listing')).primaryAddress
    );
    this._userAddresses = tempAddresses || [];

    this.calculateYearlyRent();
    this.calculateYearlyRentPerSquareMeter();
    if (this.data.indexTobeEdited >= 0) this.loadUnitValues();
  }

  // Getters for form
  get unitType() {
    return this.rentalIncomeForm.get('unitType');
  }
  get primaryAddress() {
    return this.rentalIncomeForm.get('primaryAddress');
  }
  get numberOfRooms() {
    return this.rentalIncomeForm.get('numberOfRooms');
  }
  get unitArea() {
    return this.rentalIncomeForm.get('unitArea');
  }
  get monthlyRent() {
    return this.rentalIncomeForm.get('monthlyRent');
  }
  get yearlyRent() {
    return this.rentalIncomeForm.get('yearlyRent');
  }
  get yearlyRentPerSquareMeter() {
    return this.rentalIncomeForm.get('yearlyRentPerSquareMeter');
  }
  get tenant() {
    return this.rentalIncomeForm.get('tenant');
  }
  get prePaidRent() {
    return this.rentalIncomeForm.get('prePaidRent');
  }
  get deposit() {
    return this.rentalIncomeForm.get('deposit');
  }
  get rentAdjustment() {
    return this.rentalIncomeForm.get('rentAdjustment');
  }
  get predeterminedYearlyRentRise() {
    return this.rentalIncomeForm.get('predeterminedYearlyRentRise');
  }
  get moveInDate() {
    return this.rentalIncomeForm.get('moveInDate');
  }
  get terminationDuration() {
    return this.rentalIncomeForm.get('terminationDuration');
  }
  get notes() {
    return this.rentalIncomeForm.get('notes');
  }

  _addEditUnit() {
    if (this.rentalIncomeForm.valid) {
      // Prepare table row from form values
      let unit: Unit = {
        unitType: this.unitType?.value,
        primaryAddress: this.primaryAddress.value,
        numberOfRooms: this.numberOfRooms.value,
        unitArea: unformatNumber(this.unitArea?.value),
        monthlyRent: unformatNumber(this.monthlyRent.value),
        yearlyRent: unformatNumber(this.yearlyRent.value),
        yearlyRentPerSquareMeter: unformatNumber(
          this.yearlyRentPerSquareMeter.value
        ),
        tenant: this.tenant.value,
        prePaidRent: unformatNumber(this.prePaidRent.value),
        deposit: unformatNumber(this.deposit.value),
        rentAdjustment: this.rentAdjustment.value,
        predeterminedYearlyRentRise:
          this.rentAdjustment.value !== 'PredeterminedYearlyRentRise'
            ? 0
            : unformatNumber(this.predeterminedYearlyRentRise.value),
        moveInDate: this.moveInDate.value,
        terminationDuration: this.terminationDuration.value,
        notes: this.notes.value,
      };
      // Push table row to unitList Array if new entry
      if (this.data.indexTobeEdited === -1) this.data.units?.unshift(unit);
      if (this.data.indexTobeEdited >= 0)
        this.data.units[this.data.indexTobeEdited] = unit;
      this.closeDialog();
    }
  }

  calculateYearlyRent() {
    this.monthlyRent.valueChanges.subscribe((value) => {
      this.yearlyRent.setValue(
        unformatNumber(value) == 0 || !value
          ? '0,0'
          : ifNaN(formatNumber(roundNumber(unformatNumber(value) * 12)))
      );
    });
  }

  calculateYearlyRentPerSquareMeter() {
    this.monthlyRent.valueChanges.subscribe((value) => {
      unformatNumber(value) == 0 ||
      !value ||
      unformatNumber(this.unitArea.value) == 0 ||
      !this.unitArea.value
        ? '0,0'
        : this.yearlyRentPerSquareMeter.setValue(
            ifNaN(
              formatNumber(
                roundNumber(
                  (unformatNumber(value) * 12) /
                    unformatNumber(this.unitArea.value)
                )
              )
            )
          );
    });

    this.unitArea.valueChanges.subscribe((value) => {
      unformatNumber(value) == 0 ||
      !value ||
      unformatNumber(this.monthlyRent.value) == 0 ||
      !this.monthlyRent.value
        ? '0,0'
        : this.yearlyRentPerSquareMeter.setValue(
            ifNaN(
              formatNumber(
                roundNumber(
                  (unformatNumber(this.monthlyRent.value) * 12) /
                    unformatNumber(value)
                )
              )
            )
          );
    });
  }

  loadUnitValues() {
    const unit = this.data.units[this.data.indexTobeEdited];
    this.unitType.setValue(unit.unitType);
    this.primaryAddress.setValue(
      this._userAddresses.find(
        (item) =>
          item.latitude === unit.primaryAddress.latitude &&
          item.longitude === unit.primaryAddress.longitude
      )
    );
    this.numberOfRooms.setValue(unit.numberOfRooms);
    this.unitArea.setValue(formatNumber(unit.unitArea));
    this.monthlyRent.setValue(formatNumber(unit.monthlyRent));
    this.tenant.setValue(unit.tenant);
    this.prePaidRent.setValue(formatNumber(unit.prePaidRent));
    this.deposit.setValue(formatNumber(unit.deposit));
    this.rentAdjustment.setValue(unit.rentAdjustment);
    this.predeterminedYearlyRentRise.setValue(
      unit.rentAdjustment === 'NetPriceIndex'
        ? 0
        : formatNumber(unit.predeterminedYearlyRentRise)
    );
    this.moveInDate.setValue(unit.moveInDate);
    this.terminationDuration.setValue(unit.terminationDuration);
    this.notes.setValue(unit.notes);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  // helpers
  formatInput(event: any) {
    const parts = event.target.value.toString().split(',');
    if (!isNaN(Number(parts[0]))) {
      parts[0] = this.numberpipe.transform(
        parts[0].replace(/\./g, '').replace(/,/g, ''),
        '1.0-2',
        'da-DK'
      );
      event.target.value = parts.join(',');
    }
  }

  displaySelectText(list, value) {
    if (list && value) return list.find((list) => list.value === value).text;
  }
}

<svg
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M16.9799 6.7207H3.22992"
    [attr.stroke]="strokeColor"
    stroke-width="1.25"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M13.2299 9.8457H3.22992"
    [attr.stroke]="strokeColor"
    stroke-width="1.25"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M10.2098 13.127H3.33484"
    [attr.stroke]="strokeColor"
    stroke-width="1.25"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-text-align-right',
  templateUrl: './text-align-right.component.html',
  styleUrls: ['./text-align-right.component.scss'],
})
export class TextAlignRightComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

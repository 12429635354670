import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-tag-hub',
  templateUrl: './tag-hub.component.html',
  styleUrls: ['./tag-hub.component.scss'],
})
export class TagHubComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

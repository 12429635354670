<div
  *ngIf="mode === 'FORM'"
  [formGroup]="group"
  [ngStyle]="{
    width: _width ? _width : '500px',
    height: _height ? _height : '323px'
  }"
  fileDragDrop
  defaultColor="#c7cbcf"
  (filesChangeEmiter)="onFileChange($event)"
  class="image-upload-container"
>
  <div *ngIf="isLoaderEnabled" class="image-loader">
    <app-loader [isMedium]="true"></app-loader>
  </div>
  <!-- // Default image when no upload -->
  <img
    *ngIf="!isLoaderEnabled"
    class="default-image"
    [ngStyle]="{
      width: _width && isUploaded ? _width : isUploaded ? '500px' : 'auto',
      height: _height && isUploaded ? _height : isUploaded ? '323px' : 'auto',
      objectFit: isUploaded ? 'contain' : 'fill'
    }"
    [src]="url"
    alt="Loading..."
  />
  <div *ngIf="!isLoaderEnabled" class="flex-row-10 button-containers">
    <!-- //upload image button -->
    <label class="upload-button">
      <input
        [formControlName]="_id"
        type="file"
        accept="image/jpeg,image/png,image/bmp"
        (change)="onFileChange($event.target.files)"
      />
    </label>

    <!-- //view image button -->
    <label class="show-button">
      <button (click)="viewImage()"></button>
    </label>
    <!-- //delete image button -->
    <label class="delete-button">
      <button (click)="deleteImage()"></button>
    </label>
  </div>
</div>

<!-- // ----------- Not as form contol----------------------- -->

<div
  *ngIf="mode === 'SINGLE'"
  [ngStyle]="{
    width: _width ? _width : '500px',
    height: _height ? _height : '323px'
  }"
  fileDragDrop
  defaultColor="#c7cbcf"
  (filesChangeEmiter)="onFileChange($event)"
  class="image-upload-container"
>
  <div *ngIf="isLoaderEnabled" class="image-loader">
    <app-loader [isMedium]="true"></app-loader>
  </div>

  <!-- // Default image when no upload -->
  <img
    *ngIf="!isLoaderEnabled"
    class="default-image"
    [ngStyle]="{
      width: _width && isUploaded ? _width : isUploaded ? '500px' : 'auto',
      height: _height && isUploaded ? _height : isUploaded ? '323px' : 'auto',
      objectFit: isUploaded ? 'contain' : 'fill'
    }"
    [src]="url"
  />
  <div *ngIf="!isLoaderEnabled" class="flex-row-10 button-containers">
    <!-- //upload image button -->
    <label class="upload-button">
      <input
        type="file"
        accept="image/jpeg,image/png,image/bmp"
        (change)="onFileChange($event.target.files)"
      />
    </label>

    <!-- //view image button -->
    <label class="show-button">
      <button (click)="viewImage()"></button>
    </label>
    <!-- //delete image button -->
    <label class="delete-button">
      <button (click)="deleteImage()"></button>
    </label>
  </div>
</div>

<!-- Trigger the Modal -->
<!-- The Modal -->
<div [ngClass]="{ activated: viewModal }" class="modal">
  <!-- The Close Button -->
  <span class="close" (click)="closeModal()">&times;</span>

  <!-- Modal Content (The Image) -->
  <img class="modal-content" [src]="url" />
</div>

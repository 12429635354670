import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'icon-plus-twenty',
  templateUrl: './plus-twenty.component.html',
  styleUrls: ['./plus-twenty.component.scss'],
})
export class PlusTwentyComponent implements OnInit {
  @Input() strokeColor: string = '#FFFFFF';

  constructor() {}

  ngOnInit(): void {}
}

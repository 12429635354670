<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M19.5 5.25H4.5C3.67157 5.25 3 5.92157 3 6.75V17.25C3 18.0784 3.67157 18.75 4.5 18.75H19.5C20.3284 18.75 21 18.0784 21 17.25V6.75C21 5.92157 20.3284 5.25 19.5 5.25Z"
    stroke="#24535B"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M3 11.25H8.325C8.49773 11.2491 8.66535 11.3085 8.79889 11.4181C8.93242 11.5276 9.02346 11.6804 9.05625 11.85C9.19948 12.5246 9.57002 13.1295 10.1059 13.5635C10.6417 13.9975 11.3104 14.2343 12 14.2343C12.6896 14.2343 13.3583 13.9975 13.8941 13.5635C14.43 13.1295 14.8005 12.5246 14.9437 11.85C14.9765 11.6804 15.0676 11.5276 15.2011 11.4181C15.3346 11.3085 15.5023 11.2491 15.675 11.25H21"
    stroke="#24535B"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M3 8.25H21"
    stroke="#24535B"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

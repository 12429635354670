<div class="flex items-center gap-2 mb-5">
  <a routerLink="/home"
    ><svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6719 8.56245L10.4219 2.88277C10.3067 2.77736 10.1562 2.7189 10 2.7189C9.84384 2.7189 9.69334 2.77736 9.57812 2.88277L3.32812 8.56245C3.26523 8.62185 3.21486 8.69323 3.17998 8.77239C3.14509 8.85155 3.1264 8.9369 3.125 9.02339V16.25C3.125 16.4157 3.19085 16.5747 3.30806 16.6919C3.42527 16.8091 3.58424 16.875 3.75 16.875H16.25C16.4158 16.875 16.5747 16.8091 16.6919 16.6919C16.8092 16.5747 16.875 16.4157 16.875 16.25V9.02339C16.8736 8.9369 16.8549 8.85155 16.82 8.77239C16.7851 8.69323 16.7348 8.62185 16.6719 8.56245V8.56245Z"
        stroke="#283F43"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </a>
  <span
    *ngFor="let item of breadCrumb"
    class="font-normal text-sm leading-[18px] text-[#283F43] last:text-[#091315]"
  >
    <a routerLink="{{ item.url }}">/&nbsp;{{ item?.title }}</a>
  </span>
</div>

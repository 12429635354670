<div
  class="flex items-center justify-between absolute top-[15px] left-[16px] z-40 py-[11px] px-4 rounded-[90px] bg-white max-w-[224px]"
  [ngClass]="{
    'shadow-menu-logo gap-[10px] rounded-[90px] border border-solid border-[#d1d8d9]':
      collapse && screen_wmin_1024.matches,
    'w-full': !collapse,
    '!static max-w-none px-[40px] py-[26px]': !screen_wmin_1024.matches
  }"
  (click)="$event.stopPropagation()"
>
  <div
    [routerLink]="['/home/front-page']"
    class="flex items-center gap-2 cursor-pointer"
  >
    <icon-logo-sidenavbar></icon-logo-sidenavbar>
    <icon-logo-text-sidenavbar *ngIf="!collapse || !screen_wmin_1024.matches">
    </icon-logo-text-sidenavbar>
  </div>
  <icon-logo-menu-sidenavbar
    *ngIf="!drawerOpened || screen_wmin_1024.matches"
    class="cursor-pointer"
    (click)="screen_wmin_1024.matches ? toggleSidebar() : openDrawer()"
  ></icon-logo-menu-sidenavbar>

  <icon-close-dialog
    *ngIf="drawerOpened && !screen_wmin_1024.matches"
    class="cursor-pointer"
    (click)="
      screen_wmin_1024.matches ? toggleSidebar() : closeDrawer('default')
    "
  ></icon-close-dialog>
</div>

<mat-sidenav-container
  class="w-full h-full"
  [ngClass]="{
    'h-[calc(100%-84px)]': !screen_wmin_1024.matches
  }"
  autosize="true"
  (backdropClick)="closeDrawer('backdrop')"
>
  <mat-sidenav
    class="flex flex-col items-center justify-start max-w-[264px] border border-solid border-[#D1D8D9] px-5"
    [ngClass]="{
      'w-full': !collapse,
      'px-[40px] !border-none': !screen_wmin_1024.matches
    }"
    [mode]="screen_wmin_1024.matches ? 'side' : 'over'"
    [opened]="screen_wmin_1024.matches"
    (keydown.escape)="closeDrawer('escapePressed')"
    #drawer
  >
    <div
      class="flex flex-col gap-[10px] mt-[98px] w-full max-w-[224px] relative"
      [ngClass]="{
        '!mt-[7px]': !screen_wmin_1024.matches
      }"
    >
      <a
        [matTooltip]="route.title"
        [matTooltipPosition]="'right'"
        [matTooltipDisabled]="!collapse || !screen_wmin_1024.matches"
        matTooltipClass="sidemenu-tooltip right"
        *ngFor="let route of routes; let i = index"
        [routerLink]="[route.url]"
        class="flex items-center p-[12px]"
        [ngClass]="{
          'bg-[#24535B] rounded-[90px]': route.isActive
        }"
        (click)="!screen_wmin_1024.matches ? drawer.toggle() : ''"
      >
        <div class="flex items-center gap-2">
          <menu-icons [id]="route._id" [active]="route.isActive"></menu-icons>
          <span
            class="text-[#283F43] font-nomral text-base"
            [ngClass]="{ '!text-white': route.isActive }"
            [hidden]="collapse && screen_wmin_1024.matches"
            >{{ route.title }}</span
          >
        </div>
      </a>
    </div>
  </mat-sidenav>

  <mat-sidenav-content class="bg-white">
    <div
      class="flex items-center justify-between flex-wrap py-[15px] gap-1 border-b border-solid border-[#D1D8D9]"
      [ngClass]="{
        'sidenavbar-not-collapsed': !collapse && screen_wmin_1024.matches,
        'sidenavbar-collapsed': collapse || !screen_wmin_1024.matches
      }"
    >
      <p class="font-semibold text-2xl text-[#091315]">
        {{ activeRoute?.title }}
      </p>
      <div class="flex items-center gap-[34px]">
        <button *ngIf="screen_wmin_640.matches">
          <icon-chat-topnavbar></icon-chat-topnavbar>
        </button>
        <button *ngIf="screen_wmin_640.matches">
          <icon-notification-topnavbar></icon-notification-topnavbar>
        </button>
        <div class="pl-4 py-[11px] flex items-center gap-[10px]">
          <img
            src="https://ui-avatars.com/api/?name={{
              firstName[0]
            }}&background=0D8ABC&color=fff"
            class="rounded-full w-8"
          />
          <p class="text-[#091315] font-normal text-base leading-[20px]">
            {{ firstName }}
          </p>
          <button [matMenuTriggerFor]="profileMenu">
            <icon-caret-down strokeColor="#091315"></icon-caret-down>
          </button>
          <mat-menu #profileMenu="matMenu">
            <button mat-menu-item (click)="logout()">Logout</button>
          </mat-menu>
        </div>
      </div>
    </div>
    <div
      class="pt-[22px]"
      [ngClass]="{
        'sidenavbar-not-collapsed': !collapse && screen_wmin_1024.matches,
        'sidenavbar-collapsed': collapse || !screen_wmin_1024.matches
      }"
    >
      <bread-crumbs></bread-crumbs>
    </div>
    <div
      class="h-[calc(100%-147px)] | sidenav-content-container"
      [ngClass]="{
        'sidenavbar-not-collapsed': !collapse && screen_wmin_1024.matches,
        'sidenavbar-collapsed': collapse || !screen_wmin_1024.matches
      }"
    >
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatKrowns',
})
export class FormatKrownsPipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): unknown {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'DKK',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
    // const _value = value.toString().replace(/,/g, '.');
    return `${formatter
      .format(value)
      .replace(/,/g, '.')
      .replace(/DKK\s/, '')} Kr.`;
  }
}

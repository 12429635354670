<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M20.0062 10.275L12.5062 3.45942C12.368 3.33293 12.1874 3.26278 12 3.26278C11.8126 3.26278 11.632 3.33293 11.4937 3.45942L3.99375 10.275C3.91828 10.3463 3.85783 10.432 3.81597 10.527C3.77411 10.622 3.75168 10.7244 3.75 10.8282V19.5C3.75 19.699 3.82902 19.8897 3.96967 20.0304C4.11032 20.171 4.30109 20.25 4.5 20.25H19.5C19.6989 20.25 19.8897 20.171 20.0303 20.0304C20.171 19.8897 20.25 19.699 20.25 19.5V10.8282C20.2483 10.7244 20.2259 10.622 20.184 10.527C20.1422 10.432 20.0817 10.3463 20.0062 10.275V10.275Z"
    stroke="black"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

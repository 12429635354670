<!-- Buttons -->
<div class="footer-container">
  <div *ngIf="isLoaderEnabledLocale" class="saving-loader">
    <span class="saving-icon rotate"></span>
    <p>Gemmer...</p>
  </div>
  <div class="prev-next-buttons">
    <button type="button" class="button-prev" (click)="onPrevClicked()">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z"
          fill="#262F63"
        />
      </svg>
      Tilbage
    </button>
    <button
      [type]="isSubmit ? 'submit' : 'button'"
      class="button-next"
      (click)="onNextClicked()"
      [ngClass]="{
        'button-next-active': group?.status === 'VALID' || isActive
      }"
    >
      Næste
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z"
          fill="white"
        />
      </svg>
    </button>
  </div>
</div>

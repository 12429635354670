import { Component, OnInit } from '@angular/core';
import { NOTIFICATIONS, SnackBarService } from 'src/snack-bar.service';

@Component({
  selector: 'snack-bars',
  templateUrl: './snack-bars.component.html',
  styleUrls: ['./snack-bars.component.scss'],
})
export class SnackBarsComponent implements OnInit {
  notificationsLocale: NOTIFICATIONS = [];

  constructor(private snackBars: SnackBarService) {
    this.snackBars.notifications.subscribe((newVal) => {
      this.notificationsLocale = newVal;
    });
  }

  ngOnInit(): void {}

  onClick(_id: string) {
    this.snackBars.removeNotification(_id);
  }
}

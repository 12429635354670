import { Input, Component, OnInit, HostListener } from '@angular/core';

type STEP = {
  title: string;
  isActive?: boolean;
  isComplete?: boolean;
};
export type STEPS = STEP[];

@Component({
  selector: 'steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss'],
})
export class StepsComponent implements OnInit {
  @Input() steps: STEPS = [];
  screen_wmin_1320: any;

  constructor() {}

  @HostListener('window:resize', ['$event'])
  ngOnInit(): void {
    this.screen_wmin_1320 = window.matchMedia('(min-width: 1320px)');
  }
}

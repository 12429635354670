import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Listing, Unit } from 'src/app/_models/property.model';
import { ListingService } from 'src/app/_services/listing/listing.service';
import { SelectObject } from '../add-property-data/add-property-data.util';
import { AddEditUnitDialogComponent } from '../dialogs/add-edit-unit-dialog/add-edit-unit-dialog.component';
import {
  NUMBER_OF_ROOMS,
  RENT_ADJUSTMENT,
  TERMINATION_DURATION,
  UNIT_TYPES,
} from '../dialogs/add-edit-unit-dialog/add-edit-unit-dialog.util';

@Component({
  selector: 'add-rental-income-data',
  templateUrl: './add-rental-income-data.component.html',
  styleUrls: ['./add-rental-income-data.component.scss'],
})
export class AddRentalIncomeDataComponent implements OnInit {
  @Output() prevClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() nextClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  _unitType: SelectObject[] = JSON.parse(JSON.stringify(UNIT_TYPES));
  _rentAdjustment: SelectObject[] = JSON.parse(JSON.stringify(RENT_ADJUSTMENT));
  _numberOfRooms: SelectObject[] = JSON.parse(JSON.stringify(NUMBER_OF_ROOMS));
  _terminationDuration: SelectObject[] = JSON.parse(
    JSON.stringify(TERMINATION_DURATION)
  );

  units: Unit[] = [];

  totalUnitArea: number = 0;
  totalMonthlyRent: number = 0;
  totalDeposit: number = 0;

  businessTotalUnitArea: number = 0;
  businessTotalMonthlyRent: number = 0;
  businessTotalDeposit: number = 0;

  privateTotalUnitArea: number = 0;
  privateTotalMonthlyRent: number = 0;
  privateTotalDeposit: number = 0;

  loading: boolean = false;
  isSubmitted: boolean = false;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private listService: ListingService
  ) {}

  ngOnInit(): void {
    const listing = JSON.parse(sessionStorage.getItem('listing'));
    listing?.id && listing.creationStep >= 2
      ? (this.units = listing.units)
      : '';
    if (this.units?.length) this.calculate_table_footer_data();
  }

  // Calcudate table footer data to be added
  calculate_table_footer_data() {
    // Total share
    this.totalUnitArea = this.units.reduce((accumulator, unit) => {
      return accumulator + unit.unitArea;
    }, 0);

    this.totalMonthlyRent = this.units.reduce((accumulator, unit) => {
      return accumulator + unit.monthlyRent;
    }, 0);

    this.totalDeposit = this.units.reduce((accumulator, unit) => {
      return accumulator + unit.deposit;
    }, 0);

    // Business housing share
    this.businessTotalUnitArea = this.units
      .filter((x) => x.tenant != null && x.tenant === 'Business')
      .reduce((accumulator, unit) => {
        return accumulator + unit.unitArea;
      }, 0);

    this.businessTotalMonthlyRent = this.units
      .filter((x) => x.tenant != null && x?.tenant === 'Business')
      .reduce((accumulator, unit) => {
        return accumulator + unit.monthlyRent;
      }, 0);

    this.businessTotalDeposit = this.units
      .filter((x) => x.tenant != null && x?.tenant === 'Business')
      .reduce((accumulator, unit) => {
        return accumulator + unit.deposit;
      }, 0);

    // Private housing share
    this.privateTotalUnitArea = this.units
      .filter((x) => x.tenant != null && x?.tenant === 'Private')
      .reduce((accumulator, unit) => {
        return accumulator + unit.unitArea;
      }, 0);

    this.privateTotalMonthlyRent = this.units
      .filter((x) => x.tenant != null && x?.tenant === 'Private')
      .reduce((accumulator, unit) => {
        return accumulator + unit.monthlyRent;
      }, 0);

    this.privateTotalDeposit = this.units
      .filter((x) => x.tenant != null && x?.tenant === 'Private')
      .reduce((accumulator, unit) => {
        return accumulator + unit.deposit;
      }, 0);
  }

  // New
  addNewUnit() {
    const units = this.units;
    this.dialog
      .open(AddEditUnitDialogComponent, {
        data: {
          units,
          indexTobeEdited: -1,
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.calculate_table_footer_data();
      });
  }

  editUnit(indexTobeEdited: number) {
    const units = this.units;
    this.dialog
      .open(AddEditUnitDialogComponent, {
        data: {
          units,
          indexTobeEdited,
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.calculate_table_footer_data();
      });
  }

  duplicateUnit(index: number) {
    // find unit to be duplicated
    let unit = this.units[index];

    // Push unit to the unitList
    this.units.splice(index + 1, 0, unit);

    // re-calculate total values in table footer
    this.calculate_table_footer_data();
  }

  deleteUnit(index: number) {
    // find unit to be deleted
    this.units.splice(index, 1);

    // re-calculate total values in table footer
    this.calculate_table_footer_data();
  }

  onNextClicked() {
    this.isSubmitted = true;
    if (this.units?.length) {
      const listing = JSON.parse(sessionStorage.getItem('listing'));
      if (listing) {
        this.loading = true;
        const createOrUpdateProperty: Listing = { ...listing };
        createOrUpdateProperty.creationStep = 2;
        createOrUpdateProperty.units = this.units;
        // createOrUpdateProperty.units.map((unit) => {
        //   if (unit.rentAdjustment !== 'PredeterminedYearlyRentRise')
        //     unit.predeterminedYearlyRentRise = 0;
        // });
        // console.log(createOrUpdateProperty);
        this.listService
          .createOrUpdateProperty(createOrUpdateProperty)
          .subscribe({
            next: (res) => {
              sessionStorage.setItem('listing', JSON.stringify(res));
              this.loading = false;
              this.nextClick.emit(true);
            },
            error: (err) => {
              console.log(err);
              this.loading = false;
            },
          });
      }
    }
  }

  onPrevClicked() {
    this.prevClick.emit(true);
  }

  // helpers
  displaySelectText(list, value) {
    if (list && value) return list.find((list) => list.value === value).text;
  }
}

<ul class="panel-container">
  <li class="header">
    <span class="flex-row-10">
      <h6>{{ title }}</h6>
      <span tabindex="0" *ngIf="isInfo" class="info-icon-white"></span>
    </span>
  </li>
  <li class="content">
    <ng-content></ng-content>
  </li>
</ul>

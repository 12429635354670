import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'view-property-title-description',
  templateUrl: './title-description.component.html',
  styleUrls: ['./title-description.component.scss'],
})
export class TitleDescriptionComponent implements OnInit {
  @Input() title = 'title';
  @Input() description = 'description...';
  constructor() {}

  ngOnInit(): void {}
}

<div class="flex justify-between items-center mb-5">
  <p
    class="text-black text-2xl font-semibold leading-[30px] !m-0 wmin1460:min-w-[1060px]"
  >
    Enhed
  </p>
  <icon-close-dialog
    class="cursor-pointer"
    mat-dialog-close
  ></icon-close-dialog>
</div>

<mat-dialog-content class="rounded-lg !overflow-x-hidden">
  <div class="!mt-5">
    <form
      #firstForm="ngForm"
      id="rentalIncomeForm"
      [formGroup]="rentalIncomeForm"
      (ngSubmit)="_addEditUnit()"
      class="flex flex-col gap-6 w-full"
    >
      <div class="flex gap-6 flex-wrap">
        <mat-form-field
          class="max-w-[332px] w-full relative | select-box color-747f81 color-091315 text-size-16 line-height-20 font-weight-600 padding-52"
          appearance="fill"
        >
          <mat-select
            placeholder="Enhedstype*"
            floatPlaceholder="never"
            formControlName="unitType"
            panelClass="text-size-16 line-height-20 color-1b2022"
          >
            <mat-option *ngFor="let item of _unitTypes" [value]="item.value">
              {{ item.text }}
            </mat-option>
          </mat-select>
          <mat-error> Påkrævet </mat-error>
          <icon-caret-down
            class="absolute right-0 bottom-[6px]"
          ></icon-caret-down>
          <tooltip
            class="absolute right-9 bottom-[6px]"
            message="Typen af den pågældende boligenhed"
          ></tooltip>
        </mat-form-field>

        <mat-form-field
          class="max-w-[332px] w-full relative | select-box color-747f81 color-091315 text-size-16 line-height-20 font-weight-600 padding-52"
          appearance="fill"
        >
          <mat-select
            placeholder="Vælg adresse*"
            floatPlaceholder="never"
            formControlName="primaryAddress"
            panelClass="text-size-16 line-height-20 color-1b2022"
          >
            <mat-option *ngFor="let item of _userAddresses" [value]="item">
              <div class="py-2 px-3">
                <p class="leading-5 text-black">
                  {{ item.street }}
                  {{ item.buildingNumber }},
                </p>
                <p class="leading-5 text-black">
                  {{ item?.postalCode }} {{ item.city }}
                </p>
              </div>
            </mat-option>
          </mat-select>
          <mat-error> Påkrævet </mat-error>
          <icon-caret-down
            class="absolute right-0 bottom-[6px]"
          ></icon-caret-down>
          <tooltip
            class="absolute right-9 bottom-[6px]"
            message="Adressen på den enkelte enhed"
          ></tooltip>
        </mat-form-field>

        <mat-form-field
          class="max-w-[332px] w-full relative | select-box color-747f81 color-091315 text-size-16 line-height-20 font-weight-600 padding-52"
          appearance="fill"
        >
          <mat-select
            placeholder="Antal rum/ værelser*"
            floatPlaceholder="never"
            formControlName="numberOfRooms"
            panelClass="text-size-16 line-height-20 color-1b2022"
          >
            <mat-option
              *ngFor="let room of _numberOfRooms"
              [value]="room.value"
            >
              {{ room.text }}
            </mat-option>
          </mat-select>
          <mat-error> Påkrævet </mat-error>
          <icon-caret-down
            class="absolute right-0 bottom-[6px]"
          ></icon-caret-down>
          <tooltip
            class="absolute right-9 bottom-[6px]"
            message="Adressen på den enkelte enhed"
          ></tooltip>
        </mat-form-field>
      </div>

      <div class="flex gap-6 flex-wrap">
        <mat-form-field class="max-w-[336px] w-full | pr26" appearance="fill">
          <mat-label>Enhedsareal</mat-label>
          <input
            matInput
            numbersWithDecimalOnly
            maxlength="20"
            [group]="rentalIncomeForm"
            autocomplete="off"
            formControlName="unitArea"
            (input)="formatInput($event)"
          />
          <div
            matSuffix
            class="relative -right-4 bottom-2 p-[17px] bg-[#D1D8D9] text-[#747F81] rounded-tr-[12px] rounded-br-[12px]"
          >
            <span class="font-semibold text-base leading-5">
              m<sup>2</sup>
            </span>
          </div>
          <tooltip
            matSuffix
            class="relative right-4 bottom-[46px]"
            message="Enhedens areal for bolig indtastes eksempelvis BBR area"
          ></tooltip>
          <mat-error> Påkrævet </mat-error>
        </mat-form-field>

        <mat-form-field class="max-w-[336px] w-full | pr26" appearance="fill">
          <mat-label>Månedlig leje</mat-label>
          <input
            matInput
            numbersWithDecimalOnly
            maxlength="20"
            [group]="rentalIncomeForm"
            autocomplete="off"
            formControlName="monthlyRent"
            (input)="formatInput($event)"
          />
          <div
            matSuffix
            class="relative -right-4 bottom-2 p-[17px] bg-[#D1D8D9] text-[#747F81] rounded-tr-[12px] rounded-br-[12px]"
          >
            <span class="font-semibold text-base leading-5"> kr. </span>
          </div>
          <tooltip
            matSuffix
            class="relative right-4 bottom-[46px]"
            message="Enhedens månedsleje excl. forbrug"
          ></tooltip>
          <mat-error *ngIf="monthlyRent.hasError('required')">
            Påkrævet
          </mat-error>
          <mat-error *ngIf="!monthlyRent.hasError('required')">
            Enter Valid Input
          </mat-error>
        </mat-form-field>

        <mat-form-field
          class="max-w-[336px] w-full | pr26 | read-only"
          appearance="fill"
        >
          <mat-label>Årlig leje</mat-label>
          <input
            matInput
            autocomplete="off"
            readonly
            tabindex="-1"
            formControlName="yearlyRent"
          />
          <div
            matSuffix
            class="relative -right-4 bottom-2 p-[17px] bg-[#D1D8D9] text-[#747F81] rounded-tr-[12px] rounded-br-[12px]"
          >
            <span class="font-semibold text-base leading-5"> kr. </span>
          </div>
          <tooltip
            matSuffix
            class="relative right-4 bottom-[46px]"
            message="Enhedens månedsleje excl. forbrug"
          ></tooltip>
          <mat-error> Påkrævet </mat-error>
        </mat-form-field>
      </div>

      <div class="flex gap-6 flex-wrap">
        <mat-form-field
          class="max-w-[336px] w-full | pr26 | read-only"
          appearance="fill"
        >
          <mat-label>Årlig leje pr. m<sup>2</sup></mat-label>
          <input
            matInput
            autocomplete="off"
            readonly
            tabindex="-1"
            formControlName="yearlyRentPerSquareMeter"
          />
          <div
            matSuffix
            class="relative -right-4 bottom-2 p-[17px] bg-[#D1D8D9] text-[#747F81] rounded-tr-[12px] rounded-br-[12px]"
          >
            <span class="font-semibold text-base leading-5"> kr. </span>
          </div>
          <tooltip
            matSuffix
            class="relative right-4 bottom-[46px]"
            message="Enhedens månedsleje excl. forbrug"
          ></tooltip>
          <mat-error> Påkrævet </mat-error>
        </mat-form-field>

        <mat-form-field
          class="max-w-[332px] w-full relative | select-box color-747f81 color-091315 text-size-16 line-height-20 font-weight-600 padding-52"
          appearance="fill"
        >
          <mat-select
            placeholder="Lejetype*"
            floatPlaceholder="never"
            formControlName="tenant"
            panelClass="text-size-16 line-height-20 color-1b2022"
          >
            <mat-option *ngFor="let item of _tenant" [value]="item.value">
              {{ item.text }}
            </mat-option>
          </mat-select>
          <mat-error> Påkrævet </mat-error>
          <icon-caret-down
            class="absolute right-0 bottom-[6px]"
          ></icon-caret-down>
          <tooltip
            class="absolute right-9 bottom-[6px]"
            message="Er det en privat lejer eller en erhvervslejer"
          ></tooltip>
        </mat-form-field>

        <mat-form-field class="max-w-[336px] w-full | pr26" appearance="fill">
          <mat-label>Forudbetalt leje</mat-label>
          <input
            matInput
            numbersWithDecimalOnly
            maxlength="20"
            [group]="rentalIncomeForm"
            autocomplete="off"
            formControlName="prePaidRent"
            (input)="formatInput($event)"
          />
          <div
            matSuffix
            class="relative -right-4 bottom-2 p-[17px] bg-[#D1D8D9] text-[#747F81] rounded-tr-[12px] rounded-br-[12px]"
          >
            <span class="font-semibold text-base leading-5"> kr. </span>
          </div>
          <tooltip
            matSuffix
            class="relative right-4 bottom-[46px]"
            message="Forudbetalt leje i DKK indtastes"
          ></tooltip>
          <mat-error *ngIf="prePaidRent.hasError('required')">
            Påkrævet
          </mat-error>
          <mat-error *ngIf="!prePaidRent.hasError('required')">
            Enter Valid Input
          </mat-error>
        </mat-form-field>
      </div>

      <div class="flex gap-6 flex-wrap">
        <mat-form-field class="max-w-[336px] w-full | pr26" appearance="fill">
          <mat-label>Depositum</mat-label>
          <input
            matInput
            numbersWithDecimalOnly
            maxlength="20"
            [group]="rentalIncomeForm"
            autocomplete="off"
            formControlName="deposit"
            (input)="formatInput($event)"
          />
          <div
            matSuffix
            class="relative -right-4 bottom-2 p-[17px] bg-[#D1D8D9] text-[#747F81] rounded-tr-[12px] rounded-br-[12px]"
          >
            <span class="font-semibold text-base leading-5"> kr. </span>
          </div>
          <tooltip
            matSuffix
            class="relative right-4 bottom-[46px]"
            message="Forudbetalt leje i DKK indtastes"
          ></tooltip>
          <mat-error *ngIf="deposit.hasError('required')"> Påkrævet </mat-error>
          <mat-error *ngIf="!deposit.hasError('required')">
            Enter Valid Input
          </mat-error>
        </mat-form-field>

        <mat-form-field
          class="max-w-[332px] w-full relative | select-box color-747f81 color-091315 text-size-16 line-height-20 font-weight-600 padding-52"
          appearance="fill"
        >
          <mat-select
            placeholder="Huslejeregulering*"
            floatPlaceholder="never"
            formControlName="rentAdjustment"
            panelClass="text-size-16 line-height-20 color-1b2022"
          >
            <mat-option
              *ngFor="let item of _rentAdjustment"
              [value]="item.value"
            >
              {{ item.text }}
            </mat-option>
          </mat-select>
          <mat-error> Påkrævet </mat-error>
          <icon-caret-down
            class="absolute right-0 bottom-[6px]"
          ></icon-caret-down>
          <tooltip
            class="absolute right-9 bottom-[6px]"
            message="Type af huslejeregulering fx. Nettoprisindex eller fx. trappeleje + 200 kr."
          ></tooltip>
        </mat-form-field>

        <mat-form-field
          *ngIf="rentAdjustment.value === 'PredeterminedYearlyRentRise'"
          class="max-w-[336px] w-full | pr26"
          appearance="fill"
        >
          <mat-label>Årlig lejestigning</mat-label>
          <input
            matInput
            numbersWithDecimalOnly
            maxlength="20"
            [group]="rentalIncomeForm"
            autocomplete="off"
            formControlName="predeterminedYearlyRentRise"
            (input)="formatInput($event)"
          />
          <div
            matSuffix
            class="relative -right-4 bottom-2 p-[17px] bg-[#D1D8D9] text-[#747F81] rounded-tr-[12px] rounded-br-[12px]"
          >
            <span class="font-semibold text-base leading-5"> Kr. </span>
          </div>
          <tooltip
            matSuffix
            class="relative right-4 bottom-[46px]"
            message="Den årlige faste lejestigning i kr."
          ></tooltip>
          <mat-error *ngIf="predeterminedYearlyRentRise.hasError('required')">
            Påkrævet
          </mat-error>
          <mat-error *ngIf="!predeterminedYearlyRentRise.hasError('required')">
            Enter Valid Input
          </mat-error>
        </mat-form-field>
      </div>
      <div class="flex gap-6 flex-wrap">
        <monthpicker
          class="!max-w-[510px] w-full"
          mode="FORM"
          [control]="moveInDate"
          placeholder="MM/ÅÅÅÅ*"
          tooltipMessage="Indflytningsdato for nuværende lejer"
        ></monthpicker>

        <mat-form-field
          class="max-w-[510px] w-full relative | select-box color-747f81 color-091315 text-size-16 line-height-20 font-weight-600 padding-52"
          appearance="fill"
        >
          <mat-select
            placeholder="Opsigelsesvarsel*"
            floatPlaceholder="never"
            formControlName="terminationDuration"
            panelClass="text-size-16 line-height-20 color-1b2022"
          >
            <mat-option
              *ngFor="let item of _terminationDuration"
              [value]="item.value"
            >
              {{ item.text }}
            </mat-option>
          </mat-select>
          <mat-error> Påkrævet </mat-error>
          <icon-caret-down
            class="absolute right-0 bottom-[6px]"
          ></icon-caret-down>
          <tooltip
            class="absolute right-9 bottom-[6px]"
            message="Antal måneder i opsigelsesvarsel"
          ></tooltip>
        </mat-form-field>
      </div>

      <mat-form-field class="w-full | textarea rental-notes" appearance="fill">
        <textarea
          matInput
          formControlName="notes"
          placeholder="Skriv note til enhed"
        ></textarea>
        <icon-text-align-right matPrefix> </icon-text-align-right>
        <tooltip
          class="absolute right-0 top-[0px]"
          message="Fri tekst til beskrivelse af note"
        ></tooltip>
      </mat-form-field>
    </form>
  </div>
</mat-dialog-content>
<button
  mat-flat-button
  type="submit"
  form="rentalIncomeForm"
  color="primary"
  class="uppercase w-full max-w-[360px] !mt-6"
  [ngClass]="{
    'animate-shake': firstForm.invalid && firstForm.submitted
  }"
>
  Gem og tilføje enheden
</button>

export interface NotitficationData {
  text: string;
  value: string;
  body: string;
  pushEnabled: boolean;
  emailEnabled: boolean;
}

// Notification's tab data

export const NOTIFICATIONS_DATA: NotitficationData[] = [
  {
    text: 'Nye beskeder',
    value: 'chatMessageReceived',
    body: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
    pushEnabled: false,
    emailEnabled: false,
  },
  {
    text: 'Ny fremvisningsanmodning',
    value: 'newExhibition',
    body: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
    pushEnabled: false,
    emailEnabled: false,
  },
  {
    text: 'Nyhedsbrev',
    value: 'newsletter',
    body: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
    pushEnabled: false,
    emailEnabled: false,
  },
];

<svg
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect width="20" height="20" rx="10" fill="#24535B" />
  <path
    d="M3.3335 9.99967C3.3335 13.6816 6.31826 16.6663 10.0002 16.6663C13.6821 16.6663 16.6668 13.6816 16.6668 9.99967C16.6668 6.31778 13.6821 3.33301 10.0002 3.33301C6.31826 3.33301 3.3335 6.31778 3.3335 9.99967Z"
    fill="#24535B"
  />
  <path
    d="M14.5838 7.08301L9.82353 13.308C9.49171 13.7419 8.83913 13.7446 8.50377 13.3134L6.25049 10.4163"
    fill="#24535B"
  />
  <path
    d="M14.5838 7.08301L9.82353 13.308C9.49171 13.7419 8.83913 13.7446 8.50377 13.3134L6.25049 10.4163"
    stroke="white"
    stroke-width="1.56667"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

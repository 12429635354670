import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-password-hide',
  templateUrl: './password-hide.component.html',
  styleUrls: ['./password-hide.component.scss'],
})
export class PasswordHideComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

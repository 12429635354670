import { DecimalPipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import {
  ExpenseItems,
  defaultExpenseList,
  LOCATION_FACTOR,
  RENOVATION_TYPE,
  PROPERTY_CONDITION,
  CLIMATE_IMPROVEMENTS,
} from './add-expenses.util';
import { MatDialog } from '@angular/material/dialog';
import { EditExpenseDialogComponent } from '../dialogs/edit-expense-dialog/edit-expense-dialog.component';
import { AddEditFinancingDialogComponent } from '../dialogs/add-edit-financing-dialog/add-edit-financing-dialog.component';
import {
  ClimateImprovements,
  Financing,
  Listing,
} from 'src/app/_models/property.model';
import {
  INTEREST_RATE_TYPE,
  LOAN_TYPE,
} from '../dialogs/add-edit-financing-dialog/add-edit-financing-dialog.util';
import {
  formatNumber,
  ifNaN,
  ifZero,
  roundNumber,
  unformatNumber,
} from 'src/utils';
import { ListingService } from 'src/app/_services/listing/listing.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'add-expenses',
  templateUrl: './add-expenses.component.html',
  styleUrls: ['./add-expenses.component.scss'],
})
export class AddExpensesComponent implements OnInit {
  @Output() prevClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() nextClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('firstForm') firstForm: NgForm;
  @ViewChild('secondForm') secondForm: NgForm;

  expenseForm: FormGroup;
  _defaultExpenseList = JSON.parse(JSON.stringify(defaultExpenseList));

  _locationFactor = JSON.parse(JSON.stringify(LOCATION_FACTOR));
  _renovation = JSON.parse(JSON.stringify(RENOVATION_TYPE));
  // _yearOfRenovation: number[] = [];
  _propertyCondition = JSON.parse(JSON.stringify(PROPERTY_CONDITION));
  _climateImprovementsOptions = JSON.parse(
    JSON.stringify(CLIMATE_IMPROVEMENTS)
  );
  _climateImprovements: ClimateImprovements = {
    windows: false,
    roof: false,
    doors: false,
    masonry: false,
    others: false,
    manualInput: '',
  };

  pricingForm: FormGroup;
  _loanType = JSON.parse(JSON.stringify(LOAN_TYPE));
  _interestRateType = JSON.parse(JSON.stringify(INTEREST_RATE_TYPE));
  financing: Financing[] = [];

  loading: boolean = false;
  pageLoading: boolean = false;

  // from other steps
  _totalLandArea: number = JSON.parse(sessionStorage.getItem('listing'))
    ?.totalLandArea;
  _sumYearlyRent: number = 0;
  _sumDeposit: number = 0;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private numberpipe: DecimalPipe,
    private listService: ListingService,
    private spinner: NgxSpinnerService
  ) {
    this.expenseForm = this.fb.group({
      expenses: this.fb.array([]),
      sumOfOperatingExpenses: new FormControl(''),
      sumOfOperatingExpensesPerSqMeter: new FormControl(''),
      totalGrossRentalIncome: new FormControl(''),
      totalGrossRentalIncomePerSqMeter: new FormControl(''),
      totalNetRent: new FormControl(''),
      totalNetRentPerSqMeter: new FormControl(''),
    });

    this.pricingForm = this.fb.group({
      baseReturn: new FormControl('', [Validators.required]),
      locationFactor: new FormControl('', [Validators.required]),
      finalBaseReturn: new FormControl('', [
        Validators.required,
        Validators.pattern(/(?!0*[,]*0*$)/),
      ]),
      renovation: new FormControl('', [Validators.required]),
      // yearOfRenovation: new FormControl('', [Validators.required]),
      propertyCondition: new FormControl('', [Validators.required]),
      climateImprovements: new FormControl(''),
      manualInput: new FormControl(''),
      estimatedSellingPrice: new FormControl(''),
      estimatedSellingPricePerSquareMeter: new FormControl(''),
      desiredPrice: new FormControl(''),
      desiredPricePerSquareMeter: new FormControl(''),
      rentPotential: new FormControl(''),
      rentPotentialPerSquareMeters: new FormControl(''),
      ejosIndicativeAssessedPrice: new FormControl(''),
      ejosIndicativeAssessedPricePerSquareMeter: new FormControl(''),
      indicativePrice: new FormControl('', [
        Validators.required,
        Validators.pattern(/(?!0*[,]*0*$)/),
      ]),
      indicativePricePerSqMeters: new FormControl(''),
    });

    // for (let year = new Date().getFullYear(); year >= 1960; year--) {
    //   this._yearOfRenovation.push(year);
    // }

    // this.setBaseReturnByPostalCode();
    // this.baseReturn.setValue('3');
    // this.locationFactor.setValue('Basic');
  }

  setBaseReturnByPostalCode() {
    const listing: Listing = JSON.parse(sessionStorage.getItem('listing'));
    if (listing?.id && listing?.primaryAddress?.postalCode) {
      this.pageLoading = true;
      this.spinner.show();
      this.listService
        .getBaseReturnByPostalcode(listing?.primaryAddress?.postalCode)
        .subscribe({
          next: (res) => {
            this.baseReturn.setValue(
              ifZero(formatNumber(roundNumber(res.correctedBaseReturn)))
            );
            this.pageLoading = false;
            this.spinner.hide();
          },
          error: (err) => {
            console.log(err);
            this.pageLoading = false;
            this.spinner.hide();
          },
        });
    }
  }

  ngOnInit(): void {
    const listing = JSON.parse(sessionStorage.getItem('listing'));

    listing?.id && listing?.creationStep >= 3
      ? ''
      : (this.addDefaultExpenses(), this.setBaseReturnByPostalCode());

    this.calculateSumOfYearlyRent();
    this.calculateSumOfDeposit();

    this.calculateTaxPerSquareMeter();
    this.calculateSumOfOperatingExpenses();
    this.calculateGrossRentalIncome();
    this.calculateFinalBaseRate();
    this.OnChangingBaseRate();
    this.onSelectingLocationFactor();
    this.calculateClimateImprovements();
    this.calculateDesiredPricePerSquareMeter();
    this.calculateRentPotentialPerSquareMeter();
    this.calculateEjosIndicativeAssessedPrice();
    this.calculateIndicativePricePerSquareMeter();

    listing?.id && listing?.creationStep >= 3
      ? this.setStoredValues(listing)
      : '';
  }

  // Set stored value
  setStoredValues(listing: Listing) {
    listing.expenseItem.map((_expense, index) => {
      this.expenses.push(
        this.fb.group({
          name: new FormControl(),
          description: new FormControl(''),
          tax: new FormControl('', [Validators.pattern(/(?!0*[,]*0*$)/)]),
          expenseType: new FormControl(_expense.expenseType),
          taxPerSquareMeter: new FormControl(
            ifZero(formatNumber(roundNumber(_expense.taxPerSquareMeter)))
          ),
        })
      );
      this.calculateTaxPerSquareMeter();
      this.expenses.controls[index].get('name')?.setValue(_expense.name);
      this.expenses.controls[index]
        .get('description')
        ?.setValue(`${this._defaultExpenseList[index]?.description || ''}`);
      this.expenses.controls[index]
        .get('tax')
        ?.setValue(ifZero(formatNumber(roundNumber(_expense.tax))));
    });
    this.sumOfOperatingExpenses.setValue(
      ifZero(formatNumber(roundNumber(listing.sumOfOperatingExpenses)))
    );
    this.baseReturn.setValue(
      ifZero(formatNumber(roundNumber(listing.baseReturn)))
    );
    this.locationFactor.setValue(listing.locationFactor);
    // this.finalBaseReturn.setValue(
    //   ifZero(formatNumber(roundNumber(listing.finalBaseReturn)))
    // );
    this.renovation.setValue(listing.renovation);
    // this.yearOfRenovation.setValue(listing.yearOfRenovation);
    this.propertyCondition.setValue(listing.propertyCondition);
    this.climateImprovements.setValue(
      Object.keys(listing.climateImprovements).filter(
        (val) => val !== 'manualInput' && listing.climateImprovements[val]
      )
    );
    this.manualInput.setValue(listing.climateImprovements.manualInput);
    this.financing = listing.financing;

    this.desiredPrice.setValue(
      ifZero(formatNumber(roundNumber(listing.desiredPrice)))
    );
    this.rentPotential.setValue(
      ifZero(formatNumber(roundNumber(listing.rentPotential)))
    );
    this.indicativePrice.setValue(
      ifZero(formatNumber(roundNumber(listing.indicativePrice)))
    );
  }

  calculateSumOfYearlyRent() {
    this._sumYearlyRent = 0;
    JSON.parse(sessionStorage.getItem('listing'))?.units.map((unit) => {
      this._sumYearlyRent += unit.yearlyRent;
    });
  }

  calculateGrossRentalIncome() {
    this.totalGrossRentalIncome.setValue(
      ifZero(ifNaN(formatNumber(roundNumber(this._sumYearlyRent))))
    );
    //Per totalLandArea
    this.totalGrossRentalIncomePerSqMeter.setValue(
      ifZero(
        ifNaN(
          formatNumber(roundNumber(this._sumYearlyRent / this._totalLandArea))
        )
      )
    );
  }

  calculateNetRent() {
    this.totalNetRent.setValue(
      ifZero(
        ifNaN(
          formatNumber(
            roundNumber(
              this._sumYearlyRent -
                unformatNumber(this.sumOfOperatingExpenses?.value || '0')
            )
          )
        )
      )
    );
    //Per totalLandArea
    this.totalNetRentPerSqMeter.setValue(
      ifZero(
        ifNaN(
          formatNumber(
            roundNumber(
              (this._sumYearlyRent -
                unformatNumber(this.sumOfOperatingExpenses?.value || '0')) /
                this._totalLandArea
            )
          )
        )
      )
    );
  }

  createExpense(name: string, description: string): FormGroup {
    return this.fb.group({
      name: new FormControl(name ? name : 'Diverse udgift'),
      description: new FormControl(description ? description : ''),
      tax: new FormControl('', [
        Validators.required,
        Validators.pattern(/(?!0*[,]*0*$)/),
      ]),
      expenseType: new FormControl('Actual'),
      taxPerSquareMeter: new FormControl(''),
    });
  }

  addExpense() {
    // open dialog
    this.dialog
      .open(EditExpenseDialogComponent, {})
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          // const expense = this.expenseForm.get('expenses') as FormArray;
          this.expenses.push(this.createExpense(res?.name, res?.description));
          this.calculateTaxPerSquareMeter();
          this.calculateSumOfOperatingExpenses();
        }
      });
  }

  addDefaultExpenses() {
    this._defaultExpenseList.map((_expense) => {
      // const expense = this.expenseForm.get('expenses') as FormArray;
      this.expenses.push(
        this.fb.group({
          name: new FormControl(`${_expense.name}`),
          description: new FormControl(`${_expense.description}`),
          tax: new FormControl('', [Validators.pattern(/(?!0*[,]*0*$)/)]),
          expenseType: new FormControl('Actual'),
          taxPerSquareMeter: new FormControl(''),
        })
      );
    });
  }

  deleteExpense(index: number) {
    if (index > -1) {
      this.expenses.removeAt(index);
      this.calculateTaxPerSquareMeter();
      this.calculateSumOfOperatingExpenses();
    }
  }

  editExpenseName(index: number) {
    const expenses = this.expenses;
    this.dialog
      .open(EditExpenseDialogComponent, {
        data: {
          expenses,
          indexTobeEdited: index,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.expenses.controls[index].get('name').setValue(res?.name);
          this.expenses.controls[index]
            .get('description')
            .setValue(res?.description);
        }
      });
  }

  calculateTaxPerSquareMeter() {
    this.expenses.controls.map((expenseItem) => {
      expenseItem.get('tax').valueChanges.subscribe((value) => {
        expenseItem
          .get('taxPerSquareMeter')
          .setValue(
            ifZero(
              ifNaN(
                formatNumber(
                  roundNumber(unformatNumber(value) / this._totalLandArea)
                )
              )
            )
          );
      });
    });
  }

  calculateSumOfOperatingExpenses() {
    this.expenses.valueChanges.subscribe(() => {
      let _sumOfOperatingExpenses = 0.0;
      this.expenses.controls.map((item) => {
        _sumOfOperatingExpenses += unformatNumber(item.get('tax').value);
      });
      this.sumOfOperatingExpenses.setValue(
        ifZero(ifNaN(formatNumber(roundNumber(_sumOfOperatingExpenses))))
      );
      this.sumOfOperatingExpensesPerSqMeter.setValue(
        ifZero(
          ifNaN(
            formatNumber(
              roundNumber(_sumOfOperatingExpenses / this._totalLandArea)
            )
          )
        )
      );
      this.calculateNetRent();
      this.calculateEstimatedSellingPrice();
    });
  }

  calculateFinalBaseRate() {
    this.locationFactor.value === 'Exclusive' &&
    unformatNumber(this.baseReturn.value) > 0
      ? this.finalBaseReturn.setValue(
          ifZero(
            ifNaN(
              formatNumber(
                roundNumber(unformatNumber(this.baseReturn.value) - 0.5)
              )
            )
          )
        )
      : this.locationFactor.value === 'Good' &&
        unformatNumber(this.baseReturn.value) > 0
      ? this.finalBaseReturn.setValue(
          ifZero(
            ifNaN(
              formatNumber(
                roundNumber(unformatNumber(this.baseReturn.value) - 0.25)
              )
            )
          )
        )
      : this.locationFactor.value === 'Basic' &&
        unformatNumber(this.baseReturn.value) > 0
      ? this.finalBaseReturn.setValue(
          ifZero(
            ifNaN(
              formatNumber(roundNumber(unformatNumber(this.baseReturn.value)))
            )
          )
        )
      : this.locationFactor.value === 'Secondary' &&
        unformatNumber(this.baseReturn.value) > 0
      ? this.finalBaseReturn.setValue(
          ifZero(
            ifNaN(
              formatNumber(
                roundNumber(unformatNumber(this.baseReturn.value) + 0.25)
              )
            )
          )
        )
      : this.finalBaseReturn.setValue('');

    this.calculateEstimatedSellingPrice();
  }

  OnChangingBaseRate() {
    this.baseReturn.valueChanges.subscribe((value) => {
      this.calculateFinalBaseRate();
      this.calculateEstimatedSellingPrice();
    });
  }

  onSelectingLocationFactor() {
    this.locationFactor.valueChanges.subscribe((value) => {
      this.calculateFinalBaseRate();
      this.calculateEstimatedSellingPrice();
    });
  }

  calculateClimateImprovements() {
    this.climateImprovements.valueChanges.subscribe((value) => {
      // reset options
      this._climateImprovements.windows = false;
      this._climateImprovements.roof = false;
      this._climateImprovements.doors = false;
      this._climateImprovements.masonry = false;
      this._climateImprovements.others = false;
      // mark options true as per selection
      value.map((improvement) => {
        improvement === 'windows'
          ? (this._climateImprovements.windows = true)
          : improvement === 'roof'
          ? (this._climateImprovements.roof = true)
          : improvement === 'doors'
          ? (this._climateImprovements.doors = true)
          : improvement === 'masonry'
          ? (this._climateImprovements.masonry = true)
          : improvement === 'others'
          ? (this._climateImprovements.others = true)
          : '';
      });

      // set manualInput='' in case others is deselected
      if (!this._climateImprovements.others) {
        this.manualInput.setValue('', { emitEvent: false });
        this._climateImprovements.manualInput = '';
      }
    });

    this.manualInput.valueChanges.subscribe((value) => {
      if (this._climateImprovements.others)
        this._climateImprovements.manualInput = value;
    });
  }

  addFinanceItem() {
    const financing = this.financing;
    this.dialog.open(AddEditFinancingDialogComponent, {
      data: {
        financing,
        indexTobeEdited: -1,
      },
    });
  }

  duplicateFinance(index: number) {
    // find unit to be duplicated
    let finance = this.financing[index];

    // Push unit to the unitList
    this.financing.splice(index + 1, 0, finance);
  }

  deleteFinance(index: number) {
    // find unit to be deleted
    this.financing.splice(index, 1);
  }

  editFinanceItem(indexTobeEdited: number) {
    const financing = this.financing;
    this.dialog.open(AddEditFinancingDialogComponent, {
      data: {
        financing,
        indexTobeEdited,
      },
    });
  }

  calculateSumOfDeposit() {
    this._sumDeposit = 0;
    JSON.parse(sessionStorage.getItem('listing'))?.units.map((unit) => {
      this._sumDeposit += unit.deposit;
    });
  }

  calculateEstimatedSellingPrice() {
    if (this.finalBaseReturn?.value > 0 || this.finalBaseReturn?.value != '') {
      this.estimatedSellingPrice.setValue(
        ifZero(
          ifNaN(
            formatNumber(
              roundNumber(
                unformatNumber(this.totalNetRent?.value) /
                  unformatNumber(this.finalBaseReturn.value)
              )
            )
          )
        )
      );
      this.estimatedSellingPricePerSquareMeter.setValue(
        ifZero(
          ifNaN(
            formatNumber(
              roundNumber(
                unformatNumber(this.totalNetRent?.value) /
                  unformatNumber(this.finalBaseReturn.value) /
                  this._totalLandArea
              )
            )
          )
        )
      );
    }
    this.calculateEjosIndicativeAssessedPrice();
  }

  calculateDesiredPricePerSquareMeter() {
    this.desiredPrice.valueChanges.subscribe((value) => {
      this.desiredPricePerSquareMeter.setValue(
        ifZero(
          ifNaN(
            formatNumber(
              roundNumber(unformatNumber(value) / this._totalLandArea)
            )
          )
        )
      );
      this.calculateEjosIndicativeAssessedPrice();
    });
  }

  calculateRentPotentialPerSquareMeter() {
    this.rentPotential.valueChanges.subscribe((value) => {
      this.rentPotentialPerSquareMeters.setValue(
        ifZero(
          ifNaN(
            formatNumber(
              roundNumber(unformatNumber(value) / this._totalLandArea)
            )
          )
        )
      );
      this.calculateEjosIndicativeAssessedPrice();
    });
  }

  calculateEjosIndicativeAssessedPrice() {
    this.ejosIndicativeAssessedPrice.setValue(
      ifZero(
        ifNaN(
          formatNumber(
            roundNumber(
              unformatNumber(this.estimatedSellingPrice.value) +
                unformatNumber(this.desiredPrice.value) +
                unformatNumber(this.rentPotential.value)
            )
          )
        )
      )
    );
    this.ejosIndicativeAssessedPricePerSquareMeter.setValue(
      ifZero(
        ifNaN(
          formatNumber(
            roundNumber(
              (unformatNumber(this.estimatedSellingPrice.value) +
                unformatNumber(this.desiredPrice.value) +
                unformatNumber(this.rentPotential.value)) /
                this._totalLandArea
            )
          )
        )
      )
    );
  }

  calculateIndicativePricePerSquareMeter() {
    this.indicativePrice.valueChanges.subscribe((value) => {
      this.indicativePricePerSqMeters.setValue(
        ifZero(
          ifNaN(
            formatNumber(
              roundNumber(unformatNumber(value) / this._totalLandArea)
            )
          )
        )
      );
    });
  }

  onNextClicked() {
    // this.nextClick.emit(true);
    //logic for submitting data to endpoint

    this.firstForm.onSubmit(undefined);
    this.secondForm.onSubmit(undefined);
    if (this.expenseForm.valid && this.pricingForm.valid) {
      this.loading = true;
      this.createOrUpdateProperty();
    }
  }

  onPrevClicked() {
    this.prevClick.emit(true);
  }

  createOrUpdateProperty() {
    const listing = JSON.parse(sessionStorage.getItem('listing'));

    if (listing) {
      const createOrUpdateProperty: Listing = { ...listing };
      createOrUpdateProperty.creationStep = 3;
      createOrUpdateProperty.expenseItem = [];
      this.expenses.controls.map((item) => {
        const expenseItem = {
          name: item.get('name').value,
          tax: unformatNumber(item.get('tax').value),
          expenseType: item.get('expenseType').value,
          taxPerSquareMeter: unformatNumber(
            item.get('taxPerSquareMeter').value
          ),
        };
        createOrUpdateProperty.expenseItem.push(expenseItem);
      });
      createOrUpdateProperty.sumOfOperatingExpenses = unformatNumber(
        this.sumOfOperatingExpenses.value
      );
      createOrUpdateProperty.sumOfOperatingExpensesPerSqMeter = unformatNumber(
        this.sumOfOperatingExpensesPerSqMeter.value
      );
      createOrUpdateProperty.totalGrossRentalIncome = unformatNumber(
        this.totalGrossRentalIncome.value
      );
      createOrUpdateProperty.totalGrossRentalIncomePerSqMeter = unformatNumber(
        this.totalGrossRentalIncomePerSqMeter.value
      );
      createOrUpdateProperty.totalNetRent = unformatNumber(
        this.totalNetRent.value
      );
      createOrUpdateProperty.totalNetRentPerSqMeter = unformatNumber(
        this.totalNetRentPerSqMeter.value
      );
      createOrUpdateProperty.baseReturn = unformatNumber(this.baseReturn.value);
      createOrUpdateProperty.locationFactor = this.locationFactor.value;
      createOrUpdateProperty.finalBaseReturn = unformatNumber(
        this.finalBaseReturn.value
      );
      createOrUpdateProperty.renovation = this.renovation.value;
      // createOrUpdateProperty.yearOfRenovation = this.yearOfRenovation.value;
      createOrUpdateProperty.propertyCondition = this.propertyCondition.value;
      createOrUpdateProperty.climateImprovements = this._climateImprovements;
      createOrUpdateProperty.financing = this.financing;
      createOrUpdateProperty.estimatedSellingPrice = unformatNumber(
        this.estimatedSellingPrice.value
      );
      createOrUpdateProperty.estimatedSellingPricePerSquareMeter =
        unformatNumber(this.estimatedSellingPricePerSquareMeter.value);
      createOrUpdateProperty.desiredPrice = unformatNumber(
        this.desiredPrice.value
      );
      createOrUpdateProperty.desiredPricePerSquareMeter = unformatNumber(
        this.desiredPricePerSquareMeter.value
      );
      createOrUpdateProperty.rentPotential = unformatNumber(
        this.rentPotential.value
      );
      createOrUpdateProperty.rentPotentialPerSquareMeters = unformatNumber(
        this.rentPotentialPerSquareMeters.value
      );
      createOrUpdateProperty.ejosIndicativeAssessedPrice = unformatNumber(
        this.ejosIndicativeAssessedPrice.value
      );
      createOrUpdateProperty.ejosIndicativeAssessedPricePerSquareMeter =
        unformatNumber(this.ejosIndicativeAssessedPricePerSquareMeter.value);
      createOrUpdateProperty.indicativePrice = unformatNumber(
        this.indicativePrice.value
      );
      createOrUpdateProperty.indicativePricePerSqMeters = unformatNumber(
        this.indicativePricePerSqMeters.value
      );
      // console.log(createOrUpdateProperty);
      this.listService
        .createOrUpdateProperty(createOrUpdateProperty)
        .subscribe({
          next: (res) => {
            sessionStorage.setItem('listing', JSON.stringify(res));
            this.loading = false;
            this.nextClick.emit(true);
          },
          error: (err) => {
            console.log(err);
            this.loading = false;
          },
        });
    }
  }

  // Getter
  get expenses(): FormArray {
    return this.expenseForm.get('expenses') as FormArray;
  }
  get sumOfOperatingExpenses() {
    return this.expenseForm.get('sumOfOperatingExpenses');
  }
  get sumOfOperatingExpensesPerSqMeter() {
    return this.expenseForm.get('sumOfOperatingExpensesPerSqMeter');
  }
  get totalGrossRentalIncome() {
    return this.expenseForm.get('totalGrossRentalIncome');
  }
  get totalGrossRentalIncomePerSqMeter() {
    return this.expenseForm.get('totalGrossRentalIncomePerSqMeter');
  }
  get totalNetRent() {
    return this.expenseForm.get('totalNetRent');
  }
  get totalNetRentPerSqMeter() {
    return this.expenseForm.get('totalNetRentPerSqMeter');
  }

  get baseReturn() {
    return this.pricingForm.get('baseReturn');
  }
  get locationFactor() {
    return this.pricingForm.get('locationFactor');
  }
  get finalBaseReturn() {
    return this.pricingForm.get('finalBaseReturn');
  }
  get renovation() {
    return this.pricingForm.get('renovation');
  }
  // get yearOfRenovation() {
  //   return this.pricingForm.get('yearOfRenovation');
  // }
  get propertyCondition() {
    return this.pricingForm.get('propertyCondition');
  }
  get climateImprovements() {
    return this.pricingForm.get('climateImprovements');
  }
  get manualInput() {
    return this.pricingForm.get('manualInput');
  }
  get estimatedSellingPrice() {
    return this.pricingForm.get('estimatedSellingPrice');
  }
  get estimatedSellingPricePerSquareMeter() {
    return this.pricingForm.get('estimatedSellingPricePerSquareMeter');
  }
  get desiredPrice() {
    return this.pricingForm.get('desiredPrice');
  }
  get desiredPricePerSquareMeter() {
    return this.pricingForm.get('desiredPricePerSquareMeter');
  }
  get rentPotential() {
    return this.pricingForm.get('rentPotential');
  }
  get rentPotentialPerSquareMeters() {
    return this.pricingForm.get('rentPotentialPerSquareMeters');
  }
  get ejosIndicativeAssessedPrice() {
    return this.pricingForm.get('ejosIndicativeAssessedPrice');
  }
  get ejosIndicativeAssessedPricePerSquareMeter() {
    return this.pricingForm.get('ejosIndicativeAssessedPricePerSquareMeter');
  }
  get indicativePrice() {
    return this.pricingForm.get('indicativePrice');
  }
  get indicativePricePerSqMeters() {
    return this.pricingForm.get('indicativePricePerSqMeters');
  }

  // helpers
  formatInput(event: any) {
    const parts = event.target.value.toString().split(',');
    if (!isNaN(Number(parts[0]))) {
      parts[0] = this.numberpipe.transform(
        parts[0].replace(/\./g, '').replace(/,/g, ''),
        '1.0-2',
        'da-DK'
      );
      event.target.value = parts.join(',');
    }
  }

  displaySelectText(list, value) {
    if (list && value) return list.find((list) => list.value === value).text;
  }
}

<svg
  width="32"
  height="32"
  viewBox="0 0 32 32"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M12 22H3.75C3.55109 22 3.36032 21.921 3.21967 21.7803C3.07902 21.6397 3 21.4489 3 21.25V13C3 10.6131 3.94821 8.32387 5.63604 6.63604C7.32387 4.94821 9.61305 4 12 4V4C14.3869 4 16.6761 4.94821 18.364 6.63604C20.0518 8.32387 21 10.6131 21 13V13C21 15.3869 20.0518 17.6761 18.364 19.364C16.6761 21.0518 14.3869 22 12 22V22Z"
    stroke="#283F43"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M11.5127 22C12.1332 23.755 13.2827 25.2745 14.8027 26.3491C16.3228 27.4236 18.1387 28.0004 20.0002 28H28.2502C28.4491 28 28.6399 27.9209 28.7805 27.7803C28.9212 27.6396 29.0002 27.4489 29.0002 27.25V19C29.0034 16.6964 28.122 14.4795 26.5379 12.807C24.9538 11.1346 22.7881 10.1342 20.4877 10.0125"
    stroke="#283F43"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

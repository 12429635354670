<div
  *ngIf="mode === 'DARK'; else light"
  [ngClass]="{
    loader: true,
    'loader-small': isSmall,
    'loader-medium': isMedium
  }"
>
  <span class="pip-0"></span>
  <span class="pip-1"></span>
  <span class="pip-2"></span>
  <span class="pip-3"></span>
  <span class="pip-4"></span>
  <span class="pip-5"></span>
</div>
<ng-template #light>
  <div
    class="loader-light"
    [ngClass]="{
      'loader-small': isSmall,
      'loader-medium': isMedium
    }"
  >
    <span class="pip-0"></span>
    <span class="pip-1"></span>
    <span class="pip-2"></span>
    <span class="pip-3"></span>
    <span class="pip-4"></span>
    <span class="pip-5"></span>
  </div>
</ng-template>

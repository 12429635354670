import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-pencil-line',
  templateUrl: './pencil-line.component.html',
  styleUrls: ['./pencil-line.component.scss'],
})
export class PencilLineComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

<div class="h-full w-full flex justify-center items-start pt-[184px]">
  <div class="max-w-[360px] mx-4 w-full relative">
    <h2 class="font-semibold text-5xl leading-[48px] mb-5">Opret profil</h2>

    <p class="font-normal text-base leading-5 mb-4">
      <strong>Step {{ stepper.selectedIndex + 1 | number : "2.0" }}</strong> /
      03
    </p>

    <mat-stepper [linear]="true" #stepper>
      <mat-step [stepControl]="firstFormGroup">
        <form #firstForm="ngForm" [formGroup]="firstFormGroup">
          <p
            class="text-black font-semibold text-xl leading-[25px] mt-9 mb-6 w-full"
          >
            Personlig informationer
          </p>

          <mat-form-field class="mb-6 w-full" appearance="fill">
            <mat-label>Fornavn</mat-label>
            <input matInput formControlName="firstName" />
            <mat-error *ngIf="firstName.hasError('required')">
              Påkrævet
            </mat-error>
          </mat-form-field>

          <mat-form-field class="mb-6 w-full" appearance="fill">
            <mat-label>Efternavn</mat-label>
            <input matInput formControlName="lastName" />
            <mat-error *ngIf="lastName.hasError('required')">
              Påkrævet
            </mat-error>
          </mat-form-field>

          <mat-form-field class="mb-6 w-full" appearance="fill">
            <mat-label>Firmanavn (Valgfrit)</mat-label>
            <input matInput formControlName="companyName" />
          </mat-form-field>
          <div>
            <button
              class="w-full"
              [ngClass]="{
                'animate-shake': firstForm.invalid && firstForm.submitted
              }"
              mat-flat-button
              matStepperNext
              color="primary"
            >
              <span class="uppercase">Fortsæt</span>
            </button>
          </div>

          <p class="font-normal text-base leading-5 mt-6 text-black mb-4">
            Har du allerede en konto?
            <a
              routerLink="/user/login"
              class="underline cursor-pointer text-primary"
              >Log ind her</a
            >
          </p>
        </form>
      </mat-step>

      <mat-step [stepControl]="secondFormGroup">
        <form #secondForm="ngForm" [formGroup]="secondFormGroup">
          <p
            class="text-black font-semibold text-xl leading-[25px] mt-9 mb-6 w-full"
          >
            Kontaktoplysninger
          </p>
          <div class="relative mb-10">
            <ngx-intl-tel-input
              [cssClass]="secondForm.submitted ? 'submitted' : 'not_submitted'"
              [preferredCountries]="preferredCountries"
              [enableAutoCountrySelect]="true"
              [enablePlaceholder]="true"
              [searchCountryFlag]="true"
              [searchCountryField]="[
                SearchCountryField.Iso2,
                SearchCountryField.Name
              ]"
              [selectFirstCountry]="false"
              [selectedCountryISO]="CountryISO.Denmark"
              [maxLength]="15"
              [phoneValidation]="true"
              [separateDialCode]="separateDialCode"
              [numberFormat]="PhoneNumberFormat.International"
              name="phoneNumber"
              formControlName="phoneNumber"
            >
            </ngx-intl-tel-input>

            <mat-error
              class="left-3 bottom-[-21.5px] absolute text-xs text-[#f44336] error-message"
              style="
                animation-name: fadeInDown;
                animation-timing-function: ease-out;
                animation-duration: 0.3s;
              "
              *ngIf="
                phoneNumber.hasError('required') &&
                (phoneNumber.touched || secondForm.submitted)
              "
            >
              Påkrævet
            </mat-error>
          </div>

          <mat-form-field class="mb-6 w-full" appearance="fill">
            <mat-label>E-mail</mat-label>
            <input matInput formControlName="email" />
            <mat-error
              *ngIf="
                email.hasError('email') &&
                !email.hasError('required') &&
                !email.hasError('existingEmail') &&
                secondForm.submitted
              "
            >
              Indtast venligst en gyldig e-mailadresse
            </mat-error>
            <mat-error *ngIf="email.hasError('required')"> Påkrævet </mat-error>
            <mat-error *ngIf="email.hasError('existingEmail')">
              E-mailen er allerede registreret
            </mat-error>
          </mat-form-field>

          <mat-form-field class="mb-6 w-full" appearance="fill">
            <mat-label>Adresse</mat-label>
            <input matInput formControlName="address" />
            <mat-error *ngIf="address.hasError('required')">
              Påkrævet
            </mat-error>
          </mat-form-field>

          <div class="flex gap-4 mb-6 max-w-[360px] w-full">
            <mat-form-field class="w-full max-w-[140px]" appearance="fill">
              <mat-label>Postnummer</mat-label>
              <input
                matInput
                minlength="4"
                maxlength="4"
                pattern="\d*"
                numbersOnly
                formControlName="postalcode"
                [group]="secondFormGroup"
              />
              <mat-error *ngIf="postalcode.hasError('required')">
                Påkrævet
              </mat-error>
            </mat-form-field>

            <mat-form-field class="w-full" appearance="fill">
              <mat-label>By</mat-label>
              <input matInput formControlName="city" />
              <mat-error *ngIf="city.hasError('required')">
                Påkrævet
              </mat-error>
            </mat-form-field>
          </div>

          <div class="flex items-center gap-3 mb-4">
            <button
              class="!py-[15px] !px-[25px] !border !border-solid !border-[#D1D8D9] !rounded-[90px] !min-w-fit"
              mat-flat-button
              matStepperPrevious
            >
              <icon-caret-left></icon-caret-left>
            </button>
            <button
              class="w-full"
              [ngClass]="{
                'animate-shake': secondForm.invalid && secondForm.submitted
              }"
              mat-flat-button
              matStepperNext
              color="primary"
            >
              <span class="uppercase">Fortsæt</span>
            </button>
          </div>
        </form>
      </mat-step>

      <mat-step [stepControl]="thirdFormGroup">
        <form
          #thirdForm="ngForm"
          #form="ngForm"
          [formGroup]="thirdFormGroup"
          (ngSubmit)="handleSubmit()"
        >
          <p
            class="text-black font-semibold text-xl leading-[25px] mt-9 mb-6 w-full"
          >
            Opret adgangskode
          </p>

          <mat-form-field class="w-full" appearance="fill">
            <mat-label>Adgangskode</mat-label>
            <input
              formControlName="password"
              matInput
              [type]="_hidePassword ? 'password' : 'text'"
              autocomplete="off"
            />
            <icon-password-hide
              *ngIf="_hidePassword"
              matSuffix
              (click)="_hidePassword = !_hidePassword"
            ></icon-password-hide>
            <icon-password-visible
              *ngIf="!_hidePassword"
              matSuffix
              (click)="_hidePassword = !_hidePassword"
            ></icon-password-visible>
            <mat-error
              *ngIf="password.invalid && !password.hasError('required')"
            >
              Indtast venligst en gyldig adgangskode
            </mat-error>
            <mat-error *ngIf="password.hasError('required')">
              Påkrævet
            </mat-error>
          </mat-form-field>

          <div class="mb-3 mt-1 flex justify-between">
            <p class="text-sm font-semibold">
              {{
                _passwordStrength === 100
                  ? "Strong password"
                  : _passwordStrength === 75
                  ? "Medium password"
                  : _passwordStrength === 50
                  ? "Medium password"
                  : _passwordStrength === 25
                  ? "Weak password"
                  : "Password strength"
              }}
            </p>
          </div>

          <mat-password-strength
            #passwordComponent
            [enableDigitRule]="false"
            [password]="password.value"
            (onStrengthChanged)="onStrengthChanged($event)"
          >
          </mat-password-strength>

          <div class="flex items-center gap-3 mt-3">
            <div
              class="flex items-center justify-center gap-[9px] px-3 py-2 rounded-[90px] text-sm leading-4 bg-[#EFF6F6] text-[#8A9C9F]"
            >
              <icon-check
                class="flex-shrink-0"
                *ngIf="
                  password?.hasError('requiresUppercase') ||
                  password?.hasError('requiresLowercase') ||
                  password?.hasError('required')
                "
                strokeColor="#8A9C9F"
              ></icon-check>
              <icon-check
                class="flex-shrink-0"
                *ngIf="
                  !password?.hasError('requiresUppercase') &&
                  !password?.hasError('requiresLowercase') &&
                  !password?.hasError('required')
                "
                strokeColor="#0E7254"
              ></icon-check>
              <span
                [ngClass]="{
                  'bg-[#E4FFF6] text-[#0E7254] transition-all':
                    !password?.hasError('requiresUppercase') &&
                    !password?.hasError('requiresLowercase') &&
                    !password?.hasError('required')
                }"
                >Et stort og et lille bogstav</span
              >
            </div>

            <div
              class="flex items-center justify-center gap-[9px] px-3 py-2 rounded-[90px] text-sm leading-4 bg-[#EFF6F6] text-[#8A9C9F]"
            >
              <icon-check
                class="flex-shrink-0"
                *ngIf="
                  password?.hasError('requiresDigit') ||
                  password?.hasError('required')
                "
                strokeColor="#8A9C9F"
              ></icon-check>
              <icon-check
                class="flex-shrink-0"
                *ngIf="
                  !password?.hasError('requiresDigit') &&
                  !password?.hasError('required')
                "
                strokeColor="#0E7254"
              ></icon-check>
              <span
                class="flex-1"
                [ngClass]="{
                  'bg-[#E4FFF6] text-[#0E7254] transition-all':
                    !password?.hasError('requiresDigit') &&
                    !password?.hasError('required')
                }"
                >Et tal</span
              >
            </div>
          </div>

          <div class="flex items-center gap-3 my-3">
            <div
              class="flex items-center justify-center gap-[9px] px-3 py-2 rounded-[90px] text-sm leading-4 bg-[#EFF6F6] text-[#8A9C9F]"
            >
              <icon-check
                class="flex-shrink-0"
                *ngIf="
                  password?.hasError('minlength') ||
                  password?.hasError('required')
                "
                strokeColor="#8A9C9F"
              ></icon-check>
              <icon-check
                class="flex-shrink-0"
                *ngIf="
                  !password?.hasError('minlength') &&
                  !password?.hasError('required')
                "
                strokeColor="#0E7254"
              ></icon-check>
              <span
                [ngClass]="{
                  'bg-[#E4FFF6] text-[#0E7254] transition-all':
                    !password?.hasError('minlength') &&
                    !password?.hasError('required')
                }"
                >Mindst 8 tegn</span
              >
            </div>

            <div
              class="flex items-center justify-center gap-[9px] px-3 py-2 rounded-[90px] text-sm leading-4 bg-[#EFF6F6] text-[#8A9C9F]"
            >
              <icon-check
                class="flex-shrink-0"
                *ngIf="
                  password?.hasError('requiresSpecialChars') ||
                  password?.hasError('required')
                "
                strokeColor="#8A9C9F"
              ></icon-check>
              <icon-check
                class="flex-shrink-0"
                *ngIf="
                  !password?.hasError('requiresSpecialChars') &&
                  !password?.hasError('required')
                "
                strokeColor="#0E7254"
              ></icon-check>
              <span
                class="flex-1"
                [ngClass]="{
                  'bg-[#E4FFF6] text-[#0E7254] transition-all':
                    !password?.hasError('requiresSpecialChars') &&
                    !password?.hasError('required')
                }"
                >Et specialtegn</span
              >
            </div>
          </div>

          <mat-form-field class="my-3 w-full" appearance="fill">
            <mat-label>Bekræft adgangskode</mat-label>
            <input
              formControlName="confirmPassword"
              matInput
              [type]="_hideConfirmPassword ? 'password' : 'text'"
              autocomplete="off"
            />
            <icon-password-hide
              *ngIf="_hideConfirmPassword"
              matSuffix
              (click)="_hideConfirmPassword = !_hideConfirmPassword"
            ></icon-password-hide>
            <icon-password-visible
              *ngIf="!_hideConfirmPassword"
              matSuffix
              (click)="_hideConfirmPassword = !_hideConfirmPassword"
            ></icon-password-visible>
            <mat-error
              *ngIf="
                confirmPassword.invalid && !confirmPassword.hasError('required')
              "
            >
              Adgangskoden passer ikke
            </mat-error>
            <mat-error *ngIf="confirmPassword.hasError('required')">
              Påkrævet
            </mat-error>
          </mat-form-field>

          <div class="mb-6 relative">
            <mat-checkbox color="primary" formControlName="tNC" class="mb-6">
              <span
                class="font-normal text-base leading-5 text-black flex flex-wrap"
              >
                Jeg accepterer Ejos’s
                <a class="font-semibold text text-red">
                  &nbsp;Brugerbetingelser&nbsp;
                </a>
                og
                <a class="font-semibold text-red">
                  &nbsp;Privatlivspolitik&nbsp;
                </a>
              </span>
            </mat-checkbox>
            <mat-error
              class="left-3 bottom-1 absolute text-xs text-[#f44336] error-message"
              style="
                animation-name: fadeInDown;
                animation-timing-function: ease-out;
                animation-duration: 0.3s;
              "
              *ngIf="tNC.hasError('required') && thirdForm.submitted"
            >
              Påkrævet
            </mat-error>
          </div>

          <div class="flex items-center gap-3 mb-4">
            <button
              class="!py-[15px] !px-[25px] !border !border-solid !border-[#D1D8D9] !rounded-[90px] !min-w-fit"
              mat-flat-button
              matStepperPrevious
            >
              <icon-caret-left></icon-caret-left>
            </button>
            <button
              type="submit"
              class="w-full"
              [ngClass]="{
                'animate-shake': thirdForm.invalid && thirdForm.submitted
              }"
              mat-flat-button
              matStepperNext
              color="primary"
            >
              <div *ngIf="loading" class="flex items-center justify-center">
                <icon-loading-button class="animate-spin"></icon-loading-button>
              </div>
              <span class="uppercase" *ngIf="!loading">Fortsæt</span>
            </button>
          </div>
        </form>
      </mat-step>
    </mat-stepper>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';
import { UserRoutingModule } from './user-routing.module';
import { SharedModule } from 'src/shared/shared.module';
import { UserComponent } from './user.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { ForgotPasswordComponent } from './forgot-passwd/forgot-passwd.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [
    LoginComponent,
    SignupComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    UserComponent,
  ],
  imports: [
    UserRoutingModule,
    RouterModule,
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatInputModule,
    MatButtonModule,
    MatStepperModule,
    MatSnackBarModule,
    NgSelectModule,
    MatPasswordStrengthModule,
    MatTooltipModule,
    NgxIntlTelInputModule,
    BrowserAnimationsModule,
  ],
})
export class UserModule {}

<svg
  width="25"
  height="24"
  viewBox="0 0 25 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M4.25 8.55938V19.5C4.25 19.6989 4.32902 19.8897 4.46967 20.0303C4.61033 20.171 4.80109 20.25 5 20.25H20C20.1989 20.25 20.3897 20.171 20.5303 20.0303C20.671 19.8897 20.75 19.6989 20.75 19.5V4.5C20.75 4.30109 20.671 4.11033 20.5303 3.96967C20.3897 3.82902 20.1989 3.75 20 3.75H9.05938C8.96199 3.74966 8.86549 3.76853 8.77541 3.80553C8.68532 3.84253 8.60341 3.89694 8.53438 3.96563L4.46563 8.03438C4.39694 8.10341 4.34253 8.18532 4.30553 8.27541C4.26853 8.36549 4.24966 8.46199 4.25 8.55938V8.55938Z"
    stroke="white"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M12.5 16.5C13.9497 16.5 15.125 15.3247 15.125 13.875C15.125 12.4253 13.9497 11.25 12.5 11.25C11.0503 11.25 9.875 12.4253 9.875 13.875C9.875 15.3247 11.0503 16.5 12.5 16.5Z"
    stroke="white"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M9.5 7.5H15.5V3.75"
    stroke="white"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

import { Input, Component, OnInit } from '@angular/core';

@Component({
  selector: 'page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent implements OnInit {
  @Input()
  title: string = '';
  @Input()
  description: string = '';

  constructor() {}

  ngOnInit(): void {}
}

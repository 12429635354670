import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'icon-caret-down',
  templateUrl: './caret-down.component.html',
  styleUrls: ['./caret-down.component.scss'],
})
export class CaretDownComponent implements OnInit {
  @Input() strokeColor: string = '#747F81';
  constructor() {}

  ngOnInit(): void {}
}

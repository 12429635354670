<svg
  width="32"
  height="32"
  viewBox="0 0 32 32"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect width="32" height="32" rx="16" fill="#091315" />
  <mask
    id="mask0_961_947"
    style="mask-type: alpha"
    maskUnits="userSpaceOnUse"
    x="8"
    y="6"
    width="17"
    height="17"
  >
    <rect
      x="8.65097"
      y="6.7843"
      width="15.7647"
      height="15.7647"
      fill="#D9D9D9"
      stroke="#091315"
      stroke-width="0.235294"
    />
  </mask>
  <g mask="url(#mask0_961_947)">
    <path
      d="M11.6172 16.9387C11.5123 17.0084 11.3862 17.0124 11.2124 16.968C11.0387 16.9236 10.8174 16.8308 10.5223 16.707L10.1241 16.5399C10.0158 16.4937 9.94548 16.3874 9.94548 16.2696V10.1743C9.94548 9.89595 9.94548 9.75684 9.97306 9.63174C10.0362 9.34515 10.2043 9.09253 10.4442 8.92351C10.549 8.84974 10.6773 8.79599 10.934 8.68844C11.041 8.64362 11.1212 8.61001 11.1855 8.58724C11.2498 8.56448 11.2981 8.55257 11.3413 8.55115C11.542 8.54451 11.7248 8.66612 11.7963 8.85383C11.8116 8.8942 11.8193 8.94337 11.8232 9.01147C11.827 9.07956 11.827 9.16655 11.827 9.28256V15.8395C11.827 16.1595 11.827 16.3994 11.8008 16.5768C11.7745 16.7542 11.722 16.869 11.6172 16.9387Z"
      fill="white"
      stroke="white"
      stroke-width="0.000813604"
    />
    <path
      d="M19.7623 12.5405L19.7624 12.5409L19.7623 12.5405L18.2238 13.1636L17.4573 13.4743L17.3722 13.5088L17.1086 13.6162L15.485 14.2738C15.1786 14.3976 14.978 14.6951 14.978 15.0256V22.6329C14.978 23.0129 14.6693 23.3207 14.2895 23.3344C13.8898 23.3488 13.5535 23.0332 13.5535 22.6333V18.4528C13.5535 18.1223 13.3529 17.8248 13.0465 17.701L13.0463 17.7014L13.0465 17.701C12.8789 17.6337 12.7691 17.4713 12.7691 17.2907V9.5431C12.7691 9.19979 12.7691 8.94234 12.7893 8.73622C12.8096 8.53012 12.8501 8.37539 12.931 8.23753C12.9557 8.19563 12.9828 8.1553 13.0124 8.11677C13.1098 7.98997 13.238 7.89435 13.4215 7.79821C13.6049 7.70207 13.8435 7.60542 14.1617 7.47654C14.5518 7.31856 14.7467 7.23961 14.9511 7.23136C14.9826 7.23009 15.0142 7.23009 15.0456 7.23135C15.2501 7.23954 15.445 7.31844 15.8351 7.47631L18.5924 8.59217C18.9485 8.7363 19.2156 8.84438 19.4187 8.95369C19.6219 9.06299 19.7611 9.17351 19.8615 9.32248C19.9619 9.47146 20.0122 9.64195 20.0373 9.87127C20.0624 10.1006 20.0624 10.3887 20.0624 10.7729V12.0982C20.0624 12.2932 19.9435 12.4685 19.7623 12.5405Z"
      fill="white"
      stroke="white"
      stroke-width="0.000813604"
    />
    <path
      d="M17.474 22.7334C17.474 23.2024 17.0733 23.569 16.605 23.5414C16.1741 23.516 15.8278 23.164 15.8278 22.7324V16.3188C15.8278 15.899 15.8278 15.6892 15.8896 15.5061C15.9582 15.3033 16.0808 15.123 16.2442 14.9847C16.3917 14.8599 16.5869 14.7827 16.9773 14.6284C17.0556 14.5974 17.1144 14.5742 17.1614 14.5599C17.2085 14.5457 17.2438 14.5404 17.2753 14.5453C17.3449 14.5562 17.406 14.5977 17.4417 14.6584C17.4578 14.686 17.4659 14.7207 17.47 14.7697C17.474 14.8187 17.474 14.8819 17.474 14.9662V22.7334Z"
      fill="#E6E6E6"
      fill-opacity="0.39"
      stroke="white"
      stroke-width="0.000813604"
    />
    <path
      d="M24.5174 25.2545C24.5154 25.2545 24.5134 25.2541 24.5116 25.2534C23.1354 24.6951 21.7014 24.2519 20.2227 23.9381C19.7168 23.8307 19.3374 23.7502 19.0517 23.656C18.7661 23.5619 18.5741 23.4541 18.443 23.2923C18.3119 23.1305 18.2464 22.9289 18.2136 22.647C18.1808 22.3651 18.1808 22.003 18.1808 21.5201V15.3333C18.1808 14.8296 18.1808 14.5778 18.2693 14.3628C18.3313 14.212 18.424 14.0758 18.5415 13.9628C18.7091 13.8016 18.9433 13.7092 19.4119 13.5244L19.7672 13.3842C19.9749 13.3023 20.2052 13.2983 20.4156 13.3729L23.0482 14.4177C23.4077 14.5604 23.6774 14.6674 23.8825 14.7761C24.0877 14.8848 24.2283 14.9953 24.3299 15.1448C24.4314 15.2943 24.4822 15.4658 24.5076 15.6966C24.533 15.9274 24.533 16.2175 24.533 16.6043V25.2389C24.533 25.2475 24.526 25.2545 24.5174 25.2545Z"
      fill="#E6E6E6"
      fill-opacity="0.39"
      stroke="white"
      stroke-width="0.000813604"
    />
    <path
      d="M12.2788 23.3917C12.17 23.47 12.0296 23.5243 11.8303 23.5744C11.631 23.6244 11.3728 23.6701 11.0286 23.731L11.0286 23.731C10.5461 23.8164 10.1843 23.8805 9.9038 23.9073C9.62333 23.934 9.42431 23.9235 9.26743 23.8598C9.02437 23.7612 8.85342 23.6178 8.71389 23.3957C8.62383 23.2523 8.57878 23.0665 8.55626 22.8022C8.53373 22.538 8.53373 22.1954 8.53373 21.7386V18.6567C8.53373 18.5775 8.53373 18.5379 8.53597 18.5016C8.56222 18.0769 8.81584 17.6995 9.19915 17.5148C9.23186 17.499 9.26851 17.4841 9.34185 17.4541L9.70745 17.3049C9.78545 17.2731 9.87278 17.2729 9.95093 17.3043L11.8795 18.0913C12.0304 18.1529 12.1059 18.1837 12.1709 18.2205C12.4939 18.4031 12.7118 18.7274 12.7587 19.0953C12.7682 19.1695 12.7682 19.251 12.7682 19.414V21.7437C12.7682 22.0642 12.7682 22.3045 12.7537 22.4949C12.7392 22.6854 12.7101 22.8259 12.652 22.9467C12.5622 23.1334 12.447 23.2708 12.2788 23.3917Z"
      fill="white"
      stroke="white"
      stroke-width="0.000813604"
    />
  </g>
</svg>

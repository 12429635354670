import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-no-descriptions-available',
  templateUrl: './no-descriptions-available.component.html',
  styleUrls: ['./no-descriptions-available.component.scss'],
})
export class NoDescriptionsAvailableComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

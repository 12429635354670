import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'icon-close',
  templateUrl: './close.component.html',
  styleUrls: ['./close.component.scss'],
})
export class CloseComponent implements OnInit {
  @Input() strokeColor = '#091315';
  constructor() {}

  ngOnInit(): void {}
}

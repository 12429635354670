import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthService } from '../_services/auth/auth.service';
import { User } from '../_models/user.model';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  private refreshingInProgress: boolean = false;
  private accessTokenSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>(null);

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const user = JSON.parse(localStorage.getItem('user'));
    const accessToken = JSON.parse(localStorage.getItem('user'))?.jwtToken;

    const isLoggedIn = user && user.jwtToken;

    const isApiUrl =
      (request.url.includes('v1/twofactorcode') &&
        !request.url.includes('v1/twofactorcode/password/login/change')) ||
      request.url.includes('v1/user/login') ||
      request.url.includes('v1/user/token/refresh') ||
      request.url.endsWith('user');
    if (!isApiUrl && isLoggedIn) {
      request = this.addAuthorizationHeader(request, accessToken);
      // console.log('Request with bearer token');
    }
    return next.handle(request).pipe(
      catchError((err) => {
        // in case of 401 http error
        if (err instanceof HttpErrorResponse && err?.status === 401) {
          // console.log('received 401 error');
          const refreshToken = JSON.parse(
            localStorage.getItem('user')
          ).refreshToken;
          // console.log('refreshed token value', refreshToken);
          // if there are tokens then send refresh token request
          if (refreshToken && accessToken) {
            // console.log('called refresh token method in interceptor');
            return this.refreshToken(request, next, refreshToken);
          }

          // otherwise logout and redirect to login page
          // console.log('call logoutAndRedirect method in interceptor');
          return this.logoutAndRedirect(err);
        }

        // in case of 403 http error (refresh token failed)
        if (err instanceof HttpErrorResponse && err?.status === 403) {
          // console.log('403 error');
          // console.log('call logoutAndRedirect method in interceptor');
          // logout and redirect to login page
          return this.logoutAndRedirect(err);
        }

        if (
          err instanceof HttpErrorResponse &&
          err?.error?.text?.includes('400')
        ) {
          // console.log('400 error');
          // console.log('call logoutAndRedirect method in interceptor');
          // logout and redirect to login page
          return this.logoutAndRedirect(err);
        }

        // if error has status neither 401 nor 403 then just return this error
        // console.log('Error', err);
        return next.handle(request);
      })
    );
  }

  private addAuthorizationHeader(
    request: HttpRequest<any>,
    token: string
  ): HttpRequest<any> {
    if (token) {
      // console.log('token Added');
      return request.clone({
        setHeaders: { Authorization: `Bearer ${token}` },
      });
    }

    return request;
  }

  private logoutAndRedirect(err): Observable<HttpEvent<any>> {
    // console.log('logout will be called after');
    this.authService.logout();
    return throwError(() => new Error(err));
  }

  private refreshToken(
    request: HttpRequest<any>,
    next: HttpHandler,
    refreshToken
  ): Observable<HttpEvent<any>> {
    // console.log('inside refresh token in inteceptor');
    if (!this.refreshingInProgress) {
      this.refreshingInProgress = true;
      this.accessTokenSubject.next(null);

      return this.authService.refreshToken({ token: refreshToken }).pipe(
        switchMap((res: User) => {
          // console.log('calling refresh token endpoint');
          this.refreshingInProgress = false;
          this.accessTokenSubject.next(res.jwtToken);
          // copy the new result to user in locastorage
          const rememberMe = JSON.parse(
            localStorage.getItem('user')
          )?.remeberMeChecked;
          localStorage.setItem(
            'user',
            JSON.stringify({ ...res, remeberMeChecked: rememberMe })
          );
          // repeat failed request with new token
          return next.handle(
            this.addAuthorizationHeader(request, res.jwtToken)
          );
        })
      );
    } else {
      // wait while getting new token
      return this.accessTokenSubject.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap((token) => {
          // console.log('Still waiting');
          // repeat failed request with new token
          return next.handle(this.addAuthorizationHeader(request, token));
        })
      );
    }
  }
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Listing, ServiceAdditions } from 'src/app/_models/property.model';
import { ListingService, PaymentService } from 'src/app/_services';
import {
  LISTING_TYPES,
  SelectObject,
} from '../add-property-data/add-property-data.util';

interface Package {
  packageId?: string;
  packageName?: string;
  packageDescription?: string;
  packagePrice?: number;
  packageBenefits?: string[];
  packageServiceAdditions?: ServiceAdditions[];
}
interface SalesStrategy {
  salesStrategyId?: string;
  salesStrategyName?: string;
  salesStrategyPrice?: number;
  salesStrategyBenefits?: string[];
  salesStrategyServiceAdditions?: ServiceAdditions[];
}

@Component({
  selector: 'sales-strategy-data',
  templateUrl: './sales-strategy-data.component.html',
  styleUrls: ['./sales-strategy-data.component.scss'],
})
export class SalesStrategyDataComponent implements OnInit {
  @Output() prevClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() nextClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  pageLoading: boolean = false;
  loading: boolean = false;

  salesStrategies: SalesStrategy[] = [];
  _selectedPackage: Package = {};
  _selectedSalesStrategy: SalesStrategy = {};
  totalSumForPackagesAndStrategies: number = 0;

  _typeOfListing: Listing['typeOfListing'];
  _listingTypes: SelectObject[] = JSON.parse(JSON.stringify(LISTING_TYPES));

  constructor(
    private payment: PaymentService,
    private listService: ListingService,
    private spinner: NgxSpinnerService
  ) {
    const listing = JSON.parse(sessionStorage.getItem('listing'));
  }

  ngOnInit(): void {
    const listing: Listing = JSON.parse(sessionStorage.getItem('listing'));
    if (
      listing?.id &&
      listing?.creationStep >= 4 &&
      listing?.typeOfListing &&
      listing?.indicativePrice
    ) {
      this._typeOfListing = listing?.typeOfListing;
      this.getPackageDataFromAPI(listing);
      this.getAllSalesStrategies(listing);
      this.calculateTotalSumForPackagesAndStrategies();
    }
  }

  getPackageDataFromAPI(listing: Listing) {
    this.pageLoading = true;
    this.spinner.show();
    this.payment
      .getListingPackage(listing?.typeOfListing, listing?.indicativePrice)
      .subscribe({
        next: (res) => {
          this._selectedPackage.packageId = res?.id;
          this._selectedPackage.packageName = res?.name;
          this._selectedPackage.packageDescription = res?.description;
          this._selectedPackage.packagePrice = res?.packagePrice;
          this._selectedPackage.packageBenefits = res?.packageBenefits;
          this._selectedPackage.packageServiceAdditions =
            res?.packageServiceAdditions;
          this._selectedPackage.packageServiceAdditions.map((item) => {
            item['isChecked'] = false;
            listing?.packageServiceAdditions.map((addition) => {
              item.serviceName === addition.serviceName
                ? (item['isChecked'] = true)
                : '';
            });
          });
          this.calculateTotalSumForPackagesAndStrategies();
        },
        error: (err) => {
          console.log(err);
          this.pageLoading = false;
          this.spinner.hide();
        },
      });
  }

  getAllSalesStrategies(listing: Listing) {
    this.payment.getAllSalesStrategy().subscribe({
      next: (res) => {
        res.map((item) => {
          this.salesStrategies.push({
            salesStrategyId: item.id,
            salesStrategyName: item.name,
            salesStrategyPrice: item.price,
            salesStrategyBenefits: item.benefits,
            salesStrategyServiceAdditions: item.saleStrategyAdditions,
          });
        });

        this.salesStrategies.map((salesStratergy) => {
          salesStratergy?.salesStrategyServiceAdditions?.map((item) => {
            item['isChecked'] = false;

            listing?.strategyAdditions.map((addition) => {
              item.serviceName === addition.serviceName
                ? (item['isChecked'] = true)
                : '';
            });
          });
        });

        if (listing?.salesStrategyName) {
          this._selectedSalesStrategy = this.salesStrategies.find(
            (item) => item.salesStrategyName == listing.salesStrategyName
          );
        } else {
          this._selectedSalesStrategy = this.salesStrategies.find(
            (item) => item.salesStrategyName == 'Basis'
          );
        }

        this.calculateTotalSumForPackagesAndStrategies();
        this.pageLoading = false;
        this.spinner.hide();
      },
      error: (err) => {
        console.log(err);
        this.pageLoading = false;
        this.spinner.hide();
      },
    });
  }

  toggleSelectedPackageServiceAddition(index: number) {
    this._selectedPackage.packageServiceAdditions[index].isChecked =
      !this._selectedPackage.packageServiceAdditions[index].isChecked;
    // console.log(this._selectedPackage.packageServiceAdditions);
    this.calculateTotalSumForPackagesAndStrategies();
  }

  toggleSelectedSalesStrategyServiceAddition(index: number) {
    this._selectedSalesStrategy.salesStrategyServiceAdditions[index].isChecked =
      !this._selectedSalesStrategy.salesStrategyServiceAdditions[index]
        .isChecked;
    // console.log(this._selectedSalesStrategy.salesStrategyServiceAdditions);
    this.calculateTotalSumForPackagesAndStrategies();
  }

  toggleSelectedSalesStrategy() {
    this.calculateTotalSumForPackagesAndStrategies();
  }

  calculateTotalSumForPackagesAndStrategies() {
    let temp: number = 0;

    temp = temp + this._selectedPackage?.packagePrice || 0;

    this._selectedPackage?.packageServiceAdditions?.map((item) => {
      item.isChecked ? (temp = temp + item.price) : '';
    });

    temp = temp + this._selectedSalesStrategy?.salesStrategyPrice || 0;

    this._selectedSalesStrategy?.salesStrategyServiceAdditions?.map((item) => {
      item.isChecked ? (temp = temp + item.price) : '';
    });

    this.totalSumForPackagesAndStrategies = temp;
  }

  createOrUpdateProperty() {
    const listing = JSON.parse(sessionStorage.getItem('listing'));
    if (listing) {
      const createOrUpdateProperty: Listing = { ...listing };
      createOrUpdateProperty.creationStep = 5;
      createOrUpdateProperty.packageId = this._selectedPackage.packageId;
      createOrUpdateProperty.packageName = this._selectedPackage.packageName;
      createOrUpdateProperty.packageDescription =
        this._selectedPackage.packageDescription;
      createOrUpdateProperty.packagePrice = this._selectedPackage.packagePrice;
      createOrUpdateProperty.packageBenefits =
        this._selectedPackage.packageBenefits;
      createOrUpdateProperty.packageServiceAdditions =
        this._selectedPackage.packageServiceAdditions;

      createOrUpdateProperty.salesStrategyId =
        this._selectedSalesStrategy.salesStrategyId;
      createOrUpdateProperty.salesStrategyName =
        this._selectedSalesStrategy.salesStrategyName;
      createOrUpdateProperty.saleStrategyPrice =
        this._selectedSalesStrategy.salesStrategyPrice;
      createOrUpdateProperty.salesStrategyBenefits =
        this._selectedSalesStrategy.salesStrategyBenefits;
      createOrUpdateProperty.strategyAdditions =
        this._selectedSalesStrategy.salesStrategyServiceAdditions;

      createOrUpdateProperty.totalSumForPackagesAndStrategies =
        this.totalSumForPackagesAndStrategies;

      // console.log(createOrUpdateProperty);

      this.listService
        .createOrUpdateProperty(createOrUpdateProperty)
        .subscribe({
          next: (res) => {
            sessionStorage.setItem('listing', JSON.stringify(res));
            this.loading = false;
            this.nextClick.emit(true);
          },
          error: (err) => {
            console.log(err);
            this.loading = false;
          },
        });
    }
  }

  onNextClicked() {
    if (
      this._selectedPackage.packageId &&
      this._selectedSalesStrategy.salesStrategyId
    ) {
      this.loading = true;
      this.createOrUpdateProperty();
    }
  }

  onPrevClicked() {
    this.prevClick.emit(true);
  }

  displaySelectText(list, value) {
    if (list && value) return list?.find((list) => list.value === value).text;
  }
}

<svg
  width="24"
  height="25"
  viewBox="0 0 24 25"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M9.00001 20.7499H4.5C4.30109 20.7499 4.11033 20.6709 3.96967 20.5302C3.82902 20.3896 3.75 20.1988 3.75 19.9999V15.8093C3.74966 15.7119 3.76853 15.6154 3.80553 15.5253C3.84253 15.4352 3.89694 15.3533 3.96563 15.2843L15.2156 4.03429C15.2854 3.96343 15.3686 3.90715 15.4603 3.86874C15.5521 3.83033 15.6505 3.81055 15.75 3.81055C15.8495 3.81055 15.9479 3.83033 16.0397 3.86874C16.1314 3.90715 16.2146 3.96343 16.2844 4.03429L20.4656 8.21554C20.5365 8.28533 20.5928 8.36851 20.6312 8.46025C20.6696 8.552 20.6894 8.65046 20.6894 8.74992C20.6894 8.84938 20.6696 8.94784 20.6312 9.03958C20.5928 9.13132 20.5365 9.21451 20.4656 9.28429L9.00001 20.7499Z"
    stroke="#283F43"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M20.25 20.75H9"
    stroke="#283F43"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M12.75 6.5L18 11.75"
    stroke="#283F43"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

import { DecimalPipe } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Financing } from 'src/app/_models/property.model';
import { formatNumber, unformatNumber } from 'src/utils';
import {
  INTEREST_RATE_TYPE,
  LOAN_TYPE,
} from './add-edit-financing-dialog.util';

@Component({
  selector: 'app-add-edit-financing-dialog',
  templateUrl: './add-edit-financing-dialog.component.html',
  styleUrls: ['./add-edit-financing-dialog.component.scss'],
})
export class AddEditFinancingDialogComponent implements OnInit {
  financingForm: FormGroup;
  _loanType = JSON.parse(JSON.stringify(LOAN_TYPE));
  _interestRateType = JSON.parse(JSON.stringify(INTEREST_RATE_TYPE));

  constructor(
    private fb: FormBuilder,
    private numberpipe: DecimalPipe,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddEditFinancingDialogComponent>
  ) {
    this.financingForm = this.fb.group({
      loanType: new FormControl('', [Validators.required]),
      interestRateType: new FormControl('', [Validators.required]),
      loanProvider: new FormControl('', [Validators.required]),
      financingType: new FormControl('', [Validators.required]),
      interestRate: new FormControl('', [
        Validators.required,
        Validators.pattern(/(?!0*[,]*0*$)/),
      ]),
      totalDebt: new FormControl('', [
        Validators.required,
        Validators.pattern(/(?!0*[,]*0*$)/),
      ]),
      remainingDebt: new FormControl('', [
        Validators.required,
        Validators.pattern(/(?!0*[,]*0*$)/),
      ]),
    });
  }

  ngOnInit(): void {
    if (this.data.indexTobeEdited >= 0) this.loadFinanceValues();
  }

  // Getters
  get loanType() {
    return this.financingForm.get('loanType');
  }
  get interestRateType() {
    return this.financingForm.get('interestRateType');
  }

  get loanProvider() {
    return this.financingForm.get('loanProvider');
  }
  get financingType() {
    return this.financingForm.get('financingType');
  }
  get interestRate() {
    return this.financingForm.get('interestRate');
  }
  get totalDebt() {
    return this.financingForm.get('totalDebt');
  }
  get remainingDebt() {
    return this.financingForm.get('remainingDebt');
  }

  addFinance() {
    if (this.financingForm.valid) {
      let finance: Financing = {
        loanType: this.loanType.value,
        interestRateType: this.interestRateType.value,
        loanProvider: this.loanProvider.value,
        financingType: this.financingType.value,
        interestRate: unformatNumber(this.interestRate.value),
        totalDebt: unformatNumber(this.totalDebt.value),
        remainingDebt: unformatNumber(this.remainingDebt.value),
      };
      // console.log(finance);
      // Push table row to unitList Array if new entry
      if (this.data.indexTobeEdited === -1)
        this.data.financing.unshift(finance);
      if (this.data.indexTobeEdited >= 0)
        this.data.financing[this.data.indexTobeEdited] = finance;
      this.closeDialog();
    }
  }

  loadFinanceValues() {
    // find out unit to be edited
    let finance = this.data.financing[this.data.indexTobeEdited];
    // Set form values
    this.loanType.setValue(finance.loanType);
    this.interestRateType.setValue(finance.interestRateType);
    this.loanProvider.setValue(finance.loanProvider);
    this.financingType.setValue(finance.financingType);
    this.interestRate.setValue(formatNumber(finance.interestRate));
    this.totalDebt.setValue(formatNumber(finance.totalDebt));
    this.remainingDebt.setValue(formatNumber(finance.remainingDebt));
  }

  closeDialog() {
    this.dialogRef.close();
  }

  // helpers
  formatInput(event: any) {
    const parts = event.target.value.toString().split(',');
    if (!isNaN(Number(parts[0]))) {
      parts[0] = this.numberpipe.transform(
        parts[0].replace(/\./g, '').replace(/,/g, ''),
        '1.0-2',
        'da-DK'
      );
      event.target.value = parts.join(',');
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent implements OnInit {
  @Input() title: string = 'Heading';
  @Input() isInfo: boolean = true;

  constructor() {}

  ngOnInit(): void {}
}

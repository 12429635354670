import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { patternValidator } from 'src/utils';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { LoginRequest, User } from 'src/app/_models/user.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loading = false;
  loginForm: FormGroup;
  hide = true;
  responseErrorMessage: string;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private titleService: Title
  ) {
    this.loginForm = this.fb.group({});
    this.titleService.setTitle('Ejos - Log ind');
  }

  ngOnInit(): void {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.remeberMeChecked) {
      this.router.navigate(['/home/']);
    }

    this.loginForm = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        patternValidator(new RegExp('(?=.*[0-9])'), {
          requiresDigit: true,
        }),
        patternValidator(new RegExp('(?=.*[A-Z])'), {
          requiresUppercase: true,
        }),
        patternValidator(new RegExp('(?=.*[a-z])'), {
          requiresLowercase: true,
        }),
        patternValidator(new RegExp('(?=.*[$@^!%*?&#-+])'), {
          requiresSpecialChars: true,
        }),
      ]),
      rememberMe: new FormControl(true),
    });
  }

  handleSubmit = () => {
    this.responseErrorMessage = '';

    if (this.loginForm.status === 'VALID') {
      this.loading = true;
      const loginRequest: LoginRequest = {
        email: this.email.value,
        password: this.password.value,
      };

      this.authService.loginUser(loginRequest).subscribe({
        next: (res: User) => {
          this.loading = false;
          let user: any = res;
          user = { ...user, remeberMeChecked: this.rememberMe.value };
          localStorage.setItem('user', JSON.stringify(user));
          this.router.navigate(['/home/front-page']);
        },
        error: (err) => {
          console.log(err);
          this.loading = false;
          if (err?.error?.messageStatus === 'WrongEmailOrPassword') {
            this._snackBar.open('Forkert e-mail eller adgangskode', 'Dismiss', {
              duration: 3000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          } else
            this._snackBar.open('Kunne ikke logge ind', 'Dismiss', {
              duration: 3000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
        },
      });
    }
  };

  get email() {
    return this.loginForm.get('email');
  }

  get password() {
    return this.loginForm.get('password');
  }

  get rememberMe() {
    return this.loginForm.get('rememberMe');
  }
}

<svg
  width="32"
  height="32"
  viewBox="0 0 32 32"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M12 28H20"
    stroke="#283F43"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M7.0249 13C7.02323 11.8145 7.25613 10.6403 7.71018 9.54522C8.16423 8.45009 8.83046 7.45561 9.67049 6.61906C10.5105 5.78251 11.5078 5.12042 12.6048 4.67093C13.7018 4.22144 14.8769 3.99342 16.0624 4.00002C21.0124 4.03752 24.9749 8.15002 24.9749 13.1125V14C24.9749 18.475 25.9124 21.075 26.7374 22.5C26.825 22.6518 26.8712 22.8239 26.8714 22.9991C26.8715 23.1744 26.8256 23.3466 26.7383 23.4985C26.6509 23.6504 26.5252 23.7767 26.3737 23.8647C26.2221 23.9527 26.0501 23.9994 25.8749 24H6.1249C5.94966 23.9994 5.77766 23.9527 5.62613 23.8647C5.4746 23.7767 5.34886 23.6504 5.26151 23.4985C5.17416 23.3466 5.12826 23.1744 5.12842 22.9991C5.12857 22.8239 5.17478 22.6518 5.2624 22.5C6.0874 21.075 7.0249 18.475 7.0249 14V13Z"
    stroke="#283F43"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

type ITEM = { title: string; url: string };

type TRAIL = {
  [t: string]: ITEM[];
};

@Component({
  selector: 'bread-crumbs',
  templateUrl: './bread-crumbs.component.html',
  styleUrls: ['./bread-crumbs.component.scss'],
})
export class BreadCrumbsComponent implements OnInit {
  trail: TRAIL = {
    '/home/front-page': [{ title: 'Forside', url: '/home/front-page' }],
    '/home/properties': [{ title: 'Ejendomme', url: '/home/properties' }],
    '/home/properties/create-property': [
      { title: 'Ejendomme', url: '/home/properties' },
      { title: 'Opret ejendom', url: '/home/properties/create-property' },
    ],
    '/home/properties/view-property': [
      { title: 'Ejendomme', url: '/home/properties' },
      { title: 'Se ejendom', url: '/home/properties/view-property' },
    ],
    '/home/my-profile': [{ title: 'Min Profil', url: '/home/my-profile' }],
  };

  breadCrumb: ITEM[] = this.trail?.[''];

  constructor(private router: Router, private location: Location) {
    // this.breadCrumb = this.trail?.[this.router.url];
    location.onUrlChange((url) => {
      this.breadCrumb = this.trail?.[this.router.url];
    });
  }

  ngOnInit(): void {
    this.breadCrumb = this.trail?.[this.router.url];
  }
}

import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.scss'],
})
export class CheckBoxComponent implements OnInit {
  @Input() mode = 'FORM_CONTROL';
  @Input() _id: any = '';
  @Input() group: any = '';
  @Input() control: any = '';
  @Input() checked: boolean = false;
  @Input() isErrorsEnabled: BehaviorSubject<boolean> | undefined;
  @Output() changeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  isErrorsEnabledLocale: boolean = false;

  constructor() {}

  ngOnInit(): void {
    if (this.isErrorsEnabled) {
      this.isErrorsEnabled.subscribe((newVal) => {
        this.isErrorsEnabledLocale = newVal;
      });
    }
  }

  onChange(event) {
    // console.log(event.currentTarget.checked);
    this.changeEvent.emit(event.currentTarget.checked);
  }
}

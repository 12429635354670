<!-- Form Control Checkbox -->
<div *ngIf="mode === 'FORM_CONTROL'" class="main-container">
  <div [formGroup]="group" class="container">
    <div>
      <input
        [id]="_id"
        [name]="_id"
        [formControlName]="_id"
        [control]="control"
        [group]="group"
        type="checkbox"
        tabindex="0"
      />
      <div class="custom-checkbox"></div>
    </div>
  </div>
  <ng-content *ngIf="mode === 'FORM_CONTROL'"> </ng-content>
</div>

<!-- Single Control Checkbox -->
<div *ngIf="mode === 'SINGLE'" class="main-container">
  <div class="container">
    <div>
      <input
        [id]="_id"
        [name]="_id"
        [checked]="checked"
        type="checkbox"
        (change)="onChange($event)"
      />
      <div class="custom-checkbox"></div>
    </div>
  </div>
</div>

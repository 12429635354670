<form class="upload-pictures-step">
  <page-title
    title="Upload billeder"
    description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
  ></page-title>

  <div class="width-100">
    <mat-expansion-panel hideToggle class="panel" [expanded]="true">
      <mat-expansion-panel-header class="panel-header">
        <mat-panel-title>
          Side 1 <span class="info-icon-white"></span
        ></mat-panel-title>
        <span class="dropdown-filled-white"></span>
      </mat-expansion-panel-header>
      <div class="submenu-items">
        <image-upload
          mode="SINGLE"
          [_id]="imagesData[0].header._id"
          [group]="uploadPicturesForm"
        >
        </image-upload>
        <div class="text-info-container">
          <text-input
            mode="SINGLE"
            [_id]="imagesData[0].header._id"
            [title]="imagesData[0].header.title"
            [placeholder]="imagesData[0].header.placeholder"
            [isRequired]="imagesData[0].header.isRequired"
            [isErrorsEnabled]="isErrorsEnabled"
          ></text-input>
          <text-input
            [_id]="imagesData[0].header._id"
            [title]="imagesData[0].subHeading.title"
            [placeholder]="imagesData[0].subHeading.placeholder"
            [isRequired]="imagesData[0].subHeading.isRequired"
            [isErrorsEnabled]="isErrorsEnabled"
            [group]="uploadPicturesForm"
            [control]="frontImageHeader"
          ></text-input>
          <text-input
            [_id]="imagesData[0].header._id"
            [title]="imagesData[0].propertyFacts.title"
            [placeholder]="imagesData[0].propertyFacts.placeholder"
            [isRequired]="imagesData[0].propertyFacts.isRequired"
            [group]="uploadPicturesForm"
            [isErrorsEnabled]="isErrorsEnabled"
            [control]="frontImageHeader"
            [isTextArea]="imagesData[0].propertyFacts.isTextArea"
            [textAreaHeight]="imagesData[0].propertyFacts.textAreaHeight"
          ></text-input>
        </div>
      </div>
    </mat-expansion-panel>
  </div>

  <div class="width-100">
    <mat-expansion-panel hideToggle class="panel" [expanded]="true">
      <mat-expansion-panel-header class="panel-header">
        <mat-panel-title>
          Side 2 <span class="info-icon-white"></span
        ></mat-panel-title>
        <span class="dropdown-filled-white"></span>
      </mat-expansion-panel-header>
      <div class="submenu-items">
        <div class="flex-col-10">
          <image-upload
            mode="SINGLE"
            [_id]="imagesData[0].header._id"
            [group]="uploadPicturesForm"
          >
          </image-upload>
          <text-input
            [_id]="imagesData[0].header._id"
            [title]="imagesData[0].caption.title"
            [placeholder]="imagesData[0].caption.placeholder"
            [isRequired]="imagesData[0].caption.isRequired"
            [isErrorsEnabled]="isErrorsEnabled"
            [group]="uploadPicturesForm"
            [control]="frontImageHeader"
          ></text-input>
        </div>

        <div class="flex-col-10">
          <image-upload
            mode="SINGLE"
            [_id]="imagesData[0].header._id"
            [group]="uploadPicturesForm"
          >
          </image-upload>
          <text-input
            [_id]="imagesData[0].header._id"
            [title]="imagesData[0].caption.title"
            [placeholder]="imagesData[0].caption.placeholder"
            [isRequired]="imagesData[0].caption.isRequired"
            [isErrorsEnabled]="isErrorsEnabled"
            [group]="uploadPicturesForm"
            [control]="frontImageHeader"
          ></text-input>
        </div>
      </div>
    </mat-expansion-panel>
  </div>

  <div class="width-100">
    <mat-expansion-panel hideToggle class="panel" [expanded]="true">
      <mat-expansion-panel-header class="panel-header">
        <mat-panel-title>
          Side 3 <span class="info-icon-white"></span
        ></mat-panel-title>
        <span class="dropdown-filled-white"></span>
      </mat-expansion-panel-header>
      <!-- // Content Start -->
      <!-- //Image component -->
      <div class="submenu-items">
        <div
          *ngFor="let item of page3ImageList; index as i"
          class="flex-col-10"
        >
          <image-upload
            *ngIf="!isLoaderEnabled"
            mode="SINGLE"
            _width="336px"
            _height="180px"
            [_id]="'page3Image' + i"
            [imagesFiles]="item"
          >
          </image-upload>
          <text-input
            *ngIf="!isLoaderEnabled"
            mode="SINGLE"
            [_id]="'page3Text' + i"
            [title]="'Billedtekst'"
            [placeholder]="'Skriv overskrift'"
            [isRequired]="true"
            [isErrorsEnabled]="isErrorsEnabled"
          ></text-input>
        </div>

        <div
          fileDragDrop
          defaultColor="#fff"
          (filesChangeEmiter)="onFileChange($event)"
          class="add-image-container"
        >
          <div>
            <label class="upload-button">
              <input
                type="file"
                accept="image/jpeg,image/png,image/bmp"
                (change)="onFileChange($event.target.files)"
                multiple
              />
            </label>
            <p>Træk eller slip filer her</p>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </div>

  <prev-next-buttons
    (prevClick)="onPrevClicked()"
    (nextClick)="onNextClicked()"
  ></prev-next-buttons>
</form>

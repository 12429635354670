import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-books-hub',
  templateUrl: './books-hub.component.html',
  styleUrls: ['./books-hub.component.scss'],
})
export class BooksHubComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';
import { Listing } from 'src/app/_models/property.model';
import { ListingService } from 'src/app/_services';

@Component({
  selector: 'payment-data',
  templateUrl: './payment-data.component.html',
  styleUrls: ['./payment-data.component.scss'],
})
export class PaymentDataComponent implements OnInit {
  @Output() prevClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() nextClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  listing: Listing;
  paymentForm: FormGroup;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO.Denmark;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.Denmark,
    CountryISO.Sweden,
    CountryISO.Finland,
    CountryISO.Norway,
  ];
  loading: boolean = false;

  constructor(private fb: FormBuilder, private listService: ListingService) {
    this.paymentForm = this.fb.group({
      fullNameInvoice: new FormControl('', [Validators.required]),
      emailInvoice: new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
      phoneNumberInvoiceCountryCode: new FormControl('DK', [
        Validators.required,
      ]),
      phoneNumberInvoice: new FormControl(undefined, [Validators.required]),
      addressInvoice: new FormControl('', [Validators.required]),
      postalCodeInvoice: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[0-9]{4}$/),
      ]),
      cityInvoice: new FormControl('', [Validators.required]),
      paymentMethod: new FormControl('Invoice', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.listing = JSON.parse(sessionStorage.getItem('listing'));
    if (this.listing?.saveAsInvoiceInfo && this.listing?.creationStep < 8) {
      this.fullNameInvoice.setValue(this.listing?.sellerFullName);
      this.emailInvoice.setValue(this.listing?.email);
      this.phoneNumberInvoiceCountryCode.setValue(
        this.listing?.phoneNumber?.split('-')[0]
      );
      this.phoneNumberInvoice.setValue(
        this.listing?.phoneNumber?.split('-')[1]
      );
      this.addressInvoice.setValue(
        `${this.listing?.primaryAddress.street} ${this.listing?.primaryAddress.buildingNumber}`
      );
      this.postalCodeInvoice.setValue(this.listing?.primaryAddress.postalCode);
      this.cityInvoice.setValue(this.listing?.primaryAddress.city);
    }

    if (this.listing?.creationStep === 8) {
      this.fullNameInvoice.setValue(this.listing?.fullNameInvoice);
      this.emailInvoice.setValue(this.listing?.emailInvoice);
      this.phoneNumberInvoiceCountryCode.setValue(
        this.listing?.phoneNumberInvoice?.split('-')[0]
      );
      this.phoneNumberInvoice.setValue(
        this.listing?.phoneNumberInvoice?.split('-')[1]
      );
      this.addressInvoice.setValue(this.listing?.addressInvoice);
      this.postalCodeInvoice.setValue(this.listing?.postalCodeInvoice);
      this.cityInvoice.setValue(this.listing?.cityInvoice);
      this.paymentMethod.setValue(this.listing?.paymentMethod);
    }
  }

  onNextClicked() {
    if (this.paymentForm.valid && this.listing) {
      this.loading = true;
      const createOrUpdateProperty: Listing = { ...this.listing };
      createOrUpdateProperty.creationStep = 8;
      createOrUpdateProperty.fullNameInvoice = this.fullNameInvoice?.value;
      createOrUpdateProperty.emailInvoice = this.emailInvoice?.value;
      createOrUpdateProperty.phoneNumberInvoice = `${this.phoneNumberInvoice?.value?.countryCode}-${this.phoneNumberInvoice?.value?.number}`;
      createOrUpdateProperty.addressInvoice = this.addressInvoice?.value;
      createOrUpdateProperty.cityInvoice = this.cityInvoice?.value;
      createOrUpdateProperty.postalCodeInvoice = this.postalCodeInvoice?.value;
      createOrUpdateProperty.paymentMethod = this.paymentMethod?.value;
      // console.log(createOrUpdateProperty);
      this.listService
        .createOrUpdateProperty(createOrUpdateProperty)
        .subscribe({
          next: (res) => {
            sessionStorage.setItem('listing', JSON.stringify(res));
            this.loading = false;
            this.nextClick.emit(true);
          },
          error: (err) => {
            console.log(err);
            this.loading = false;
          },
        });
    }
  }

  onPrevClicked() {
    this.prevClick.emit(true);
  }

  updatePaymentMethod(value: Listing['paymentMethod']) {
    this.paymentMethod.setValue(value);
  }

  // Getters
  get fullNameInvoice() {
    return this.paymentForm.get('fullNameInvoice');
  }
  get emailInvoice() {
    return this.paymentForm.get('emailInvoice');
  }
  get phoneNumberInvoiceCountryCode() {
    return this.paymentForm.get('phoneNumberInvoiceCountryCode');
  }
  get phoneNumberInvoice() {
    return this.paymentForm.get('phoneNumberInvoice');
  }
  get addressInvoice() {
    return this.paymentForm.get('addressInvoice');
  }
  get postalCodeInvoice() {
    return this.paymentForm.get('postalCodeInvoice');
  }
  get cityInvoice() {
    return this.paymentForm.get('cityInvoice');
  }
  get paymentMethod() {
    return this.paymentForm.get('paymentMethod');
  }
}

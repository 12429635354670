<div class="mt-[52px] max-w-[1108px]">
  <h3 class="font-semibold text-[32px] leading-10 text-black">
    1. Ejendommens data
  </h3>
  <div class="mt-10 flex gap-[40px] justify-between items-start flex-wrap">
    <h5
      class="font-semibold text-xl leading-[25px] text-black max-w-[286px] w-full"
    >
      Oplysninger om ejendommen
    </h5>
    <form
      class="flex flex-col gap-3 max-w-[660px] w-full items-end"
      #firstForm="ngForm"
      [formGroup]="propertyDataForm"
    >
      <mat-form-field class="max-w-[628px] w-full" appearance="fill">
        <mat-label>Ejendoms- og projektnavn</mat-label>
        <input matInput formControlName="listingTitle" />
        <mat-error *ngIf="listingTitle.hasError('required')">
          Påkrævet
        </mat-error>
      </mat-form-field>

      <mat-form-field class="max-w-[628px] w-full" appearance="fill">
        <mat-label>Primær adresse</mat-label>
        <input
          type="text"
          aria-label="Primær adresse"
          matInput
          [matAutocomplete]="auto"
          formControlName="primaryAddress"
        />
        <mat-autocomplete
          autoActiveFirstOption
          #auto="matAutocomplete"
          [displayWith]="valueMapper"
          (optionSelected)="setPrimaryAddress($event)"
        >
          <mat-option
            *ngFor="let option of filteredPrimaryAddresses"
            [value]="option.data"
          >
            <div class="py-2 px-3">
              <p class="leading-5 text-black">
                {{ option.data.streetName }}
                {{ option.data.buildingNumber }},
              </p>
              <p class="leading-5 text-black">
                {{ option.data?.postalCode }} {{ option.data.city }}
              </p>
            </div>
          </mat-option>

          <mat-option disabled *ngIf="filteredPrimaryAddresses?.length == 0">
            <div class="py-2 px-3">
              <p class="leading-5 text-black">
                Kunne ikke finde nogen adresser
              </p>
              <p class="leading-5 text-[#747f81]">
                Prøv at søg efter en anden adresse
              </p>
            </div>
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="primaryAddress.hasError('required')">
          Påkrævet
        </mat-error>
        <mat-error
          *ngIf="
            !primaryAddress.hasError('required') &&
            primaryAddress.hasError('requireMatch')
          "
          >Please select address from dropdown</mat-error
        >
      </mat-form-field>
      <div
        formArrayName="secondaryAddresses"
        *ngFor="let address of secondaryAddresses.controls; let i = index"
        class="w-full flex gap-2 items-center mb-6"
      >
        <icon-delete
          class="cursor-pointer"
          (click)="deleteSecondaryAddress(i)"
        ></icon-delete>
        <mat-form-field
          class="max-w-[628px] w-full relative| no-error"
          appearance="fill"
        >
          <mat-label>Sekundær adresse&nbsp; {{ i + 1 }}</mat-label>
          <input
            type="text"
            aria-label="Sekundær adresse{{ i + 1 }}"
            matInput
            [matAutocomplete]="auto"
            formControlName="{{ i }}"
          />
          <mat-autocomplete
            autoActiveFirstOption
            #auto="matAutocomplete"
            [displayWith]="valueMapper"
            (optionSelected)="setSecondaryAddress($event, i)"
          >
            <mat-option
              *ngFor="let option of filteredSecondaryAddresses[i]"
              [value]="option.data"
            >
              <div>
                <p class="leading-5 text-black">
                  {{ option.data.streetName }}
                  {{ option.data.buildingNumber }},
                </p>
                <p class="leading-5 text-black">
                  {{ option.data?.postalCode }} {{ option.data.city }}
                </p>
              </div>
            </mat-option>

            <mat-option
              disabled
              *ngIf="filteredSecondaryAddresses[i]?.length == 0"
            >
              <div class="py-2 px-3">
                <p class="leading-5 text-black">
                  Kunne ikke finde nogen adresser
                </p>
                <p class="leading-5 text-[#747f81]">
                  Prøv at søg efter en anden adresse
                </p>
              </div>
            </mat-option>
          </mat-autocomplete>
          <mat-error
            *ngIf="secondaryAddresses.controls[i].hasError('required')"
            class="!pt-5"
          >
            Påkrævet
          </mat-error>
          <mat-error
            *ngIf="
              !secondaryAddresses.controls[i].hasError('required') &&
              secondaryAddresses.controls[i].hasError('requireMatch')
            "
            class="!pt-5"
            >Please select address from dropdown</mat-error
          >
        </mat-form-field>
      </div>

      <div class="max-w-[628px] w-full">
        <button
          class="flex items-center justify-start gap-[10px]"
          type="button"
          (click)="addSecondaryAddress()"
        >
          <icon-plus></icon-plus>
          <span class="font-semibold text-base leading-20 text-red uppercase"
            >Tilføj sekundær adresse</span
          >
        </button>
      </div>

      <div class="max-w-[628px] w-full flex gap-[14px] flex-wrap">
        <mat-form-field
          class="max-w-[307px] w-full relative | select-box color-747f81 color-091315 text-size-16 line-height-20 font-weight-600 padding-52"
          appearance="fill"
        >
          <mat-select
            placeholder="Enhedstype*"
            floatPlaceholder="never"
            formControlName="typeOfListing"
            panelClass="text-size-16 line-height-20 color-1b2022"
          >
            <mat-option *ngFor="let item of _listingTypes" [value]="item.value">
              {{ item.text }}
            </mat-option>
          </mat-select>
          <mat-error> Påkrævet </mat-error>
          <icon-caret-down
            class="absolute right-0 bottom-[6px]"
          ></icon-caret-down>
          <tooltip
            class="absolute right-9 bottom-[6px]"
            message="Her indtaster du hvilken type ejendom du vil sælge"
          ></tooltip>
        </mat-form-field>

        <mat-form-field class="max-w-[307px] w-full | pr26" appearance="fill">
          <mat-label>Samlet areal</mat-label>
          <input
            matInput
            numbersOnly
            maxlength="20"
            [group]="propertyDataForm"
            autocomplete="off"
            formControlName="totalLandArea"
            (input)="formatInput($event)"
          />
          <div
            matSuffix
            class="relative -right-4 bottom-2 p-[17px] bg-[#D1D8D9] text-[#747F81] rounded-tr-[12px] rounded-br-[12px]"
          >
            <span class="font-semibold text-base leading-5">
              m<sup>2</sup>
            </span>
          </div>
          <tooltip
            matSuffix
            class="relative right-4 bottom-[46px]"
            message="Her indtaster du det samlede areal af den ejendom, du ønsker at sælge"
          ></tooltip>
          <mat-error> Påkrævet </mat-error>
        </mat-form-field>
      </div>

      <div class="max-w-[628px] w-full flex gap-[14px] flex-wrap">
        <mat-form-field
          class="max-w-[307px] w-full relative | select-box color-747f81 color-091315 text-size-16 line-height-20 font-weight-600 padding-52"
          appearance="fill"
        >
          <mat-select
            placeholder="Vælg antal etager*"
            floatPlaceholder="never"
            formControlName="numberOfFloors"
            panelClass="text-size-16 line-height-20 color-1b2022"
          >
            <mat-option
              *ngFor="let floor of _numberofFloors"
              [value]="floor.value"
            >
              {{ floor.text }}
            </mat-option>
          </mat-select>
          <mat-error> Påkrævet </mat-error>
          <icon-caret-down
            class="absolute right-0 bottom-[6px]"
          ></icon-caret-down>
          <tooltip
            class="absolute right-9 bottom-[6px]"
            message="Indtast antal etager i ejendommen"
          ></tooltip>
        </mat-form-field>

        <mat-form-field
          class="max-w-[307px] w-full relative | select-box color-747f81 color-091315 text-size-16 line-height-20 font-weight-600 padding-52"
          appearance="fill"
        >
          <mat-select
            placeholder="Opført i år*"
            floatPlaceholder="never"
            formControlName="listedYear"
            panelClass="text-size-16 line-height-20 color-1b2022"
          >
            <mat-option *ngFor="let year of _years" [value]="year">
              {{ year }}
            </mat-option>
          </mat-select>
          <mat-error> Påkrævet </mat-error>
          <icon-caret-down
            class="absolute right-0 bottom-[6px]"
          ></icon-caret-down>
          <tooltip
            class="absolute right-9 bottom-[6px]"
            message="Ejendommens opførelsesår"
          ></tooltip>
        </mat-form-field>
      </div>
    </form>
  </div>
</div>

<hr class="border-t border-solid border-[#D1D8D9] mt-[52px] max-w-[1108px]" />

<div class="mt-[52px] max-w-[1108px]">
  <h3 class="font-semibold text-[32px] leading-10 text-black">2. Dine data</h3>
  <div class="mt-10 flex gap-[40px] justify-between items-start flex-wrap">
    <h5
      class="font-semibold text-xl leading-[25px] text-black max-w-[286px] w-full"
    >
      Oplysninger om sælgeren
    </h5>
    <form
      class="flex flex-col gap-3 max-w-[660px] w-full items-end"
      #secondForm="ngForm"
      [formGroup]="dineDataForm"
    >
      <mat-form-field
        class="max-w-[628px] w-full relative | select-box color-747f81 color-091315 text-size-16 line-height-20 font-weight-600 padding-52"
        appearance="fill"
      >
        <mat-select
          placeholder="Vælg din rolle*"
          floatPlaceholder="never"
          formControlName="listingCreatorRole"
          panelClass="text-size-16 line-height-20 color-1b2022"
        >
          <mat-option
            *ngFor="let item of _listingCreatorRoles"
            [value]="item.value"
          >
            {{ item.text }}
          </mat-option>
        </mat-select>
        <mat-error> Påkrævet </mat-error>
        <icon-caret-down
          class="absolute right-0 bottom-[6px]"
        ></icon-caret-down>
        <tooltip
          class="absolute right-9 bottom-[6px]"
          message="Vælge den rolle, du har i salget af ejendommen og herunder dine kontaktoplysninger"
        ></tooltip>
      </mat-form-field>

      <div class="max-w-[628px] w-full flex gap-[14px] flex-wrap">
        <mat-form-field class="max-w-[307px] w-full" appearance="fill">
          <mat-label>Navn</mat-label>
          <input matInput formControlName="sellerFullName" />
          <mat-error> Påkrævet </mat-error>
        </mat-form-field>

        <mat-form-field class="max-w-[307px] w-full" appearance="fill">
          <mat-label>Firmanavn</mat-label>
          <input matInput formControlName="firmName" />
        </mat-form-field>
      </div>

      <div class="max-w-[628px] w-full flex gap-[14px] flex-wrap">
        <mat-form-field class="max-w-[307px] w-full" appearance="fill">
          <mat-label>E-mail</mat-label>
          <input matInput formControlName="email" />
          <mat-error
            *ngIf="email.hasError('email') && !email.hasError('required')"
          >
            Indtast venligst en gyldig e-mailadresse
          </mat-error>
          <mat-error *ngIf="email.hasError('required')"> Påkrævet </mat-error>
        </mat-form-field>

        <div class="relative max-w-[307px] w-full">
          <ngx-intl-tel-input
            [cssClass]="secondForm.submitted ? 'submitted' : 'not_submitted'"
            [preferredCountries]="preferredCountries"
            [enableAutoCountrySelect]="true"
            [enablePlaceholder]="true"
            [searchCountryFlag]="true"
            [searchCountryField]="[
              SearchCountryField.Iso2,
              SearchCountryField.Name
            ]"
            [selectFirstCountry]="false"
            [selectedCountryISO]="phoneNumberCountryCode.value"
            [maxLength]="15"
            [phoneValidation]="true"
            [separateDialCode]="separateDialCode"
            [numberFormat]="PhoneNumberFormat.International"
            name="phoneNumber"
            formControlName="phoneNumber"
          >
          </ngx-intl-tel-input>

          <mat-error
            class="left-3 bottom-[-1px] absolute text-xs text-[#f44336] error-message"
            style="
              animation-name: fadeInDown;
              animation-timing-function: ease-out;
              animation-duration: 0.3s;
            "
            *ngIf="
              phoneNumber.hasError('required') &&
              (phoneNumber.touched || secondForm.submitted)
            "
          >
            Påkrævet
          </mat-error>
        </div>
      </div>

      <div class="mt-2 max-w-[628px] w-full">
        <mat-checkbox
          formControlName="saveAsInvoice"
          class="mb-6 save-as-invoice"
        >
          <span class="font-semibold text-base leading-5 text-black">
            Gem som fakturaoplysninger
          </span>
        </mat-checkbox>
      </div>
    </form>
  </div>
</div>

<hr class="border-t border-solid border-[#D1D8D9] mt-[52px] max-w-[1108px]" />

<form
  class="mt-[52px] max-w-[1108px]"
  #thirdForm="ngForm"
  [formGroup]="advisersDataForm"
>
  <h3 class="font-semibold text-[32px] leading-10 text-black">3. Rådgivere</h3>
  <p class="mt-3 text-[#747F81] leading-5">
    Her kan du oplyse dine rådgiveres oplysninger, men det er ikke obligatorisk
    og du kan derfor godt gå videre uden at afgive disse oplysninger.
  </p>

  <div
    formArrayName="advisers"
    *ngFor="let adviser of advisers.controls; index as i"
    class="mt-10 flex gap-[40px] justify-between items-start flex-wrap"
  >
    <div class="flex gap-6 items-center max-w-[286px] w-full">
      <h5 class="font-semibold text-xl leading-[25px] text-black">
        {{ adviser.get("title")?.value }}
      </h5>
      <span *ngIf="i > 1" class="flex items-center gap-3">
        <icon-pencil-line
          class="cursor-pointer"
          (click)="editAdvisorTitle(i)"
        ></icon-pencil-line>
        <icon-delete
          class="cursor-pointer"
          (click)="deleteAdvisor(i)"
        ></icon-delete>
      </span>
    </div>
    <div
      [formGroupName]="i"
      class="flex flex-col gap-3 max-w-[660px] w-full items-end"
    >
      <div class="max-w-[628px] w-full flex gap-[14px] flex-wrap">
        <mat-form-field class="max-w-[307px] w-full" appearance="fill">
          <mat-label>Firmanavn</mat-label>
          <input matInput formControlName="name" />
        </mat-form-field>

        <mat-form-field class="max-w-[307px] w-full" appearance="fill">
          <mat-label>Kontaktperson</mat-label>
          <input matInput formControlName="firmName" />
        </mat-form-field>
      </div>
      <div class="max-w-[628px] w-full flex gap-[14px] flex-wrap">
        <mat-form-field class="max-w-[307px] w-full" appearance="fill">
          <mat-label>E-mail</mat-label>
          <input matInput formControlName="email" />
          <mat-error
            *ngIf="
              adviser.get('email').hasError('email') &&
              !adviser.get('email').hasError('required')
            "
          >
            Indtast venligst en gyldig e-mailadresse
          </mat-error>
        </mat-form-field>

        <div class="relative max-w-[307px] w-full mb-[21.5px]">
          <ngx-intl-tel-input
            [cssClass]="secondForm.submitted ? 'submitted' : 'not_submitted'"
            [preferredCountries]="preferredCountries"
            [enableAutoCountrySelect]="true"
            [enablePlaceholder]="true"
            [searchCountryFlag]="true"
            [searchCountryField]="[
              SearchCountryField.Iso2,
              SearchCountryField.Name
            ]"
            [selectFirstCountry]="false"
            [selectedCountryISO]="adviser.get('phoneNumberCountryCode').value"
            [maxLength]="15"
            [phoneValidation]="true"
            [separateDialCode]="separateDialCode"
            [numberFormat]="PhoneNumberFormat.International"
            name="phoneNumberBank"
            formControlName="phoneNumber"
          >
          </ngx-intl-tel-input>
        </div>
      </div>

      <div
        *ngIf="i === advisers.controls.length - 1"
        class="max-w-[628px] w-full flex gap-[14px] flex-wrap"
      >
        <div class="max-w-[307px] w-full">
          <button
            class="flex items-center justify-start gap-[10px]"
            type="button"
            (click)="addAdvisor()"
          >
            <icon-plus></icon-plus>
            <span class="font-semibold text-base leading-20 text-red uppercase"
              >Tilføj anden rådgiver</span
            >
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="max-w-[628px] w-full ml-[480px]">
    <button
      class="flex items-center justify-start gap-[10px]"
      type="button"
      (click)="addAdvisor()"
    >
      <icon-plus></icon-plus>
      <span class="font-semibold text-base leading-20 text-red uppercase"
        >Tilføj anden rådgiver</span
      >
    </button>
  </div> -->
</form>

<hr class="border-t border-solid border-[#D1D8D9] mt-[52px]" />

<button
  class="max-w-[360px] w-full !my-8"
  [ngClass]="{
    'animate-shake':
      (firstForm.invalid && firstForm.submitted) ||
      (secondForm.invalid && secondForm.submitted)
  }"
  mat-flat-button
  color="primary"
  (click)="onNextClicked()"
>
  <div *ngIf="loading" class="flex items-center justify-center">
    <icon-loading-button class="animate-spin"></icon-loading-button>
  </div>
  <span *ngIf="!loading">GEM & FORTSÆT</span>
</button>

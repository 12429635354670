<div id="pageTop">
  <div class="flex justify-between gap-8">
    <div class="flex items-center gap-1">
      <icon-caret-left
        class="cursor-pointer"
        routerLink="/home/properties"
      ></icon-caret-left>
      <p class="font-[700] text-lg leading-[23px] text-black uppercase">
        {{ listing?.listingTitle }}
      </p>
    </div>
    <div class="flex items-center gap-4">
      <button
        class="rounded-[90px] bg-[#EFF6F6] hover:bg-[#D1D8D9] px-[12px] py-[6px] font-semibold text-normal leading-5 text-primary uppercase"
      >
        edit
      </button>
      <button
        class="rounded-[120px] bg-[#EFF6F6] hover:bg-[#D1D8D9] px-[6px] py-[4px]"
      >
        <icon-menu-horizontal
          class="flex items-center justify-center"
        ></icon-menu-horizontal>
      </button>
    </div>
  </div>

  <div class="flex flex-wrap items-end justify-between gap-8 mt-4 mb-7">
    <div>
      <p class="leading-5 text-black">
        {{ listing?.primaryAddress?.street }}&nbsp;{{
          listing?.primaryAddress?.buildingNumber
        }},
      </p>
      <p class="leading-5 text-black">
        {{ listing?.primaryAddress?.postalCode }}&nbsp;{{
          listing?.primaryAddress?.city
        }},
      </p>
    </div>
    <div>
      <p class="font-semibold leading-5 text-black">Status</p>
      <div
        class="py-1 px-2 rounded-[90px] mt-4 font-semibold text-sm leading-[18px]"
        [ngClass]="{
          'bg-[#FBF3E7] text-[#E97B2C]': listing?.state === 'Awaiting',
          'bg-[#E4FFF6] text-[#19BC8B]': listing?.state === 'Live',
          'bg-[#FBE8E7] text-[#EB4C44]': listing?.state === 'Rejected',
          'bg-[#EFF6F6] text-[#24535B]': listing?.state === 'InProgress'
        }"
      >
        {{
          listing?.state === "Awaiting"
            ? "Afventer godkendelse"
            : listing?.state === "Live"
            ? "Aktiv"
            : listing?.state === "Rejected"
            ? "Afslået"
            : listing?.state === "InProgress"
            ? "Igangværende"
            : "Error-fetching state"
        }}
      </div>
    </div>
    <div>
      <p class="font-semibold text-[32px] leading-10 text-black">
        {{
          listing?.desiredPrice != 0
            ? (listing?.desiredPrice | number : "1.0-2")
            : "-"
        }}
        kr.
      </p>
      <p class="leading-5 text-black mt-1">
        Areal
        {{
          listing?.totalLandArea != 0
            ? (listing?.totalLandArea | number : "1.0-2")
            : "-"
        }}
        m<sup>2</sup>
      </p>
    </div>
  </div>

  <hr
    class="expand-left border-t border-solid border-light-black-3 mr-[-40px]"
  />
</div>

<div class="expand-left flex flex-wrap">
  <div class="max-w-[14.75rem] w-full flex flex-col gap-7 mt-6">
    <p
      (click)="scroll(item.title)"
      *ngFor="let item of steps; index as i"
      class="px-4 font-semibold leading-5 text-light-black-2 cursor-pointer transition-colors duration-200"
      [ngClass]="{
        'border-l-2 border-solid border-red !text-black ': item.isFocused
      }"
      (click)="onPageSectionClicked(i)"
    >
      {{ item.title }}
    </p>
  </div>

  <div
    class="py-4 pl-6 border-l w-full border-solid border-light-black-3 flex-1"
  >
    <view-property-section-header
      (scrollToTop)="scrollToTop()"
      sectionTitle="Ejendommens"
    >
      <div class="mt-7">
        <view-property-title-description
          title="1. Ejendommens data"
          description="Oplysninger om ejendommen"
        ></view-property-title-description>
      </div>

      <div class="mt-7 flex flex-wrap items-start justify-start gap-7">
        <view-property-input-info
          class="w-full max-w-[360px]"
          label="Ejendoms- og projektnavn"
          [value]="listing?.listingTitle"
        ></view-property-input-info>
        <view-property-input-info
          class="w-full max-w-[360px]"
          label="Primær adresse*"
          value="{{ listing?.primaryAddress?.street }}
                {{ listing?.primaryAddress?.buildingNumber }},
                {{ listing?.primaryAddress?.postalCode }}
                {{ listing?.primaryAddress?.city }}"
        ></view-property-input-info>
      </div>

      <div
        *ngIf="listing?.secondaryAddress.length"
        class="mt-7 flex flex-wrap items-start justify-start gap-7 max-w-[748px]"
      >
        <view-property-input-info
          *ngFor="let address of listing?.secondaryAddress; let i = index"
          class="w-full max-w-[360px]"
          label="Sekundær adresse {{ i + 1 }}"
          value="{{ address?.street }}
          {{ address?.buildingNumber }},
          {{ address?.postalCode }}
          {{ address?.city }}"
        ></view-property-input-info>
      </div>

      <hr
        class="border-t border-solid border-light-black-3 -mr-10 -ml-6 mt-10"
      />

      <div class="mt-10">
        <view-property-title-description
          title="2. Dine data"
          description="Oplysninger om sælgeren"
        ></view-property-title-description>
      </div>

      <div class="mt-7 flex flex-wrap items-start justify-start gap-7">
        <view-property-input-info
          class="w-full max-w-[360px]"
          label="Vælg din rolle"
          value=" {{
            displaySelectText(_listingCreatorRoles, listing?.listingCreatorRole)
          }} "
        ></view-property-input-info>
        <view-property-input-info
          class="w-full max-w-[360px]"
          label="Email"
          value="{{ listing?.email }}"
        ></view-property-input-info>
      </div>

      <div class="mt-7 flex flex-wrap items-start justify-start gap-7">
        <view-property-input-info
          class="w-full max-w-[360px]"
          label="Navn"
          value="{{ listing?.sellerFullName }}"
        ></view-property-input-info>
        <view-property-input-info
          class="w-full max-w-[360px]"
          label="Firmanavn"
          value="{{ listing?.firmName }}"
        ></view-property-input-info>
      </div>

      <div class="mt-7">
        <view-property-input-info
          label="Phone"
          [value]="
            listing?.phoneNumber
              ? '(' +
                displaySelectText(
                  _phoneCodes,
                  listing?.phoneNumber?.split('-')[0]
                ) +
                ') ' +
                listing?.phoneNumber?.split('-')[1]
              : '-'
          "
        ></view-property-input-info>
      </div>

      <hr
        class="border-t border-solid border-light-black-3 -mr-10 -ml-6 mt-10"
      />

      <div *ngIf="listing?.advisers?.length" class="mt-10">
        <view-property-title-description
          title="3. Rådgivere"
          description="Her kan du oplyse dine rådgiveres oplysninger, men det er ikke obligatorisk og du kan derfor godt gå videre uden at afgive disse oplysninger."
        ></view-property-title-description>
      </div>
      <div *ngFor="let advisor of listing?.advisers">
        <p class="mt-7 font-semibold leading-5 text-black">
          {{ advisor?.title || "-" }}
        </p>
        <div class="mt-7 flex flex-wrap items-start justify-start gap-7">
          <view-property-input-info
            class="w-full max-w-[360px]"
            label="Firmanavn"
            value="{{ advisor?.firmName || '-' }}"
          ></view-property-input-info>
          <view-property-input-info
            class="w-full max-w-[360px]"
            label="Kontaktperson"
            value="{{ advisor?.name || '-' }}"
          ></view-property-input-info>
        </div>
        <div class="mt-7 flex flex-wrap items-start justify-start gap-7">
          <view-property-input-info
            class="w-full max-w-[360px]"
            label="Email"
            value="{{ advisor?.email || '-' }}"
          ></view-property-input-info>
          <view-property-input-info
            class="w-full max-w-[360px]"
            label="Phone"
            [value]="
              advisor?.phoneNumber
                ? '(' +
                  displaySelectText(
                    _phoneCodes,
                    advisor?.phoneNumber?.split('-')[0]
                  ) +
                  ') ' +
                  advisor?.phoneNumber?.split('-')[1]
                : '-'
            "
          ></view-property-input-info>
        </div>
      </div>
    </view-property-section-header>

    <div id="Lejeindtægter" class="mt-12">
      <view-property-section-header
        (scrollToTop)="scrollToTop()"
        sectionTitle="Lejeindtægter"
      >
        <div class="mt-7">
          <view-property-title-description
            title="Lejeindtægter"
            description="I nedenstående skal du oplyse lejeindtægt mv. for hver enkelt bolig-/erhvervslejemål i ejendommen."
          ></view-property-title-description>
        </div>
        <div class="!overflow-x-auto mt-7">
          <table class="units-table">
            <thead>
              <tr>
                <th>Adresse</th>
                <th>Antal rum/ værelser</th>
                <th>Areal (m<sup>2</sup>)</th>
                <th>Månedlig leje (kr.)</th>
                <th>Årlig leje (kr)</th>
                <th>Årlig leje pr. (m<sup>2</sup>) (kr.)</th>
                <th>Depositum og forudbetalt leje (kr)</th>
                <th>Husleje- principper</th>
                <th>Indflytnings- dato</th>
              </tr>
            </thead>
            <tbody *ngIf="listing?.units?.length">
              <tr *ngFor="let unit of listing?.units; index as i">
                <td>
                  <p class="text-xs leading-[15px] text-[#283F43] mb-1">
                    Type:&nbsp;{{
                      displaySelectText(_unitType, unit?.unitType)
                    }}
                  </p>
                  <p class="text-sm leading-[18px] text-black">
                    {{ unit?.primaryAddress?.street }}&nbsp;{{
                      unit?.primaryAddress?.buildingNumber
                    }}
                  </p>
                  <div
                    *ngIf="!unit?.notes"
                    class="mt-4 flex gap-1 items-center flex-nowrap"
                  >
                    <icon-text-align-right-twenty
                      strokeColor="#747F81"
                    ></icon-text-align-right-twenty>
                    <span class="text-xs leading-[15px] text-[#747F81]"
                      >No note</span
                    >
                  </div>
                  <div
                    *ngIf="unit?.notes"
                    class="mt-4 flex gap-1 items-center flex-nowrap"
                  >
                    <icon-text-align-right-twenty
                      strokeColor="#283F43"
                    ></icon-text-align-right-twenty>
                    <span class="text-xs leading-[15px] text-[#283F43]">{{
                      unit?.notes
                    }}</span>
                  </div>
                </td>
                <td>
                  <span class="text-sm leading-[18px] text-black">{{
                    displaySelectText(_numberOfRooms, unit?.numberOfRooms)
                  }}</span>
                </td>
                <td>
                  <span class="text-sm leading-[18px] text-black">{{
                    unit?.unitArea | number : "1.0-2"
                  }}</span>
                </td>
                <td>
                  <span class="text-sm leading-[18px] text-black">{{
                    unit?.monthlyRent | number : "1.0-2"
                  }}</span>
                </td>
                <td>
                  <span class="text-sm leading-[18px] text-black">{{
                    unit?.yearlyRent | number : "1.0-2"
                  }}</span>
                </td>
                <td>
                  <span class="text-sm leading-[18px] text-black">{{
                    unit?.yearlyRentPerSquareMeter | number : "1.0-2"
                  }}</span>
                </td>
                <td>
                  <span class="text-sm leading-[18px] text-black">{{
                    unit?.deposit | number : "1.0-2"
                  }}</span>
                </td>
                <td>
                  <span class="text-sm leading-[18px] text-black">
                    {{
                      displaySelectText(_rentAdjustment, unit?.rentAdjustment)
                    }}
                    <div *ngIf="unit?.predeterminedYearlyRentRise != 0">
                      &nbsp;+&nbsp;{{
                        unit?.predeterminedYearlyRentRise | number : "1.0-2"
                      }}
                    </div>
                  </span>
                </td>
                <td>
                  <p class="text-sm leading-[18px] text-black">
                    {{ unit?.moveInDate | date : "MM/y" }}
                  </p>
                  <p class="text-xs leading-[15px] text-[#283F43] mt-1">
                    Ops. varsel(mdr.):&nbsp;{{
                      displaySelectText(
                        _terminationDuration,
                        unit?.terminationDuration
                      )
                    }}
                  </p>
                </td>
              </tr>
            </tbody>
            <tfoot *ngIf="listing?.units?.length">
              <tr>
                <td colspan="2">I alt</td>
                <td>{{ totalUnitArea | number : "1.0-2" }}</td>
                <td>{{ totalMonthlyRent | number : "1.0-2" }}</td>
                <td>{{ totalMonthlyRent * 12 | number : "1.0-2" }}</td>
                <td>
                  {{
                    totalUnitArea == 0
                      ? 0
                      : ((totalMonthlyRent * 12) / totalUnitArea
                        | number : "1.0-2")
                  }}
                </td>
                <td>{{ totalDeposit || 0 | number : "1.0-2" }}</td>
                <td colspan="3"></td>
              </tr>

              <tr>
                <td colspan="2">Boligdelens andel</td>
                <td>{{ privateTotalUnitArea }}</td>
                <td>{{ privateTotalMonthlyRent | number : "1.0-2" }}</td>
                <td>{{ privateTotalMonthlyRent * 12 | number : "1.0-2" }}</td>
                <td>
                  {{
                    privateTotalUnitArea == 0
                      ? 0
                      : ((privateTotalMonthlyRent * 12) / privateTotalUnitArea
                        | number : "1.0-2")
                  }}
                </td>
                <td>{{ privateTotalDeposit | number : "1.0-2" }}</td>
                <td colspan="3"></td>
              </tr>

              <tr>
                <td colspan="2">Erhvervsdelens andel</td>
                <td>{{ businessTotalUnitArea | number : "1.0-2" }}</td>
                <td>{{ businessTotalMonthlyRent | number : "1.0-2" }}</td>
                <td>{{ businessTotalMonthlyRent * 12 | number : "1.0-2" }}</td>
                <td>
                  {{
                    businessTotalUnitArea == 0
                      ? 0
                      : ((businessTotalMonthlyRent * 12) / businessTotalUnitArea
                        | number : "1.0-2")
                  }}
                </td>
                <td>{{ businessTotalDeposit | number : "1.0-2" }}</td>
                <td colspan="3"></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </view-property-section-header>
    </div>

    <div id="Driftsudgifter" class="mt-12">
      <view-property-section-header
        (scrollToTop)="scrollToTop()"
        sectionTitle="Driftsudgifter"
      >
        <div class="mt-7">
          <view-property-title-description
            title="Driftsudgifter"
            description="Du bedes nu indtaste ejendommens driftsudgifter på årsbasis. Endvidere angiver du, hvorvidt udgiften er faktisk eller en skønnet udgift."
          ></view-property-title-description>
        </div>

        <div class="!overflow-x-auto mt-7">
          <table class="expense-table">
            <thead>
              <tr>
                <th>Udgifter</th>
                <th>Beløb i kr.</th>
                <th>Faktiske- eller anslåede udgifter</th>
                <th>kr. pr. m<sup>2</sup></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let expense of listing?.expenseItem">
                <td>
                  <div class="flex gap-2 items-center flex-nowrap">
                    <span class="text-sm leading-[18px] text-black">
                      {{ expense?.name }}
                    </span>
                  </div>
                </td>
                <td>
                  <span class="text-sm leading-[18px] text-black">
                    {{ expense?.tax | number : "1.0-2" }}
                  </span>
                </td>
                <td>
                  <span
                    class="text-sm leading-[18px] text-black py-[6px] px-3 border border-solid border-light-black-3 rounded-md"
                  >
                    {{ expense?.expenseType }}
                  </span>
                </td>
                <td>
                  <span class="text-sm leading-[18px] text-black">
                    {{ expense?.taxPerSquareMeter | number : "1.0-2" }}
                  </span>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr class="border-b border-dashed border-[#D1D8D9]">
                <td class="text-sm leading-[18px] font-semibold text-[#091315]">
                  Samlet driftsudgifter
                </td>
                <td>
                  <span class="text-sm leading-[18px] text-black">
                    {{
                      listing?.sumOfOperatingExpenses | number : "1.0-2" || "-"
                    }}
                  </span>
                </td>
                <td></td>
                <td>
                  <span class="text-sm leading-[18px] text-black">
                    {{
                      listing?.sumOfOperatingExpensesPerSqMeter
                        | number : "1.0-2" || "-"
                    }}
                  </span>
                </td>
              </tr>
              <tr>
                <td class="text-sm leading-[18px] text-[#091315]">
                  Samlet bruttolejenidtægter
                </td>
                <td>
                  <span class="text-sm leading-[18px] text-black">
                    {{
                      listing?.totalGrossRentalIncome | number : "1.0-2" || "-"
                    }}
                  </span>
                </td>
                <td></td>
                <td>
                  <span class="text-sm leading-[18px] text-black">
                    {{
                      listing?.totalGrossRentalIncomePerSqMeter
                        | number : "1.0-2" || "-"
                    }}
                  </span>
                </td>
              </tr>
              <tr>
                <td class="text-sm leading-[18px] text-[#091315]">
                  Samlet nettoleje
                </td>
                <td>
                  <span class="text-sm leading-[18px] text-black">
                    {{ listing?.totalNetRent | number : "1.0-2" || "-" }}
                  </span>
                </td>
                <td></td>
                <td>
                  <span class="text-sm leading-[18px] text-black">
                    {{
                      listing?.totalNetRentPerSqMeter | number : "1.0-2" || "-"
                    }}
                  </span>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>

        <hr
          class="border-t border-solid border-light-black-3 -mr-10 -ml-6 mt-10"
        />
        <div class="mt-10">
          <view-property-title-description
            title="Prissætning"
            description="Her indtastes forskellige oplysninger af relevans for prissætningen af ejendommen"
          ></view-property-title-description>
          <div class="flex items-center gap-[11px] mt-7">
            <p class="font-semibold leading-5 text-black">Afkast</p>
            <tooltip message="Message...."></tooltip>
          </div>
        </div>

        <div class="mt-7 flex flex-wrap items-start justify-start gap-7">
          <view-property-input-info
            class="w-full max-w-[280px]"
            type="one"
            label="Beregnet basisafkast"
            message=""
            [value]="
              listing?.baseReturn
                ? (listing?.baseReturn | number : '1.0-2') + '%'
                : '-'
            "
          ></view-property-input-info>
          <view-property-input-info
            class="w-full max-w-[280px]"
            type="one"
            label="Beliggenhedsfaktor"
            message="Message..."
            value="{{
              displaySelectText(_locationFactor, listing?.locationFactor) || '-'
            }}"
          ></view-property-input-info>
          <view-property-input-info
            class="w-full max-w-[280px]"
            type="one"
            label="Endeligt afkastkrav"
            message=""
            [value]="
              listing?.finalBaseReturn
                ? (listing?.finalBaseReturn | number : '1.0-2') + '%'
                : '-'
            "
          ></view-property-input-info>
        </div>

        <hr class="border-t border-solid border-light-black-3 mt-7" />

        <div class="flex items-center gap-[11px] mt-7">
          <p class="font-semibold leading-5 text-black">Ejendomsfaktorer</p>
          <tooltip
            message="Nedenfor er der mulighed for at angive faktorer, der har betydning for prissætningen"
          ></tooltip>
        </div>

        <div class="mt-7 flex flex-wrap items-start justify-start gap-7">
          <view-property-input-info
            class="w-full max-w-[280px]"
            type="one"
            label="Ejendommen er"
            message=""
            value="{{
              displaySelectText(_renovation, listing?.renovation) || '-'
            }}"
          ></view-property-input-info>
          <view-property-input-info
            class="w-full max-w-[280px]"
            type="one"
            label="Ejendommens stand"
            message="Angiv forhold omkring ejendommens stand"
            value="{{
              displaySelectText(
                _propertyCondition,
                listing?.propertyCondition
              ) || '-'
            }}"
          ></view-property-input-info>
          <div
            *ngIf="_climateImprovements.length"
            class="flex flex-col w-full max-w-[280px]"
          >
            <view-property-input-info
              type="one"
              label="Klimaforbedringer"
              message="Du markerer hvilke områder du har lavet klimaforbedringer på"
              value=""
            ></view-property-input-info>
            <div class="flex items-center flex-wrap gap-3">
              <span
                *ngFor="let improvement of _climateImprovements"
                class="px-2 py-[6px] text-sm leading-[18px] text-black rounded-[90px] bg-primary-light"
                >{{
                  displaySelectText(_climateImprovementsOptions, improvement)
                }}
              </span>
            </div>
          </div>
        </div>

        <hr
          *ngIf="listing?.financing?.length"
          class="border-t border-solid border-light-black-3 mt-7"
        />

        <div
          *ngIf="listing?.financing?.length"
          class="flex items-center gap-[11px] mt-7"
        >
          <p class="font-semibold leading-5 text-black">Finansieringsforhold</p>
          <tooltip message="Message..."></tooltip>
        </div>

        <div *ngIf="listing?.financing?.length" class="!overflow-x-auto mt-8">
          <table class="expense-table">
            <thead>
              <tr>
                <th>Lånetype</th>
                <th>Rentetype</th>
                <th>Låneudbyder</th>
                <th>Finansieringstype</th>
                <th>Rentesats p.a (%)</th>
                <th>Hovedstol (kr.)</th>
                <th>Restgæld(kr.)</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="last:!border-none"
                *ngFor="let finance of listing?.financing"
              >
                <td>
                  <p class="text-sm leading-[18px] text-black">
                    {{ displaySelectText(_loanType, finance.loanType) }}
                  </p>
                </td>
                <td>
                  <p class="text-sm leading-[18px] text-black">
                    {{
                      displaySelectText(
                        _interestRateType,
                        finance.interestRateType
                      )
                    }}
                  </p>
                </td>
                <td>
                  <p class="text-sm leading-[18px] text-black">
                    {{ finance.loanProvider }}
                  </p>
                </td>
                <td>
                  <p class="text-sm leading-[18px] text-black">
                    {{ finance.financingType }}
                  </p>
                </td>
                <td>
                  <p class="text-sm leading-[18px] text-black">
                    {{ finance.interestRate | number : "1.0-2" }}
                  </p>
                </td>
                <td>
                  <p class="text-sm leading-[18px] text-black">
                    {{ finance.totalDebt | number : "1.0-2" }}
                  </p>
                </td>
                <td>
                  <p class="text-sm leading-[18px] text-black">
                    {{ finance.remainingDebt | number : "1.0-2" }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <hr class="border-t border-solid border-light-black-3 mt-7" />

        <div class="flex items-center gap-[11px] mt-7">
          <p class="font-semibold leading-5 text-black">Pris</p>
          <tooltip message="Message..."></tooltip>
        </div>

        <div class="mt-7 flex flex-wrap items-start justify-start gap-7">
          <view-property-input-info
            class="w-full max-w-[280px]"
            type="one"
            label="Beregnet salgspris"
            message="Message..."
            [value]="
              listing?.estimatedSellingPrice
                ? (listing?.estimatedSellingPrice | number : '1.0-2') + ' kr.'
                : '-'
            "
          ></view-property-input-info>
          <view-property-input-info
            class="w-full max-w-[280px]"
            type="one"
            label="Ønsket pris på byggeret"
            message="Indtast beløb på en eventuel byggeret på ejendommen"
            [value]="
              listing?.desiredPrice
                ? (listing?.desiredPrice | number : '1.0-2') + ' kr.'
                : '-'
            "
          ></view-property-input-info>
          <view-property-input-info
            class="w-full max-w-[280px]"
            type="one"
            label="Lejepotentiale"
            message="Indtast dit eget estimeret lejepotentiale i kr. på års basis"
            [value]="
              listing?.rentPotential
                ? (listing?.rentPotential | number : '1.0-2') + ' kr.'
                : '-'
            "
          ></view-property-input-info>
        </div>
        <div class="mt-7 flex flex-wrap items-start justify-start gap-7">
          <view-property-input-info
            class="w-full max-w-[280px]"
            type="one"
            label="EJOS vurderede udbudspris"
            message="Message..."
            [value]="
              listing?.ejosIndicativeAssessedPrice
                ? (listing?.ejosIndicativeAssessedPrice | number : '1.0-2') +
                  ' kr.'
                : '-'
            "
          ></view-property-input-info>
          <view-property-input-info
            class="w-full max-w-[280px]"
            type="one"
            label="Ønsket pris udbudspris"
            message="Indtast din ønsket udbudspris"
            [value]="
              listing?.indicativePrice
                ? (listing?.indicativePrice | number : '1.0-2') + ' kr.'
                : '-'
            "
          ></view-property-input-info>
        </div>
      </view-property-section-header>
    </div>

    <div
      id="Beskrivelse"
      *ngIf="listingDescription || areaDescription"
      class="mt-12 editor-content"
    >
      <view-property-section-header
        (scrollToTop)="scrollToTop()"
        sectionTitle="Beskrivelse"
      >
        <div class="mt-7">
          <view-property-title-description
            title="Beskrivelse"
            description="Her kan du vælge mellem 2 forskellige templates og rette i dem eller skrive din helt egen ejendomsbeskrivelse"
          ></view-property-title-description>
        </div>

        <p
          *ngIf="listingDescription"
          class="font-semibold leading-5 text-black mt-7"
        >
          Ejendomsbeskrivelse
        </p>
        <div
          *ngIf="listingDescription"
          class="relative mt-6 h-[240px] w-full bg-light-black-4 rounded-xl py-4 pl-11 pr-4"
        >
          <div [innerHTML]="listingDescription"></div>
          <icon-text-align-right
            class="absolute top-4 left-4"
          ></icon-text-align-right>
        </div>

        <hr
          *ngIf="listingDescription"
          class="border-t border-solid border-[#D1D8D9] my-6"
        />

        <p *ngIf="areaDescription" class="font-semibold leading-5 text-black">
          Områdebeskrivelse
        </p>

        <div
          *ngIf="areaDescription"
          class="relative mt-6 h-[240px] w-full bg-light-black-4 rounded-xl py-4 pl-11 pr-4"
        >
          <div [innerHTML]="areaDescription"></div>
          <icon-text-align-right
            class="absolute top-4 left-4"
          ></icon-text-align-right>
        </div>
      </view-property-section-header>
    </div>

    <div id="Opsummering" class="mt-12">
      <view-property-section-header
        (scrollToTop)="scrollToTop()"
        sectionTitle="Opsummering"
      >
        <div class="mt-7">
          <view-property-title-description
            title="Opsummering"
            description="Her kan du læse en opsumering af hovedpunkterne i formidlingsaftale, salgspakke og salgsstrategi"
          ></view-property-title-description>
        </div>

        <p class="font-semibold leading-5 text-black mt-12">Valgt pakke</p>
        <div
          class="rounded-[20px] py-6 px-12 bg-[#EFF6F6] mt-5 flex flex-wrap gap-[76px] overflow-x-auto"
        >
          <div class="flex flex-col gap-4 flex-1">
            <p class="font-semibold leading-5 text-black">Ejendomspakken</p>
            <div
              class="flex gap-3"
              *ngFor="let item of listing?.packageBenefits"
            >
              <icon-check-green></icon-check-green>
              <p class="leading-5">{{ item }}</p>
            </div>
          </div>
          <div
            *ngIf="listing?.packageServiceAdditions?.length"
            class="flex flex-col gap-4 flex-1"
          >
            <p class="font-semibold leading-5 text-black">
              Tilvalg til pakken:
            </p>
            <div
              class="flex gap-3"
              *ngFor="let item of listing?.packageServiceAdditions"
            >
              <icon-check-green></icon-check-green>
              <p class="leading-5">{{ item?.serviceName }}</p>
            </div>
          </div>
        </div>

        <p class="font-semibold leading-5 text-black mt-12">Salgstrategi</p>
        <div
          class="rounded-[20px] py-6 px-12 bg-[#EFF6F6] mt-5 flex flex-wrap gap-[76px] overflow-x-auto"
        >
          <div class="flex flex-col gap-4 flex-1">
            <p class="font-semibold leading-5 text-black">
              Formidlingsstrategi
            </p>
            <div
              class="flex gap-3"
              *ngFor="let item of listing?.salesStrategyBenefits"
            >
              <icon-check-green></icon-check-green>
              <p class="leading-5">{{ item }}</p>
            </div>
          </div>
          <div
            *ngIf="listing?.strategyAdditions?.length"
            class="flex flex-col gap-4 flex-1"
          >
            <p class="font-semibold leading-5 text-black">
              Tilvalg til salgsstrategi:
            </p>
            <div
              class="flex gap-3"
              *ngFor="let item of listing?.strategyAdditions"
            >
              <icon-check-green></icon-check-green>
              <p class="leading-5">{{ item?.serviceName }}</p>
            </div>
          </div>
        </div>

        <p class="font-semibold leading-5 text-black mt-12">Honorar</p>
        <div class="rounded-[20px] py-6 px-12 bg-[#EFF6F6] mt-5">
          <div
            class="flex justify-between items-center gap-2 flex-wrap max-w-[1440px]"
          >
            <p class="leading-5 text-black max-w-[540px] w-full">
              Ejendomspakken
            </p>
            <p class="leading-5 text-black">
              {{ listing?.packagePrice | number : "1.0-2" }} kr.
            </p>
          </div>
          <div
            *ngFor="let item of listing?.packageServiceAdditions"
            class="flex justify-between items-center gap-2 flex-wrap max-w-[1440px] mt-6"
          >
            <p class="leading-5 text-black max-w-[540px] w-full">
              {{ item?.serviceName }}
            </p>
            <p class="leading-5 text-black">
              {{ item?.price | number : "1.0-2" }} kr.
            </p>
          </div>

          <div
            class="flex justify-between items-center gap-2 flex-wrap max-w-[1440px] mt-6"
          >
            <p class="leading-5 text-black max-w-[540px] w-full">
              Salgsstrategi - {{ listing?.salesStrategyName }}
            </p>
            <p class="leading-5 text-black">
              {{ listing?.saleStrategyPrice | number : "1.0-2" }} kr.
            </p>
          </div>
          <div
            *ngFor="let item of listing?.strategyAdditions"
            class="flex justify-between items-center gap-2 flex-wrap max-w-[1440px] mt-6"
          >
            <p class="leading-5 text-black max-w-[540px] w-full">
              {{ item?.serviceName }}
            </p>
            <p class="leading-5 text-black">
              {{ item?.price | number : "1.0-2" }} kr.
            </p>
          </div>
          <hr class="border-t border-solid border-[#D1D8D9] mt-4" />
          <div
            class="flex justify-between items-center gap-2 flex-wrap max-w-[1440px] mt-2"
          >
            <p class="font-semibold leading-5 text-black max-w-[540px] w-full">
              Honorar ved salg hos EJOS
            </p>
            <p class="font-semibold leading-5 text-black">
              {{ listing?.totalSumForPackagesAndStrategies | number : "1.0-2" }}
              kr.
            </p>
          </div>
        </div>
      </view-property-section-header>
    </div>

    <div id="Betaling" class="mt-12">
      <view-property-section-header
        (scrollToTop)="scrollToTop()"
        sectionTitle="Betaling"
      >
        <div class="mt-7">
          <view-property-title-description
            title="Betaling"
            description="Vælge faktureringsadresse og betalingsmetode"
          ></view-property-title-description>
          <div class="mt-7 flex flex-wrap items-start justify-start gap-7">
            <view-property-input-info
              class="w-full max-w-[360px]"
              label="Navn"
              value="{{ listing?.fullNameInvoice || '-' }}"
            ></view-property-input-info>
            <view-property-input-info
              class="w-full max-w-[360px]"
              label="Email*"
              value="{{ listing?.emailInvoice || '-' }}"
            ></view-property-input-info>
          </div>
          <div class="mt-7 flex flex-wrap items-start justify-start gap-7">
            <view-property-input-info
              class="w-full max-w-[360px]"
              label="Phone"
              [value]="
                listing?.phoneNumberInvoice
                  ? '(' +
                    displaySelectText(
                      _phoneCodes,
                      listing?.phoneNumberInvoice?.split('-')[0]
                    ) +
                    ') ' +
                    listing?.phoneNumberInvoice?.split('-')[1]
                  : '-'
              "
            ></view-property-input-info>
            <view-property-input-info
              class="w-full max-w-[360px]"
              label="Address*"
              value="{{ listing?.addressInvoice || '-' }}"
            ></view-property-input-info>
          </div>
          <div class="mt-7 flex flex-wrap items-start justify-start gap-7">
            <view-property-input-info
              class="w-full max-w-[360px]"
              label="Postnummer"
              value="{{ listing?.postalCodeInvoice || '-' }}"
            ></view-property-input-info>
            <view-property-input-info
              class="w-full max-w-[360px]"
              label="By*"
              value="{{ listing?.cityInvoice || '-' }}"
            ></view-property-input-info>
          </div>
        </div>
      </view-property-section-header>
    </div>
  </div>
</div>

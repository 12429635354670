import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'view-property-input-info',
  templateUrl: './input-info.component.html',
  styleUrls: ['./input-info.component.scss'],
})
export class InputInfoComponent implements OnInit {
  @Input() type = 'default';
  @Input() label = 'title';
  @Input() message = '';
  @Input() value = 'description...';

  constructor() {}

  ngOnInit(): void {}
}

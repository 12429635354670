<div
  class="snack-bars-container"
  *ngFor="let notification of notificationsLocale"
>
  <div
    [ngClass]="{
      'snack-bar': true,
      'snack-bar-negative': !notification.isPositive
    }"
  >
    <div class="snack-bar-header">
      <h6>{{ notification?.title }}</h6>
      <button (click)="onClick(notification?._id)">x</button>
    </div>
    <h5>{{ notification?.message }}</h5>
    <p>{{ notification.timeStamp | dateFormatter }}</p>
  </div>
</div>

//Expense Type

// Expesne Item
type ExpenseItem = {
  name: string;
  description: string;
  tax?: number;
  expenseType?: number;
  taxPerSquareMeter?: number;
};

// ExpenseItem
export type ExpenseItems = ExpenseItem[];

// ExpenseItemList

export const defaultExpenseList: ExpenseItems = [
  {
    name: 'Ejendomsskat',
    description: 'Årlig ejendomsskat og evt. grundskyld',
  },
  {
    name: 'Renovation',
    description: 'Årlig udgift til renovation og affaldshåndtering',
  },
  {
    name: 'Forsikring',
    description: 'Årlig ejendomsforsikring',
  },
  {
    name: 'Vicevært',
    description: 'Årlig udgift til vicevært og trappevask mv.',
  },
  {
    name: 'Udvendig vedligeholdelse',
    description:
      'Årlig udgift til udvendig vedligeholdelse, der afholdes af udlejer',
  },
  {
    name: 'Indvendig vedligeholdelse §18',
    description: 'Årlig udgift til §18 i det omfang ejendommen er omfattet',
  },
  {
    name: 'Indvendig vedligeholdelse §18b',
    description: 'Årlig udgift til §18b i det omfang ejendommen er omfattet',
  },
  {
    name: 'Indvendig vedligeholdelse',
    description:
      'Årlig udgift til indvendig vedligeholdelse som afholdes af udlejere',
  },
  {
    name: 'Fællesudgifter -  El, vand, varme mm.',
    description:
      'Årlig udgift til el, vand og varme på fællesafrealer mv. der ikke fordeles på lejerne',
  },
  {
    name: 'Grundejerforening/ fælleshus',
    description: 'Årlig udgift til grundejerforening og fælleshus mv.',
  },
];

export interface SelectObject {
  value: string;
  text: string;
}

export const LOCATION_FACTOR: SelectObject[] = [
  { value: 'Exclusive', text: 'Eksklusiv' },
  { value: 'Good', text: 'God' },
  { value: 'Basic', text: 'Basis' },
  { value: 'Secondary', text: 'Sekundær' },
];

export const PROPERTY_CONDITION: SelectObject[] = [
  { value: 'Very Good', text: 'Meget god' },
  { value: 'Good', text: 'God' },
  { value: 'Average', text: 'Middel' },
  { value: 'BelowAverage', text: 'Under middel' },
  { value: 'Renovated', text: 'Renoveres' },
];

export const RENOVATION_TYPE: SelectObject[] = [
  { value: 'PartialRenovation', text: 'Delvis nyrenoveret' },
  { value: 'CompleteRenovation', text: 'Helt nyrenoveret' },
  { value: 'NotRenovated', text: 'Ikke renoveret' },
];

export const CLIMATE_IMPROVEMENTS: SelectObject[] = [
  { value: 'windows', text: 'Vinduer' },
  { value: 'roof', text: 'Tag' },
  { value: 'doors', text: 'Døre' },
  { value: 'masonry', text: 'Murværk' },
  { value: 'others', text: 'Andet' },
];

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type OPTION = {
  text: string;
  id: number;
  isSelected: boolean;
};

export type OPTIONS = OPTION[];

@Component({
  selector: 'select-checkbox',
  templateUrl: './select-checkbox.component.html',
  styleUrls: ['./select-checkbox.component.scss'],
})
export class SelectCheckboxComponent implements OnInit {
  @Output() selectedValue: EventEmitter<any> = new EventEmitter<any>();

  @Input() mode: string = 'FORM';
  @Input() _id: string = '';
  @Input() title: string = '';
  @Input() placeholder: string = '';
  @Input() isErrorsEnabled: BehaviorSubject<boolean> | undefined;
  @Input() group: any;
  @Input() control: any;
  @Input() isInfo = false;
  @Input() isRequired: boolean = false;
  @Input() showLabel: boolean = true;
  @Input() options: OPTIONS = [
    {
      text: 'OPTION0',
      id: 0,
      isSelected: false,
    },
    {
      text: 'OPTION1',
      id: 1,
      isSelected: false,
    },
    {
      text: 'OPTION2',
      id: 2,
      isSelected: false,
    },
    {
      text: 'OPTION3',
      id: 3,
      isSelected: false,
    },
    {
      text: 'OPTION4',
      id: 4,
      isSelected: false,
    },
  ];

  selectedOptions = [];

  isErrorsEnabledLocale = false;
  constructor() {}

  ngOnInit(): void {
    if (this.isErrorsEnabled) {
      this.isErrorsEnabled.subscribe((newVal) => {
        this.isErrorsEnabledLocale = newVal;
      });
    }
    this.options = [
      ...this.options,
      { text: 'not selected', id: -1, isSelected: false },
    ];
  }

  changeSelectedValue(selected: any) {
    // set all selections to NULL
    this.options.map((item) => {
      if (item.text !== 'not selected') item.isSelected = false;
    });

    // Change selected value
    selected.map((selectedValue) => {
      selectedValue.isSelected = true;
      this.options[selectedValue.id].isSelected = true;
    });

    //emit value
    this.selectedValue.emit(this.options);
  }
}

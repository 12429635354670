import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BreadCrumbsComponent } from './bread-crumbs/bread-crumbs.component';
import { FormatKrownsPipe } from './format-krowns.pipe';
import { CarouselComponent } from './carousel/carousel.component';
import { StepsComponent } from './steps/steps.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { SharedModule as SharedModuleGlobal } from '../../shared/shared.module';
import { PrevNextButtonsComponent } from './prev-next-buttons/prev-next-buttons.component';
import { MenuIconsComponent } from './menu-icons/menu-icons.component';

@NgModule({
  declarations: [
    BreadCrumbsComponent,
    FormatKrownsPipe,
    CarouselComponent,
    StepsComponent,
    PageTitleComponent,
    PrevNextButtonsComponent,
    MenuIconsComponent,
  ],
  imports: [CommonModule, RouterModule, SharedModuleGlobal],
  exports: [
    BreadCrumbsComponent,
    FormatKrownsPipe,
    CarouselComponent,
    StepsComponent,
    PageTitleComponent,
    PrevNextButtonsComponent,
    MenuIconsComponent,
  ],
})
export class SharedModule {}

import _ from 'lodash';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { randomStringGenerator } from './utils';

export type NOTIFICATION = {
  _id: string;
  title: string;
  message: string;
  timeStamp: number;
  isPositive?: boolean;
  isAutoDissmissEnabled?: boolean;
  autoDismissAfter?: number;
};

export type NOTIFICATIONS = NOTIFICATION[];

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  notifications = new BehaviorSubject<NOTIFICATIONS>([]);
  timer: number | undefined;

  constructor() {}

  removeNotification(_id: string) {
    this.notifications.next(
      this.notifications.getValue().filter((el) => el._id !== _id)
    );
  }

  createNotification({
    _id = randomStringGenerator(),
    title = '',
    message = '',
    isPositive = true,
    isAutoDissmissEnabled = false,
    autoDismissAfter = 3000,
  }) {
    this.notifications.next(
      _.xorBy(
        this.notifications.getValue(),
        [
          {
            _id,
            title,
            message,
            isPositive,
            isAutoDissmissEnabled,
            autoDismissAfter,
            timeStamp: Date.now(),
          },
        ],
        '_id'
      )
    );
    if (isAutoDissmissEnabled) {
      this.timer = window.setTimeout(() => {
        this.removeNotification(_id);
        clearTimeout(this.timer);
      }, autoDismissAfter);
    }
  }
}

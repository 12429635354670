import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HomeRoutingModule } from './home-routing.module';
import { FrontPageComponent } from './front-page/front-page.component';
import { HomeComponent } from './home.component';
import { SharedModule } from './shared/shared.module';
import { SharedModule as SharedModuleGlobal } from '../shared/shared.module';
import { PropertyComponent } from './properties/property.component';
import { ProfileSettingsComponent } from './profile-settings/profile-settings.component';
import { AddPropertyDataComponent } from './properties/create-property/add-property-data/add-property-data.component';
import { AddRentalIncomeDataComponent } from './properties/create-property/add-rental-income-data/add-rental-income-data.component';
import { DescriptionDataComponent } from './properties/create-property/description-data/description-data.component';
import { PropertiesLandingPageComponent } from './properties/properties-landing-page/properties-landing-page.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AddExpensesComponent } from './properties/create-property/add-expenses/add-expenses.component';
import { NgxEditorModule } from 'ngx-editor';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { GalleryModule } from 'ng-gallery';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { EditAdvisorTitleDialogComponent } from './properties/create-property/dialogs/edit-advisor-title-dialog/edit-advisor-title-dialog.component';
import { AddEditUnitDialogComponent } from './properties/create-property/dialogs/add-edit-unit-dialog/add-edit-unit-dialog.component';
import { MatNativeDateModule } from '@angular/material/core';
import { EditExpenseDialogComponent } from './properties/create-property/dialogs/edit-expense-dialog/edit-expense-dialog.component';
import { AddEditFinancingDialogComponent } from './properties/create-property/dialogs/add-edit-financing-dialog/add-edit-financing-dialog.component';
import { PickDesriptionDialogComponent } from './properties/create-property/dialogs/pick-desription-dialog/pick-desription-dialog.component';
import { SalesStrategyDataComponent } from './properties/create-property/sales-strategy-data/sales-strategy-data.component';
import { AgreementDataComponent } from './properties/create-property/agreement-data/agreement-data.component';
import { SummaryDataComponent } from './properties/create-property/summary-data/summary-data.component';
import { UploadPicturesComponent } from './properties/create-property/upload-pictures/upload-pictures.component';
import { PaymentDataComponent } from './properties/create-property/payment-data/payment-data.component';
import { ViewPropertyComponent } from './properties/view-property/view-property.component';
import { SectionHeaderComponent } from './properties/view-property/section-header/section-header.component';
import { TitleDescriptionComponent } from './properties/view-property/title-description/title-description.component';
import { InputInfoComponent } from './properties/view-property/input-info/input-info.component';

@NgModule({
  declarations: [
    FrontPageComponent,
    HomeComponent,
    PropertyComponent,
    ProfileSettingsComponent,
    AddPropertyDataComponent,
    AddRentalIncomeDataComponent,
    DescriptionDataComponent,
    PropertiesLandingPageComponent,
    AddExpensesComponent,
    EditAdvisorTitleDialogComponent,
    AddEditUnitDialogComponent,
    EditExpenseDialogComponent,
    AddEditFinancingDialogComponent,
    PickDesriptionDialogComponent,
    SalesStrategyDataComponent,
    AgreementDataComponent,
    SummaryDataComponent,
    UploadPicturesComponent,
    PaymentDataComponent,
    ViewPropertyComponent,
    SectionHeaderComponent,
    TitleDescriptionComponent,
    InputInfoComponent,
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    SharedModule,
    SharedModuleGlobal,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    MatExpansionModule,
    NgxSpinnerModule,
    NgxEditorModule,
    MatSidenavModule,
    MatTooltipModule,
    MatMenuModule,
    MatButtonModule,
    MatTabsModule,
    MatInputModule,
    MatCheckboxModule,
    NgxIntlTelInputModule,
    GalleryModule,
    NgxSkeletonLoaderModule,
    MatPasswordStrengthModule,
    MatDatepickerModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatNativeDateModule,
  ],
  exports: [],
})
export class HomeModule {}

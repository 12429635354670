import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { STEPS } from '../shared/steps/steps.component';

@Component({
  selector: 'property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.scss'],
})
export class PropertyComponent implements OnInit {
  steps: STEPS = [
    // property data
    { title: 'Ejendommens', isActive: false, isComplete: false },
    { title: 'Lejeindtægter', isActive: false, isComplete: false },
    { title: 'Driftsudgifter', isActive: false, isComplete: false },
    { title: 'Beskrivelse', isActive: false, isComplete: false },
    { title: 'Salgsstrategi', isActive: false, isComplete: false },
    { title: 'Formidlingsaftale', isActive: false, isComplete: false },
    { title: 'Opsummering', isActive: false, isComplete: false },
    { title: 'Betaling', isActive: false, isComplete: false },
  ];

  currentStep = JSON.parse(sessionStorage.getItem('listing'))?.lastExecutedStep
    ? JSON.parse(sessionStorage.getItem('listing'))?.lastExecutedStep
    : 1;

  updateCurrentStep(currentStep: number) {
    for (let i = 0; i < this.steps.length; i++) {
      if (i === currentStep - 1) {
        this.steps[i].isActive = true;
        this.steps[i].isComplete = false;
      } else if (i < currentStep - 1) {
        this.steps[i].isActive = false;
        this.steps[i].isComplete = true;
      } else {
        this.steps[i].isActive = false;
        this.steps[i].isComplete = false;
      }
    }
  }

  constructor(private router: Router, private titleService: Title) {
    this.titleService.setTitle('Ejos - Opret ejendom');
  }

  ngOnInit(): void {
    this.updateCurrentStep(this.currentStep);
  }

  decrementCurrentStep() {
    if (this.currentStep > 1) {
      this.currentStep -= 1;
      this.updateCurrentStep(this.currentStep);
    } else this.router.navigate(['/home/properties']);
  }

  incrementCurrentStep() {
    if (this.currentStep < 8) {
      this.currentStep += 1;
      this.updateCurrentStep(this.currentStep);
    } else this.router.navigate(['/home/properties']);
  }
}

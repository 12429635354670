import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-lifebuoy-hub',
  templateUrl: './lifebuoy-hub.component.html',
  styleUrls: ['./lifebuoy-hub.component.scss'],
})
export class LifebuoyHubComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

<button
  [@shake]="isSubmit"
  (click)="handleClick()"
  [type]="isSubmit ? 'submit' : 'button'"
  class="ring-offset-1"
  [ngClass]="{
    'submit-button': true,
    'hover:ring-2 hover:ring-indigo-500': !isLoaderEnabledLocale,
    'submit-button-active': group?.status !== 'INVALID',
    'focus:ring-2 focus:ring-indigo-500': !isLoaderEnabledLocale
  }"
>
  <span *ngIf="!isLoaderEnabledLocale">{{ title }}</span>

  <div *ngIf="isLoaderEnabledLocale" class="flex items-center justify-center">
    <app-loader [mode]="mode" isSmall="true"></app-loader>
  </div>
</button>

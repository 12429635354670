import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'accordian',
  templateUrl: './accordian.component.html',
  styleUrls: ['./accordian.component.scss'],
})
export class AccordianComponent implements OnInit {
  @Input() accodinaMenuHeadder: string;
  @Input() isInfo: boolean = false;
  active: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  toogle() {
    this.active = !this.active;
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { GalleryItem, ImageItem } from 'ng-gallery';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'front-page',
  templateUrl: './front-page.component.html',
  styleUrls: ['./front-page.component.scss'],
})
export class FrontPageComponent implements OnInit, OnDestroy {
  firstName: string = 'User';
  welcomeMessage: string = 'Velkommen';
  welcomeMessageId: any;
  images: GalleryItem[];

  constructor(private titleService: Title) {
    this.titleService.setTitle('Ejos - Forside');
    this.firstName = JSON.parse(localStorage.getItem('user')).firstName;
    this.updateWelcomeMessage();
  }

  ngOnInit(): void {
    const seconds = 60;
    this.welcomeMessageId = setInterval(() => {
      this.updateWelcomeMessage();
    }, seconds * 1000);

    this.images = [
      new ImageItem({
        src: '/assets/home/listing/test-image.png',
      }),
      new ImageItem({
        src: '/assets/home/listing/test-image.png',
      }),
      new ImageItem({
        src: '/assets/home/listing/test-image.png',
      }),
      // ... more items
    ];
  }

  ngOnDestroy(): void {
    clearInterval(this.welcomeMessageId);
  }

  updateWelcomeMessage() {
    let current_time = new Date().getHours();
    this.welcomeMessage =
      current_time >= 6 && current_time < 9
        ? 'Godmorgen'
        : current_time >= 9 && current_time < 11
        ? 'Godformiddag'
        : current_time >= 11 && current_time < 13
        ? 'Godmiddag'
        : current_time >= 11 && current_time < 13
        ? 'Godmiddag'
        : current_time >= 13 && current_time < 18
        ? 'Godeftermiddag'
        : current_time >= 18 || current_time < 6
        ? 'Godaften'
        : 'Velkommen';
  }
}

import { Address } from 'src/app/_models/address.model';
import { Listing } from 'src/app/_models/property.model';

interface SelectObject {
  value: string;
  text: string;
}

export type Sorting =
  | 'ByCreationDateAsc'
  | 'ByCreationDateDesc'
  | 'ByModifiedDateAsc'
  | 'ByModifiedDateDesc'
  | 'ByPriceAsc'
  | 'ByPriceDesc'
  // | 'ByTotalAreaAsc'
  // | 'ByTotalAreaDesc'
  | 'ByTotalLandAreaAsc'
  | 'ByTotalLandAreaDesc';

export const SORTING_OPTIONS: SelectObject[] = [
  { value: 'ByCreationDateAsc', text: 'Oprettelsesdato (Gammel-Ny)' },
  { value: 'ByCreationDateDesc', text: 'Oprettelsesdato (Ny-Gammel)' },
  { value: 'ByModifiedDateAsc', text: 'Ændretdato (Gammel-Ny)' },
  { value: 'ByModifiedDateDesc', text: 'Ændretdato (Ny-Gammel)' },
  { value: 'ByPriceAsc', text: 'Pris (Lav-høj)' },
  { value: 'ByPriceDesc', text: 'Pris (høj-Lav)' },
  // { value: 'ByTotalAreaAsc', text: 'Areal (Lav-høj)' },
  // { value: 'ByTotalAreaDesc', text: 'Areal (høj-Lav)' },
  { value: 'ByTotalLandAreaAsc', text: 'Grundareal (Lav-høj)' },
  { value: 'ByTotalLandAreaDesc', text: 'Grundareal (høj-Lav)' },
];

export interface LiveListingRequest {
  postalCode?: string[];
  typeOfListings?: Listing['typeOfListing'];
  minPrice?: number;
  maxPrice?: number;
  minTotalLandArea?: number;
  maxTotalLandArea?: number;
  minTotalUnitArea?: number;
  maxTotalUnitArea?: number;
  minListedYear?: number;
  maxListedYear?: number;
  sorting?: Sorting;
  startIndex?: number;
  indexSize?: number;
}

export interface OwnedListing {
  listingId?: 'string';
  listingTitle?: 'string';
  address?: Address;
  typeOfListing?: Listing['typeOfListing'];
  totalLandArea?: number;
  totalAreaOfUnits?: number;
  listedYear?: number;
  price?: number;
  pricePerSquareMeter?: number;
  finalBaseReturn?: number;
  state?: Listing['state'];
  step?: number;
  isFavorite?: boolean;
}

export interface LiveListings {
  id?: string;
  listingTitle?: string;
  primaryAddress?: Address;
  typeOfListing?: Listing['typeOfListing'];
  totalLandArea?: number;
  totalAreaOfUnits?: number;
  listedYear?: number;
  desiredPrice?: number;
  desiredPricePerSquareMeter?: number;
  finalBaseReturn?: number;
  state?: Listing['state'];
  isFavorite?: boolean;
}

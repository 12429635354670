<mat-tab-group
  class="pt-1 my-profile-tab"
  animationDuration="500ms"
  [dynamicHeight]="true"
>
  <mat-tab label="Personlige oplysninger">
    <ngx-spinner
      bdColor="rgba(0, 0, 0, 0.8)"
      size="medium"
      color="#fff"
      type="timer"
      [fullScreen]="true"
      ><p style="color: white">Loading...</p></ngx-spinner
    >
    <form
      class="pt-6 mx-[1px]"
      #firstForm="ngForm"
      [formGroup]="profileDataForm"
      (ngSubmit)="updateUserInfo()"
    >
      <h4 class="font-semibold text-2xl text-black">Personlige oplysninger</h4>
      <div class="flex items-start justify-start flex-wrap gap-4 mt-6">
        <mat-form-field class="max-w-[360px] w-full" appearance="fill">
          <mat-label>Fornavn</mat-label>

          <input
            matInput
            formControlName="firstName"
            [formGroup]="profileDataForm"
          />
          <mat-error *ngIf="firstName.hasError('required')">
            Påkrævet
          </mat-error>
        </mat-form-field>

        <mat-form-field class="max-w-[360px] w-full" appearance="fill">
          <mat-label>Efternavn</mat-label>

          <input matInput formControlName="lastName" />
          <mat-error *ngIf="lastName.hasError('required')">
            Påkrævet
          </mat-error>
        </mat-form-field>
      </div>

      <div class="flex items-start justify-start flex-wrap gap-4 mt-6">
        <mat-form-field class="max-w-[360px] w-full" appearance="fill">
          <mat-label>Firmanavn</mat-label>

          <input matInput formControlName="cvr" />
        </mat-form-field>

        <mat-form-field class="max-w-[360px] w-full" appearance="fill">
          <mat-label>E-mail</mat-label>

          <input matInput formControlName="email" readonly tabindex="-1" />
          <mat-error
            *ngIf="
              email.hasError('email') &&
              !email.hasError('required') &&
              !email.hasError('existingEmail') &&
              firstForm.submitted
            "
          >
            Indtast venligst en gyldig e-mailadresse
          </mat-error>
          <mat-error *ngIf="email.hasError('required')"> Påkrævet </mat-error>
          <mat-error *ngIf="email.hasError('existingEmail')">
            E-mailen er allerede registreret
          </mat-error>
        </mat-form-field>
      </div>

      <mat-form-field class="max-w-[736px] w-full mt-6" appearance="fill">
        <mat-label>Adresse</mat-label>
        <input matInput formControlName="address" />
        <mat-error *ngIf="address.hasError('required')"> Påkrævet </mat-error>
      </mat-form-field>

      <div class="flex items-start justify-start flex-wrap gap-4 mt-6">
        <div class="relative max-w-[360px] w-full">
          <ngx-intl-tel-input
            [cssClass]="firstForm.submitted ? 'submitted' : 'not_submitted'"
            [preferredCountries]="preferredCountries"
            [enableAutoCountrySelect]="true"
            [enablePlaceholder]="true"
            [searchCountryFlag]="true"
            [searchCountryField]="[
              SearchCountryField.Iso2,
              SearchCountryField.Name
            ]"
            [selectFirstCountry]="false"
            [selectedCountryISO]="CountryISO"
            [maxLength]="15"
            [phoneValidation]="true"
            [separateDialCode]="separateDialCode"
            [numberFormat]="PhoneNumberFormat.International"
            name="phoneNumber"
            formControlName="phoneNumber"
          >
          </ngx-intl-tel-input>

          <mat-error
            class="left-3 bottom-[-21.5px] absolute text-xs text-[#f44336] error-message"
            style="
              animation-name: fadeInDown;
              animation-timing-function: ease-out;
              animation-duration: 0.3s;
            "
            *ngIf="
              phoneNumber.hasError('required') &&
              (phoneNumber.touched || firstForm.submitted)
            "
          >
            Påkrævet
          </mat-error>
        </div>

        <mat-form-field class="w-full max-w-[171.5px]" appearance="fill">
          <mat-label>Postnummer</mat-label>

          <input
            matInput
            minlength="4"
            maxlength="4"
            pattern="\d*"
            numbersOnly
            formControlName="postalcode"
            [group]="profileDataForm"
          />
          <mat-error *ngIf="postalcode.hasError('required')">
            Påkrævet
          </mat-error>
        </mat-form-field>

        <mat-form-field class="w-full max-w-[171.5px]" appearance="fill">
          <mat-label>By</mat-label>

          <input matInput formControlName="city" />
          <mat-error *ngIf="city.hasError('required')"> Påkrævet </mat-error>
        </mat-form-field>
      </div>

      <button
        type="submit"
        [disabled]="!hasProfileFormChanged"
        class="w-full max-w-[232px] !mt-6"
        [ngClass]="{
          'animate-shake': firstForm.invalid && firstForm.submitted
        }"
        mat-flat-button
        color="primary"
      >
        <div
          *ngIf="isProfileFormLoaderEnabled.value"
          class="flex items-center justify-center"
        >
          <icon-loading-button class="animate-spin"></icon-loading-button>
        </div>

        <div
          *ngIf="!isProfileFormLoaderEnabled.value"
          class="flex items-center justify-center gap-[10px]"
        >
          <icon-save></icon-save>
          <span class="uppercase !text-white">Gem ændringer </span>
        </div>
      </button>
    </form>

    <hr class="border-t border-solid border-[#D1D8D9] my-8" />

    <form
      class="mx-[1px]"
      #secondForm="ngForm"
      [formGroup]="passwordResetForm"
      (ngSubmit)="changePassword()"
    >
      <h4 class="font-semibold text-2xl text-black">Skift adgangskode</h4>
      <!-- <p class="text-sm leading-[18px] text-black mt-3">
        Krav til adgangskode: Mindst 8 karakterer med en kombination af et stort
        og et lille bogstav, et tal og et specialtegn.
      </p> -->

      <mat-form-field class="w-full max-w-[360px] mt-6" appearance="fill">
        <mat-label>Nuværende adgangskode</mat-label>
        <input
          formControlName="oldPassword"
          matInput
          [type]="_hideOldPassword ? 'password' : 'text'"
          autocomplete="off"
        />
        <icon-password-hide
          *ngIf="_hideOldPassword"
          matSuffix
          (click)="_hideOldPassword = !_hideOldPassword"
        ></icon-password-hide>

        <icon-password-visible
          *ngIf="!_hideOldPassword"
          matSuffix
          (click)="_hideOldPassword = !_hideOldPassword"
        ></icon-password-visible>
        <mat-error
          *ngIf="oldPassword.invalid && !oldPassword.hasError('required')"
        >
          Indtast venligst en gyldig adgangskode
        </mat-error>
        <mat-error *ngIf="oldPassword.hasError('required')">
          Påkrævet
        </mat-error>
      </mat-form-field>

      <div class="flex gap-4 flex-wrap mt-6">
        <div class="w-full max-w-[360px]">
          <mat-form-field class="w-full" appearance="fill">
            <mat-label>Ny adgangskode</mat-label>
            <input
              formControlName="newPassword"
              matInput
              [type]="_hideNewPassword ? 'password' : 'text'"
              autocomplete="off"
            />
            <icon-password-hide
              *ngIf="_hideNewPassword"
              matSuffix
              (click)="_hideNewPassword = !_hideNewPassword"
            ></icon-password-hide>
            <icon-password-visible
              *ngIf="!_hideNewPassword"
              matSuffix
              (click)="_hideNewPassword = !_hideNewPassword"
            ></icon-password-visible>
            <mat-error
              *ngIf="newPassword.invalid && !newPassword.hasError('required')"
            >
              Indtast venligst en gyldig adgangskode
            </mat-error>
            <mat-error *ngIf="newPassword.hasError('required')">
              Påkrævet
            </mat-error>
          </mat-form-field>

          <div class="mb-3 mt-1 flex justify-between">
            <p class="text-sm font-semibold">
              {{
                _passwordStrength === 100
                  ? "Strong password"
                  : _passwordStrength === 75
                  ? "Medium password"
                  : _passwordStrength === 50
                  ? "Medium password"
                  : _passwordStrength === 25
                  ? "Weak password"
                  : "Password strength"
              }}
            </p>
          </div>

          <mat-password-strength
            #passwordComponent
            [enableDigitRule]="false"
            [password]="newPassword.value"
            (onStrengthChanged)="onStrengthChanged($event)"
          >
          </mat-password-strength>

          <div class="flex items-center gap-3 mt-3 flex-wrap">
            <div
              class="flex items-center justify-center gap-[9px] px-3 py-2 rounded-[90px] text-sm leading-4 bg-[#EFF6F6] text-[#8A9C9F]"
            >
              <icon-check
                class="flex-shrink-0"
                *ngIf="
                  newPassword?.hasError('requiresUppercase') ||
                  newPassword?.hasError('requiresLowercase') ||
                  newPassword?.hasError('required')
                "
                strokeColor="#8A9C9F"
              ></icon-check>
              <icon-check
                class="flex-shrink-0"
                *ngIf="
                  !newPassword?.hasError('requiresUppercase') &&
                  !newPassword?.hasError('requiresLowercase') &&
                  !newPassword?.hasError('required')
                "
                strokeColor="#0E7254"
              ></icon-check>
              <span
                [ngClass]="{
                  'bg-[#E4FFF6] text-[#0E7254] transition-all':
                    !newPassword?.hasError('requiresUppercase') &&
                    !newPassword?.hasError('requiresLowercase') &&
                    !newPassword?.hasError('required')
                }"
                >Et stort og et lille bogstav</span
              >
            </div>

            <div
              class="flex items-center justify-center gap-[9px] px-3 py-2 rounded-[90px] text-sm leading-4 bg-[#EFF6F6] text-[#8A9C9F]"
            >
              <icon-check
                class="flex-shrink-0"
                *ngIf="
                  newPassword?.hasError('requiresDigit') ||
                  newPassword?.hasError('required')
                "
                strokeColor="#8A9C9F"
              ></icon-check>
              <icon-check
                class="flex-shrink-0"
                *ngIf="
                  !newPassword?.hasError('requiresDigit') &&
                  !newPassword?.hasError('required')
                "
                strokeColor="#0E7254"
              ></icon-check>
              <span
                class="flex-1"
                [ngClass]="{
                  'bg-[#E4FFF6] text-[#0E7254] transition-all':
                    !newPassword?.hasError('requiresDigit') &&
                    !newPassword?.hasError('required')
                }"
                >Et tal</span
              >
            </div>
          </div>

          <div class="flex items-center gap-3 my-3 flex-wrap">
            <div
              class="flex items-center justify-center gap-[9px] px-3 py-2 rounded-[90px] text-sm leading-4 bg-[#EFF6F6] text-[#8A9C9F]"
            >
              <icon-check
                class="flex-shrink-0"
                *ngIf="
                  newPassword?.hasError('minlength') ||
                  newPassword?.hasError('required')
                "
                strokeColor="#8A9C9F"
              ></icon-check>
              <icon-check
                class="flex-shrink-0"
                *ngIf="
                  !newPassword?.hasError('minlength') &&
                  !newPassword?.hasError('required')
                "
                strokeColor="#0E7254"
              ></icon-check>
              <span
                [ngClass]="{
                  'bg-[#E4FFF6] text-[#0E7254] transition-all':
                    !newPassword?.hasError('minlength') &&
                    !newPassword?.hasError('required')
                }"
                >Mindst 8 tegn</span
              >
            </div>

            <div
              class="flex items-center justify-center gap-[9px] px-3 py-2 rounded-[90px] text-sm leading-4 bg-[#EFF6F6] text-[#8A9C9F]"
            >
              <icon-check
                class="flex-shrink-0"
                *ngIf="
                  newPassword?.hasError('requiresSpecialChars') ||
                  newPassword?.hasError('required')
                "
                strokeColor="#8A9C9F"
              ></icon-check>
              <icon-check
                class="flex-shrink-0"
                *ngIf="
                  !newPassword?.hasError('requiresSpecialChars') &&
                  !newPassword?.hasError('required')
                "
                strokeColor="#0E7254"
              ></icon-check>
              <span
                class="flex-1"
                [ngClass]="{
                  'bg-[#E4FFF6] text-[#0E7254] transition-all':
                    !newPassword?.hasError('requiresSpecialChars') &&
                    !newPassword?.hasError('required')
                }"
                >Et specialtegn</span
              >
            </div>
          </div>
        </div>

        <mat-form-field class="w-full max-w-[360px]" appearance="fill">
          <mat-label>Gentag ny adgangskode*</mat-label>
          <input
            formControlName="confirmPassword"
            matInput
            [type]="_hideConfirmPassword ? 'password' : 'text'"
            autocomplete="off"
          />
          <icon-password-hide
            *ngIf="_hideConfirmPassword"
            matSuffix
            (click)="_hideConfirmPassword = !_hideConfirmPassword"
          ></icon-password-hide>
          <icon-password-visible
            *ngIf="!_hideConfirmPassword"
            matSuffix
            (click)="_hideConfirmPassword = !_hideConfirmPassword"
          ></icon-password-visible>
          <mat-error
            *ngIf="
              confirmPassword.invalid && !confirmPassword.hasError('required')
            "
          >
            Adgangskoden passer ikke
          </mat-error>
          <mat-error *ngIf="confirmPassword.hasError('required')">
            Påkrævet
          </mat-error>
        </mat-form-field>
      </div>

      <button
        type="submit"
        [disabled]="!hasPasswordFormChanged"
        class="w-full max-w-[232px] !mt-6 !mb-4"
        [ngClass]="{
          'animate-shake': secondForm.invalid && secondForm.submitted
        }"
        mat-flat-button
        color="primary"
      >
        <div
          *ngIf="isPasswordFormLoaderEnabled.value"
          class="flex items-center justify-center"
        >
          <icon-loading-button class="animate-spin"></icon-loading-button>
        </div>

        <div
          *ngIf="!isPasswordFormLoaderEnabled.value"
          class="flex items-center justify-center gap-[10px]"
        >
          <icon-save></icon-save>
          <span class="uppercase">Gem ændringer </span>
        </div>
      </button>
    </form>
  </mat-tab>
  <mat-tab label="Notifikationer">
    <p class="color-black text-sm leading-[18px] mt-6 mb-[42px]">
      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
      Lorem Ipsum has been the industry's standard dummy text ever since the
      1500s.
    </p>
    <div class="!overflow-x-auto">
      <table>
        <thead>
          <tr>
            <th>Notifikationer</th>
            <th>Push-meddelelser</th>
            <th>E-mail</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let notification of notitficationsData">
            <td>
              <p class="font-semibold text-base leading-5 text-black mb-2">
                {{ notification?.text }}
              </p>
              <p class="text-sm leading-[18px] text-black">
                {{ notification?.body }}
              </p>
            </td>
            <td>
              <mat-checkbox
                color="primary"
                [checked]="notification?.pushEnabled"
                (change)="onChange($event.checked, notification, 'PUSH')"
              >
              </mat-checkbox>
            </td>
            <td>
              <mat-checkbox
                color="primary"
                [checked]="notification?.emailEnabled"
                (change)="onChange($event.checked, notification, 'EMAIL')"
              >
              </mat-checkbox>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex justify-end">
      <button
        class="w-full max-w-[232px] !mt-6 !mb-4"
        mat-flat-button
        color="primary"
        (click)="upsertUserSettings()"
      >
        <div *ngIf="false" class="flex items-center justify-center">
          <icon-loading-button class="animate-spin"></icon-loading-button>
        </div>

        <div *ngIf="true" class="flex items-center justify-center gap-[10px]">
          <icon-save></icon-save>
          <span class="uppercase">Gem ændringer </span>
        </div>
      </button>
    </div>
  </mat-tab>
</mat-tab-group>

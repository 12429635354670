import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'prev-next-buttons',
  templateUrl: './prev-next-buttons.component.html',
  styleUrls: ['./prev-next-buttons.component.scss'],
})
export class PrevNextButtonsComponent implements OnInit {
  @Input() group: any;
  @Input() title: string = '';
  @Input() isLoaderEnabled: BehaviorSubject<boolean> | undefined;
  @Input() onClick = () => {};
  @Input() isSubmit = true;
  @Input() isActive = false;

  @Output() prevClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() nextClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  isLoaderEnabledLocale = false;

  constructor() {}

  ngOnInit(): void {
    if (this.isLoaderEnabled) {
      this.isLoaderEnabled.subscribe((newVal) => {
        this.isLoaderEnabledLocale = newVal;
      });
    }
  }

  handleClick = () => {
    this.onClick();
  };

  onPrevClicked() {
    this.prevClick.emit(true);
  }
  onNextClicked() {
    this.nextClick.emit(true);
  }
}

import { Component, OnDestroy, OnInit, Sanitizer } from '@angular/core';
import { Router } from '@angular/router';
import { Listing } from 'src/app/_models/property.model';
import sanitizeHtml from 'sanitize-html';
import { PHONE_CODES } from 'src/shared/text-input/helper';
import {
  CLIMATE_IMPROVEMENTS,
  LOCATION_FACTOR,
  PROPERTY_CONDITION,
  RENOVATION_TYPE,
} from '../create-property/add-expenses/add-expenses.util';
import { LISTING_CREATOR_ROLES } from '../create-property/add-property-data/add-property-data.util';
import {
  INTEREST_RATE_TYPE,
  LOAN_TYPE,
} from '../create-property/dialogs/add-edit-financing-dialog/add-edit-financing-dialog.util';
import {
  NUMBER_OF_ROOMS,
  RENT_ADJUSTMENT,
  TERMINATION_DURATION,
  UNIT_TYPES,
} from '../create-property/dialogs/add-edit-unit-dialog/add-edit-unit-dialog.util';
import { SANITIZE_HTML_OPTIONS, STEPS } from './view-property.component.util';
import { DomSanitizer, Title } from '@angular/platform-browser';
@Component({
  selector: 'view-property',
  templateUrl: './view-property.component.html',
  styleUrls: ['./view-property.component.scss'],
})
export class ViewPropertyComponent implements OnInit, OnDestroy {
  listing: Listing;
  _listingCreatorRoles = JSON.parse(JSON.stringify(LISTING_CREATOR_ROLES));
  _phoneCodes = JSON.parse(JSON.stringify(PHONE_CODES));
  _unitType = JSON.parse(JSON.stringify(UNIT_TYPES));
  _rentAdjustment = JSON.parse(JSON.stringify(RENT_ADJUSTMENT));
  _numberOfRooms = JSON.parse(JSON.stringify(NUMBER_OF_ROOMS));
  _terminationDuration = JSON.parse(JSON.stringify(TERMINATION_DURATION));
  _locationFactor = JSON.parse(JSON.stringify(LOCATION_FACTOR));
  _renovation = JSON.parse(JSON.stringify(RENOVATION_TYPE));
  _propertyCondition = JSON.parse(JSON.stringify(PROPERTY_CONDITION));
  _climateImprovementsOptions = JSON.parse(
    JSON.stringify(CLIMATE_IMPROVEMENTS)
  );
  _climateImprovements: string[] = [];
  _loanType = JSON.parse(JSON.stringify(LOAN_TYPE));
  _interestRateType = JSON.parse(JSON.stringify(INTEREST_RATE_TYPE));

  totalUnitArea: number = 0;
  totalMonthlyRent: number = 0;
  totalDeposit: number = 0;

  businessTotalUnitArea: number = 0;
  businessTotalMonthlyRent: number = 0;
  businessTotalDeposit: number = 0;

  privateTotalUnitArea: number = 0;
  privateTotalMonthlyRent: number = 0;
  privateTotalDeposit: number = 0;

  steps = STEPS;
  listingDescription: any;
  areaDescription: any;

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private titleService: Title
  ) {
    this.listing = JSON.parse(sessionStorage.getItem('listingView'));
    if (!this.listing?.id) {
      this.router.navigateByUrl('home/properties');
    }
    this.titleService.setTitle('Ejos - ' + this.listing?.listingTitle);
  }

  ngOnInit(): void {
    this.calculateUnitTableFooterData();

    this.listing.expenseItem = this.listing.expenseItem.filter(
      (value) => value.tax !== 0
    );

    this.listing?.climateImprovements?.windows
      ? this._climateImprovements.push('window')
      : this.listing?.climateImprovements?.roof
      ? this._climateImprovements.push('roof')
      : this.listing?.climateImprovements?.doors
      ? this._climateImprovements.push('doors')
      : this.listing?.climateImprovements?.masonry
      ? this._climateImprovements.push('masonry')
      : this.listing?.climateImprovements?.others &&
        this.listing?.climateImprovements?.manualInput
      ? this._climateImprovements.push(
          this.listing?.climateImprovements?.manualInput
        )
      : '';

    this.listingDescription = this.sanitizer.bypassSecurityTrustHtml(
      sanitizeHtml(this.listing?.listingDescription, SANITIZE_HTML_OPTIONS)
    );
    this.areaDescription = this.sanitizer.bypassSecurityTrustHtml(
      sanitizeHtml(this.listing?.areaDescription, SANITIZE_HTML_OPTIONS)
    );
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem('listingView');
    this.titleService.setTitle('Ejos');
  }

  // Calcudate table footer data to be added
  calculateUnitTableFooterData() {
    // Total share
    this.totalUnitArea = this.listing?.units.reduce((accumulator, unit) => {
      return accumulator + unit.unitArea;
    }, 0);

    this.totalMonthlyRent = this.listing?.units.reduce((accumulator, unit) => {
      return accumulator + unit.monthlyRent;
    }, 0);

    this.totalDeposit = this.listing?.units.reduce((accumulator, unit) => {
      return accumulator + unit.deposit;
    }, 0);

    // Business housing share
    this.businessTotalUnitArea = this.listing?.units
      .filter((x) => x.tenant != null && x.tenant === 'Business')
      .reduce((accumulator, unit) => {
        return accumulator + unit.unitArea;
      }, 0);

    this.businessTotalMonthlyRent = this.listing?.units
      .filter((x) => x.tenant != null && x?.tenant === 'Business')
      .reduce((accumulator, unit) => {
        return accumulator + unit.monthlyRent;
      }, 0);

    this.businessTotalDeposit = this.listing?.units
      .filter((x) => x.tenant != null && x?.tenant === 'Business')
      .reduce((accumulator, unit) => {
        return accumulator + unit.deposit;
      }, 0);

    // Private housing share
    this.privateTotalUnitArea = this.listing?.units
      .filter((x) => x.tenant != null && x?.tenant === 'Private')
      .reduce((accumulator, unit) => {
        return accumulator + unit.unitArea;
      }, 0);

    this.privateTotalMonthlyRent = this.listing?.units
      .filter((x) => x.tenant != null && x?.tenant === 'Private')
      .reduce((accumulator, unit) => {
        return accumulator + unit.monthlyRent;
      }, 0);

    this.privateTotalDeposit = this.listing?.units
      .filter((x) => x.tenant != null && x?.tenant === 'Private')
      .reduce((accumulator, unit) => {
        return accumulator + unit.deposit;
      }, 0);
  }

  onPageSectionClicked(i: number) {
    this.steps.map((step, index) => {
      index === i ? (step.isFocused = true) : (step.isFocused = false);
    });
  }

  displaySelectText(list, value) {
    if (list && value) return list.find((list) => list.value === value).text;
    else return value;
  }

  scroll(section: string) {
    const el = document.getElementById(section);
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  scrollToTop() {
    document.querySelector('.mat-sidenav-content').scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
}

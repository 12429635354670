<div class="flex justify-between items-center mb-5">
  <p
    class="text-black text-2xl font-semibold leading-[30px] !m-0 wmin1460:min-w-[1060px]"
  >
    Lånegruppe
  </p>
  <icon-close-dialog
    class="cursor-pointer"
    mat-dialog-close
  ></icon-close-dialog>
</div>
<mat-dialog-content class="rounded-lg !overflow-x-hidden">
  <form
    #firstForm="ngForm"
    id="financingForm"
    [formGroup]="financingForm"
    (ngSubmit)="addFinance()"
    class="flex flex-col gap-10 mt-5 mb-4"
  >
    <div class="flex gap-6 flex-wrap">
      <mat-form-field
        class="max-w-[332px] w-full relative | select-box no-error color-747f81 color-091315 text-size-16 line-height-20 font-weight-600 padding-52"
        appearance="fill"
      >
        <mat-select
          placeholder="Vælg lånetype*"
          floatPlaceholder="never"
          panelClass="text-size-16 line-height-20 color-1b2022"
          formControlName="loanType"
        >
          <mat-option *ngFor="let item of _loanType" [value]="item.value">
            {{ item.text }}
          </mat-option>
        </mat-select>
        <mat-error class="!pt-5"> Påkrævet </mat-error>
        <icon-caret-down
          class="absolute right-0 bottom-[6px]"
        ></icon-caret-down>
        <tooltip
          class="absolute right-9 bottom-[6px]"
          message="Lånets type"
        ></tooltip>
      </mat-form-field>

      <mat-form-field
        class="max-w-[332px] w-full relative | select-box no-error color-747f81 color-091315 text-size-16 line-height-20 font-weight-600 padding-52"
        appearance="fill"
      >
        <mat-select
          placeholder="Vælg rentetype*"
          floatPlaceholder="never"
          panelClass="text-size-16 line-height-20 color-1b2022"
          formControlName="interestRateType"
        >
          <mat-option
            *ngFor="let item of _interestRateType"
            [value]="item.value"
          >
            {{ item.text }}
          </mat-option>
        </mat-select>
        <mat-error class="!pt-5"> Påkrævet </mat-error>
        <icon-caret-down
          class="absolute right-0 bottom-[6px]"
        ></icon-caret-down>
        <tooltip
          class="absolute right-9 bottom-[6px]"
          message="Vælge fast eller variabel rente"
        ></tooltip>
      </mat-form-field>

      <mat-form-field
        class="max-w-[332px] w-full relative | unit center no-error"
        appearance="fill"
      >
        <input
          class="!mt-0"
          matInput
          autocomplete="off"
          placeholder="Skriv navnet på låneudbyder*"
          formControlName="loanProvider"
        />
        <mat-error class="!pt-5"> Påkrævet </mat-error>
        <tooltip
          class="absolute right-0 bottom-[6px]"
          message="Skrive navn på finansieringskilde"
        ></tooltip>
      </mat-form-field>
    </div>

    <div class="flex gap-6 flex-wrap">
      <mat-form-field
        class="max-w-[332px] w-full relative | unit center no-error"
        appearance="fill"
      >
        <input
          class="!mt-0"
          matInput
          autocomplete="off"
          placeholder="Bank eller 3. parts finansering*"
          formControlName="financingType"
        />
        <mat-error class="!pt-5"> Påkrævet </mat-error>
        <tooltip
          class="absolute right-0 bottom-[6px]"
          message="Typen af långiver"
        ></tooltip>
      </mat-form-field>

      <mat-form-field
        class="max-w-[332px] w-full relative | unit-tooltip center no-error"
        appearance="fill"
      >
        <span *ngIf="!interestRate?.value" matPrefix>Rentesats p.a.*</span>
        <input
          class="!text-right !mt-0"
          matInput
          numbersWithDecimalOnly
          maxlength="20"
          [group]="financingForm"
          autocomplete="off"
          placeholder="0,0"
          formControlName="interestRate"
          (input)="formatInput($event)"
        />
        <div
          class="absolute right-[-1rem] bottom-[-10px] p-[17px] bg-[#D1D8D9] text-[#747F81] rounded-tr-[12px] rounded-br-[12px]"
        >
          <span class="font-semibold text-base leading-5"> % </span>
        </div>
        <tooltip
          class="absolute right-12 bottom-[6px]"
          message="Lånets rentesats p.a"
        ></tooltip>
        <mat-error class="!pt-5"> Påkrævet </mat-error>
      </mat-form-field>

      <mat-form-field
        class="max-w-[332px] w-full relative | unit-tooltip center no-error"
        appearance="fill"
      >
        <span *ngIf="!totalDebt?.value" matPrefix>Hovedstol*</span>
        <input
          class="!text-right !mt-0"
          matInput
          numbersWithDecimalOnly
          maxlength="20"
          [group]="financingForm"
          autocomplete="off"
          placeholder="0,0"
          formControlName="totalDebt"
          (input)="formatInput($event)"
        />
        <div
          class="absolute right-[-1rem] bottom-[-10px] p-[17px] bg-[#D1D8D9] text-[#747F81] rounded-tr-[12px] rounded-br-[12px]"
        >
          <span class="font-semibold text-base leading-5"> kr. </span>
        </div>
        <tooltip
          class="absolute right-12 bottom-[6px]"
          message="Lånets hovedsto"
        ></tooltip>
        <mat-error class="!pt-5"> Påkrævet </mat-error>
      </mat-form-field>
    </div>

    <div class="flex gap-6 flex-wrap">
      <mat-form-field
        class="max-w-[332px] w-full relative | unit-tooltip center no-error"
        appearance="fill"
      >
        <span *ngIf="!remainingDebt?.value" matPrefix>Restgæld*</span>
        <input
          class="!text-right !mt-0"
          numbersWithDecimalOnly
          maxlength="20"
          [group]="financingForm"
          matInput
          autocomplete="off"
          placeholder="0,0"
          formControlName="remainingDebt"
          (input)="formatInput($event)"
        />
        <div
          class="absolute right-[-1rem] bottom-[-10px] p-[17px] bg-[#D1D8D9] text-[#747F81] rounded-tr-[12px] rounded-br-[12px]"
        >
          <span class="font-semibold text-base leading-5"> kr. </span>
        </div>
        <tooltip
          class="absolute right-12 bottom-[6px]"
          message="Lånets aktuelle restgæld"
        ></tooltip>
        <mat-error class="!pt-5"> Påkrævet </mat-error>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<button
  mat-flat-button
  type="submit"
  form="financingForm"
  color="primary"
  class="uppercase w-full max-w-[360px] !mt-5"
  [ngClass]="{
    'animate-shake': firstForm.invalid && firstForm.submitted
  }"
>
  Gem og tilføje enheden
</button>

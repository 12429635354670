export interface SelectObject {
  value: string;
  text: string;
}

export const LISTING_TYPES: SelectObject[] = [
  { value: 'RentalProperty', text: 'Udlejningsejendom' },
  { value: 'ProjectAndTurnKey', text: 'Projekt og turn-key' },
  { value: 'RawAndAgriculturalLand', text: 'Råjord - og landbrugsjord' },
  { value: 'GroundsAndBuildings', text: 'Grunde og byggeret' },
];

export const NUMBER_OF_FLOORS: SelectObject[] = [
  { value: 'One', text: '1' },
  { value: 'Two', text: '2' },
  { value: 'Three', text: '3' },
  { value: 'Four', text: '4' },
  { value: 'Five', text: '5' },
  { value: 'MoreThanFive', text: '5+' },
];

export const LISTING_CREATOR_ROLES: SelectObject[] = [
  { value: 'Owner', text: 'Ejer' },
  { value: 'Director', text: 'Direktør' },
  { value: 'AssistantOrRepresentative', text: 'Assistent og Ejeres' },
  { value: 'Lawyer', text: 'Advokat' },
  { value: 'Accountant', text: 'Revisor' },
  { value: 'Banker', text: 'Bankrådgiver' },
  { value: 'Administrator', text: 'Administrator' },
  { value: 'Developer', text: 'Udvikler' },
  { value: 'EstateAgent', text: 'Mægler' },
];

export const defaultAdvisersList: string[] = ['Advokat', 'Bankforbindelse'];

import {
  Directive,
  HostListener,
  HostBinding,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';

@Directive({
  selector: '[fileDragDrop]',
})
export class FileDragNDropDirective {
  // @Input() private allowed_extensions : Array<string> = ['png', 'jpg', 'bmp'];
  //@Output() private filesInvalidEmiter : EventEmitter<File[]> = new EventEmitter();
  @Output() private filesChangeEmiter: EventEmitter<File[]> =
    new EventEmitter();
  @Input() defaultColor: string;
  @HostBinding('style.background') private background;

  constructor() {}

  @HostListener('dragover', ['$event']) public onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = 'rgba(0, 0, 0, 0.7)';
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = this.defaultColor;
  }

  @HostListener('drop', ['$event']) public onDrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = this.defaultColor;
    let files = evt.dataTransfer.files;
    let valid_files: Array<File> = files;
    this.filesChangeEmiter.emit(valid_files);
  }
}

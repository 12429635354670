import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from 'src/user/login/login.component';
import { SignupComponent } from 'src/user/signup/signup.component';
import { ForgotPasswordComponent } from './forgot-passwd/forgot-passwd.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { UserComponent } from './user.component';

const routes: Routes = [
  {
    path: 'user',
    component: UserComponent,
    children: [
      {
        path: 'login',
        pathMatch: 'full',
        component: LoginComponent
      },
      {
        path: 'sign-up',
        pathMatch: 'full',
        component: SignupComponent
      },
      {
        path: 'forgot-password',
        pathMatch: 'full',
        component: ForgotPasswordComponent
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent
      },
      {
        path: '**',
        redirectTo: '/user/login'
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule {}

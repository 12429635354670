import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { GlobalConstants } from '../global-constants';
import { Listing } from 'src/app/_models/property.model';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private apiService: ApiService) {}

  getListingPackage = (
    typeOfListing: Listing['typeOfListing'],
    price: number
  ) =>
    this.apiService.get(
      `${GlobalConstants.paymentURL}package/type/${typeOfListing}/price/${price}`
    );

  getAllSalesStrategy = () =>
    this.apiService.get(`${GlobalConstants.paymentURL}salesstrategy`);

  getSavingParameter = (
    typeOfListing: Listing['typeOfListing'],
    price: number
  ) =>
    this.apiService.get(
      `${GlobalConstants.paymentURL}savings/type/${typeOfListing}/price/${price}`
    );
}

import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { GlobalConstants } from '../global-constants';

@Injectable({
  providedIn: 'root',
})
export class ListingService {
  constructor(private apiService: ApiService) {}

  createOrUpdateProperty = (body: any) =>
    this.apiService.post(`${GlobalConstants.listURL}property`, body);

  getLiveListings = (body: any) =>
    this.apiService.post(`${GlobalConstants.listURL}property/live`, body);

  searchProperyAddress = (searchText: string) =>
    this.apiService.get(
      `${GlobalConstants.listURL}search/autocomplete?searchText=${searchText}`
    );

  searchDescriptions = (postalCode: string) =>
    this.apiService.get(
      `${GlobalConstants.listURL}areadescription/postalcode/${postalCode}`
    );

  getAgreementVersion = () =>
    this.apiService.get(`${GlobalConstants.listURL}saleterms/latest`);

  getBaseReturnByPostalcode = (postalCode: string) =>
    this.apiService.get(
      `${GlobalConstants.listURL}basereturns/postalcode/${postalCode}`
    );
  getOwnedListingsById = (id: string) =>
    this.apiService.get(`${GlobalConstants.listURL}userlistings/owned/${id}`);

  getFavoriteListingsById = (id: string) =>
    this.apiService.get(
      `${GlobalConstants.listURL}userlistings/favorite/${id}`
    );

  addListingToFavorites = (body: any) =>
    this.apiService.put(`${GlobalConstants.listURL}userlistings/add`, body);

  removeListingFromFavorites = (body: any) =>
    this.apiService.put(`${GlobalConstants.listURL}userlistings/remove`, body);

  deleteListingByListingId(listingId: string) {
    return this.apiService.delete(
      `${GlobalConstants.listURL}property?propertyId=${listingId}`
    );
  }

  getListingByListingId = (listingId: string) =>
    this.apiService.get(`${GlobalConstants.listURL}property/${listingId}`);

  getAllPostalcodes = () =>
    this.apiService.get(`${GlobalConstants.listURL}basereturns`);
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-notification-topnavbar',
  templateUrl: './notification-topnavbar.component.html',
  styleUrls: ['./notification-topnavbar.component.scss'],
})
export class NotificationTopnavbarComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

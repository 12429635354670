<svg
  width="32"
  height="32"
  viewBox="0 0 32 32"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    opacity="0.2"
    d="M17.3375 21.9375L14.9375 28.45C14.8664 28.6414 14.7385 28.8064 14.5709 28.923C14.4033 29.0395 14.2041 29.102 14 29.102C13.7958 29.102 13.5966 29.0395 13.429 28.923C13.2614 28.8064 13.1335 28.6414 13.0625 28.45L10.6625 21.9375C10.6118 21.8 10.5319 21.6752 10.4283 21.5716C10.3248 21.468 10.1999 21.3881 10.0625 21.3375L3.54996 18.9375C3.35859 18.8665 3.19353 18.7386 3.07698 18.571C2.96042 18.4034 2.89795 18.2041 2.89795 18C2.89795 17.7959 2.96042 17.5966 3.07698 17.429C3.19353 17.2614 3.35859 17.1335 3.54996 17.0625L10.0625 14.6625C10.1999 14.6118 10.3248 14.532 10.4283 14.4284C10.5319 14.3248 10.6118 14.2 10.6625 14.0625L13.0625 7.54999C13.1335 7.35862 13.2614 7.19356 13.429 7.07701C13.5966 6.96045 13.7958 6.89798 14 6.89798C14.2041 6.89798 14.4033 6.96045 14.5709 7.07701C14.7385 7.19356 14.8664 7.35862 14.9375 7.54999L17.3375 14.0625C17.3881 14.2 17.468 14.3248 17.5716 14.4284C17.6752 14.532 17.8 14.6118 17.9375 14.6625L24.45 17.0625C24.6413 17.1335 24.8064 17.2614 24.9229 17.429C25.0395 17.5966 25.102 17.7959 25.102 18C25.102 18.2041 25.0395 18.4034 24.9229 18.571C24.8064 18.7386 24.6413 18.8665 24.45 18.9375L17.9375 21.3375C17.8 21.3881 17.6752 21.468 17.5716 21.5716C17.468 21.6752 17.3881 21.8 17.3375 21.9375Z"
    fill="#24535B"
  />
  <path
    d="M17.3375 21.9375L14.9375 28.45C14.8664 28.6414 14.7385 28.8064 14.5709 28.923C14.4033 29.0395 14.2041 29.102 14 29.102C13.7958 29.102 13.5966 29.0395 13.429 28.923C13.2614 28.8064 13.1335 28.6414 13.0625 28.45L10.6625 21.9375C10.6118 21.8 10.5319 21.6752 10.4283 21.5716C10.3248 21.468 10.1999 21.3881 10.0625 21.3375L3.54996 18.9375C3.35859 18.8665 3.19353 18.7386 3.07698 18.571C2.96042 18.4034 2.89795 18.2041 2.89795 18C2.89795 17.7959 2.96042 17.5966 3.07698 17.429C3.19353 17.2614 3.35859 17.1335 3.54996 17.0625L10.0625 14.6625C10.1999 14.6118 10.3248 14.532 10.4283 14.4284C10.5319 14.3248 10.6118 14.2 10.6625 14.0625L13.0625 7.54999C13.1335 7.35862 13.2614 7.19356 13.429 7.07701C13.5966 6.96045 13.7958 6.89798 14 6.89798C14.2041 6.89798 14.4033 6.96045 14.5709 7.07701C14.7385 7.19356 14.8664 7.35862 14.9375 7.54999L17.3375 14.0625C17.3881 14.2 17.468 14.3248 17.5716 14.4284C17.6752 14.532 17.8 14.6118 17.9375 14.6625L24.45 17.0625C24.6413 17.1335 24.8064 17.2614 24.9229 17.429C25.0395 17.5966 25.102 17.7959 25.102 18C25.102 18.2041 25.0395 18.4034 24.9229 18.571C24.8064 18.7386 24.6413 18.8665 24.45 18.9375L17.9375 21.3375C17.8 21.3881 17.6752 21.468 17.5716 21.5716C17.468 21.6752 17.3881 21.8 17.3375 21.9375V21.9375Z"
    stroke="#24535B"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M22 2V8"
    stroke="#24535B"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M25 5H19"
    stroke="#24535B"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M28 9V13"
    stroke="#24535B"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M30 11H26"
    stroke="#24535B"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

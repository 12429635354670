import { DecimalPipe } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  fadeInOnEnterAnimation,
  fadeOutOnLeaveAnimation,
} from 'angular-animations';
import { indexOf } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { PHONE_CODES } from './helper';

@Component({
  selector: 'text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  animations: [
    fadeInOnEnterAnimation({ duration: 1000 }),
    fadeOutOnLeaveAnimation({ duration: 500 }),
  ],
})
export class TextInputComponent implements OnInit {
  @Input() showPasswordValidator = false;
  @Input() mode = 'FORM';
  @Input() _id: string = '';
  @Input() title: string = '';
  @Input() placeholder: string = '';
  @Input() isPhone = false;
  @Input() isCountryCode = false;
  @Input() isMetric = false;
  @Input() unit = '';
  @Input() isPassword = false;
  @Input() isNumber = false;
  @Input() isDate = false;
  @Input() isEmail = false;
  @Input() isInfo = false;
  @Input() isAlphaNumeric = false;
  @Input() isPositiveInteger = false;
  @Input() isErrorsEnabled: BehaviorSubject<boolean> | undefined;
  @Input() isRequired: boolean = false;
  @Input() group: any;
  @Input() control: FormControl;
  @Input() autoComplete: string = 'off';
  @Input() isCalculated = false;
  @Input() showLabel = true;
  @Input() value: any;
  @Input() isTextArea: boolean = false;
  @Input() textAreaHeight: any;
  @Input() isPostalcode = false;

  _phoneCodes = PHONE_CODES;

  @Output() outputValue: EventEmitter<number> = new EventEmitter<number>();

  _showError = false;

  isPasswordVisible = false;
  isErrorsEnabledLocale = false;

  _countryCode = '';
  _tempPhone;

  constructor(private decimal: DecimalPipe) {}

  ngOnInit(): void {
    if (this.isErrorsEnabled) {
      this.isErrorsEnabled.subscribe((newVal) => {
        this.isErrorsEnabledLocale = newVal;
      });
    }
  }

  onChangeCountryCode(code: string) {
    console.log(code, typeof this.control.value);

    // this.control.value.concat(code);
    code.concat(this.control.value);
    this.control.setValue(code);
    console.log(this.control.value);

    // if (this.control.value.indexOf('-') !== -1) {
    //   this.control.value = this.control.value.split('-')[1];
    // }
    // const tempPhone = `${value}-${this.control.value}`;
    // this.control.value = tempPhone;
  }

  onPasswordVisibilityToggle() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  emitInputValue(enteredValue: number) {
    this.outputValue.emit(enteredValue);
  }

  formatNumber(event: any) {
    if (this.isNumber || this.isPositiveInteger) {
      const parts = event.target.value.toString().split(',');
      parts[0] = this.decimal.transform(
        parts[0].replace(/\./g, '').replace(/,/g, ''),
        '1.0-2',
        'da-DK'
      );
      event.target.value = parts.join(',');
    }
  }

  onFocus() {
    this._showError = true;
  }

  onBlur() {
    this._showError = false;
  }
}

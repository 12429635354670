<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    opacity="0.2"
    d="M14.8782 15.3277L12.7032 21.3089C12.6499 21.4525 12.554 21.5763 12.4283 21.6637C12.3026 21.7511 12.1531 21.7979 12 21.7979C11.8469 21.7979 11.6975 21.7511 11.5718 21.6637C11.4461 21.5763 11.3502 21.4525 11.2969 21.3089L9.12192 15.3277C9.08393 15.2246 9.02403 15.131 8.94634 15.0533C8.86864 14.9756 8.77502 14.9157 8.67192 14.8777L2.69067 12.7027C2.54714 12.6494 2.42335 12.5535 2.33593 12.4278C2.24852 12.3021 2.20166 12.1527 2.20166 11.9996C2.20166 11.8465 2.24852 11.697 2.33593 11.5713C2.42335 11.4456 2.54714 11.3497 2.69067 11.2964L8.67192 9.12143C8.77502 9.08345 8.86864 9.02354 8.94634 8.94585C9.02403 8.86816 9.08393 8.77453 9.12192 8.67143L11.2969 2.69018C11.3502 2.54665 11.4461 2.42286 11.5718 2.33544C11.6975 2.24803 11.8469 2.20117 12 2.20117C12.1531 2.20117 12.3026 2.24803 12.4283 2.33544C12.554 2.42286 12.6499 2.54665 12.7032 2.69018L14.8782 8.67143C14.9162 8.77453 14.9761 8.86816 15.0538 8.94585C15.1314 9.02354 15.2251 9.08345 15.3282 9.12143L21.3094 11.2964C21.453 11.3497 21.5767 11.4456 21.6642 11.5713C21.7516 11.697 21.7984 11.8465 21.7984 11.9996C21.7984 12.1527 21.7516 12.3021 21.6642 12.4278C21.5767 12.5535 21.453 12.6494 21.3094 12.7027L15.3282 14.8777C15.2251 14.9157 15.1314 14.9756 15.0538 15.0533C14.9761 15.131 14.9162 15.2246 14.8782 15.3277Z"
    fill="#24535B"
  />
  <path
    d="M14.8782 15.3277L12.7032 21.3089C12.6499 21.4525 12.554 21.5763 12.4283 21.6637C12.3026 21.7511 12.1531 21.7979 12 21.7979C11.8469 21.7979 11.6975 21.7511 11.5718 21.6637C11.4461 21.5763 11.3502 21.4525 11.2969 21.3089L9.12192 15.3277C9.08393 15.2246 9.02403 15.131 8.94634 15.0533C8.86864 14.9756 8.77502 14.9157 8.67192 14.8777L2.69067 12.7027C2.54714 12.6494 2.42335 12.5535 2.33593 12.4278C2.24852 12.3021 2.20166 12.1527 2.20166 11.9996C2.20166 11.8465 2.24852 11.697 2.33593 11.5713C2.42335 11.4456 2.54714 11.3497 2.69067 11.2964L8.67192 9.12143C8.77502 9.08345 8.86864 9.02354 8.94634 8.94585C9.02403 8.86816 9.08393 8.77453 9.12192 8.67143L11.2969 2.69018C11.3502 2.54665 11.4461 2.42286 11.5718 2.33544C11.6975 2.24803 11.8469 2.20117 12 2.20117C12.1531 2.20117 12.3026 2.24803 12.4283 2.33544C12.554 2.42286 12.6499 2.54665 12.7032 2.69018L14.8782 8.67143C14.9162 8.77453 14.9761 8.86816 15.0538 8.94585C15.1314 9.02354 15.2251 9.08345 15.3282 9.12143L21.3094 11.2964C21.453 11.3497 21.5767 11.4456 21.6642 11.5713C21.7516 11.697 21.7984 11.8465 21.7984 11.9996C21.7984 12.1527 21.7516 12.3021 21.6642 12.4278C21.5767 12.5535 21.453 12.6494 21.3094 12.7027L15.3282 14.8777C15.2251 14.9157 15.1314 14.9756 15.0538 15.0533C14.9761 15.131 14.9162 15.2246 14.8782 15.3277V15.3277Z"
    stroke="#24535B"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<div
  class="flex gap-4 flex-wrap justify-between px-4 py-5 items-center rounded-xl bg-primary-light"
>
  <p class="font-semibold leading-5 text-black">{{ sectionTitle }}</p>
  <div
    *ngIf="sectionTitle !== 'Ejendommens'"
    class="flex items-center cursor-pointer"
    (click)="onscrollToTopClicked()"
  >
    <icon-caret-down class="rotate-180"></icon-caret-down>
    <p class="font-semibold leading-5 text-black">Top</p>
  </div>
</div>
<ng-content></ng-content>

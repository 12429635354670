<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="timer"
  [fullScreen]="true"
  ><p style="color: white">Loading...</p></ngx-spinner
>
<div *ngIf="agreement?.terms" class="mt-[52px]">
  <h3 class="font-semibold text-[32px] leading-10 text-black">
    Formidlingsaftale
  </h3>
  <p class="text-[#747F81] mt-3">Her kan du læse formidlingsaftalen med EJOS</p>
  <form
    #firstForm="ngForm"
    [formGroup]="agreementForm"
    (ngSubmit)="onNextClicked()"
  >
    <div class="mt-10">
      <p class="leading-5 text-black mt-5">
        {{ agreement?.terms }}
      </p>
    </div>
    <div class="border-t border-solid border-[#D1D8D9] mt-[80px] py-6">
      <div class="ml-4 mb-6 flex items-center gap-3">
        <mat-checkbox color="primary" formControlName="termsAccepted">
        </mat-checkbox>

        <p class="leading-[18px] text-black">
          Jeg accepterer Ejos’
          <span class="text-[#EB4C60]"
            >Genrelle betingelser, Formidlingsvilkår
          </span>
          og <span class="text-[#EB4C60]">Privatslivspolitik </span>.
        </p>
      </div>

      <div class="flex items-center gap-3">
        <button
          class="!py-[15px] !px-[25px] !border !border-solid !border-[#D1D8D9] !rounded-[90px] !min-w-fit"
          mat-flat-button
          type="button"
          (click)="onPrevClicked()"
        >
          <icon-caret-left></icon-caret-left>
        </button>
        <button
          class="w-full max-w-[274px]"
          type="submit"
          [disabled]="!termsAccepted.value && agreement?.version"
          mat-flat-button
          color="primary"
        >
          <div *ngIf="loading" class="flex items-center justify-center">
            <icon-loading-button class="animate-spin"></icon-loading-button>
          </div>
          <span *ngIf="!loading">GEM & FORTSÆT</span>
        </button>
      </div>
    </div>
  </form>
</div>

export interface SelectObject {
  value: string;
  text: string;
}

export const LOAN_TYPE: SelectObject[] = [
  { value: 'ObligationLoan', text: 'Obligationslån' },
  { value: 'PriorityLoan', text: 'Prioritetslån' },
];

export const INTEREST_RATE_TYPE: SelectObject[] = [
  { value: 'Fixed', text: 'Fast rente' },
  { value: 'Variable', text: 'Variabel rente' },
];

<div class="mt-[52px] min-w-[412px]">
  <h3 class="font-semibold text-[32px] leading-10 text-black">Betaling</h3>
  <p class="text-[#747F81] mt-3">
    Vælge faktureringsadresse og betalingsmetode
  </p>

  <form
    class="max-w-[1112px]"
    #firstForm="ngForm"
    [formGroup]="paymentForm"
    (ngSubmit)="onNextClicked()"
  >
    <p class="font-semibold text-xl leading-[25px] text-black mt-10">
      1.Mine oplysninger
    </p>

    <div
      class="rounded-[20px] py-10 px-12 border border-solid border-[#D1D8D9] mt-5"
    >
      <p class="font-semibold text-xl leading-[25px] text-black">
        Faktureringsadresse
      </p>

      <div class="flex gap-[20px] mt-6 flex-wrap">
        <mat-form-field class="max-w-[324px] w-full" appearance="fill">
          <mat-label>Navn</mat-label>

          <input matInput formControlName="fullNameInvoice" />
          <mat-error *ngIf="fullNameInvoice.hasError('required')">
            Påkrævet
          </mat-error>
        </mat-form-field>

        <mat-form-field class="max-w-[324px] w-full" appearance="fill">
          <mat-label>E-mail</mat-label>

          <input matInput formControlName="emailInvoice" />
          <mat-error
            *ngIf="
              emailInvoice.hasError('email') &&
              !emailInvoice.hasError('required') &&
              firstForm.submitted
            "
          >
            Indtast venligst en gyldig e-mailadresse
          </mat-error>
          <mat-error *ngIf="emailInvoice.hasError('required')">
            Påkrævet
          </mat-error>
        </mat-form-field>

        <div class="relative max-w-[324px] w-full mb-[21.5px]">
          <ngx-intl-tel-input
            [cssClass]="firstForm.submitted ? 'submitted' : 'not_submitted'"
            [preferredCountries]="preferredCountries"
            [enableAutoCountrySelect]="true"
            [enablePlaceholder]="true"
            [searchCountryFlag]="true"
            [searchCountryField]="[
              SearchCountryField.Iso2,
              SearchCountryField.Name
            ]"
            [selectFirstCountry]="false"
            [selectedCountryISO]="phoneNumberInvoiceCountryCode.value"
            [maxLength]="15"
            [phoneValidation]="true"
            [separateDialCode]="separateDialCode"
            [numberFormat]="PhoneNumberFormat.International"
            name="phoneNumberInvoice"
            formControlName="phoneNumberInvoice"
          >
          </ngx-intl-tel-input>

          <mat-error
            class="left-3 bottom-[-1.5rem] absolute text-xs text-[#f44336] error-message"
            style="
              animation-name: fadeInDown;
              animation-timing-function: ease-out;
              animation-duration: 0.3s;
            "
            *ngIf="
              phoneNumberInvoice.hasError('required') &&
              (phoneNumberInvoice.touched || firstForm.submitted)
            "
          >
            Påkrævet
          </mat-error>
        </div>

        <mat-form-field class="max-w-[324px] w-full" appearance="fill">
          <mat-label>Adresse</mat-label>

          <input matInput formControlName="addressInvoice" />
          <mat-error *ngIf="addressInvoice.hasError('required')">
            Påkrævet
          </mat-error>
        </mat-form-field>

        <mat-form-field class="max-w-[324px] w-full" appearance="fill">
          <mat-label>Postnummer</mat-label>

          <input
            matInput
            minlength="4"
            maxlength="4"
            pattern="\d*"
            numbersOnly
            formControlName="postalCodeInvoice"
            [group]="paymentForm"
          />
          <mat-error *ngIf="postalCodeInvoice.hasError('required')">
            Påkrævet
          </mat-error>
        </mat-form-field>

        <mat-form-field class="max-w-[324px] w-full" appearance="fill">
          <mat-label>By</mat-label>

          <input matInput formControlName="cityInvoice" />
          <mat-error *ngIf="cityInvoice.hasError('required')">
            Påkrævet
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <p class="font-semibold text-xl leading-[25px] text-black mt-10">
      2. Vælg betalingsmetode
    </p>

    <div
      class="rounded-[20px] py-6 px-5 border border-solid border-[#D1D8D9] mt-5"
    >
      <mat-radio-group formControlName="paymentMethod">
        <div
          class="rounded-[20px] py-4 px-7 flex items-start justify-between gap-[50px] cursor-pointer"
          [ngClass]="{ 'bg-[#EFF6F7]': paymentMethod.value === 'Invoice' }"
          (click)="updatePaymentMethod('Invoice')"
        >
          <div class="flex gap-3">
            <icon-receipt></icon-receipt>
            <div>
              <p>Faktura</p>
              <p>
                Du modtager en faktura på opstartsbeløb kr. 10.000, når din
                opstilling er blevet godkendt af os. Du modtager en ny faktura
                med det resternede beløb, når din ejendom er solgt.
              </p>
            </div>
          </div>
          <mat-radio-button value="Invoice"></mat-radio-button>
        </div>

        <div
          class="rounded-[20px] py-4 px-7 flex items-start justify-between gap-[50px] cursor-pointer"
          [ngClass]="{ 'bg-[#EFF6F7]': paymentMethod.value === 'BankTransfer' }"
          (click)="updatePaymentMethod('BankTransfer')"
        >
          <div class="flex gap-3">
            <icon-card-holder></icon-card-holder>
            <div>
              <p>Betalingsmetode</p>
              <p>
                Du kan betale din faktura via tilsendt beltalingslink eller
                bankoverførelse.
              </p>
            </div>
          </div>
          <mat-radio-button value="BankTransfer"></mat-radio-button>
        </div>
      </mat-radio-group>
    </div>

    <p class="font-semibold text-xl leading-[25px] text-black mt-10">
      3. Du har valgt
    </p>

    <div
      class="rounded-[20px] py-10 px-12 border border-solid border-[#D1D8D9] mt-5"
    >
      <p class="font-semibold text-xl leading-[25px] text-black">
        Ejendomspakke med følgende tilvalg
      </p>

      <div class="flex justify-between gap-[50px] mt-5 flex-wrap">
        <div class="flex flex-col max-w-[454px] gap-4">
          <div
            class="flex gap-3"
            *ngFor="let item of listing?.packageServiceAdditions"
          >
            <icon-check-green></icon-check-green>
            <p class="leading-5">{{ item?.serviceName }}</p>
          </div>
          <div *ngIf="listing?.salesStrategyName" class="flex gap-3">
            <icon-check-green></icon-check-green>
            <p class="leading-5">
              Salgsstrategi - {{ listing?.salesStrategyName }}
            </p>
          </div>
          <div
            class="flex gap-3"
            *ngFor="let item of listing?.strategyAdditions"
          >
            <icon-check-green></icon-check-green>
            <p class="leading-5">{{ item?.serviceName }}</p>
          </div>
        </div>

        <div *ngIf="listing?.totalSumForPackagesAndStrategies">
          <p class="leading-5">Pris</p>
          <p class="font-semibold text-2xl leading-[30px]">
            {{ listing?.totalSumForPackagesAndStrategies | number : "1.0-2" }}
            kr.
          </p>
        </div>
      </div>
    </div>

    <div class="flex items-center gap-3 mt-10">
      <button
        class="!py-[15px] !px-[25px] !border !border-solid !border-[#D1D8D9] !rounded-[90px] !min-w-fit !mb-6"
        mat-flat-button
        (click)="onPrevClicked()"
      >
        <icon-caret-left></icon-caret-left>
      </button>
      <button
        type="submit"
        class="w-full !mb-6"
        mat-flat-button
        color="primary"
        [ngClass]="{
          'animate-shake': firstForm.invalid && firstForm.submitted
        }"
      >
        <div *ngIf="loading" class="flex items-center justify-center">
          <icon-loading-button class="animate-spin"></icon-loading-button>
        </div>
        <span class="uppercase" *ngIf="!loading">Bekræft OG opret</span>
      </button>
    </div>
  </form>
</div>

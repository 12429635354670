<!-- // Not part of form group -->
<div *ngIf="mode === 'SINGLE'" class="text-input-container">
  <div *ngIf="showLabel" class="text-input-title flex-row-4 mar-btm-4">
    <label [for]="_id">{{ title }}</label>
    <p class="text-input-required" *ngIf="isRequired">*</p>
    <span class="info-icon" *ngIf="isInfo"></span>
  </div>
  <div class="text-input">
    <ng-select
      [items]="options"
      [multiple]="true"
      bindLabel="text"
      [placeholder]="placeholder"
      [closeOnSelect]="false"
      bindValue="id"
      [(ngModel)]="selectedOptions"
    >
      <ng-template
        ng-option-tmp
        let-item="item"
        let-item$="item$"
        let-index="index"
      >
        <input
          id="item-{{ index }}"
          type="checkbox"
          [ngModel]="item$.selected"
        />
        {{ item.text }}
      </ng-template>
    </ng-select>
  </div>
</div>

<div class="pt-12">
  <h3 class="text-5xl font-semibold leading-[60px] text-[#747F81]">
    {{ welcomeMessage }},
  </h3>
  <h3 class="text-5xl font-semibold leading-[60px] text-[#091315]">
    {{ firstName }}
  </h3>

  <div class="mt-8 flex justify-start gap-6 flex-wrap max-w-[1098px]">
    <div
      class="flex-1 min-w-[290px] border border-solid border-[#D1D8D9] rounded-[28px] p-[28px] flex flex-col gap-4 justify-start items-start"
    >
      <icon-sparkle-hub></icon-sparkle-hub>
      <p class="font-semibold text-xl text-[#091315]">Mød EJOS</p>
      <p class="font-normal flex-1 text-sm text-[#091315]">
        Læs mere om hvordan EJOS fører din ejendom fra formidlingsaftale til et
        salg
      </p>
      <button mat-flat-button color="primary">
        <span class="py-4 px-10 !align-baseline">LÆS MERE HER</span>
      </button>
    </div>
    <div
      class="flex-1 min-w-[290px] border border-solid border-[#D1D8D9] rounded-[28px] p-[28px] flex flex-col gap-4 justify-start items-start"
    >
      <icon-books-hub></icon-books-hub>
      <p class="font-semibold text-xl text-[#091315]">Mælgerens ordbog</p>
      <p class="font-normal text-sm text-[#091315]">
        Få svar på alle de udtryk som en ejendomsmælger bruger om en ejendom og
        om salgsprocessen
      </p>
      <button mat-flat-button color="primary">
        <span class="py-4 px-10 !align-baseline">LÆS MERE HER</span>
      </button>
    </div>
    <div
      class="flex-1 min-w-[290px] bg-[#24535B] rounded-[28px] p-[28px] flex flex-col gap-4 justify-start items-start"
    >
      <icon-tag-hub></icon-tag-hub>
      <p class="font-semibold text-xl text-white">Klar til at sælge</p>
      <p class="font-normal text-base leading-5 text-white">
        Vi står klar til at behandle din ejendom så snart du lægger den på EJOS.
      </p>
      <p class="font-normal text-base leading-5 text-white">
        Inden for få dage er bliver der lagt en plan for hvordan din ejendom
        bedst når fra marked til salg
      </p>
    </div>
  </div>

  <div
    class="mt-8 flex items-start justify-start gap-6 flex-wrap max-w-[1098px]"
  >
    <div
      class="flex-1 min-w-[565px] w645:!min-w-[290px] min border border-solid border-[#D1D8D9] rounded-[28px] p-[28px] flex gap-4 items-center justify-start flex-wrap"
    >
      <div class="flex flex-col gap-4 justify-start items-start flex-1">
        <icon-lifebuoy-hub></icon-lifebuoy-hub>
        <p class="font-semibold text-xl text-[#091315]">Mød EJOS</p>
        <p class="font-normal text-sm text-[#091315]">
          Kontakt os og mere om dine muligheder hos EJOS. Vi står klar til at
          guide dig til et godt salg
        </p>
        <button mat-flat-button color="primary">
          <span class="py-4 px-10 !align-baseline">LÆS MERE HER</span>
        </button>
      </div>
      <div class="flex flex-col gap-4 justify-start items-start">
        <p class="font-semibold text-xl text-[#091315] pt-12">Mød din mægler</p>
        <div class="flex items-center gap-3">
          <img
            src="https://ui-avatars.com/api/?name={{
              firstName[0]
            }}&background=0D8ABC&color=fff"
            class="rounded-[12px] w-[52px]"
          />
          <div>
            <p class="text-[#091315] font-semibold text-[18px] leading-[23px]">
              {{ firstName }}
            </p>
          </div>
        </div>
        <button class="flex items-center gap-1">
          <span class="text-[#24535B] font-semibold text-sm leading-[18px]"
            >Kontakt os</span
          >
          <icon-caret-right strokeColor="#24535B"></icon-caret-right>
        </button>
      </div>
    </div>
    <div
      class="flex-1 min-w-[290px] w-full max-h-[280px] front-page-image-slider mb-6"
    >
      <gallery
        [thumb]="false"
        [counter]="false"
        [dots]="true"
        dotsPosition="'top'"
        imageSize="cover"
        [items]="images"
        [autoPlay]="true"
        [nav]="true"
        [playerInterval]="5000"
      ></gallery>
    </div>
  </div>
</div>

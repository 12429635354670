<svg
  xmlns="http://www.w3.org/2000/svg"
  width="25"
  height="24"
  viewBox="0 0 25 24"
  fill="none"
>
  <path
    d="M16.25 3.81543C18.1168 4.67108 19.6349 6.13942 20.5522 7.97672C21.4695 9.81402 21.7309 11.9098 21.293 13.9161C20.8551 15.9224 19.7442 17.7187 18.1448 19.0067C16.5454 20.2947 14.5536 20.997 12.5 20.997C10.4464 20.997 8.45462 20.2947 6.85519 19.0067C5.25576 17.7187 4.14489 15.9224 3.707 13.9161C3.2691 11.9098 3.5305 9.81402 4.44782 7.97672C5.36513 6.13942 6.88319 4.67108 8.75 3.81543"
    stroke="white"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

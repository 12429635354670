<svg
  *ngIf="id === 'home'"
  width="32"
  height="32"
  viewBox="0 0 32 32"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M26.675 13.7L16.675 4.61252C16.4907 4.44387 16.2499 4.35034 16 4.35034C15.7501 4.35034 15.5093 4.44387 15.325 4.61252L5.325 13.7C5.22437 13.7951 5.14378 13.9093 5.08796 14.0359C5.03215 14.1626 5.00224 14.2991 5 14.4375V26C5 26.2652 5.10536 26.5196 5.29289 26.7071C5.48043 26.8947 5.73478 27 6 27H26C26.2652 27 26.5196 26.8947 26.7071 26.7071C26.8946 26.5196 27 26.2652 27 26V14.4375C26.9978 14.2991 26.9679 14.1626 26.912 14.0359C26.8562 13.9093 26.7756 13.7951 26.675 13.7V13.7Z"
    [attr.stroke]="active ? '#ffffff' : '#283F43'"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<svg
  *ngIf="id === 'properties'"
  width="32"
  height="32"
  viewBox="0 0 32 32"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M2 27H30"
    [attr.stroke]="active ? '#ffffff' : '#283F43'"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M18 27V5C18 4.73478 17.8946 4.48043 17.7071 4.29289C17.5196 4.10536 17.2652 4 17 4H5C4.73478 4 4.48043 4.10536 4.29289 4.29289C4.10536 4.48043 4 4.73478 4 5V27"
    [attr.stroke]="active ? '#ffffff' : '#283F43'"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M28 27V13C28 12.7348 27.8946 12.4804 27.7071 12.2929C27.5196 12.1054 27.2652 12 27 12H18"
    [attr.stroke]="active ? '#ffffff' : '#283F43'"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M8 9H12"
    [attr.stroke]="active ? '#ffffff' : '#283F43'"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M10 17H14"
    [attr.stroke]="active ? '#ffffff' : '#283F43'"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M8 22H12"
    [attr.stroke]="active ? '#ffffff' : '#283F43'"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M22 22H24"
    [attr.stroke]="active ? '#ffffff' : '#283F43'"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M22 17H24"
    [attr.stroke]="active ? '#ffffff' : '#283F43'"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<svg
  *ngIf="id === 'statistics'"
  width="32"
  height="32"
  viewBox="0 0 32 32"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M5.5 26V17H12.5"
    [attr.stroke]="active ? '#ffffff' : '#283F43'"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M28.5 26H3.5"
    [attr.stroke]="active ? '#ffffff' : '#283F43'"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M12.5 26V11H19.5"
    [attr.stroke]="active ? '#ffffff' : '#283F43'"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M26.5 5H19.5V26H26.5V5Z"
    [attr.stroke]="active ? '#ffffff' : '#283F43'"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<svg
  *ngIf="id === 'messages'"
  width="32"
  height="32"
  viewBox="0 0 32 32"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M16.5001 27H5.96258C5.83572 27.0017 5.70981 26.9779 5.59227 26.9302C5.47474 26.8824 5.36796 26.8115 5.27825 26.7218C5.18854 26.6321 5.1177 26.5253 5.06993 26.4078C5.02215 26.2903 4.9984 26.1644 5.00008 26.0375V15.5C5.00008 12.45 6.21169 9.52494 8.36836 7.36827C10.525 5.2116 13.4501 4 16.5001 4V4C18.0103 4 19.5057 4.29746 20.9009 4.87539C22.2962 5.45331 23.5639 6.3004 24.6318 7.36827C25.6997 8.43615 26.5468 9.7039 27.1247 11.0991C27.7026 12.4944 28.0001 13.9898 28.0001 15.5V15.5C28.0001 17.0102 27.7026 18.5056 27.1247 19.9009C26.5468 21.2961 25.6997 22.5639 24.6318 23.6317C23.5639 24.6996 22.2962 25.5467 20.9009 26.1246C19.5057 26.7025 18.0103 27 16.5001 27V27Z"
    [attr.stroke]="active ? '#ffffff' : '#283F43'"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M12.5 14H20"
    [attr.stroke]="active ? '#ffffff' : '#283F43'"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M12.5 18H20"
    [attr.stroke]="active ? '#ffffff' : '#283F43'"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<svg
  *ngIf="id === 'screenings'"
  width="32"
  height="32"
  viewBox="0 0 32 32"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M16 7C6 7 2 16 2 16C2 16 6 25 16 25C26 25 30 16 30 16C30 16 26 7 16 7Z"
    [attr.stroke]="active ? '#ffffff' : '#283F43'"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M16 21C18.7614 21 21 18.7614 21 16C21 13.2386 18.7614 11 16 11C13.2386 11 11 13.2386 11 16C11 18.7614 13.2386 21 16 21Z"
    [attr.stroke]="active ? '#ffffff' : '#283F43'"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<svg
  *ngIf="id === 'calendar'"
  width="32"
  height="32"
  viewBox="0 0 32 32"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M26 5H6C5.44772 5 5 5.44772 5 6V26C5 26.5523 5.44772 27 6 27H26C26.5523 27 27 26.5523 27 26V6C27 5.44772 26.5523 5 26 5Z"
    [attr.stroke]="active ? '#ffffff' : '#283F43'"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M22 3V7"
    [attr.stroke]="active ? '#ffffff' : '#283F43'"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M10 3V7"
    [attr.stroke]="active ? '#ffffff' : '#283F43'"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M5 11H27"
    [attr.stroke]="active ? '#ffffff' : '#283F43'"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<svg
  *ngIf="id === 'documents'"
  width="32"
  height="32"
  viewBox="0 0 32 32"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M25 28H7C6.73478 28 6.48043 27.8946 6.29289 27.7071C6.10536 27.5196 6 27.2652 6 27V5C6 4.73478 6.10536 4.48043 6.29289 4.29289C6.48043 4.10536 6.73478 4 7 4H19L26 11V27C26 27.2652 25.8946 27.5196 25.7071 27.7071C25.5196 27.8946 25.2652 28 25 28Z"
    [attr.stroke]="active ? '#ffffff' : '#283F43'"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M19 4V11H26"
    [attr.stroke]="active ? '#ffffff' : '#283F43'"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<svg
  *ngIf="id === 'profile'"
  width="32"
  height="32"
  viewBox="0 0 32 32"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M16 20C20.4183 20 24 16.4183 24 12C24 7.58172 20.4183 4 16 4C11.5817 4 8 7.58172 8 12C8 16.4183 11.5817 20 16 20Z"
    [attr.stroke]="active ? '#ffffff' : '#283F43'"
    stroke-width="2"
    stroke-miterlimit="10"
  />
  <path
    d="M3.875 26.9999C5.10367 24.8713 6.87104 23.1037 8.99944 21.8747C11.1278 20.6458 13.5423 19.9988 16 19.9988C18.4577 19.9988 20.8722 20.6458 23.0006 21.8747C25.129 23.1037 26.8963 24.8713 28.125 26.9999"
    [attr.stroke]="active ? '#ffffff' : '#283F43'"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

import {
  OnInit,
  Input,
  Directive,
  HostListener,
  HostBinding,
} from '@angular/core';

@Directive({
  selector: '[phoneFormatter]',
})
export class PhoneFormatterDirective implements OnInit {
  @Input() isPhone: boolean | undefined = false;
  @Input() group: any;
  @Input() control: any;
  @Input() name: any;
  regex = /(\d{1,2})?(\d{1,2})?(\d{1,2})?(\d{1,2})?/;
  phoneSanitizer = /[^0-9]/gi;

  constructor() {}

  ngOnInit(): void {}

  @HostListener('keypress', ['$event'])
  changeListener(event: KeyboardEvent) {
    if (this.isPhone) {
      // console.log('Inside phone formatter keypress ');
      this.handleChange(event);
    }
  }

  handleChange(event: KeyboardEvent) {
    event.preventDefault();
    // console.log('Inside phone formatter handleChange ');
    const sanitizedKey = event?.key.replace(this.phoneSanitizer, '');
    // console.log('Key Pressed', sanitizedKey);
    const form = this.group.get(this.name);
    const { value } = form;
    // console.log('Value', value);
    const sanitizedValue = value?.replace(this.phoneSanitizer, '');
    // console.log('value', sanitizedValue.length);
    if (sanitizedValue?.length === 8) return;
    form.setValue(this.formatPhone(sanitizedValue, sanitizedKey));
  }

  @HostListener('paste', ['$event'])
  handlePaste(event: ClipboardEvent) {
    // ignore if not phonenumber
    if (!this.isPhone) return null;
    // get data
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData?.getData('text');
    event.preventDefault();
    // sanitize data
    const form = this.group.get(this.name);
    const sanitizedValue = pastedText?.replace(this.phoneSanitizer, '');
    if (!sanitizedValue) return null;
    form.setValue(this.formatPhone(sanitizedValue.substring(0, 8), ''));
    return null;
  }

  // @HostListener('keydown.backspace', ['$event'])
  // onKeydownHandler(event: KeyboardEvent) {
  //   if (this.isPhone) {
  //     event.preventDefault();
  //     const form = this.group.get(this.name);
  //     const { value } = form;
  //     if (value.length > 0) {
  //       // handling white space on back space.
  //       const sanitizedValue = value.substring(0, value.length - 1).trim();
  //       form.setValue(sanitizedValue);
  //     }
  //   }
  // }

  formatPhone(value: string, key: string) {
    if (!value) return `${key}`;
    const matchedValue = `${value}${key}`.match(this.regex);
    if (matchedValue) {
      // console.log(matchedValue);
      const [_match, firstBlock, secondBlock, thirdBlock, forthBlock] =
        matchedValue;
      // block duplicate space after isd
      if (!firstBlock) return ``;
      if (!secondBlock) return `${firstBlock}`;
      if (!thirdBlock) return `${firstBlock} ${secondBlock}`;
      if (!forthBlock) return `${firstBlock} ${secondBlock} ${thirdBlock}`;
      return `${firstBlock} ${secondBlock} ${thirdBlock} ${forthBlock}`;
    }
    return `${value}${key}`;
  }
}

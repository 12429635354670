import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  @Input() mode: 'DARK' | 'LIGHT' = 'DARK';
  @Input() isSmall: boolean | undefined = false;
  @Input() isMedium: boolean | undefined = false;

  constructor() {}

  ngOnInit(): void {}
}

import { Directive, Input, HostListener, ElementRef } from '@angular/core';
import { TextInputComponent } from './text-input/text-input.component';

@Directive({
  selector: '[userInputSanitizer]',
})
export class UserInputSanitizerDirective {
  @Input() isPassword: boolean | undefined = false;
  // @Input() isPhone: boolean | undefined = false;
  @Input() isAlphaNumeric: boolean | undefined = false;
  @Input() isEmail: boolean | undefined = false;
  @Input() isNumber: boolean | undefined = false;
  @Input() isPositiveInteger: boolean | undefined = false;
  @Input() isPostalcode: boolean | undefined = false;
  @Input() control: any;
  @Input() group: any;
  @Input() name: any;

  emailSanitizer = /[^0-9a-z!#$%&'*+-/=?^_`{|}~"(),:;<>@[\].-]/gi;
  passwordSanitizer = /[^0-9a-zæøåß_\-!@#$%^&*+?()]/gi;
  alphaNumericSanitiser = /[^0-9a-zæøåß_]/gi;
  numericSanitiser = /[^0-9,-]/gi;
  positiveIntegerSanitiser = /[^0-9]/gi;

  constructor(private host: TextInputComponent) {}

  replaceValue(regex: RegExp, event: KeyboardEvent) {
    if (!event?.key.replace(regex, '')) event.preventDefault();
    const form = this.group.get(this.name);
    const { value } = form;
    if (value) {
      const sanitizedValue = value.replace(regex, '');
      form.setValue(sanitizedValue);
    }
  }

  @HostListener('keypress', ['$event'])
  handleData(event: KeyboardEvent) {
    if (this.isEmail) {
      this.replaceValue(this.emailSanitizer, event);
      return null;
    }
    if (this.isPassword) {
      this.replaceValue(this.passwordSanitizer, event);
      return null;
    }
    if (this.isAlphaNumeric) {
      this.replaceValue(this.alphaNumericSanitiser, event);
      return null;
    }
    if (this.isNumber) {
      this.replaceValue(this.numericSanitiser, event);
      return null;
    }
    if (this.isPositiveInteger) {
      this.replaceValue(this.positiveIntegerSanitiser, event);
      return null;
    }
    if (this.isPostalcode) {
      this.replaceValue(this.positiveIntegerSanitiser, event);
      return null;
    }
    return null;
  }

  filterPastedValue(regex: RegExp, event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    event.preventDefault();

    // do not allow pasting for passwords
    if (this.isPassword) return null;
    const pastedText = clipboardData?.getData('text');
    if (pastedText) {
      const form = this.group.get(this.name);
      const sanitizedValue = pastedText.replace(regex, '');
      form.setValue(sanitizedValue);
    }
    return null;
  }

  @HostListener('paste', ['$event'])
  handlePasteEvent(event: ClipboardEvent) {
    if (this.isEmail) {
      this.filterPastedValue(this.emailSanitizer, event);
      return null;
    }
    if (this.isPassword) {
      this.filterPastedValue(this.passwordSanitizer, event);
      return null;
    }
    if (this.isAlphaNumeric) {
      this.filterPastedValue(this.alphaNumericSanitiser, event);
      return null;
    }
    if (this.isNumber) {
      this.filterPastedValue(this.numericSanitiser, event);
      return null;
    }
    if (this.isPositiveInteger) {
      this.filterPastedValue(this.positiveIntegerSanitiser, event);
      return null;
    }
    if (this.isPostalcode) {
      this.filterPastedValue(this.positiveIntegerSanitiser, event);
      return null;
    }
    return null;
  }
}

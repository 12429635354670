import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type OPTION = {
  text: string;
  id: number;
  isSelected: boolean;
};

export type OPTIONS = OPTION[];

@Component({
  selector: 'select-radio-box',
  templateUrl: './select-radio-box.component.html',
  styleUrls: ['./select-radio-box.component.scss'],
})
export class SelectRadioBoxComponent implements OnInit {
  // @Output() selectedValue: EventEmitter<any> = new EventEmitter<any>();

  @Input() selectedType;

  @Input() mode: string = 'FORM';
  @Input() _id: string = '';
  @Input() title: string = '';
  @Input() placeholder: string = '';
  @Input() isErrorsEnabled: BehaviorSubject<boolean> | undefined;
  @Input() group: any;
  @Input() control: any;
  @Input() isInfo = false;
  @Input() isRequired: boolean = false;
  @Input() options: OPTIONS = [];
  @Input() showLabel: boolean = true;

  isErrorsEnabledLocale = false;
  isLoading = false;
  selected;

  constructor() {}

  ngOnInit(): void {
    if (this.isErrorsEnabled) {
      this.isErrorsEnabled.subscribe((newVal) => {
        this.isErrorsEnabledLocale = newVal;
      });
    }
  }

  OnChange(event: any) {
    this.isLoading = true;
    // set all selections to NULL
    this.options.map((option) => {
      option.isSelected = false;
    });

    // make event true and use settimeout to correctly render DOM element
    setTimeout(() => {
      event.isSelected = true;
      this.isLoading = false;
    }, 0);
  }
}

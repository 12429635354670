import { Component, OnInit } from '@angular/core';
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { ConfirmedValidator, patternValidator } from 'src/utils';
import { ResetPasswordRequest } from 'src/app/_models/user.model';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  isLoaderEnabled = new BehaviorSubject(false);
  email: string;

  resetPasswordForm: FormGroup;
  responseErrorMessage = '';

  _hidePassword = true;
  _hideConfirmPassword = true;

  _passwordStrength: number = 0;

  _passwordUpdated = false;
  _counter = 10;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private titleService: Title
  ) {
    this.titleService.setTitle('Ejos - Nulstille kodeord');
    this.resetPasswordForm = this.fb.group({});
  }

  ngOnInit(): void {
    this.email = this.route.snapshot.paramMap.get('email');

    this.resetPasswordForm = this.fb.group(
      {
        token: new FormControl('', [
          Validators.required,
          Validators.minLength(6),
        ]),
        password: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
          patternValidator(new RegExp('(?=.*[0-9])'), {
            requiresDigit: true,
          }),
          patternValidator(new RegExp('(?=.*[A-Z])'), {
            requiresUppercase: true,
          }),
          patternValidator(new RegExp('(?=.*[a-z])'), {
            requiresLowercase: true,
          }),
          patternValidator(new RegExp('(?=.*[$@^!%*?&#-+])'), {
            requiresSpecialChars: true,
          }),
        ]),
        confirmPassword: new FormControl('', [Validators.required]),
      },
      {
        validator: ConfirmedValidator('password', 'confirmPassword'),
      }
    );
  }

  onStrengthChanged(value: number) {
    setTimeout(() => {
      this._passwordStrength = value;
    });
  }

  handleSubmit = () => {
    this.responseErrorMessage = '';

    if (this.resetPasswordForm?.status === 'VALID') {
      this.isLoaderEnabled.next(true);

      const resetPasswordRequest: ResetPasswordRequest = {
        email: this.email,
        token: this.resetPasswordForm.get('token')!.value,
        newPassword: this.resetPasswordForm.get('password')!.value,
      };

      this.authService.resetUserPassword(resetPasswordRequest).subscribe({
        next: () => {
          this._passwordUpdated = true;
          this.isLoaderEnabled.next(false);

          const intervalId = setInterval(() => {
            this._counter--;
            if (this._counter === 0) {
              clearInterval(intervalId);
              this.router.navigate(['/']);
            }
          }, 1000);
        },
        error: (err) => {
          this.isLoaderEnabled.next(false);
          if (err.error.messageStatus === 'TokenIncorrect')
            this._snackBar.open('Ugyldig Token', 'Dismiss', {
              duration: 3000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          else
            this._snackBar.open(
              'Server Fejl! Prøv venligst efter et stykke tid',
              'Dismiss',
              {
                duration: 3000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              }
            );
        },
      });
    }
  };

  get token() {
    return this.resetPasswordForm.get('token');
  }

  get password() {
    return this.resetPasswordForm.get('password');
  }

  get confirmPassword() {
    return this.resetPasswordForm.get('confirmPassword');
  }
}

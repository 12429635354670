import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-trend-up',
  templateUrl: './trend-up.component.html',
  styleUrls: ['./trend-up.component.scss'],
})
export class TrendUpComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

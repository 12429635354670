import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'icon-close-dialog',
  templateUrl: './close-dialog.component.html',
  styleUrls: ['./close-dialog.component.scss'],
})
export class CloseDialogComponent implements OnInit {
  @Input() strokeColor: string = '#091315';
  constructor() {}

  ngOnInit(): void {}
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { delay, map, tap } from 'rxjs';
import { User } from 'src/app/_models/user.model';
import { ApiService } from '../api.service';
import { GlobalConstants } from '../global-constants';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private apiService: ApiService,
    private router: Router,
    private http: HttpClient
  ) {}

  loginUser = (body: any) =>
    this.apiService.post(`${GlobalConstants.authURL}user/login`, body);

  logout() {
    localStorage.clear();
    this.router.navigate(['/user/login']);
  }

  refreshToken = (body: any) =>
    this.apiService.post(
      `${GlobalConstants.authURL}user/token/refresh/cookie`,
      body
    );

  // refreshTokenWithCookie() {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Access-Control-Allow-Origin': 'https://devapi.ejos.dk/',
  //       'ACCESS-CONTROL-ALLOW-CREDENTIAL': 'true',
  //     }),
  //     withCredentials: true,
  //   };
  //   return this.http
  //     .post(`${GlobalConstants.authURL}user/token/refresh`, httpOptions)
  //     .pipe(
  //       tap((response) => {
  //         localStorage.setItem('user', JSON.stringify(response));
  //       })
  //     );
  // }

  signUpUser = (body: any) =>
    this.apiService.post(`${GlobalConstants.authURL}user`, body);

  checkEmail = (emailId: string) =>
    this.apiService
      .get(`${GlobalConstants.authURL}user/email/${emailId}/exists`)
      .pipe(delay(500));

  generateForgotPasswordToken = (body: any) =>
    this.apiService.post(
      `${GlobalConstants.authURL}twofactorcode/password/reset`,
      body
    );

  resetUserPassword = (body: any) =>
    this.apiService.post(
      `${GlobalConstants.authURL}twofactorcode/password/change`,
      body
    );

  getUserById = (id: string) =>
    this.apiService.get(`${GlobalConstants.authURL}user/${id}`);

  updateUserInfo = (id: string, body: any) =>
    this.apiService.put(`${GlobalConstants.authURL}user/${id}`, body);

  changePassword(body: any) {
    return this.apiService.post(
      `${GlobalConstants.authURL}twofactorcode/password/login/change`,
      body
    );
  }
}

<div
  class="tooltip flex flex-col gap-2 justify-start items-start p-[10px] rounded-xl bg-wrapper"
>
  <p class="font-bold text-lg leading-[22px]">Adgfangkoden skal indeholde:</p>

  <div class="flex flex-col gap-1 justify-start items-start">
    <!-- minimum 8 chars -->
    <div class="flex justify-start items-center gap-2">
      <span
        class="valid"
        *ngIf="
          !control?.hasError('required') && !control?.hasError('minlength')
        "
      ></span>
      <span
        class="invalid"
        *ngIf="control?.hasError('required') || control?.hasError('minlength')"
      ></span>
      <p
        class="text-base font-semibold"
        [ngClass]="{
          'text-red-500 ':
            control?.hasError('required') || control?.hasError('minlength'),
          'text-green-500 ':
            !control?.hasError('required') && !control?.hasError('minlength')
        }"
      >
        Mindst 8 tegn
      </p>
    </div>

    <!-- minimum 1 digit -->
    <div class="flex justify-start items-center gap-2">
      <span
        class="valid"
        *ngIf="
          !control?.hasError('required') && !control?.hasError('requiresDigit')
        "
      ></span>
      <span
        class="invalid"
        *ngIf="
          control?.hasError('required') || control?.hasError('requiresDigit')
        "
      ></span>
      <p
        class="font-semibold text-base"
        [ngClass]="{
          'text-red-500 ':
            control?.hasError('required') || control?.hasError('requiresDigit'),
          'text-green-500 ':
            !control?.hasError('required') &&
            !control?.hasError('requiresDigit')
        }"
      >
        Et tal
      </p>
    </div>

    <!-- minimum 1 small letter -->
    <div class="flex justify-start items-center gap-2">
      <span
        class="valid"
        *ngIf="
          !control?.hasError('required') &&
          !control?.hasError('requiresLowercase')
        "
      ></span>
      <span
        class="invalid"
        *ngIf="
          control?.hasError('required') ||
          control?.hasError('requiresLowercase')
        "
      ></span>
      <p
        class="font-semibold text-base"
        [ngClass]="{
          'text-red-500 ':
            control?.hasError('required') ||
            control?.hasError('requiresLowercase'),
          'text-green-500 ':
            !control?.hasError('required') &&
            !control?.hasError('requiresLowercase')
        }"
      >
        Et lille bogstav
      </p>
    </div>

    <!-- minimum 1 capital letter -->
    <div class="flex justify-start items-center gap-2">
      <span
        class="valid"
        *ngIf="
          !control?.hasError('required') &&
          !control?.hasError('requiresUppercase')
        "
      ></span>
      <span
        class="invalid"
        *ngIf="
          control?.hasError('required') ||
          control?.hasError('requiresUppercase')
        "
      ></span>
      <p
        class="font-semibold text-base"
        [ngClass]="{
          'text-red-500 ':
            control?.hasError('required') ||
            control?.hasError('requiresUppercase'),
          'text-green-500 ':
            !control?.hasError('required') &&
            !control?.hasError('requiresUppercase')
        }"
      >
        Et stort bogstav
      </p>
    </div>

    <!-- minimum 1 special case letter -->
    <div class="flex justify-start items-center gap-2">
      <span
        class="valid"
        *ngIf="
          !control?.hasError('required') &&
          !control?.hasError('requiresSpecialChars')
        "
      ></span>
      <span
        class="invalid"
        *ngIf="
          control?.hasError('required') ||
          control?.hasError('requiresSpecialChars')
        "
      ></span>
      <p
        class="font-semibold text-base"
        [ngClass]="{
          'text-red-500 ':
            control?.hasError('required') ||
            control?.hasError('requiresSpecialChars'),
          'text-green-500 ':
            !control?.hasError('required') &&
            !control?.hasError('requiresSpecialChars')
        }"
      >
        Et specialtegn
      </p>
    </div>
  </div>
</div>

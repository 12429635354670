import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ListingService } from 'src/app/_services/listing/listing.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

type AreaDescription = {
  id: string;
  postalCode: string;
  title: string;
  description: string;
};

@Component({
  selector: 'app-pick-desription-dialog',
  templateUrl: './pick-desription-dialog.component.html',
  styleUrls: ['./pick-desription-dialog.component.scss'],
})
export class PickDesriptionDialogComponent implements OnInit {
  areaDescriptions: AreaDescription[] = [];
  loading: boolean = false;
  pickDescriptionForm: FormGroup;
  pickDescriptionFormSubmitted: boolean = false;
  constructor(
    private listService: ListingService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PickDesriptionDialogComponent>
  ) {
    this.pickDescriptionForm = this.fb.group({
      areaDescription: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    if (this.data?.postalCode) {
      this.loading = true;
      this.spinner.show();
      this.listService.searchDescriptions(this.data?.postalCode).subscribe({
        next: (res) => {
          this.areaDescriptions = res;
          this.loading = false;
          this.spinner.hide();
        },
        error: (err) => {
          console.log(err);
          this.loading = false;
          this.spinner.hide();
        },
      });
    }
  }

  selectDescription() {
    this.pickDescriptionFormSubmitted = true;
    if (this.pickDescriptionForm.valid) {
      this.dialogRef.close({
        areaDescriptionSelected: this.areaDescription.value,
      });
    }
  }

  get areaDescription() {
    return this.pickDescriptionForm.get('areaDescription');
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-star-four',
  templateUrl: './star-four.component.html',
  styleUrls: ['./star-four.component.scss'],
})
export class StarFourComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

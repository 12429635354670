<div *ngIf="type === 'default'">
  <p class="text-xs leading-[15px] text-primary">{{ label }}</p>
  <p *ngIf="value" class="font-semibold leading-5 text-black">{{ value }}</p>
</div>

<div *ngIf="type === 'one'">
  <div class="flex gap-2 items-center">
    <p class="font-semibold leading-5 text-black">{{ label }}</p>
    <tooltip *ngIf="message !== ''" [message]="message"></tooltip>
  </div>
  <p class="text-sm leading-[18px] text-black mt-2">{{ value }}</p>
</div>

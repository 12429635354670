import { Component, OnInit, Inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-expense-dialog',
  templateUrl: './edit-expense-dialog.component.html',
  styleUrls: ['./edit-expense-dialog.component.scss'],
})
export class EditExpenseDialogComponent implements OnInit {
  addExpenseForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EditExpenseDialogComponent>,
    private fb: FormBuilder
  ) {
    this.addExpenseForm = this.fb.group({
      tempName: new FormControl('', [Validators.required]),
      tempDescription: new FormControl(''),
    });
  }

  ngOnInit(): void {
    if (this.data?.indexTobeEdited > -1) {
      this.addExpenseForm
        .get('tempName')
        .setValue(
          this.data.expenses.controls[this.data.indexTobeEdited].value.name
        );
      this.addExpenseForm
        .get('tempDescription')
        .setValue(
          this.data.expenses.controls[this.data.indexTobeEdited].value
            .description
        );
    }
  }

  _saveChanges() {
    if (this.addExpenseForm.valid) {
      this.closeDialog();
    }
  }

  closeDialog() {
    this.dialogRef.close({
      name: this.addExpenseForm.get('tempName').value,
      description: this.addExpenseForm.get('tempDescription').value,
    });
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-advisor-title-dialog',
  templateUrl: './edit-advisor-title-dialog.component.html',
  styleUrls: ['./edit-advisor-title-dialog.component.scss'],
})
export class EditAdvisorTitleDialogComponent implements OnInit {
  addAdviserForm: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EditAdvisorTitleDialogComponent>,
    private fb: FormBuilder
  ) {
    this.addAdviserForm = this.fb.group({
      tempTitle: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    // this._tempTitle = `${this.data.adviser.title}`;
    if (this.data?.indexTobeEdited > -1) {
      this.addAdviserForm
        .get('tempTitle')
        .setValue(
          this.data.advisers.controls[this.data.indexTobeEdited].value.title
        );
    }
  }
  _saveChanges() {
    if (this.addAdviserForm.valid) {
      this.closeDialog();
    }
  }

  closeDialog() {
    this.dialogRef.close({
      title: this.addAdviserForm.get('tempTitle').value,
    });
  }
}

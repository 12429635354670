import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-menu-horizontal',
  templateUrl: './menu-horizontal.component.html',
  styleUrls: ['./menu-horizontal.component.scss'],
})
export class MenuHorizontalComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { INPUT_CLASS, SEPERATOR_CLASS } from './signup.util';
import { SignupRequest } from 'src/app/_models/user.model';
import { PHONE_CODES } from 'src/shared/text-input/helper';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { patternValidator, ConfirmedValidator } from 'src/utils';
import { CustomEmailValidator } from './custom-email.validator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;

  _hidePassword = true;
  _hideConfirmPassword = true;

  loading = false;

  _inputClass: string = INPUT_CLASS;
  _seperatorClass: string = SEPERATOR_CLASS;
  _passwordFocussed = false;
  _passwordStrength: number;

  _phoneCodes = PHONE_CODES;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.Denmark,
    CountryISO.Sweden,
    CountryISO.Finland,
    CountryISO.Norway,
  ];

  valid: boolean;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private _snackBar: MatSnackBar,
    private authService: AuthService,
    private emailValidator: CustomEmailValidator,
    private titleService: Title
  ) {
    this.titleService.setTitle('Ejos - Opret profil');
    this.firstFormGroup = this.fb.group({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      companyName: new FormControl('', []),
    });

    this.secondFormGroup = this.fb.group({
      phoneNumber: new FormControl(undefined, [Validators.required]),
      email: new FormControl(
        '',
        [Validators.required, Validators.email],
        [this.emailValidator.existingEmailValidator()]
      ),
      address: new FormControl('', [Validators.required]),
      postalcode: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[0-9]{4}$/),
      ]),
      city: new FormControl('', [Validators.required]),
    });

    this.thirdFormGroup = this.fb.group(
      {
        tNC: new FormControl(false, [Validators.requiredTrue]),
        password: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
          patternValidator(new RegExp('(?=.*[0-9])'), {
            requiresDigit: true,
          }),
          patternValidator(new RegExp('(?=.*[A-Z])'), {
            requiresUppercase: true,
          }),
          patternValidator(new RegExp('(?=.*[a-z])'), {
            requiresLowercase: true,
          }),
          patternValidator(new RegExp('(?=.*[$@^!%*?&#-+])'), {
            requiresSpecialChars: true,
          }),
        ]),
        confirmPassword: new FormControl('', [Validators.required]),
      },
      {
        validator: ConfirmedValidator('password', 'confirmPassword'),
      }
    );
  }

  ngOnInit(): void {}

  onStrengthChanged(value: number) {
    setTimeout(() => {
      this._passwordStrength = value;
    }, 0);
  }

  onPasswordFocus(focus: boolean) {
    this._passwordFocussed = focus;
  }

  handleSubmit = () => {
    if (this.thirdFormGroup.status === 'VALID') {
      this.loading = true;

      const signupRequest: SignupRequest = {
        firstName: this.firstFormGroup.get('firstName')!.value,
        lastName: this.firstFormGroup.get('lastName')!.value,
        cvr: this.firstFormGroup.get('companyName')!.value,
        phoneNumber: `${
          this.secondFormGroup.get('phoneNumber')!.value.countryCode
        }-${this.secondFormGroup.get('phoneNumber')!.value.number}`,
        email: this.secondFormGroup.get('email')!.value,
        addressRequest: {
          postalCode: this.secondFormGroup.get('postalcode')!.value,
          city: this.secondFormGroup.get('city')!.value,
          street: this.secondFormGroup
            .get('address')!
            .value.slice(
              0,
              this.secondFormGroup.get('address')!.value.indexOf(' ')
            ),
          buildingNumber: this.secondFormGroup
            .get('address')!
            .value.slice(
              this.secondFormGroup.get('address')!.value.indexOf(' ') + 1
            ),
        },
        password: this.thirdFormGroup.get('password')!.value,
        confirmPassword: this.thirdFormGroup.get('confirmPassword')!.value,
      };

      this.authService.signUpUser(signupRequest).subscribe({
        next: () => {
          this._snackBar.open('Registreringen lykkedes', 'Dismiss', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
          this.router.navigate(['/user/login', {}]);
          this.loading = false;
        },
        error: (err) => {
          if (err.error.messageStatus === 'EmailExists') {
            this._snackBar.open('Kontoen eksisterer allerede', 'Dismiss', {
              duration: 3000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
            this.router.navigate(['/user/login', {}]);
          } else
            this._snackBar.open(
              'Server Fejl! Prøv venligst efter et stykke tid',
              'Dismiss',
              {
                duration: 3000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              }
            );
          this.loading = false;
          console.log(err);
        },
      });
    }
  };

  get firstName() {
    return this.firstFormGroup.get('firstName');
  }
  get lastName() {
    return this.firstFormGroup.get('lastName');
  }
  get companyName() {
    return this.firstFormGroup.get('companyName');
  }
  get countryCode() {
    return this.secondFormGroup.get('countryCode');
  }
  get phoneNumber() {
    return this.secondFormGroup.get('phoneNumber');
  }
  get email() {
    return this.secondFormGroup.get('email');
  }
  get address() {
    return this.secondFormGroup.get('address');
  }
  get postalcode() {
    return this.secondFormGroup.get('postalcode');
  }
  get city() {
    return this.secondFormGroup.get('city');
  }
  get password() {
    return this.thirdFormGroup.get('password');
  }
  get confirmPassword() {
    return this.thirdFormGroup.get('confirmPassword');
  }
  get tNC() {
    return this.thirdFormGroup.get('tNC');
  }
}

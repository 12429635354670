<mat-dialog-content class="rounded-lg">
  <form
    #firstForm="ngForm"
    [formGroup]="addAdviserForm"
    (ngSubmit)="_saveChanges()"
  >
    <div class="flex justify-between items-center min-w-[236px]">
      <p class="text-black text-2xl font-semibold leading-[30px] !m-0">
        Rådgiver titel
      </p>
      <icon-close-dialog
        class="cursor-pointer"
        mat-dialog-close
      ></icon-close-dialog>
    </div>
    <mat-form-field class="w-full mt-8" appearance="fill">
      <mat-label>Indtast rådgiver titel</mat-label>
      <input matInput formControlName="tempTitle" />
      <mat-error> Påkrævet </mat-error>
    </mat-form-field>
    <div class="flex items-center gap-4 mt-4">
      <button
        mat-flat-button
        type="submit"
        color="primary"
        class="flex-1 uppercase"
        [ngClass]="{
          'animate-shake': firstForm.invalid && firstForm.submitted
        }"
      >
        Gem
      </button>
      <button
        mat-dialog-close
        class="bg-white border border-solid border-[#D1D8D9] py-[17px] px-5 uppercase rounded-[90px] font-semibold text-black"
      >
        Annuller
      </button>
    </div>
  </form>
</mat-dialog-content>

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}
  jsonHeaders = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    }),
  };

  pdfHeaders = {
    responseType: 'blob' as 'json',
    observe: 'response',
  };

  formDataHeader: {
    'Access-Control-Allow-Origin': '*';
    'Content-Type': undefined;
  };

  private setHeaders(header): HttpHeaders {
    return new HttpHeaders(header);
  }

  private formatErrors(error: any) {
    return throwError(error.body ? error.json() : error);
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http
      .get(path, { headers: this.setHeaders(this.jsonHeaders), params })
      .pipe(
        catchError(this.formatErrors),
        map((res: any) => {
          if (res != null) return res;
          else return of({});
        })
      );
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http.put(path, JSON.stringify(body), this.jsonHeaders).pipe(
      catchError(this.formatErrors),
      map((res) => {
        return res;
      })
    );
  }

  downloadPdfWithoutBodyPOST(path: string) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    headers = headers.set('Access-Control-Allow-Origin', '*');
    return this.http
      .get<any>(path, {
        headers: this.setHeaders(this.pdfHeaders),
      })
      .pipe(
        catchError(this.formatErrors),
        map((res) => res)
      );
  }

  post(path: string, body: any): Observable<any> {
    return this.http.post(path, JSON.stringify(body), this.jsonHeaders).pipe(
      catchError(this.formatErrors),
      map((res) => res)
    );
  }

  postWithoutBody(path: string): Observable<any> {
    return this.http.post(path, this.jsonHeaders).pipe(
      catchError(this.formatErrors),
      map((res) => res)
    );
  }

  postFormData(path: string, body: Object = {}): Observable<any> {
    return this.http
      .post(path, body, { headers: this.setHeaders(this.formDataHeader) })
      .pipe(
        catchError(this.formatErrors),
        map((res) => res)
      );
  }

  delete(path, body?: any): Observable<any> {
    let options: any;
    if (body) {
      options = { headers: this.setHeaders(this.jsonHeaders), body: body };
    } else {
      options = { headers: this.setHeaders(this.jsonHeaders) };
    }
    return this.http.delete(path, options).pipe(catchError(this.formatErrors));
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'icon-caret-right',
  templateUrl: './caret-right.component.html',
  styleUrls: ['./caret-right.component.scss'],
})
export class CaretRightComponent implements OnInit {
  @Input() strokeColor: string = '#24535B';

  constructor() {}

  ngOnInit(): void {}
}

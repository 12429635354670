import {
  FormGroup,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
  FormControl,
} from '@angular/forms';
import { DecimalPipe } from '@angular/common';

export const validateRequiredCheckbox =
  (): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    if (control.value !== true) return { checkBox: 'Required' };
    return null;
  };

export const randomStringGenerator = () =>
  (Math.random() + 1).toString(36).substring(7);

export function patternValidator(
  regex: RegExp,
  error: ValidationErrors
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control.value) {
      // if the control value is empty return no error.
      return null;
    }

    // test the value of the control against the regexp supplied.
    const valid = regex.test(control.value);

    // if true, return no error, otherwise return the error object passed in the second parameter.
    return valid ? null : error;
  };
}

export function ConfirmedValidator(
  controlName: string,
  matchingControlName: string
): ValidatorFn {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (
      matchingControl.errors &&
      !matchingControl.errors['confirmedValidator']
    ) {
      return null;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmedValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

// export function requireMatch(control: FormControl): ValidationErrors | null {
//   const selection: any = control.value;
//   if (this.options && this.options.indexOf(selection) < 0) {
//     return { requireMatch: true };
//   }
//   return null;
// }

export function formatNumber(value: number) {
  let numberpipe = new DecimalPipe('da-DK');
  const parts = value.toString().split('.');
  if (!isNaN(Number(parts[0]))) {
    parts[0] = numberpipe.transform(
      parts[0].replace(/\./g, '').replace(/,/g, ''),
      '1.0-2',
      'da-DK'
    );
  }
  return parts.join(',');
}

export function unformatNumber(value: string) {
  if (value) return +value.replace(/\./g, '').replace(/,/g, '.');
  else return 0;
}

export function roundNumber(value: number) {
  return Math.round(value * 100) / 100;
}

export function ifNaN(value: string) {
  if (!isNaN(unformatNumber(value))) return value;
  else return '';
}

export function ifZero(value: string) {
  if (unformatNumber(value) === 0) return '';
  else return value;
}

export function displaySelectText(list, value) {
  return list.find((list) => list.value === value).text;
}

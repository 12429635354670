import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FrontPageComponent } from './front-page/front-page.component';
import { HomeComponent } from './home.component';
import { ProfileSettingsComponent } from './profile-settings/profile-settings.component';
import { AuthGuard } from 'src/app/_helpers/auth.guard';
import { PropertyComponent } from './properties/property.component';
import { PropertiesLandingPageComponent } from './properties/properties-landing-page/properties-landing-page.component';
import { ViewPropertyComponent } from './properties/view-property/view-property.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    children: [
      {
        path: 'front-page',
        pathMatch: 'full',
        component: FrontPageComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'properties',
        pathMatch: 'full',
        component: PropertiesLandingPageComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'properties/create-property',
        pathMatch: 'full',
        component: PropertyComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'properties/view-property',
        pathMatch: 'full',
        component: ViewPropertyComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'my-profile',
        pathMatch: 'full',
        component: ProfileSettingsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: '**',
        redirectTo: '/home/front-page',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}

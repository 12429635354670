import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { SnackBarService } from 'src/snack-bar.service';
import { IMAGES, IMAGE_UPLOAD_FORM_DATA } from './upload-pictures.util';

@Component({
  selector: 'upload-pictures',
  templateUrl: './upload-pictures.component.html',
  styleUrls: ['./upload-pictures.component.scss'],
})
export class UploadPicturesComponent implements OnInit {
  @Output() prevClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() nextClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  uploadPicturesForm: FormGroup;
  imagesData: IMAGES = IMAGE_UPLOAD_FORM_DATA;
  isErrorsEnabled = new BehaviorSubject(false);
  page3ImageList = [];
  isLoaderEnabled: boolean = false;

  constructor(private fb: FormBuilder, private snackService: SnackBarService) {}

  ngOnInit(): void {
    this.uploadPicturesForm = this.fb.group({
      [this.imagesData[0].header._id]: new FormControl('', [
        Validators.required,
      ]),
    });
  }

  get frontImageHeader() {
    return this.uploadPicturesForm.get(this.imagesData[0].header._id);
  }

  onNextClicked() {
    this.nextClick.emit(true);
  }

  onPrevClicked() {
    this.prevClick.emit(true);
  }

  // Upload file code to list
  onFileChange(pFileList: File[]) {
    this.isLoaderEnabled = true;
    // Get list of files to be uploaded
    let files = Object.keys(pFileList).map((key) => pFileList[key]);
    console.log('total files Pushed', files.length);

    // Push to page3ImageList
    this.page3ImageList.push(...new Set(files));

    // Check If the image has been already uploaded with the same name
    this.page3ImageList = [
      ...new Map(
        this.page3ImageList.map((item) => [item['name'], item])
      ).values(),
    ];

    // Remove unsupported files from list
    // alert('unsupported files');

    console.log('pushed to page3');
    console.log(this.page3ImageList);
    this.isLoaderEnabled = false;
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-caret-left',
  templateUrl: './caret-left.component.html',
  styleUrls: ['./caret-left.component.scss'],
})
export class CaretLeftComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

<div>
  <steps class="property-steps" [steps]="steps"></steps>
  <add-property-data
    *ngIf="currentStep === 1"
    (nextClick)="incrementCurrentStep()"
  ></add-property-data>

  <add-rental-income-data
    *ngIf="currentStep === 2"
    (prevClick)="decrementCurrentStep()"
    (nextClick)="incrementCurrentStep()"
  ></add-rental-income-data>

  <add-expenses
    *ngIf="currentStep === 3"
    (prevClick)="decrementCurrentStep()"
    (nextClick)="incrementCurrentStep()"
  ></add-expenses>

  <description-data
    *ngIf="currentStep === 4"
    (prevClick)="decrementCurrentStep()"
    (nextClick)="incrementCurrentStep()"
  ></description-data>

  <sales-strategy-data
    *ngIf="currentStep === 5"
    (prevClick)="decrementCurrentStep()"
    (nextClick)="incrementCurrentStep()"
  >
  </sales-strategy-data>
  <agreement-data
    *ngIf="currentStep === 6"
    (prevClick)="decrementCurrentStep()"
    (nextClick)="incrementCurrentStep()"
  >
  </agreement-data>
  <summary-data
    *ngIf="currentStep === 7"
    (prevClick)="decrementCurrentStep()"
    (nextClick)="incrementCurrentStep()"
  ></summary-data>
  <payment-data
    *ngIf="currentStep === 8"
    (prevClick)="decrementCurrentStep()"
    (nextClick)="incrementCurrentStep()"
  ></payment-data>
</div>

<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    opacity="0.2"
    d="M4.22838 18.3756C4.25238 18.4742 4.29588 18.567 4.35634 18.6485C4.41679 18.73 4.49296 18.7985 4.58036 18.8501C4.66775 18.9016 4.76459 18.9352 4.86516 18.9487C4.96573 18.9622 5.06798 18.9554 5.16588 18.9287C9.63493 17.6913 14.3562 17.6913 18.8253 18.9287C18.9232 18.9554 19.0254 18.9622 19.126 18.9487C19.2266 18.9352 19.3234 18.9016 19.4108 18.8501C19.4982 18.7985 19.5744 18.73 19.6348 18.6485C19.6953 18.567 19.7388 18.4742 19.7628 18.3756L22.1534 8.22245C22.1862 8.08655 22.1804 7.94418 22.1366 7.81141C22.0928 7.67864 22.0128 7.56076 21.9055 7.47106C21.7983 7.38137 21.6681 7.32344 21.5297 7.3038C21.3912 7.28417 21.2501 7.30362 21.1221 7.35995L16.3784 9.46933C16.2065 9.54501 16.0127 9.55431 15.8343 9.49544C15.656 9.43658 15.5058 9.31371 15.4128 9.15058L12.6565 4.18183C12.5917 4.0646 12.4966 3.96689 12.3812 3.89885C12.2658 3.83081 12.1342 3.79492 12.0003 3.79492C11.8663 3.79492 11.7348 3.83081 11.6194 3.89885C11.5039 3.96689 11.4089 4.0646 11.344 4.18183L8.58776 9.15058C8.49471 9.31371 8.34451 9.43658 8.16618 9.49544C7.98784 9.55431 7.79401 9.54501 7.62214 9.46933L2.87839 7.35995C2.75043 7.30362 2.60927 7.28417 2.47085 7.3038C2.33243 7.32344 2.20225 7.38137 2.09501 7.47106C1.98776 7.56076 1.90772 7.67864 1.86393 7.81141C1.82013 7.94418 1.81431 8.08655 1.84714 8.22245L4.22838 18.3756Z"
    fill="#24535B"
  />
  <path
    d="M4.22839 18.3756C4.25238 18.4742 4.29588 18.567 4.35634 18.6485C4.41679 18.73 4.49296 18.7985 4.58036 18.8501C4.66775 18.9016 4.76459 18.9352 4.86516 18.9487C4.96573 18.9622 5.06798 18.9554 5.16589 18.9287C9.63493 17.6913 14.3562 17.6913 18.8253 18.9287C18.9232 18.9554 19.0254 18.9622 19.126 18.9487C19.2266 18.9352 19.3234 18.9016 19.4108 18.8501C19.4982 18.7985 19.5744 18.73 19.6348 18.6485C19.6953 18.567 19.7388 18.4742 19.7628 18.3756L22.1534 8.22245C22.1862 8.08655 22.1804 7.94418 22.1366 7.81141C22.0928 7.67864 22.0128 7.56076 21.9055 7.47106C21.7983 7.38137 21.6681 7.32344 21.5297 7.3038C21.3912 7.28417 21.2501 7.30362 21.1221 7.35995L16.3784 9.46933C16.2065 9.54501 16.0127 9.55431 15.8343 9.49544C15.656 9.43658 15.5058 9.31371 15.4128 9.15058L12.6565 4.18183C12.5917 4.0646 12.4966 3.96689 12.3812 3.89885C12.2658 3.83081 12.1342 3.79492 12.0003 3.79492C11.8663 3.79492 11.7348 3.83081 11.6194 3.89885C11.5039 3.96689 11.4089 4.0646 11.344 4.18183L8.58776 9.15058C8.49471 9.31371 8.34451 9.43658 8.16618 9.49544C7.98784 9.55431 7.79401 9.54501 7.62214 9.46933L2.87839 7.35995C2.75043 7.30362 2.60927 7.28417 2.47085 7.3038C2.33243 7.32344 2.20225 7.38137 2.09501 7.47106C1.98776 7.56076 1.90772 7.67864 1.86393 7.81141C1.82013 7.94418 1.81431 8.08655 1.84714 8.22245L4.22839 18.3756Z"
    stroke="#24535B"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M9 15.1598C10.9942 14.9441 13.0058 14.9441 15 15.1598"
    stroke="#24535B"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<!-- If part of form group -->

<div
  *ngIf="!isCalculated && mode === 'FORM'"
  class="text-input-container | input-shared-component"
  [formGroup]="group"
>
  <ng-select
    *ngIf="isCountryCode"
    [formControlName]="_id"
    (change)="onChangeCountryCode($event)"
    [clearable]="false"
    [searchable]="false"
  >
    <ng-option *ngFor="let phone of _phoneCodes" [value]="phone.code">
      <span class="!text-black">(</span
      ><span class="visible-selected !text-black">{{ phone?.code }}</span
      ><span class="!text-black">) </span>
      <span class="!text-black">{{ phone?.name }}</span>
    </ng-option>
  </ng-select>
  <div class="text-input-title flex-row-4 mar-btm-4" *ngIf="showLabel">
    <label [for]="_id">{{ title }}</label>
    <p class="text-input-required" *ngIf="isRequired">*</p>
    <span class="info-icon" *ngIf="isInfo"></span>
  </div>
  <div
    *ngIf="!isCountryCode"
    [ngClass]="{
      'text-input': true,
      'text-input-error':
        control?.invalid && isErrorsEnabledLocale && !isPassword
    }"
  >
    <div class="flex flex-row">
      <div class="relative w-full">
        <div
          *ngIf="
            showPasswordValidator &&
            _showError &&
            !control?.hasError('required') &&
            control?.invalid
          "
          class="absolute right-[-274px] top-[-66px] z-10"
          [@fadeInOnEnter]
          [@fadeOutOnLeave]
        >
          <password-validator [control]="control"></password-validator>
        </div>
        <input
          *ngIf="!isTextArea"
          class="focus:ring-2 focus:ring-indigo-500"
          [ngClass]="{
            'remove-border-left': isPhone,
            'remove-border-right': isMetric,
            'text-align-right': isNumber || isPositiveInteger,
            'ring-1 ring-red-500': control?.invalid && isErrorsEnabledLocale
          }"
          tabindex="0"
          userInputSanitizer
          phoneFormatter
          [group]="group"
          [isEmail]="isEmail"
          [isPassword]="isPassword"
          [isAlphaNumeric]="isAlphaNumeric"
          (input)="formatNumber($event)"
          (focusout)="formatNumber($event)"
          [isNumber]="isNumber"
          [isPhone]="isPhone"
          [isPositiveInteger]="isPositiveInteger"
          [isPostalcode]="isPostalcode"
          [control]="control"
          [name]="_id"
          [formControlName]="_id"
          [id]="_id"
          [type]="
            isPassword && !isPasswordVisible
              ? 'password'
              : isDate
              ? 'date'
              : isEmail
              ? 'email'
              : 'text'
          "
          [placeholder]="placeholder"
          [autocomplete]="autoComplete"
          (focus)="onFocus()"
          (blur)="onBlur()"
        />
      </div>
      <textarea
        *ngIf="isTextArea"
        tabindex="0"
        [id]="_id"
        [name]="_id"
        [placeholder]="placeholder"
        [formControlName]="_id"
        [ngStyle]="{ minHeight: textAreaHeight ? textAreaHeight : '5em' }"
      >
      </textarea>

      <label *ngIf="isMetric" id="metric-code" [for]="_id"> {{ unit }}</label>
      <div
        (click)="onPasswordVisibilityToggle()"
        *ngIf="isPassword"
        [ngClass]="{
          'text-input-passwd-toggle': true,
          'text-input-passwd-toggle-active': isPasswordVisible
        }"
      ></div>
    </div>
  </div>

  <!-- general required error message -->
  <error-message
    [isRenderEnabled]="
      control?.invalid && control?.hasError('required') && isErrorsEnabledLocale
    "
  >
    Påkrævet
  </error-message>

  <!-- token minlength error message  -->
  <error-message
    [isRenderEnabled]="
      _id === 'token' &&
      control?.invalid &&
      !control?.hasError('required') &&
      control?.hasError('minlength') &&
      isErrorsEnabledLocale
    "
    >Token skal være på mindst 6 tegn
  </error-message>

  <!-- password confirm password equality error message -->
  <error-message
    [isRenderEnabled]="
      _id === 'confirmPassword' &&
      control?.invalid &&
      !control?.hasError('required') &&
      isErrorsEnabledLocale
    "
    >Adgangskoder stemmer ikke overens
  </error-message>

  <!-- general minlength error message  -->
  <error-message
    [isRenderEnabled]="
      _id !== 'password' &&
      _id !== 'token' &&
      control?.invalid &&
      !control?.hasError('required') &&
      control?.hasError('minlength') &&
      isErrorsEnabledLocale
    "
    >{{ title }}'s længden er for kort
  </error-message>

  <!-- general invalid error message -->
  <error-message
    [isRenderEnabled]="
      control?.invalid &&
      _id !== 'password' &&
      _id !== 'confirmPassword' &&
      !control?.hasError('required') &&
      !control?.hasError('minlength') &&
      isErrorsEnabledLocale
    "
  >
    Indtast en gyldig {{ title }}
  </error-message>
</div>

<!-- If not part of form group -->
<div *ngIf="isCalculated || mode === 'SINGLE'" class="text-input-container">
  <div class="text-input-title flex-row-4 mar-btm-4" *ngIf="showLabel">
    <label [for]="_id">{{ title }}</label>
    <p class="text-input-required" *ngIf="isRequired">*</p>
    <span class="info-icon" *ngIf="isInfo"></span>
  </div>
  <div
    [ngClass]="{
      'text-input': true
    }"
  >
    <div class="flex-row-center">
      <input
        [ngClass]="{
          'remove-border-left': false,
          'remove-border-right': isMetric,
          'background-disabled': isCalculated,
          'text-align-right': isNumber || isPositiveInteger
        }"
        tabindex="0"
        [readonly]="isCalculated"
        [name]="_id"
        [id]="_id"
        value="{{ value }}"
        [type]="
          isPassword && !isPasswordVisible
            ? 'password'
            : isDate
            ? 'date'
            : isEmail
            ? 'email'
            : isNumber && !isCalculated
            ? 'number'
            : 'text'
        "
        min="0"
        [placeholder]="placeholder"
        [autocomplete]="autoComplete"
        (blur)="emitInputValue($event.target.value)"
      />
      <textarea
        *ngIf="isTextArea"
        tabindex="0"
        [id]="_id"
        [name]="_id"
        [placeholder]="placeholder"
        [ngStyle]="{ minHeight: textAreaHeight ? textAreaHeight : '5em' }"
      >
      </textarea>
      <label *ngIf="isMetric" id="metric-code" [for]="_id"> {{ unit }}</label>
    </div>
  </div>
</div>
